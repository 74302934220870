/**
 * Call-Off-Order Form
 * For more information, please see the documentation
 * https://hesusstore.atlassian.net/wiki/spaces/HS/pages/1855815751/6.2.+Call-off+orders+form
 */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import CallOffOrderFormDetails from "./components/CallOffOrderFormDetails";
import FormContainer from "../../shared/components/FormContainer";

import callOffOrderLogo from "../../assets/shared/call-off-order.svg";

import { rejectObj } from "../../shared/utils/methods";

import { selectedJobSiteForRequestCreation } from "../../store/dataStore";
import { handleLogOut } from "../../store/loginData";

import {
  createCalloffOrder,
  getSiteMasterListDataCallOfOrder,
  getRequestMasterDataCallOfOrder,
  handleCallOrderData,
  getTrucks,
} from "./CallOffOrderForm.actionCreators";

const CallOffOrderForm = (props) => {
  const {
    calloffOrderData,
    callOffOrderSuccess,
    newCallOffOrderRequestLoading,
    userId,
    apiUnAuthenticated,
    logOutUser,
    i18n,
    t,
  } = props;

  const [formMetaData, setFormMetaData] = useState({});
  const history = useHistory();

  useEffect(() => {
    let lng = "en";
    if (localStorage.getItem("userCountryId")) {
      const countryIdLocal = localStorage.getItem("userCountryId").toString();
      if (countryIdLocal === "1") {
        lng = "en";
      } else if (countryIdLocal === "2") {
        lng = "fr";
      } else if (countryIdLocal === "3") {
        lng = "pl";
      } else if (countryIdLocal === "6") {
        lng = "de";
      } else if (countryIdLocal === "8") {
        lng = "ch";
      }
    }
    i18n.changeLanguage(lng);
  }, [i18n]);

  useEffect(() => {
    if (userId === null || apiUnAuthenticated) {
      logOutUser();
      history.replace("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, apiUnAuthenticated]);

  useEffect(() => {
    const requiredKeyObj = rejectObj(calloffOrderData, ["comment"]);
    const disabledBtn =
      Object.values(requiredKeyObj).filter((x) => x === "").length !== 0;

    setFormMetaData({ disabledBtn });
  }, [calloffOrderData]);

  // For call off order creation
  useEffect(() => {
    if (callOffOrderSuccess.status === 200) {
      localStorage.removeItem("callOffOrderData");
      localStorage.removeItem("callOffOrderJobsite");
      history.push("/dashboard");
    }
  }, [callOffOrderSuccess, history]);

  const onSubmit = () => {
    props.createCalloffOrder(calloffOrderData);
  };

  return (
    <FormContainer
      icon={callOffOrderLogo}
      isLoading={newCallOffOrderRequestLoading}
      onSubmit={onSubmit}
      isSubmitBtnDisabled={formMetaData.disabledBtn}
      title={t("CLIENT_PLATFORM_CALL_OFF_ORDER_FORM_TITLE")}
      description={t("CLIENT_PLATFORM_CALL_OFF_ORDER_FORM_DESCRIPTION")}
      submitBtnLabel={t("CLIENT_PLATFORM_CALL_OFF_ORDER_FORM_BUTTON_SAVE")}
      onCancel={() => {
        localStorage.removeItem("callOffOrderData");
        localStorage.removeItem("callOffOrderJobsite");
        props.history.goBack();
      }}
    >
      <CallOffOrderFormDetails
        selectedJobSite={props.selectedJobSite}
        transport={props.transport}
        siteMasterData={props.siteMasterData}
        requestMasterData={props.requestMasterData}
        data={props.calloffOrderData}
        callOffOrderSuccess={props.callOffOrderSuccess}
        isSiteMasterListLoading={props.isSiteMasterListLoading}
        isRequestMasterListLoading={props.isRequestMasterListLoading}
        handleCallOrderData={props.handleCallOrderData}
        getSiteMasterListDataCallOfOrder={props.getSiteMasterListDataCallOfOrder}
        getRequestListData={props.getRequestListData}
        selectedJobSiteForRequestCreation={props.selectedJobSiteForRequestCreation}
        getTrucks={props.getTrucks}
      />
    </FormContainer>
  );
};

CallOffOrderForm.propTypes = {
  t: PropTypes.func.isRequired,
  calloffOrderData: PropTypes.object,
  callOffOrderSuccess: PropTypes.object.isRequired,
  newCallOffOrderRequestLoading: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  apiUnAuthenticated: PropTypes.bool.isRequired,
  logOutUser: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    calloffOrderData: state.callOffOrderForm.calloffOrderForm,
    callOffOrderSuccess: state.callOffOrderForm.callOffOrderSuccess,
    newCallOffOrderRequestLoading:
      state.callOffOrderForm.newCallOffOrderRequestLoading,
    selectedJobSite: state.callOffOrderForm.selectedJobSiteForRequest,
    transport: state.callOffOrderForm.trucks,
    siteMasterData: state.callOffOrderForm.siteMasterCallOffOrderData,
    requestMasterData: state.callOffOrderForm.requestMasterData,
    userId:
      localStorage.getItem("userId") !== "" ? localStorage.getItem("userId") : "",
    apiUnAuthenticated: state.callOffOrderForm.apiUnAuthenticated,
    isSiteMasterListLoading: state.callOffOrderForm.isSiteMasterListLoading,
    isRequestMasterListLoading: state.callOffOrderForm.isRequestMasterListLoading,
  };
};

const mapDispatchToProps = {
  createCalloffOrder: (payload) => createCalloffOrder(payload),
  handleCallOrderData: (payload) => handleCallOrderData(payload),
  getSiteMasterListDataCallOfOrder: () => getSiteMasterListDataCallOfOrder(),
  getRequestListData: (payload) => getRequestMasterDataCallOfOrder(payload),
  selectedJobSiteForRequestCreation: (payload) =>
    selectedJobSiteForRequestCreation(payload),
  getTrucks: (payload) => getTrucks(payload),
  logOutUser: () => handleLogOut(),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CallOffOrderForm));
