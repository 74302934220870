import axios from "axios";

import baseURL from "../../shared/utils/constant";
import { getStorageInfo } from "../../shared/utils/methods";

const axiosInstance = (token) => {
  return axios.create({
    baseURL,
    timeout: 36000,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createCallOfOrderAPI = (data) => {
  const userData = getStorageInfo();
  return axiosInstance(userData.token)
    .post(`${baseURL}/trucks/order`, data)
    .then((res) => res.data);
};

export const getSiteMasterDataCallOfOrderAPI = () => {
  const userData = getStorageInfo();
  return axiosInstance(userData.token)
    .get(`${baseURL}/sites/master/callOfOrder/list`)
    .then((res) => res.data);
};

export const getRequestMasterDataCallOfOrderAPI = (id) => {
  const userData = getStorageInfo();
  return axiosInstance(userData.token)
    .get(`${baseURL}/sites/${id}/requests/master/callOfOrder/list`)
    .then((res) => res.data);
};

export const getTransportDataAPI = () => {
  const userData = getStorageInfo();
  return axiosInstance(userData.token)
    .get(`${baseURL}/trucks`)
    .then((res) => res.data);
};
