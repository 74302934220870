import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import soilImg from "../../../assets/shared/soil.png";
import removalImg from "../../../assets/shared/removal.svg";
import supplyImg from "../../../assets/shared/supply.svg";
import materialImg from "../../../assets/shared/material.png";
import trashImg from "../../../assets/shared/trash.png";
import materialNatural from "../../../assets/shared/material-natural.png";
import materialRecycle from "../../../assets/shared/material-recycle.png";
import pollutedSoil from "../../../assets/shared/polluted-soil.png";
import unpollutedSoil from "../../../assets/shared/unpolluted-soil.png";
import notSureImg from "../../../assets/shared/not-sure.png";
import hazardousImg from "../../../assets/shared/hazardous.png";
import nonHazardousImg from "../../../assets/shared/non-hazardous.png";
import asbestosImg from "../../../assets/shared/asbestos.png";

import "./RequestCategorySelection.scss";

const RequestCategorySelection = ({
  onMount,
  selectionRef,
  data,
  labelName,
  altName,
  selectCategory,
  onChange,
  name,
  selectedId,
  disabled,
  t,
}) => {
  const [requestOptions, setRequestOptions] = useState([]);

  const getCategoryImg = (imgName) => {
    if (imgName.indexOf("removal") > -1) {
      return removalImg;
    }
    if (imgName.indexOf("supply") > -1) {
      return supplyImg;
    }
    if (imgName.indexOf("unpolluted-soil") > -1) {
      return unpollutedSoil;
    }
    if (imgName.indexOf("polluted-soil") > -1) {
      return pollutedSoil;
    }
    if (imgName.indexOf("soil") > -1) {
      return soilImg;
    }
    if (imgName.indexOf("material-natural") > -1) {
      return materialNatural;
    }
    if (imgName.indexOf("material-recycle") > -1) {
      return materialRecycle;
    }
    if (imgName.indexOf("material") > -1) {
      return materialImg;
    }
    if (imgName.indexOf("trash") > -1) {
      return trashImg;
    }
    if (imgName.indexOf("non-hazardous") > -1) {
      return nonHazardousImg;
    }
    if (imgName.indexOf("hazardous") > -1) {
      return hazardousImg;
    }
    if (imgName.indexOf("asbestos") > -1) {
      return asbestosImg;
    }
    return notSureImg;
  };

  const getCategoryName = (imgName) => {
    if (imgName.indexOf("removal") > -1) {
      return "CLIENT_PLATFORM_REQUEST_FORM_SERVICE_REMOVAL";
    }
    if (imgName.indexOf("supply") > -1) {
      return "CLIENT_PLATFORM_REQUEST_FORM_SERVICE_SUPPLY";
    }
    if (imgName.indexOf("unpolluted-soil") > -1) {
      return "CLIENT_PLATFORM_REQUEST_FORM_SUB_CATEGORY_INERT_SOIL";
    }
    if (imgName.indexOf("polluted-soil") > -1) {
      return "CLIENT_PLATFORM_REQUEST_FORM_SUB_CATEGORY_CONTAMINATED_SOIL";
    }
    if (imgName.indexOf("soil") > -1) {
      return "CLIENT_PLATFORM_REQUEST_FORM_CATEGORY_SOIL";
    }
    if (imgName.indexOf("material-natural") > -1) {
      return "CLIENT_PLATFORM_REQUEST_FORM_SUB_CATEGORY_PRIMARY_MATERIAL";
    }
    if (imgName.indexOf("material-recycle") > -1) {
      return "CLIENT_PLATFORM_REQUEST_FORM_SUB_CATEGORY_RECYCLED_MATERIAL";
    }
    if (imgName.indexOf("material") > -1) {
      return "CLIENT_PLATFORM_REQUEST_FORM_CATEGORY_MATERIAL";
    }
    if (imgName.indexOf("trash") > -1) {
      return "CLIENT_PLATFORM_REQUEST_FORM_CATEGORY_WASTE";
    }
    if (imgName.indexOf("non-hazardous") > -1) {
      return "CLIENT_PLATFORM_REQUEST_FORM_SUB_CATEGORY_NON_HAZARDOUS_WASTE";
    }
    if (imgName.indexOf("hazardous") > -1) {
      return "CLIENT_PLATFORM_REQUEST_FORM_SUB_CATEGORY_HAZARDOUS_WASTE";
    }
    if (imgName.indexOf("asbestos") > -1) {
      return "CLIENT_PLATFORM_REQUEST_FORM_SUB_CATEGORY_ASBESTOS";
    }
    return "CLIENT_PLATFORM_REQUEST_FORM_SUB_CATEGORY_I_AM_NOT_SURE";
  };

  useEffect(() => {
    setRequestOptions(data);
  }, [data]);

  useEffect(() => {
    onMount?.(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="category-selection-container" ref={selectionRef}>
      <h2 className="gray-text fontLato16">{labelName}</h2>
      <ul
        key={labelName + name}
        className={`request-category-items type-enquiry ${
          disabled ? "disabled" : ""
        }`}
      >
        {requestOptions.length > 0 &&
          requestOptions.map((item, index) => {
            if (Object.prototype.hasOwnProperty.call(item, "sub_category_id")) {
              return (
                <li
                  className={`position-relative d-inline-block crsr-ptr ${
                    name === "subcategory"
                      ? selectedId.subcategory === item.sub_category_id
                        ? "active"
                        : ""
                      : ""
                  } ${disabled ? "disabled" : ""}`}
                  key={item.sub_category_id + index + item.sub_category.name}
                  onClick={(e) => {
                    selectCategory(item.sub_category_id);
                    onChange(e, item.sub_category.name, item.sub_category_id);
                  }}
                  name={name}
                >
                  {item.sub_category && item.sub_category.image !== "" && (
                    <img
                      className="d-block m-auto"
                      src={getCategoryImg(item.sub_category.image)}
                      alt={altName}
                      width="64"
                      height="64"
                    />
                  )}
                  <span className="gray-darker-text fontLato16 d-block text-center">
                    {t(getCategoryName(item.sub_category.image))}
                  </span>
                  <span className="circle-thin position-absolute gray-darker-text m-0" />
                </li>
              );
            }
            return (
              <li
                className={`position-relative d-inline-block crsr-ptr ${
                  Object.prototype.hasOwnProperty.call(item, "products")
                    ? selectedId.subcategory === item.id
                      ? "active"
                      : ""
                    : ""
                } ${
                  name === "category"
                    ? selectedId.category === item.id
                      ? "active"
                      : ""
                    : selectedId.service === item.id
                    ? "active"
                    : ""
                } ${disabled ? "disabled" : ""}`}
                key={item.id + index + item.name}
                onClick={(e) => {
                  selectCategory(item.id);
                  onChange(e, item.name, item.id);
                }}
                name={name}
              >
                {item.image !== "" && (
                  <img
                    className="d-block m-auto"
                    src={getCategoryImg(item.image)}
                    alt={altName}
                    width="64"
                    height="64"
                  />
                )}
                <span className="gray-darker-text fontLato16 d-block text-center">
                  {t(getCategoryName(item.image))}
                </span>
                <span className="circle-thin position-absolute gray-darker-text m-0" />
              </li>
            );
          })}
      </ul>
    </div>
  );
};

RequestCategorySelection.propTypes = {
  labelName: PropTypes.string,
  altName: PropTypes.string,
  name: PropTypes.string,
  selectedId: PropTypes.shape({}),
  disabled: PropTypes.bool,
  selectionRef: PropTypes.shape({}),
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onMount: PropTypes.func,
  selectCategory: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

RequestCategorySelection.defaultProps = {
  labelName: "",
  altName: "",
  name: "",
  selectedId: null,
  disabled: false,
  selectionRef: null,
  onMount: null,
};

export default withTranslation()(RequestCategorySelection);
