import { isEmpty, rejectObj } from "../../shared/utils/methods";
import { ADDRESS_NOT_FOUND } from "../../shared/utils/constant";

export const validateOnboardingForm = (data, currentStep) => {
  if (data && !isEmpty(data)) {
    let requiredFields;
    if (currentStep === 0) {
      requiredFields = rejectObj(data, [
        "city",
        "quantity",
        "unit",
        "start_date",
        "client_type",
        "email",
        "isCallForTender",
        "categoryFormData",
        "countryId",
        "phone",
        "postal",
        "maxHour",
        "minHour",
        "comment",
        "category",
        "selectedItems",
        "selectedService",
        "subCategory",
        "waste_quantity",
        "products",
        "documents",
        "uploadDocs",
        "jobsite_comment",
        "first_name",
        "last_name",
        "company_name",
        "company_reg",
        "usage_id",
        "usage",
        "latitude",
        "longitude",
        "categoryForm",
      ]);
    } else {
      requiredFields = rejectObj(data, [
        "address",
        "quantity",
        "categoryFormData",
        "unit",
        "start_date",
        "first_name",
        "last_name",
        "email",
        "phone",
        "company_name",
        "countryId",
        "isCallForTender",
        "postal",
        "maxHour",
        "minHour",
        "comment",
        "category",
        "selectedItems",
        "selectedService",
        "subCategory",
        "waste_quantity",
        "products",
        "documents",
        "uploadDocs",
        "jobsite_comment",

        "company_reg",
        "usage_id",
        "usage",
        "latitude",
        "longitude",
        "categoryForm",
      ]);
    }
    const emptyFields = Object.values(requiredFields).filter(
      (field) => !field.value || (field.value !== "" && field.error !== "")
    );
    if (
      (emptyFields.length === 0 && data.categoryForm) ||
      (emptyFields.length === 0 && data.client_type?.value)
    ) {
      return true;
    }
    return false;
  }
  return false;
};

export const getFormattedTime = (time) => {
  if (time < 10) {
    if (time - parseInt(time, 10) === 0.5) {
      return `0${parseInt(time, 10)}:30`;
    }
    return `0${time}:00`;
  }
  if (time - parseInt(time, 10) === 0.5) {
    return `${parseInt(time, 10)}:30`;
  }
  return `${time}:00`;
};

export const getFormData = (data, token, form) => {
  const pathNameSplit = window.location.pathname.split("/");
  const campaignCode = pathNameSplit[pathNameSplit.length - 1];

  const formData = new FormData(form);
  formData.delete("acceptance");
  formData.delete("first_name");
  formData.delete("last_name");
  formData.delete("phone");
  formData.delete("email");
  formData.delete("company_name");
  formData.append(
    "address",
    data.address.value === ADDRESS_NOT_FOUND ? null : data.address.value
  );
  formData.append("postal", data.postal.value);
  formData.append("city", data.city.value);
  formData.append("latitude", data.latitude.value);
  formData.append("longitude", data.longitude.value);
  formData.append("country_id", getCountryCode(data.countryId.value));
  formData.append("service", data.selectedItems.service);
  formData.append("sub_category_id", data.selectedItems.subcategory);
  formData.append("quantity", data.quantity.value);
  formData.append("unit", data.unit.value);
  formData.append("start_date", data.start_date.value);
  formData.append("opening_time", getFormattedTime(data.minHour.value));
  formData.append("closing_time", getFormattedTime(data.maxHour.value));
  formData.append("recaptcha_token", token);
  formData.append("client_type", data.client_type.value);

  if (data.isCallForTender?.value) {
    formData.append("is_call_for_tender", 1);
  } else {
    formData.append("is_call_for_tender", 0);
  }

  if (data.jobsite_comment?.value) {
    formData.append("jobsite_comment", data.jobsite_comment.value);
  }

  data.documents?.value.forEach((x) => {
    if (x.file) {
      // eslint-disable-next-line no-restricted-syntax
      formData.append("document[][type]", x.type);
      formData.append("document[][file]", x.file);
    }
  });

  if (data.waste_quantity?.value) {
    formData.append("waste_quantity", parseInt(data.waste_quantity.value, 10));
  }
  if (data.usage_id?.value) {
    formData.append("usage_id", data.usage_id.value);
  }
  // HS-1224
  if (campaignCode && campaignCode !== "onboarding") {
    formData.append("campaign_code", campaignCode);
  }

  formData.append(
    "contact",
    JSON.stringify({
      phone: data.phone.value,
      email: data.email.value,
      first_name: data.first_name.value,
      last_name: data.last_name.value,
    })
  );

  formData.append(
    "company",
    JSON.stringify({
      name: data.company_name.value,
      country_id: getCountryCode(data.company_reg.value),
    })
  );

  return formData;
};

export const getCountryCode = (hereCode) => {
  switch (hereCode) {
    case "GBR":
      return 1;
    case "FRA":
      return 2;
    case "POL":
      return 3;
    case "DEU":
      return 6;
    case "CHE":
      return 8;
    default:
  }
};
