export const CREATE_CALL_OF_ORDER = {
  LOADING: "CREATE_CALL_OF_ORDER_LOADING",
  SUCCESS: "CREATE_CALL_OF_ORDER_SUCCESS",
  ERROR: "CREATE_CALL_OF_ORDER_FAILURE",
};

export const GET_SITE_MASTER_LIST_DATA_CALL_OF_ORDER = {
  LOADING: "GET_SITE_MASTER_LIST_DATA_CALL_OF_ORDER_LOADING",
  SUCCESS: "GET_SITE_MASTER_LIST_DATA_CALL_OF_ORDER_SUCCESS",
  ERROR: "GET_SITE_MASTER_LIST_DATA_CALL_OF_ORDER_FAILURE",
};

export const GET_REQUEST_MASTER_LIST_DATA_CALL_OF_ORDER = {
  LOADING: "GET_REQUEST_MASTER_LIST_DATA_CALL_OF_ORDER_LOADING",
  SUCCESS: "GET_REQUEST_MASTER_LIST_DATA_CALL_OF_ORDER_SUCCESS",
  ERROR: "GET_REQUEST_MASTER_LIST_DATA_CALL_OF_ORDER_FAILURE",
};

export const GET_TRANSPORT_TRUCKS = {
  LOADING: "GET_TRANSPORT_TRUCKS_LOADING",
  SUCCESS: "GET_TRANSPORT_TRUCKS_SUCCESS",
  ERROR: "GET_TRANSPORT_TRUCKS_FAILURE",
};

export const CREATE_CALL_OF_ORDER_FORM = "CREATE_CALL_OF_ORDER_FORM";
export const HANDLE_CALLOFF_DATA = "HANDLE_CALLOFF_DATA";
export const EDIT_CALL_OF_ORDER_FORM = "EDIT_CALL_OF_ORDER_FORM";
