import React from "react";

import Navbar from "../../shared/components/NavBar";
import ViewSitesList from "../../shared/components/ViewSitesList";

const SitesList = () => {
  return (
    <div className="container-fluid dashboard-container">
      <Navbar />
      <div id="content">
        <ViewSitesList />
      </div>
    </div>
  );
};

export default SitesList;
