export const GET_SITE_LISTING_DATA = {
  LOADING: "GET_SITE_LISTING_DATA_LOADING",
  SUCCESS: "GET_SITE_LISTING_DATA_SUCCESS",
  ERROR: "GET_SITE_LISTING_DATA_FAILURE",
};

export const GET_SITE_LISTING_INACTIVE_DATA = {
  LOADING: "GET_SITE_LISTING_INACTIVE_DATA_LOADING",
  SUCCESS: "GET_SITE_LISTING_INACTIVE_DATA_SUCCESS",
  ERROR: "GET_SITE_LISTING_INACTIVE_DATA_FAILURE",
};
