import React, { useEffect, useState } from "react";
import * as moment from "moment";
import { withTranslation } from "react-i18next";
import { Loader } from "@googlemaps/js-api-loader";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import PropTypes from "prop-types";

import { storage } from "../../../../store/dataStore/api";

import TextInput from "../../../../shared/baseUI/TextInput";
import SelectInput from "../../../../shared/baseUI/CustomSelectBox";
import TextAreaInput from "../../../../shared/baseUI/TextAreaInput";
import FileInputGroup from "../../../../shared/baseUI/FileInputGroup";
import ErrorPopUp from "../../../../shared/baseUI/ErrorPopUp";
import ChangeMapCenter from "../ChangeMapCenter";
import CustomDatePicker from "../../../../shared/baseUI/DatePicker";

import {
  prepareDocumentLabel,
  getDurationOptions,
  rejectObj,
} from "../../../../shared/utils/methods";

import "./JobsiteDetails.scss";

const JobsiteDetails = (props) => {
  const {
    siteHrsOptions,
    data,
    selectedCountryId,
    handleSiteFormDetails,
    countryId,
    t,
  } = props;

  const [mapsLoader, setMapsLoader] = useState();
  const [isAddressEdit, setEditStatus] = useState(false);
  const [mapAddress, setMapAddress] = useState({});
  const [fields, setFormFields] = useState({
    request_priority: {
      value: 0,
      error: "",
    },
  });
  const [showErrorPopup, setErrorPopupvisibility] = useState(false);
  const [apiError, setApiError] = useState("");

  const documentNames = prepareDocumentLabel([
    {
      type: 1,
      name: t("CHEMICAL_ANALYSIS"),
      accept: ".doc, .docx, .xls, .xlsx, .ppt, .pptx, .msg, .pdf, .jpg, .jpeg, .png",
      allowedFileTypes: [
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.ms-outlook",
        "image/jpg",
        "image/jpeg",
        "image/png",
        "application/pdf",
      ],
    },
    {
      type: 2,
      name: t("ANALYSE_GTR"),
      accept: ".doc, .docx, .xls, .xlsx, .ppt, .pptx, .msg, .pdf, .jpg, .jpeg, .png",
      allowedFileTypes: [
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.ms-outlook",
        "image/jpg",
        "image/jpeg",
        "image/png",
        "application/pdf",
      ],
    },
    {
      type: 3,
      name: t("OTHER_DOCUMENT"),
      accept: ".doc, .docx, .xls, .xlsx, .ppt, .pptx, .msg, .pdf, .jpg, .jpeg, .png",
      allowedFileTypes: [
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.ms-outlook",
        "image/jpg",
        "image/jpeg",
        "image/png",
        "application/pdf",
      ],
    },
    {
      type: 4,
      name: t("SPECIFICATIONS"),
      accept: ".doc, .docx, .xls, .xlsx, .ppt, .pptx, .msg, .pdf, .jpg, .jpeg, .png",
      allowedFileTypes: [
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.ms-outlook",
        "image/jpg",
        "image/jpeg",
        "image/png",
        "application/pdf",
      ],
    },
  ]);

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS,
      version: "weekly",
    });
    setMapsLoader(loader);
    if (data.edit) {
      localStorage.setItem("isExistingJobsiteEdit", JSON.stringify(data.edit));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.stage.length === 0) {
      storage();
      props.getCategoriesData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let lng = "fr";
    if (localStorage.getItem("userCountryId")) {
      const countryIdLocal = localStorage.getItem("userCountryId").toString();
      if (countryIdLocal === "1") {
        lng = "en";
      } else if (countryIdLocal === "2") {
        lng = "fr";
      } else if (countryIdLocal === "3") {
        lng = "pl";
      } else if (countryIdLocal === "8") {
        lng = "ch";
      }
    }
    props.i18n.changeLanguage(lng);
  }, [props.i18n]);

  useEffect(() => {
    if (mapsLoader && !isAddressEdit) {
      mapsLoader.load().then(() => {
        const geocoder = new window.google.maps.Geocoder();
        if (mapAddress !== "") {
          geocoder.geocode(
            {
              address: mapAddress,
            },
            function (results, status) {
              if (status === "OK") {
                const lat = results[0].geometry.location.lat();
                const lang = results[0].geometry.location.lng();
                const fieldsLocal = { ...fields };
                fieldsLocal.latitude.value = lat;
                fieldsLocal.longitude.value = lang;
                setFormFields(fieldsLocal);
                updateFormDetails(fieldsLocal);
              }
            }
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapsLoader, mapAddress, data, isAddressEdit, handleSiteFormDetails]);

  useEffect(() => {
    const closingTime = data.error?.data?.closing_time[0];
    const fieldsLocal = { ...fields };

    if (fieldsLocal.closing_time) {
      if (closingTime) {
        fieldsLocal.closing_time.error = t(
          "CLIENT_PLATFORM_SITE_FORM_SITE_CLOSING_HOUR_WRONG_VALUE"
        );
        setFormFields(fieldsLocal);
      } else if (fieldsLocal.closing_time.error !== "") {
        fieldsLocal.closing_time.error = "";
        setFormFields(fieldsLocal);
      }
    }
    if (data.error?.message) {
      setErrorPopupvisibility(true);
      setApiError(data.error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.error, t]);

  useEffect(() => {
    if (fields.closing_time?.value && fields.opening_time?.value) {
      const isTimeAfter = moment(
        `${"2021-01-01"} ${fields.opening_time.value}`
      ).isAfter(moment(`${"2021-01-01"} ${fields.closing_time.value}`));
      const fieldsLocal = { ...fields };
      if (isTimeAfter) {
        fieldsLocal.closing_time.error = t(
          "CLIENT_PLATFORM_SITE_FORM_SITE_CLOSING_HOUR_WRONG_VALUE"
        );
        props.updateFormValidity(false);
      } else {
        fieldsLocal.closing_time.error = "";
      }
      setFormFields(fieldsLocal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.closing_time, fields.opening_time, t]);

  useEffect(() => {
    if (!data.error) {
      updateFormFields(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const updateFormFields = (siteData) => {
    const fieldsLocal = { ...fields };

    fieldsLocal.address = {
      value: siteData.address,
      error: "",
    };

    fieldsLocal.request_priority = {
      value: siteData.request_priority,
      error: "",
    };

    fieldsLocal.duration = {
      value: siteData.duration,
      error: "",
    };

    fieldsLocal.city = {
      value: siteData.city,
      error: "",
    };

    fieldsLocal.closing_time = {
      value: siteData.closing_time,
      error: "",
    };

    fieldsLocal.user_comment = {
      value: siteData.user_comment ? siteData.user_comment : "",
      error: "",
    };

    fieldsLocal["other-info-comment"] = {
      value: siteData["other-info-comment"] ? siteData["other-info-comment"] : "",
      error: "",
    };

    fieldsLocal.document = {
      value: siteData.document,
      error: "",
    };

    fieldsLocal.initialRow = {
      value: siteData.initialRow,
      error: "",
    };

    fieldsLocal.is_call_for_tender = {
      value: siteData.is_call_for_tender,
      error: "",
    };

    fieldsLocal.name = {
      value: siteData.name,
      error: "",
    };

    fieldsLocal.opening_time = {
      value: siteData.opening_time,
      error: "",
    };

    fieldsLocal.phase_id = {
      value: siteData.phase_id,
      error: "",
    };

    fieldsLocal.postal = {
      value: siteData.postal,
      error: "",
    };

    fieldsLocal.related_contact = {
      value: siteData.related_contact,
      error: "",
    };

    fieldsLocal.start_date = {
      value: siteData.start_date,
      error: "",
    };

    fieldsLocal.latitude = {
      value: siteData.latitude,
      error: "",
    };

    fieldsLocal.longitude = {
      value: siteData.longitude,
      error: "",
    };

    setFormFields(fieldsLocal);
  };

  const onChange = (e) => {
    const date = new Date(e);
    if (date.toDateString() !== "Invalid Date") {
      const validField = isDateValid(e);
      if (validField) {
        setFormFields({
          ...fields,
          start_date: validField,
        });
        localStorage.setItem(
          "jobsiteEditData",
          JSON.stringify({
            ...fields,
            start_date: validField,
          })
        );

        updateFormDetails({
          ...fields,
          start_date: validField,
        });
      }
    } else {
      const { name } = e.target ? e.target : e;
      const { value } = e.target ? e.target : e;
      const { checked } = e.target ? e.target : false;
      const requestPriority = e.target ? e.target.dataset.val : "";
      validateField(name, value, checked, requestPriority);
    }
    setEditStatus(true);
    props.isJobsiteFormEdited(true);
  };

  const validateField = (name, value, checked, requestPriority) => {
    const fieldsLocal = { ...fields };

    switch (name) {
      case "name":
        fieldsLocal[name] = {
          value,
          error: isValidName(name, value),
        };
        break;
      case "phase_id":
        fieldsLocal[name] = {
          value,
          error:
            value.length === 0
              ? "CLIENT_PLATFORM_SITE_FORM_SITE_PHASE_MISSING_ERROR"
              : "",
        };
        break;
      case "opening_time":
        fieldsLocal[name] = {
          value,
          error:
            value.length === 0
              ? "CLIENT_PLATFORM_SITE_FORM_SITE_OPENING_HOUR_MISSING_ERROR"
              : "",
        };
        break;
      case "closing_time":
        fieldsLocal[name] = {
          value,
          error:
            value.length === 0
              ? "CLIENT_PLATFORM_SITE_FORM_SITE_CLOSING_HOUR_MISSING_ERROR"
              : "",
        };
        break;
      case "address":
        fieldsLocal[name] = {
          value,
          error:
            value.length === 0
              ? "CLIENT_PLATFORM_SITE_FORM_SITE_ADDRESS_MISSING_ERROR"
              : "",
        };
        break;
      case "city":
        fieldsLocal[name] = {
          value,
          error: isCityValid(value),
        };
        break;
      case "is_call_for_tender":
        fieldsLocal[name] = {
          value: checked ? 1 : 0,
          error: "",
        };
        break;
      case "request_priority":
        fieldsLocal[name] = {
          value: requestPriority,
          error: "",
        };
        break;
      case "start_date":
        fieldsLocal[name] = {
          value,
          error: "",
        };
        break;
      case "duration":
        fieldsLocal[name] = {
          value,
          error:
            value.length === 0
              ? "CLIENT_PLATFORM_SITE_FORM_SITE_DURATION_MISSING_ERROR"
              : "",
        };
        break;
      case "postal":
        fieldsLocal[name] = {
          value: getPostalCode(value),
          error: isValidPostalCode(value),
        };
        break;
      case "user_comment":
        fieldsLocal[name] = {
          value,
          error: "",
        };
        break;
      case "other-info-comment":
        fieldsLocal[name] = {
          value,
          error: "",
        };
        break;

      default:
        fieldsLocal[name] = {
          value,
          error: "",
        };
    }

    if (
      fieldsLocal.address.value !== "" &&
      fieldsLocal.city.value !== "" &&
      fieldsLocal.postal.value !== ""
    ) {
      setMapAddress(
        fieldsLocal.address.value +
          fieldsLocal.city.value +
          fieldsLocal.postal.value.replace("-", "")
      );
    } else {
      setMapAddress("");
    }

    setFormFields(fieldsLocal);
    localStorage.setItem("jobsiteEditData", JSON.stringify(fieldsLocal));

    updateFormDetails(fieldsLocal);
  };

  const updateFormDetails = (formFields) => {
    const requiredFields = rejectObj(formFields, [
      "other-info-comment",
      "user_comment",
      "is_call_for_tender",
      "latitude",
      "longitude",
    ]);
    const errorFields = Object.values(requiredFields).filter(
      (field) => field.error !== ""
    );

    const emptyFields = Object.values(requiredFields).filter(
      (field) => field.value === ""
    );
    if (errorFields.length === 0 && emptyFields.length === 0) {
      props.updateFormValidity(true);
      updateSiteDetails(formFields);
    } else {
      props.updateFormValidity(false);
    }
  };

  const updateSiteDetails = (details) => {
    const dataLocal = { ...data };
    dataLocal.name = details.name.value;
    dataLocal.phase_id = details.phase_id.value;
    dataLocal.opening_time = details.opening_time.value;
    dataLocal.closing_time = details.closing_time.value;
    dataLocal.address = details.address.value;
    dataLocal.city = details.city.value;
    dataLocal.postal = details.postal.value;
    dataLocal.request_priority = details.request_priority.value;
    dataLocal.is_call_for_tender = details.is_call_for_tender.value;
    dataLocal.user_comment = details.user_comment.value;
    dataLocal["other-info-comment"] = details["other-info-comment"]
      ? details["other-info-comment"].value
      : "";
    dataLocal.start_date = details.start_date.value;
    dataLocal.duration = details.duration.value;
    dataLocal.end_date = details.duration.value;
    dataLocal.latitude = details.latitude?.value ? details.latitude?.value : "";
    dataLocal.longitude = details.longitude?.value ? details.longitude?.value : "";
    dataLocal.document = details.document.value;

    handleSiteFormDetails({ updatedData: dataLocal });
  };

  const isDateValid = (value) => {
    const start_date = {};
    const date = value.getDate() < 10 ? `0${value.getDate()}` : value.getDate();
    const month =
      value.getMonth() + 1 < 10 ? `0${value.getMonth() + 1}` : value.getMonth() + 1;
    start_date.value = `${value.getFullYear()}-${month}-${date}`;
    start_date.error = "";
    if (value === "") {
      start_date.error = "CLIENT_PLATFORM_SITE_FORM_SITE_START_DATE_MISSING_ERROR";
    }
    return start_date;
  };

  const isValidName = (name, value) => {
    if (name === "name") {
      if (value.length === 0) {
        return "CLIENT_PLATFORM_SITE_FORM_SITE_NAME_MISSING_ERROR";
      }
    }
    return "";
  };

  const getPostalCode = (postalCode) => {
    if (
      props.selectedCountryId === 1 ||
      props.selectedCountryId === 2 ||
      props.selectedCountryId === 8 ||
      props.selectedCountryId === 6
    ) {
      return postalCode;
    }
    // For poland format as XX-XXX
    if (props.selectedCountryId === 3) {
      let str = postalCode;
      str = str.replace("-", "");
      if (str.length > 2) {
        str = `${str.substring(0, 2)}-${str.substring(2)}`;
      }
      return str;
    }
  };

  const isValidPostalCode = (postalCode) => {
    if (postalCode === "") {
      return "CLIENT_PLATFORM_SITE_FORM_SITE_POSCODE_MISSING_ERROR";
    }
    if (selectedCountryId === 1) {
      // en
      if (postalCode.length < 1 || postalCode.length > 8) {
        return "CLIENT_PLATFORM_SITE_FORM_SITE_POSCODE_FORMAT_ERROR";
      }
    } else if (selectedCountryId === 2 || selectedCountryId === 6) {
      // fr, Germany
      if (postalCode.length !== 5) {
        return "CLIENT_PLATFORM_SITE_FORM_SITE_POSCODE_FORMAT_ERROR";
      }
      if (!/^\d+$/.test(postalCode)) {
        return "CLIENT_PLATFORM_SITE_FORM_SITE_POSCODE_FORMAT_ERROR";
      }
    } else if (selectedCountryId === 3) {
      // pl
      if (postalCode.length !== 6) {
        return "CLIENT_PLATFORM_SITE_FORM_SITE_POSCODE_FORMAT_ERROR";
      }
    } else if (selectedCountryId === 8) {
      // ch
      if (postalCode.length !== 4) {
        return "CLIENT_PLATFORM_SITE_FORM_SITE_POSCODE_FORMAT_ERROR";
      }
    }

    return "";
  };

  const isCityValid = (city) => {
    if (city.length === 0) {
      return "CLIENT_PLATFORM_SITE_FORM_SITE_CITY_MISSING_ERROR";
    }
    // check for number.
    if (/\d/.test(city)) {
      return "CLIENT_PLATFORM_SITE_FORM_SITE_CITY_FORMAT_ERROR";
    }
    return "";
  };

  const delFile = (url) => {
    props.deleteDocument(url);
  };

  const closePopup = () => {
    setErrorPopupvisibility(false);
  };

  return (
    <form className="job-site-form" id="job-siteform">
      <div className="form-section">
        <h6>{t("CLIENT_PLATFORM_SITE_FORM_SITE_INFO_TITLE")}</h6>
        <div className="row">
          <div className="col-12">
            <TextInput
              type="text"
              placeholder={t("CLIENT_PLATFORM_SITE_FORM_SITE_NAME_PLACEHOLDER")}
              inputClass={
                fields.name && fields.name.error !== ""
                  ? "form-input w-100 border border-danger"
                  : "form-input w-100"
              }
              divClass="form-field pb-0 mb-3"
              labelClass="form-label required"
              labelName={t("CLIENT_PLATFORM_SITE_FORM_SITE_NAME")}
              required
              id="name"
              name="name"
              error={fields.name && fields.name.error !== ""}
              errorMsg={fields.name ? t(fields.name.error) : ""}
              value={fields.name ? fields.name.value : ""}
              onChange={(e) => onChange(e)}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-5 ">
                <SelectInput
                  error={fields.phase_id && fields.phase_id.error !== ""}
                  errorMsg={fields.phase_id ? t(fields.phase_id.error) : ""}
                  value={fields.phase_id ? fields.phase_id.value : ""}
                  inputClass={
                    fields.phase_id && fields.phase_id.error !== ""
                      ? "jobsite__form-input border border-danger"
                      : "jobsite__form-input"
                  }
                  divClass="form-field "
                  labelClass="form-label required"
                  required
                  labelName={t("CLIENT_PLATFORM_SITE_FORM_SITE_PHASE")}
                  name="phase_id"
                  defaultOption={t(
                    "CLIENT_PLATFORM_SITE_FORM_SITE_PHASE_PLACEHOLDER"
                  )}
                  onChange={(e) => onChange(e)}
                  options={props.stage}
                />
              </div>
              <div className="col-xs-12 col-sm-12 col-md-7 time-input-container">
                <SelectInput
                  error={fields.opening_time && fields.opening_time.error !== ""}
                  errorMsg={fields.opening_time ? t(fields.opening_time.error) : ""}
                  value={fields.opening_time ? fields.opening_time.value : ""}
                  inputClass={
                    fields.opening_time && fields.opening_time.error !== ""
                      ? "jobsite__form-input border border-danger"
                      : "jobsite__form-input"
                  }
                  divClass="form-field position-relative"
                  labelClass="form-label required"
                  labelName={t("CLIENT_PLATFORM_SITE_FORM_SITE_OPENING_HOURS")}
                  required
                  name="opening_time"
                  onChange={(e) => onChange(e)}
                  defaultOption={t(
                    "CLIENT_PLATFORM_SITE_FORM_SITE_OPENING_HOUR_PLACEHOLDER"
                  )}
                  options={siteHrsOptions}
                />
                <SelectInput
                  error={fields.closing_time && fields.closing_time.error !== ""}
                  errorMsg={fields.closing_time ? t(fields.closing_time.error) : ""}
                  value={fields.closing_time ? fields.closing_time.value : ""}
                  inputClass={
                    fields.closing_time && fields.closing_time.error !== ""
                      ? "jobsite__form-input border border-danger"
                      : "jobsite__form-input"
                  }
                  divClass="form-field closing-time-wrapper"
                  labelClass="form-label required"
                  required
                  name="closing_time"
                  onChange={(e) => onChange(e)}
                  defaultOption={t(
                    "CLIENT_PLATFORM_SITE_FORM_SITE_CLOSING_HOUR_PLACEHOLDER"
                  )}
                  options={siteHrsOptions}
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <label
              className="custom-checkbox tender-checkbox p-2"
              htmlFor="is_call_for_tender"
            >
              <span className="checkbox-label mb-0">
                {t("CLIENT_PLATFORM_SITE_FORM_SITE_CALL_FOR_TENDER")}
              </span>
              <input
                type="checkbox"
                name="is_call_for_tender"
                id="is_call_for_tender"
                value={
                  fields.is_call_for_tender && fields.is_call_for_tender.value
                    ? fields.is_call_for_tender.value
                    : 0
                }
                onChange={(e) => onChange(e)}
                checked={
                  !!(
                    fields.is_call_for_tender &&
                    fields.is_call_for_tender.value === 1
                  )
                }
              />
              <span className="checkmark" />
            </label>
          </div>
        </div>
      </div>
      {/* Enquiries Info */}
      <div className="form-section">
        <h6>{t("CLIENT_PLATFORM_SITE_FORM_INQUIRIES_TITLE")}</h6>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6">
            <CustomDatePicker
              countryId={countryId}
              placeholder={t(
                "CLIENT_PLATFORM_SITE_FORM_SITE_START_DATE_PLACEHOLDER"
              )}
              divClass="form-field"
              labelClass="form-label required"
              labelName={t("CLIENT_PLATFORM_SITE_FORM_SITE_START_DATE")}
              inputClass={
                fields.start_date && fields.start_date.error !== ""
                  ? "form-input w-100 border border-danger"
                  : "form-input w-100"
              }
              required
              id="start_date"
              name="start_date"
              error={fields.start_date && fields.start_date.error !== ""}
              errorMsg={fields.start_date ? t(fields.start_date.error) : ""}
              value={fields.start_date ? fields.start_date.value : ""}
              onChange={(e) => onChange(e)}
              dateFormat="dd-MM-yyyy"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6">
            <SelectInput
              error={fields.duration && fields.duration.error !== ""}
              errorMsg={fields.duration ? t(fields.duration.error) : ""}
              value={fields.duration ? fields.duration.value : ""}
              inputClass={
                fields.duration && fields.duration.error !== ""
                  ? "jobsite__form-input border border-danger"
                  : "jobsite__form-input"
              }
              divClass="form-field"
              labelName={t("CLIENT_PLATFORM_SITE_FORM_SITE_DURATION")}
              labelClass="form-label required"
              required
              name="duration"
              id="duration"
              onChange={(e) => onChange(e)}
              defaultOption={t(
                "CLIENT_PLATFORM_SITE_FORM_SITE_DURATION_PLACEHOLDER"
              )}
              options={getDurationOptions(t)}
            />
          </div>
          <div className="col-12">
            <h4 className="requests-priority-title">
              {t("CLIENT_PLATFORM_SITE_FORM_SITE_PRIORITY")}
            </h4>
            <div
              className="btn-group requests-priority-options-wrapper"
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <input
                type="radio"
                className="btn-check"
                name="request_priority"
                id="weak"
                data-val={3}
                autoComplete="off"
                value={fields.request_priority ? fields.request_priority.value : 0}
                checked={
                  fields.request_priority &&
                  parseInt(fields.request_priority.value, 10) === 3
                }
                onChange={(e) => onChange(e)}
              />
              <label className="btn btn-outline-primary" htmlFor="weak">
                {t("CLIENT_PLATFORM_SITE_FORM_SITE_PRIORITY_LOW")}
              </label>

              <input
                type="radio"
                className="btn-check"
                name="request_priority"
                id="normal"
                data-val={2}
                autoComplete="off"
                value={fields.request_priority ? fields.request_priority.value : 0}
                checked={
                  fields.request_priority &&
                  parseInt(fields.request_priority.value, 10) === 2
                }
                onChange={(e) => onChange(e)}
              />
              <label className="btn btn-outline-primary" htmlFor="normal">
                {t("CLIENT_PLATFORM_SITE_FORM_SITE_PRIORITY_NORMAL")}
              </label>

              <input
                type="radio"
                className="btn-check"
                name="request_priority"
                id="strong"
                data-val={1}
                autoComplete="off"
                value={fields.request_priority ? fields.request_priority.value : 0}
                checked={
                  fields.request_priority &&
                  parseInt(fields.request_priority.value, 10) === 1
                }
                onChange={(e) => onChange(e)}
              />
              <label className="btn btn-outline-primary" htmlFor="strong">
                {t("CLIENT_PLATFORM_SITE_FORM_SITE_PRIORITY_HIGH")}
              </label>
            </div>
          </div>
        </div>
      </div>
      {/* Site Local Info */}
      <div className="form-section">
        <h6>{t("CLIENT_PLATFORM_SITE_FORM_SITE_LOCATION_TITLE")}</h6>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6">
            <TextInput
              type="text"
              placeholder={t("CLIENT_PLATFORM_SITE_FORM_SITE_POSTCODE_PLACEHOLDER")}
              divClass="form-field"
              labelClass="form-label required"
              labelName={t("CLIENT_PLATFORM_SITE_FORM_SITE_POSTCODE")}
              required
              id="postal"
              name="postal"
              error={fields.postal && fields.postal.error !== ""}
              errorMsg={fields.postal ? t(fields.postal.error) : ""}
              value={fields.postal ? fields.postal.value : ""}
              inputClass={
                fields.postal && fields.postal.error !== ""
                  ? "form-input w-100 border border-danger"
                  : "form-input w-100"
              }
              pattern={
                selectedCountryId === 3
                  ? "^[0-9-]*$"
                  : selectedCountryId === 1
                  ? "^(?!.,-*$)[a-zA-Z0-9s_ ]+$"
                  : "[0-9]*"
              }
              onBlur={() => setEditStatus(false)}
              onChange={(e) => onChange(e)}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6">
            <TextInput
              type="text"
              placeholder={t("CLIENT_PLATFORM_SITE_FORM_SITE_CITY_PLACEHOLDER")}
              divClass="form-field"
              labelClass="form-label required"
              labelName={t("CLIENT_PLATFORM_SITE_FORM_SITE_CITY")}
              required
              id="city"
              name="city"
              error={fields.city && fields.city.error !== ""}
              errorMsg={fields.city ? t(fields.city.error) : ""}
              value={fields.city ? fields.city.value : ""}
              inputClass={
                fields.city && fields.city.error !== ""
                  ? "form-input w-100 border border-danger"
                  : "form-input w-100"
              }
              onBlur={() => setEditStatus(false)}
              onChange={(e) => onChange(e)}
            />
          </div>
          <div className="col-12">
            <TextAreaInput
              type="text"
              placeholder={t("CLIENT_PLATFORM_SITE_FORM_SITE_ADDRESS_PLACEHOLDER")}
              divClass="form-field"
              labelClass="form-label required"
              labelName={t("CLIENT_PLATFORM_SITE_FORM_SITE_ADDRESS")}
              required
              id="address"
              name="address"
              error={fields.address && fields.address.error !== ""}
              errorMsg={fields.address ? t(fields.address.error) : ""}
              value={fields.address ? fields.address.value : ""}
              inputClass={
                fields.address && fields.address.error !== ""
                  ? "form-input w-100 border input-height border-danger"
                  : "form-input w-100 input-height"
              }
              onBlur={() => setEditStatus(false)}
              onChange={(e) => onChange(e)}
            />
          </div>
          <div className="col-12">
            <div id="mapid">
              {!fields?.latitude?.value && !fields?.longitude?.value && (
                <MapContainer
                  center={[51.505, -0.09]}
                  zoom={13}
                  scrollWheelZoom={false}
                >
                  <ChangeMapCenter center={[51.505, -0.09]} zoom={13} />
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker position={[51.505, -0.09]}>
                    {fields?.address?.value &&
                      fields?.postal?.value &&
                      fields?.city?.value && (
                        <Popup>
                          {`${fields.address.value} ${fields.postal.value} ${fields.city.value}`}
                        </Popup>
                      )}
                  </Marker>
                </MapContainer>
              )}
              {fields?.latitude?.value && fields?.longitude?.value && (
                <MapContainer
                  center={[
                    fields?.latitude?.value !== "undefined"
                      ? fields.latitude.value
                      : 51.505,
                    fields?.longitude?.value !== "undefined"
                      ? fields.longitude.value
                      : -0.09,
                  ]}
                  zoom={13}
                  scrollWheelZoom={false}
                >
                  <ChangeMapCenter
                    center={[
                      fields?.latitude?.value !== "undefined"
                        ? fields.latitude.value
                        : 51.505,
                      fields?.longitude?.value !== "undefined"
                        ? fields.longitude.value
                        : -0.09,
                    ]}
                    zoom={13}
                  />
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker
                    position={[
                      fields?.latitude?.value !== "undefined"
                        ? fields.latitude.value
                        : 51.505,
                      fields?.longitude?.value !== "undefined"
                        ? fields.longitude.value
                        : -0.09,
                    ]}
                  >
                    {fields?.address?.value &&
                      fields?.postal?.value &&
                      fields?.city?.value && (
                        <Popup>
                          {`${fields.address.value} ${fields.postal.value} ${fields.city.value}`}
                        </Popup>
                      )}
                  </Marker>
                </MapContainer>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Contact Site Details */}
      <div className="form-section">
        <h6>{t("DOCUMENT")}</h6>
        {documentNames && (
          <FileInputGroup
            data={{
              ...data,
              document: fields.document?.value ? fields.document?.value : [],
            }}
            labelNameData={documentNames}
            deleteFile={(url) => delFile(url)}
            fileGroupName="request"
            handleData={(docs) => {
              // TODO: rename variables
              setFormFields({
                ...fields,
                document: { value: docs.updatedData.document, error: "" },
              });
              updateFormDetails({
                ...fields,
                document: { value: docs.updatedData.document, error: "" },
              });
            }}
          />
        )}
      </div>

      <div className="form-section">
        <h6>{t("CLIENT_PLATFORM_SITE_FORM_COMPLEMENTARY_INFO_TITLE")} </h6>
        <div className="row">
          <div className="col-12">
            <TextAreaInput
              placeholder={t(
                "CLIENT_PLATFORM_SITE_FORM_SITE_USER_COMMENT_OPENING_PLACEHOLDER"
              )}
              inputClass="form-input w-100"
              divClass="form-field"
              labelClass="form-label"
              labelName={t("CLIENT_PLATFORM_SITE_FORM_SITE_USER_COMMENT_OPENING")}
              required={false}
              id="user_comment"
              name="user_comment"
              value={fields.user_comment ? fields.user_comment.value : ""}
              rows={5}
              columns={100}
              onChange={(e) => onChange(e)}
            />
          </div>
          {!data.edit && (
            <div className="col-12">
              <TextAreaInput
                placeholder={t(
                  "CLIENT_PLATFORM_SITE_FORM_SITE_USER_COMMENT_OTHER_PLACEHOLDER"
                )}
                inputClass="form-input w-100"
                divClass="form-field"
                labelClass="form-label"
                labelName={t("CLIENT_PLATFORM_SITE_FORM_SITE_USER_COMMENT_OTHER")}
                required={false}
                rows={5}
                columns={100}
                id="other-info-comment"
                name="other-info-comment"
                value={
                  fields["other-info-comment"]
                    ? fields["other-info-comment"].value
                    : ""
                }
                onChange={(e) => onChange(e)}
              />
            </div>
          )}
        </div>
      </div>
      {showErrorPopup && (
        <ErrorPopUp
          title={t("KICK_OFF_ERROR_BOX_TITLE")}
          btnText={t("KICK_OFF_ERROR_BOX_OK")}
          message={apiError}
          onOkClick={closePopup}
        />
      )}
    </form>
  );
};

JobsiteDetails.propTypes = {
  stage: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedCountryId: PropTypes.number,
  data: PropTypes.shape({}).isRequired,
  countryId: PropTypes.number,
  siteHrsOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSiteFormDetails: PropTypes.func.isRequired,
  updateFormValidity: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(JobsiteDetails);
