import { takeLatest, put, call } from "redux-saga/effects";

import {
  getRequestCategoriesAPI,
  getProductDataAPI,
  getRequestViewAPI,
  getLeadRequestViewAPI,
  deleteDocumentAPI,
  getSiteMasterDataAPI,
  getLeadSiteMasterDataAPI,
} from "./api";

import {
  GET_REQUEST_CATEGORIES,
  GET_PRODUCT_DETAILS,
  GET_REQUEST_VIEW_DATA,
  DELETE_DOCUMENT,
  GET_SITE_MASTER_LIST_DATA,
} from "./constant";

function* getRequestCategoriesData(action) {
  try {
    const data = yield call(getRequestCategoriesAPI, action.payload);
    yield put({ type: GET_REQUEST_CATEGORIES.SUCCESS, data });
  } catch (error) {
    const data = {
      code: error.response?.status,
      data: error.response?.data.errors,
      message: error.response?.data.message,
    };
    yield put({ type: GET_REQUEST_CATEGORIES.ERROR, data });
  }
}

function* getProductsData(action) {
  try {
    const data = yield call(getProductDataAPI, action.payload);
    yield put({ type: GET_PRODUCT_DETAILS.SUCCESS, data });
  } catch (error) {
    const data = {
      code: error.response?.status,
      data: error.response?.data.errors,
      message: error.response?.data.message,
    };
    yield put({ type: GET_PRODUCT_DETAILS.ERROR, data });
  }
}

function* getRequestView(action) {
  try {
    const userInfo = localStorage.getItem("userInfo");
    let userStatus;
    if (userInfo && JSON.parse(userInfo)) {
      userStatus = JSON.parse(userInfo).status;
    }
    const requestViewAPI =
      parseInt(userStatus, 10) === 3 ? getLeadRequestViewAPI : getRequestViewAPI;

    const data = yield call(requestViewAPI, {
      siteId: action.payload.siteId,
      id: action.payload.id,
    });
    yield put({ type: GET_REQUEST_VIEW_DATA.SUCCESS, data });
  } catch (error) {
    const data = {
      code: error.response?.status,
      data: error.response?.data.errors,
      message: error.response?.data.message,
    };
    yield put({ type: GET_REQUEST_VIEW_DATA.ERROR, data });
  }
}

function* deleteDocument(action) {
  try {
    const data = yield call(deleteDocumentAPI, action.payload);
    yield put({ type: DELETE_DOCUMENT.SUCCESS, data });
  } catch (error) {
    const data = {
      code: error.response?.status,
      data: error.response?.data.errors,
      message: error.response?.data.message,
    };
    yield put({ type: DELETE_DOCUMENT.ERROR, data });
  }
}

function* getSiteMasterData(action) {
  try {
    const userInfo = localStorage.getItem("userInfo");
    let userStatus;
    if (userInfo && JSON.parse(userInfo)) {
      userStatus = JSON.parse(userInfo).status;
    }
    const masterDataAPI =
      parseInt(userStatus, 10) === 3
        ? getLeadSiteMasterDataAPI
        : getSiteMasterDataAPI;
    const data = yield call(masterDataAPI, action.payload);
    yield put({ type: GET_SITE_MASTER_LIST_DATA.SUCCESS, data });
  } catch (error) {
    const data = {
      code: error.response?.status,
      data: error.response?.data.errors,
      message: error.response?.data.message,
    };
    yield put({ type: GET_SITE_MASTER_LIST_DATA.ERROR, data });
  }
}

// Watcher sagas

export function* getRequestCategoriesDataSaga() {
  yield takeLatest(GET_REQUEST_CATEGORIES.LOADING, getRequestCategoriesData);
}

export function* getProductsDataSaga() {
  yield takeLatest(GET_PRODUCT_DETAILS.LOADING, getProductsData);
}

export function* getRequestViewSaga() {
  yield takeLatest(GET_REQUEST_VIEW_DATA.LOADING, getRequestView);
}

export function* deleteDocumentSaga() {
  yield takeLatest(DELETE_DOCUMENT.LOADING, deleteDocument);
}

export function* getSiteMasterDataSaga() {
  yield takeLatest(GET_SITE_MASTER_LIST_DATA.LOADING, getSiteMasterData);
}
