import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "./FloatingTextInput.scss";

const FloatingTextInput = (props) => {
  const {
    label,
    value,
    name,
    id,
    divClass,
    inputClass,
    required,
    error,
    errorMsg,
    onChange,
  } = props;
  const [isInputFocussed, setInputFocus] = useState(false);
  const [inputVal, setInputVal] = useState("");

  const handleInputChange = (e) => {
    setInputVal(e.target.value);
    onChange(e);
  };

  const handleBlurChange = () => {
    setInputFocus(false);
  };

  const handleFocusChange = () => {
    setInputFocus(true);
  };

  useEffect(() => {
    setInputVal(value);
  }, [value]);

  return (
    <div
      className={
        isInputFocussed || inputVal
          ? `${divClass} floating-text-input__form-group floating-text-input__form-group--focussed`
          : `${divClass} floating-text-input__form-group`
      }
    >
      <label
        className={
          isInputFocussed || inputVal
            ? `${
                required
                  ? "floating-text-input__form-label required"
                  : "floating-text-input__form-label"
              } floating-text-input__form-group--focussed`
            : `${
                required
                  ? "floating-text-input__form-label required"
                  : "floating-text-input__form-label"
              }`
        }
        htmlFor={name}
      >
        {label}
      </label>
      <input
        aria-label={name}
        className={
          !isInputFocussed
            ? inputVal
              ? `${inputClass} floating-text-input__form-input floating-text-input__form-input--filled`
              : `${inputClass} floating-text-input__form-input`
            : `${inputClass} floating-text-input__form-input`
        }
        id={id}
        name={name}
        type="text"
        value={inputVal}
        onChange={handleInputChange}
        onBlur={handleBlurChange}
        onFocus={handleFocusChange}
      />
      {error && (
        <div className="error-text text-danger position-absolute w-100 text-nowrap">
          {errorMsg}
        </div>
      )}
    </div>
  );
};

FloatingTextInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  divClass: PropTypes.string,
  inputClass: PropTypes.string,
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
  required: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

FloatingTextInput.defaultProps = {
  label: "",
  value: "",
  name: "",
  required: false,
  error: false,
  errorMsg: "",
  divClass: "",
  inputClass: "",
};
export default FloatingTextInput;
