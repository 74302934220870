import axios from "axios";

import baseURL from "../../shared/utils/constant";

let userData = {};
export const storage = () => {
  userData = {
    token: localStorage.getItem("token") !== "" ? localStorage.getItem("token") : "",
    id: localStorage.getItem("userId") !== "" ? localStorage.getItem("userId") : "",
  };
  return userData;
};

const axiosInstance = (token) => {
  return axios.create({
    baseURL,
    timeout: 36000,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getRequestCategoriesAPI = () => {
  return axiosInstance(userData.token)
    .get(`${baseURL}/categories`)
    .then((res) => res.data);
};

export const getProductDataAPI = async (data) => {
  const payload = {
    service: data.service,
    sub_category_id: data.subCatId,
  };
  return axiosInstance(userData.token)
    .post(`${baseURL}/products`, payload)
    .then((res) => {
      return { id: data.subCatId, data: res.data };
    });
};

export const getRequestViewAPI = ({ siteId, id }) => {
  return axiosInstance(userData.token)
    .get(`${baseURL}/sites/${siteId}/requests/${id}`)
    .then((res) => res.data);
};

export const getLeadRequestViewAPI = ({ siteId, id }) => {
  return axiosInstance(userData.token)
    .get(`${baseURL}/leads/${siteId}/leadRequest/${id}`)
    .then((res) => res.data);
};

export const deleteDocumentAPI = (url) => {
  return axiosInstance(userData.token)
    .delete(url)
    .then((res) => res.data);
};

export const createCallOfOrderAPI = (data) => {
  return axiosInstance(userData.token)
    .post(`${baseURL}/trucks/order`, data)
    .then((res) => res.data);
};

export const getSiteMasterDataAPI = () => {
  return axiosInstance(userData.token)
    .get(`${baseURL}/sites/master/list`)
    .then((res) => res.data);
};

export const getLeadSiteMasterDataAPI = () => {
  return axiosInstance(userData.token)
    .get(`${baseURL}/leads/master/list`)
    .then((res) => res.data);
};

export const getSiteMasterDataCallOfOrderAPI = () => {
  return axiosInstance(userData.token)
    .get(`${baseURL}/sites/master/callOfOrder/list`)
    .then((res) => res.data);
};

export const getRequestMasterDataCallOfOrderAPI = (id) => {
  return axiosInstance(userData.token)
    .get(`${baseURL}/sites/${id}/requests/master/callOfOrder/list`)
    .then((res) => res.data);
};
