export const GET_VIEW_DATA = {
  LOADING: "GET_VIEW_DATA_LOADING",
  SUCCESS: "GET_VIEW_DATA_SUCCESS",
  ERROR: "GET_VIEW_DATA_FAILURE",
};

export const GET_REQUEST_LISTING_DATA = {
  LOADING: "GET_REQUEST_LISTING_DATA_LOADING",
  SUCCESS: "GET_REQUEST_LISTING_DATA_SUCCESS",
  ERROR: "GET_REQUEST_LISTING_DATA_FAILURE",
};

export const GET_QUOTES = {
  LOADING: "GET_QUOTES_LOADING",
  SUCCESS: "GET_QUOTES_SUCCESS",
  ERROR: "GET_QUOTES_FAILURE",
};
