import {
  GET_VIEW_DATA,
  GET_REQUEST_LISTING_DATA,
  GET_QUOTES,
} from "./SiteOverview.constants";
import { ADD_NEW_REQUEST } from "../InquiryForm/InquiryForm.constants";
import { LOGOUTDATA } from "../../store/loginData/constant";

import { LOADING, SUCCESS, INIT } from "../../shared/utils/constant";
import { preparePaginationData } from "../../shared/utils/methods";

const initialState = {
  phase: INIT,
  isViewDataLoading: false,
  apiUnAuthenticated: false,
  jobSiteFormData: {
    name: "",
    opening_time: "",
    closing_time: "",
    start_date: "",
    end_date: "",
    is_call_for_tender: "",
    related_contact: [],
    postal: "",
    city: "",
    address: "",
    document: [],
    comment: "",
    initialRow: {
      contact_id: "",
      role: "",
    },
  },
  relatedContact: {
    jobsite: {
      related_contact: [],
      document: [],
      deleted_related_contact: [],
    },
    enquiry: {
      related_contact: [],
      document: [],
      deleted_related_contact: [],
    },
  },
  paginationData: {
    jobsite: "",
    jobsiteInactive: "",
    request: [],
    quotes: [],
  },
  requestListingData: [],
  quotes: [],
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_VIEW_DATA.LOADING: {
      return {
        ...state,
        phase: LOADING,
        isViewDataLoading: true,
        apiUnAuthenticated: false,
      };
    }
    case GET_VIEW_DATA.SUCCESS: {
      let viewData = {};
      if (action.data.lead) {
        viewData = {
          ...state,
          phase: SUCCESS,
          isViewDataLoading: false,
          jobSiteFormData: {
            ...action.data.lead,
            initialRow: state.jobSiteFormData.initialRow,
            related_contact: [],
          },
          relatedContact: {
            ...state.relatedContact,
            jobsite: {
              ...state.relatedContact.jobsite,
              related_contact: action.data.lead.related_contact,
              document: action.data.lead.document,
            },
          },
        };
      } else {
        viewData = {
          ...state,
          phase: SUCCESS,
          isViewDataLoading: false,
          jobSiteFormData: {
            ...action.data.jobsite,
            initialRow: state.jobSiteFormData.initialRow,
            related_contact: [],
          },
          relatedContact: {
            ...state.relatedContact,
            jobsite: {
              ...state.relatedContact.jobsite,
              related_contact: action.data.jobsite.related_contact,
              document: action.data.jobsite.document,
            },
          },
        };
      }
      return viewData;
    }
    case GET_VIEW_DATA.ERROR: {
      return {
        ...state,
        isViewDataLoading: false,
        apiUnAuthenticated: action.data.code === 401,
      };
    }
    case GET_REQUEST_LISTING_DATA.LOADING: {
      return {
        ...state,
        phase: LOADING,
        isRequestListingLoading: true,
        apiUnAuthenticated: false,
      };
    }
    case GET_REQUEST_LISTING_DATA.SUCCESS: {
      const response = action.data;
      const paginationData = preparePaginationData(response);
      return {
        ...state,
        phase: SUCCESS,
        isRequestListingLoading: false,
        requestListingData:
          paginationData.current_page === 1
            ? [...response.data]
            : [...state.requestListingData, ...response.data],
        siteListingData: [],
        paginationData: {
          jobsite: "",
          request: paginationData,
          quotes: state.paginationData.quotes,
        },
      };
    }
    case GET_REQUEST_LISTING_DATA.ERROR: {
      return {
        ...state,
        isRequestListingLoading: false,
        apiUnAuthenticated: action.data.code === 401,
      };
    }
    case GET_QUOTES.LOADING: {
      return {
        ...state,
        phase: LOADING,
        isQuotesLoading: true,
        apiUnAuthenticated: false,
      };
    }
    case GET_QUOTES.SUCCESS: {
      const response = action.data;
      const paginationData = preparePaginationData(response);
      return {
        ...state,
        phase: SUCCESS,
        isQuotesLoading: false,
        quotes:
          paginationData.current_page === 1
            ? [...response.data]
            : [...state.quotes, ...response.data],
        paginationData: {
          jobsite: "",
          request: state.paginationData.request,
          quotes: paginationData,
        },
      };
    }
    case GET_QUOTES.ERROR: {
      return {
        ...state,
        quotes: [],
        isQuotesLoading: false,
        apiUnAuthenticated: action.data.code === 401,
      };
    }

    case ADD_NEW_REQUEST.SUCCESS: {
      return {
        ...state,
        requestListingData: [action.data.request, ...state.requestListingData],
      };
    }
    case LOGOUTDATA.SUCCESS: {
      return {
        phase: INIT,
        isViewDataLoading: false,
        apiUnAuthenticated: false,
        jobSiteFormData: {
          name: "",
          opening_time: "",
          closing_time: "",
          start_date: "",
          end_date: "",
          is_call_for_tender: "",
          related_contact: [],
          postal: "",
          city: "",
          address: "",
          document: [],
          comment: "",
          initialRow: {
            contact_id: "",
            role: "",
          },
        },
        relatedContact: {
          jobsite: {
            related_contact: [],
            document: [],
            deleted_related_contact: [],
          },
          enquiry: {
            related_contact: [],
            document: [],
            deleted_related_contact: [],
          },
        },
        paginationData: {
          jobsite: "",
          jobsiteInactive: "",
          request: [],
          quotes: [],
        },
        requestListingData: [],
        quotes: [],
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
