import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import tooltipIcon from "../../../assets/shared/ic-tooltip.svg";

import "./FileInput.scss";

const FileInput = (props) => {
  const {
    divClass,
    labelClass,
    labelName,
    tooltipMessage,
    name,
    id,
    required,
    inputClass,
    onChange,
    multiple,
    data,
    onFileDelete,
    accept,
    t,
  } = props;

  return (
    <div className={divClass}>
      <label className={labelClass} htmlFor={id}>
        <span className="file-input-label">{labelName}</span>
        {tooltipMessage && (
          <div className="tooltip-container">
            <img src={tooltipIcon} alt="Tooltip icon" className="tooltip-icon" />
            <div className="tooltip-message">{tooltipMessage}</div>
          </div>
        )}
        <br />
        <span className="btn btn-primary upload-btn fontLato14 mt-1 font-weight-bold">
          <i className="fa fa-upload upload-icon pr-3" aria-hidden="true" />
          {t("CLIENT_PLATFORM_UPLOAD_FILE")}
        </span>
      </label>
      <input
        type="file"
        id={id}
        className={inputClass}
        name={name}
        onChange={onChange}
        required={required}
        multiple={multiple}
        accept={accept}
      />
      <div className="upload-files-table overflow-auto">
        {data.length > 0 && (
          <table>
            <tbody>
              {data.map((item, index) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr key={item.id + index}>
                    <td className="file-name">
                      {Object.prototype.hasOwnProperty.call(item, "file") &&
                      item.file?.name
                        ? item.file.name
                        : item.file && item.file[0]?.name
                        ? item.file[0].name
                        : item.filename}
                    </td>
                    <td>
                      <i
                        className="fa fa-trash-o delete-icon crsr-ptr text-danger pl-2"
                        aria-hidden="true"
                        onClick={() =>
                          onFileDelete(
                            item.id,
                            Object.prototype.hasOwnProperty.call(item, "file")
                              ? ""
                              : item.delete_link
                          )
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

FileInput.propTypes = {
  divClass: PropTypes.string,
  labelClass: PropTypes.string,
  labelName: PropTypes.string,
  name: PropTypes.string.isRequired,
  accept: PropTypes.string.isRequired,
  tooltipMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  inputClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  onFileDelete: PropTypes.func.isRequired,
};

export default withTranslation()(FileInput);
