import {
  CREATE_TRANSPORT_REQUEST,
  UPDATE_TRANSPORT_REQUEST,
  CREATE_TRANSPORT_REQUEST_FORM,
  EDIT_TRANSPORT_ENQUIRY,
  HANDLE_TRANSPORT_DETAILS,
} from "./TransportOnlyForm.constants";
import { LOADING, SUCCESS, INIT, ERROR } from "../../shared/utils/constant";

import { getWeekDays } from "../../shared/utils/methods";

const initialState = {
  phase: INIT,
  newTransportRequestLoading: false,
  apiUnAuthenticated: false,
  transportOnlyData: {},
  weekDayList: getWeekDays(),
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case CREATE_TRANSPORT_REQUEST.LOADING: {
      return {
        ...state,
        phase: LOADING,
        newTransportRequestLoading: true,
        apiUnAuthenticated: false,
      };
    }
    case CREATE_TRANSPORT_REQUEST.SUCCESS: {
      return {
        ...state,
        phase: SUCCESS,
        transportOnlyData: { ...action.data.request, new: true },
        newTransportRequestLoading: false,
      };
    }
    case CREATE_TRANSPORT_REQUEST.ERROR: {
      return {
        ...state,
        phase: ERROR,
        newTransportRequestLoading: false,
        transportOnlyData: {
          ...state.transportOnlyData,
          error: action.error.response,
        },
      };
    }
    case UPDATE_TRANSPORT_REQUEST.LOADING: {
      return {
        ...state,
        phase: LOADING,
        newTransportRequestLoading: true,
        apiUnAuthenticated: false,
      };
    }
    case UPDATE_TRANSPORT_REQUEST.SUCCESS: {
      return {
        ...state,
        phase: SUCCESS,
        transportOnlyData: { ...action.data.request, new: true },
        newTransportRequestLoading: false,
      };
    }
    case UPDATE_TRANSPORT_REQUEST.ERROR: {
      return {
        ...state,
        phase: ERROR,
        newTransportRequestLoading: false,
        transportOnlyData: {
          ...state.transportOnlyData,
          error: action.error.response,
        },
      };
    }
    case CREATE_TRANSPORT_REQUEST_FORM: {
      return {
        ...state,
        transportOnlyData: {
          address: "",
          postal: "",
          city: "",
          service: "",
          address_destination: "",
          postal_destination: "",
          city_destination: "",
          date_type: "",
          start_date: "",
          end_date: "",
          selected_days: [],
          comment: "",
          product: "",
          quantity: "",
          unit: "",
          new: false,
        },
      };
    }
    case EDIT_TRANSPORT_ENQUIRY: {
      const data = action.payload;
      return {
        ...state,
        transportOnlyData: {
          id: data.id,
          address: data.address,
          postal: data.postal,
          city: data.city,
          service: data.service_id,
          address_destination: data.address_destination,
          postal_destination: data.postal_destination,
          city_destination: data.city_destination,
          date_type: data.date_type,
          start_date: data.start_date,
          end_date: data.end_date,
          quantity: data.quantity,
          unit: data.unit,
          selected_days: data.selected_days ? data.selected_days.split(",") : [],
          days: data.selected_days ? data.selected_days.split(",") : [],
          comment: data.comment,
          product: data.product.map((x) => x.product_id)[0],
          editData: true,
        },
      };
    }
    case HANDLE_TRANSPORT_DETAILS: {
      return {
        ...state,
        transportOnlyData: action.payload.updatedData,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
