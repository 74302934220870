import {
  CLEAR_DASHBOARD,
  DASHBOARDATA,
  LEADS,
  CLEAR_SELECTED_SITE,
} from "./Dashboard.constants";

import { USER_VIEWED_LEAD } from "../JobsiteForm/JobsiteForm.constants";

import {
  GET_SITE_LISTING_DATA,
  GET_SITE_LISTING_INACTIVE_DATA,
} from "../SitesList/SitesList.constants";

import { GET_VIEW_DATA } from "../SiteOverview/SiteOverview.constants";

const initialState = {
  isLoading: false,
  apiUnAuthenticated: false,
  jobSites: [],
  requests: [],
  quotes: [],
  lead: [],
  isLeadFlow: false,
  isCallOfOrder: 0,
};

export const dashboardReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case DASHBOARDATA.LOADING: {
      return {
        ...state,
        isLoading: true,
        apiUnAuthenticated: false,
      };
    }
    case DASHBOARDATA.SUCCESS: {
      return {
        ...state,
        jobSites: action.data.jobsite,
        requests: action.data.request,
        quotes: action.data.quote,
        lead: action.data.lead,
        isLeadFlow: !!(action.data.lead && !Array.isArray(action.data.lead)),
        isCallOfOrder: action.data.isCallOfOrder,
        isLoading: false,
      };
    }
    case DASHBOARDATA.ERROR: {
      return {
        ...state,
        isLoading: false,
        apiUnAuthenticated: action.error.response.status === 401,
      };
    }
    case CLEAR_DASHBOARD: {
      return {
        ...state,
        jobSites: [],
        requests: [],
        quotes: [],
        lead: [],
        isCallOfOrder: 0,
        apiUnAuthenticated: false,
      };
    }
    case CLEAR_SELECTED_SITE: {
      return {
        ...state,
      };
    }
    case GET_SITE_LISTING_DATA.SUCCESS:
    case GET_SITE_LISTING_INACTIVE_DATA.SUCCESS:
    case GET_VIEW_DATA.SUCCESS: {
      const response = action.data;
      return {
        ...state,
        isCallOfOrder: response.isCallOfOrder,
      };
    }
    case USER_VIEWED_LEAD: {
      const isLeadViewed = action.payload;
      return {
        ...state,
        lead: isLeadViewed ? [] : state.lead,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

// For leads completion.
const initialLeadsState = {
  leadsList: [],
  leadRequestList: [],
  isLoading: false,
  apiUnAuthenticated: false,
};

export const dashboardLeadsReducer = (state = initialLeadsState, action = {}) => {
  switch (action.type) {
    case LEADS.LOADING: {
      return {
        isLoading: true,
        leadsList: [],
        leadRequestList: [],
        apiUnAuthenticated: false,
      };
    }
    case LEADS.SUCCESS: {
      return {
        ...state,
        leadsList: action.data.leadList,
        leadRequestList: action.data.leadRequestList,
        isLoading: false,
      };
    }
    case LEADS.ERROR: {
      return {
        ...state,
        leadsList: [],
        isLoading: false,
        apiUnAuthenticated: action.error.response.status === 401,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
