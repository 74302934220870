import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import removalIcon from "../../../../assets/shared/removal.svg";
import supplyIcon from "../../../../assets/shared/supply.svg";

import { getEnquiryStatusList } from "../../../../shared/utils/methods";

import "./MyEnquiryRowItem.scss";

const MyEnquiryRowItem = (props) => {
  const { siteId, item, t } = props;

  const status = getEnquiryStatusList();

  const getQuoteStatusColor = (quoteStatus) => {
    if (quoteStatus === 1) {
      return "enquiry-status in-progress";
    }
    if (quoteStatus === 2) {
      return "enquiry-status quote-sent";
    }
    if (quoteStatus === 3) {
      return "enquiry-status won";
    }
    return "enquiry-status canceled";
  };

  const renderEnquiryStatus = (enquiryStatus) => {
    if (enquiryStatus === 1 || enquiryStatus === 2 || enquiryStatus === 3) {
      return (
        <span className={getQuoteStatusColor(enquiryStatus)}>
          {t(status[enquiryStatus])}
        </span>
      );
    }
    return (
      <span className={getQuoteStatusColor(enquiryStatus)}>
        {t("CLIENT_PLATFORM_INQUIRY_STATUS_CANCELED")}
      </span>
    );
  };

  return (
    <div
      className="card card-hover enquiry-row-item"
      key={item.id + siteId}
      title="View Request"
      onClick={() => props.viewData(siteId, item.id)}
      role="button"
      tabIndex="0"
    >
      <div className="enquiry-item-details">
        <div className="enquiry-item-img-wrapper">
          <img
            className="enquiry-item-img"
            src={item.service_id === 1 ? removalIcon : supplyIcon}
            alt="removal-img"
          />
        </div>

        <div className="enquiry-item-name-wrapper">
          <div className="enquiry-item-header__name-wrapper">
            <div className="enquiry-item-header__name-id-wrapper">
              <h5 className="enquiry-item-name">
                {item.name !== "Unknown"
                  ? item.name
                  : t("CLIENT_PLATFORM_REQUEST_TYPE_UNKNOWN")}
              </h5>
              <span className="enquiry-item-id">#{item.id}</span>
            </div>
            {item.step && renderEnquiryStatus(parseInt(item.step, 10))}
          </div>
          <p className="fontLato12 mb-0">
            {t(item.service)} :
            <span className="gray-darker-text pl-1">
              {t(item.category_name)}- {t(item.sub_category_name)}
            </span>
          </p>
          <p className="quantity-wrapper">
            <span>
              {`${t("CLIENT_PLATFORM_SITE_OVERVIEW_INQUIRY_QUANTITY")} : `}
            </span>
            <span className="quantity-val">{`${item.quantity} ${
              item.unit ? t(item.unit) : ""
            }`}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

MyEnquiryRowItem.propTypes = {
  item: PropTypes.shape(PropTypes.object).isRequired,
  siteId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  viewData: PropTypes.func.isRequired,
};

export default withTranslation()(MyEnquiryRowItem);
