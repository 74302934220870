export const DASHBOARDATA = {
  LOADING: "DASHBOARDATA_LOADING",
  SUCCESS: "DASHBOARDATA_SUCCESS",
  ERROR: "DASHBOARDATA_FAILURE",
};

export const LEADS = {
  LOADING: "LEADS_LOADING",
  SUCCESS: "LEADS_SUCCESS",
  ERROR: "LEADS_FAILURE",
};

export const CLEAR_DASHBOARD = "CLEAR_DASHBOARD";
export const CLEAR_SELECTED_SITE = "CLEAR_SELECTED_SITE";
