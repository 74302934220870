import React, { useState, useEffect, useCallback, useRef } from "react";
import { withTranslation } from "react-i18next";
import axios from "axios";
import PropTypes from "prop-types";
import PulseLoader from "react-spinners/PulseLoader";
import * as moment from "moment";

import RangeSlider from "../RangeSlider";
import EnquiryForm from "../EnquiryForm";
import FileInputGroup from "../../../shared/baseUI/FileInputGroup";
import TextAreaInput from "../../../shared/baseUI/TextAreaInput";
import CustomDatePicker from "../../../shared/baseUI/FloatingTextDatePicker";
import CustomCheckBox from "../../../shared/baseUI/CustomCheckBox";
import Autocomplete from "../AutoComplete";

import noServiceIcon from "../../../assets/shared/ic-no-service.svg";

import {
  prepareDocumentList,
  prepareDocumentLabel,
} from "../../../shared/utils/methods";
import { getCountryCode } from "../OnboardingV3.utils";
import baseURL, { ADDRESS_NOT_FOUND } from "../../../shared/utils/constant";

import "./InquiryForm.scss";

const InquiryForm = (props) => {
  const { t, onFormUpdate, fields, language, countryId } = props;

  const [token, setToken] = useState();
  const [countriesList, setActiveCountriesList] = useState([]);
  const [activeCountry, setActiveCountry] = useState();
  const [jobsiteName, setJobsiteName] = useState("");
  const [hereCountryCode, setHereCountryCode] = useState();
  const [jobsites, setJobsites] = useState([]);
  const [isJobsiteFocused, setJobsiteInputFocus] = useState(false);
  const [isCommentInputFocused, setCommentInputFocus] = useState(false);
  const [requestCategories, setRequestCategories] = useState({});
  const [isCategoryLoading, setCategoryLoading] = useState(false);
  const [documentLabels, setDocumentLabels] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [isCountryInService, setCountryServiceStatus] = useState(true);
  const [isJobsiteSuggestionsLoading, setJobsitesSuggestionLoad] = useState(false);

  const RESULTS_COUNT = 10;
  const MAX_RESULTS = 5;
  const JOBSITE_SEARCH_TIMEOUT = 450;
  const serviceRef = useRef();
  const productRef = useRef();
  const productTypeRef = useRef();
  const quantityRef = useRef();
  const tenderRef = useRef();
  const dateRef = useRef();
  const hoursRef = useRef();
  const commentsRef = useRef();
  const usageRef = useRef();

  // Set active country based on the language selected.
  /* istanbul ignore next */
  const setActiveCountryInfo = useCallback(() => {
    if (language === "en") {
      const activeCountryLocal = countriesList.find(
        (country) => country.language_id === 1
      );
      if (activeCountryLocal) {
        setActiveCountry(activeCountryLocal);
      }
    }
    if (language === "fr") {
      const activeCountryLocal = countriesList.find(
        (country) => country.language_id === 2
      );
      if (activeCountryLocal) {
        setActiveCountry(activeCountryLocal);
      }
    }
    if (language === "pl") {
      const activeCountryLocal = countriesList.find(
        (country) => country.language_id === 3
      );
      if (activeCountryLocal) {
        setActiveCountry(activeCountryLocal);
      }
    }
    if (language === "de") {
      const activeCountryLocal = countriesList.find(
        (country) => country.language_id === 6
      );
      if (activeCountryLocal) {
        setActiveCountry(activeCountryLocal);
      }
    }
    if (language === "ch") {
      const activeCountryLocal = countriesList.find(
        (country) => country.language_id === 8
      );
      if (activeCountryLocal) {
        setActiveCountry(activeCountryLocal);
      }
    }
  }, [language, countriesList]);

  // Fetch request categories.
  const fetchCategories = (countryID) => {
    setCategoryLoading(true);
    axios
      .get(`${baseURL}/categories/${countryID}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${
            localStorage.getItem("token") !== "" ? localStorage.getItem("token") : ""
          }`,
        },
      })
      .then((response) => {
        setCategoryLoading(false);
        setRequestCategories(response.data);
      })
      .catch((error) => {
        if (window.debugMode) {
          console.error("Error: an error occurred while fetching categories", error);
        }
        setCategoryLoading(false);
        setRequestCategories({});
      });
  };

  /**
   * Fetch countries list where Hesus is providing services.
   */
  const getActiveCountriesList = () => {
    axios
      .get(`${baseURL}/country/list`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${
            localStorage.getItem("token") !== "" ? localStorage.getItem("token") : ""
          }`,
        },
      })
      .then((response) => {
        setActiveCountriesList(response.data);
      })
      .catch((error) => {
        if (window.debugMode) {
          console.error(
            "Error: an error occurred while fetching active countries list",
            error
          );
        }
        setActiveCountriesList([]);
      });
  };

  /**
   * Fetch token from HERE API to fetch jobsites locations.
   */
  const getToken = () => {
    axios
      .get(`${baseURL}/location/token`)
      .then((response) => {
        setToken(response.data.token);
      })
      .catch(() => {
        setToken();
      });
  };

  const getDocuments = () => {
    // Show only other documents type.
    return prepareDocumentLabel([
      {
        type: 3,
        name: t(
          "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DOCUMENTS_OTHER"
        ),
        tooltipMessage: t("CLIENT_PLATFORM_ONBOARDING_DOCUMENT_TOOLTIP"),
        accept:
          ".doc, .docx, .xls, .xlsx, .ppt, .pptx, .msg, .pdf, .jpg, .jpeg, .png",
        allowedFileTypes: [
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-powerpoint",
          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          "application/vnd.ms-outlook",
          "image/jpg",
          "image/jpeg",
          "image/png",
          "application/pdf",
        ],
      },
    ]);
  };

  const getAddress = (selectedSite) => {
    if (selectedSite.address?.houseNumber && selectedSite.address?.street) {
      return `${selectedSite.address.houseNumber} ${selectedSite.address.street}`;
    }
    if (!selectedSite.address?.houseNumber && selectedSite.address?.street) {
      return selectedSite.address.street;
    }

    return ADDRESS_NOT_FOUND;
  };

  /**
   * Handle jobsite selection.
   * @param {} item
   */
  const handleSiteSelection = (item) => {
    const selectedSite = jobsites.find((jobsite) => jobsite.address.label === item);
    const countryInService = countriesList.find(
      (country) =>
        country.here_country_code === selectedSite?.address?.countryCode &&
        country.platform === 1
    );
    if (selectedSite && countryInService) {
      const updateFields = {
        ...fields,
        address: {
          value: getAddress(selectedSite),
          error: "",
          required: true,
        },
        postal: {
          value: selectedSite.address.postalCode,
          error: "",
          required: false,
        },
        city: {
          value: selectedSite.address.city,
          error: "",
          required: false,
        },
        latitude: {
          value: selectedSite.position.lat,
          error: "",
          required: false,
        },
        longitude: {
          value: selectedSite.position.lng,
          error: "",
          required: false,
        },
        countryId: {
          value: countryInService.here_country_code,
          error: "",
          required: false,
        },
        company_reg: {
          value: countryInService.here_country_code,
          error: "",
          required: false,
        },
      };
      onFormUpdate(updateFields);
      setHereCountryCode(countryInService.here_country_code);
      setCountryServiceStatus(true);
      setJobsiteName(item);
      setJobsiteInputFocus(false);
    } else {
      const updateFields = {
        ...fields,
        address: {
          value: "",
          error: "",
          required: true,
        },
        postal: {
          value: "",
          error: "",
          required: false,
        },
        city: {
          value: "",
          error: "",
          required: false,
        },
        latitude: {
          value: "",
          error: "",
          required: false,
        },
        longitude: {
          value: "",
          error: "",
          required: false,
        },
        countryId: {
          value: null,
          error: "",
          required: false,
        },
        company_reg: {
          value: "",
          error: "",
          required: false,
        },
        quantity: {
          value: "",
          error: "",
          required: false,
        },
        categoryForm: null,
        categoryFormData: { value: null, error: "", required: true },
        unit: {
          value: "",
          error: "",
          required: false,
        },
        isCallForTender: {
          value: false,
          error: "",
          required: false,
        },
        start_date: {
          value: "",
          error: "",
          required: false,
        },
        jobsite_comment: {
          value: "",
          error: "",
          required: false,
        },
      };
      onFormUpdate(updateFields);
      setJobsiteName(item);
      setCountryServiceStatus(false);
    }
  };

  /**
   * Handle jobsite change.
   * @param {*} value
   */
  const handleSiteChange = (value) => {
    setJobsiteName(value);
    setCountryServiceStatus(true);
    setJobsiteInputFocus(true);
    if (!value) {
      setJobsiteName("");
      handleSiteSelection("");
    }
  };

  const handleFiles = (files) => {
    setDocuments(files.updatedData.document);
    onFormUpdate({
      ...fields,
      documents: { value: files.updatedData.document },
      uploadDocs: files.updatedData.document,
    });
  };

  /**
   * Handle input change for quantity, unit and category form
   * @param {*} data
   */
  const updateFormData = (data) => {
    if (
      data.quantity?.value ||
      data.unit?.value ||
      data.categoryForm ||
      data.usage_id
    ) {
      onFormUpdate({
        ...fields,
        ...data,
        categoryForm: data.categoryForm !== -1 ? data.categoryForm : null,
        categoryFormData: {
          value: data.categoryForm ?? fields.categoryFormData?.value,
          error: "",
          required: true,
        },
        usage_id: data.usage_id,
        start_date: fields.start_date,
        isCallForTender: fields.isCallForTender,
        jobsite_comment: fields.jobsite_comment,
        documents: fields.documents,
        minHour: fields.minHour,
        maxHour: fields.maxHour,
      });
    }
  };

  /**
   * Handle input change for, call for Tender, start date, comments fields.
   * @param {*} e
   */
  const onChange = (e) => {
    const { name } = e.target ? e.target : e;

    if (Array.isArray(e)) {
      // set range slider value
      onFormUpdate({
        ...fields,
        minHour: {
          value: e[0],
          error: "",
        },
        maxHour: {
          value: e[1],
          error: "",
        },
      });
    }
    if (name === "call-for-tender") {
      onFormUpdate({
        ...fields,
        isCallForTender: {
          value: e.target.checked,
          error: "",
          required: false,
        },
      });
      if (hoursRef?.current) {
        hoursRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
    if (name === "start_date") {
      onFormUpdate({
        ...fields,
        start_date: {
          value: moment(e.date).format("YYYY-MM-DD"),
          error: "",
          required: true,
        },
      });
      if (commentsRef?.current) {
        commentsRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
    if (name === "comment") {
      onFormUpdate({
        ...fields,
        jobsite_comment: {
          value: e.target.value,
          error: "",
          required: false,
        },
      });
    }
  };

  const handleFormContainerClick = (e) => {
    if (e.target.className !== "v3-inquiry-form__job-site-input") {
      if (jobsiteName && jobsites.length > 1) {
        setJobsites([
          {
            address: { label: t("CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_NO_ADRESS") },
          },
          ...jobsites,
        ]);
      }
    }
  };

  const handleSelectionOptionsMount = (name) => {
    if (name === "service") {
      if (serviceRef?.current && !fields.selectedItems?.service) {
        serviceRef.current?.scrollIntoView?.({
          behavior: "smooth",
          block: "start",
        });
      }
    }
    if (name === "category") {
      if (productRef?.current && !fields.selectedItems?.category) {
        setTimeout(() => {
          productRef?.current?.scrollIntoView?.({
            behavior: "smooth",
            block: "start",
          });
        }, 100);
      }
    }
    if (name === "usage_id") {
      if (usageRef?.current) {
        setTimeout(() => {
          usageRef?.current?.scrollIntoView?.({
            behavior: "smooth",
            block: "start",
          });
        }, 100);
      }
    }
    if (name === "subcategory") {
      if (productTypeRef?.current && !fields.categoryForm) {
        setTimeout(() => {
          productTypeRef?.current?.scrollIntoView?.({
            behavior: "smooth",
            block: "start",
          });
        }, 100);
      }
    }
  };

  const getHEREDiscoverURL = useCallback(
    (jobsite) => {
      const latLng = `${activeCountry?.latitude},${activeCountry?.longitude}`;
      const hereDiscoverAPI = `https://discover.search.hereapi.com/v1/discover`;
      return `${hereDiscoverAPI}?q=${jobsite}&limit=${RESULTS_COUNT}&at=${latLng}&in=countryCode:GBR,XEA,CHE&lang=${language}`;
    },
    [activeCountry?.latitude, activeCountry?.longitude, language]
  );

  const fetchJobsites = useCallback(
    (jobsite) => {
      if (token && activeCountry) {
        setJobsitesSuggestionLoad(true);
        axios
          .get(getHEREDiscoverURL(jobsite), {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            const filterItems = response.data.items.filter(
              (item) =>
                item.resultType === "street" ||
                item.resultType === "locality" ||
                item.resultType === "houseNumber"
            );
            const latestItems = filterItems.filter(
              (item, index) => index < MAX_RESULTS
            );
            setJobsites(latestItems);
            setJobsitesSuggestionLoad(false);
          })
          .catch((error) => {
            if (window.debugMode) {
              console.error("Error: an error occurred while fetching places", error);
            }
            setJobsites([]);
            setJobsitesSuggestionLoad(false);
          });
      }
    },
    [token, activeCountry, getHEREDiscoverURL]
  );

  useEffect(() => {
    const pageUrl = window.location.host;
    /* istanbul ignore next */
    if (pageUrl.includes("my.hesus-store.com")) {
      // Add below scripts to Analytics
      const head = document.querySelector("head");
      const fbScript = document.createElement("script");
      const gtagScript = document.createElement("script");
      const googleScript = document.createElement("script");
      const linkedInScript1 = document.createElement("script");
      const linkedInScript2 = document.createElement("script");
      fbScript.text = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '1387525291605573');fbq('track', 'PageView');`;
      linkedInScript1.text = `_linkedin_partner_id = "3024217";window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];window._linkedin_data_partner_ids.push(_linkedin_partner_id);`;
      linkedInScript2.text = `(function(){var s = document.getElementsByTagName("script")[0];var b = document.createElement("script");b.type = "text/javascript";b.async = true;b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";s.parentNode.insertBefore(b, s);})();`;
      googleScript.text = `window.dataLayer = window.dataLayer || [];  function gtag(){dataLayer.push(arguments);}  gtag('js', new Date());  gtag('config', 'AW-470310638');`;
      gtagScript.setAttribute(
        "src",
        "https://www.googletagmanager.com/gtag/js?id=AW-470310638"
      );
      gtagScript.setAttribute("async", true);
      head.appendChild(fbScript);
      head.appendChild(linkedInScript1);
      head.appendChild(linkedInScript2);
      head.appendChild(gtagScript);
      head.appendChild(googleScript);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getActiveCountriesList();
    setDocuments(fields.documents?.value ? fields.documents.value : []);
    setDocumentLabels(getDocuments());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!token) {
      getToken();
    }
  }, [token]);

  useEffect(() => {
    const debouncedFn = setTimeout(() => {
      if (jobsiteName) {
        fetchJobsites(jobsiteName);
      }
    }, JOBSITE_SEARCH_TIMEOUT);

    return () => {
      if (debouncedFn) {
        clearTimeout(debouncedFn);
      }
    };
  }, [jobsiteName, token, language, activeCountry, fetchJobsites]);

  useEffect(() => {
    // Fetch categories based on HERE country code.
    const mHereCountryCode = hereCountryCode ?? activeCountry?.here_country_code;
    switch (mHereCountryCode) {
      case "GBR":
        fetchCategories(1);
        break;
      case "FRA":
        fetchCategories(2);
        break;
      case "POL":
        fetchCategories(3);
        break;
      case "DEU":
        fetchCategories(6);
        break;
      case "CHE":
        fetchCategories(8);
        break;
      default:
    }
  }, [activeCountry?.here_country_code, hereCountryCode]);

  useEffect(() => {
    // Set active country on language change.
    setActiveCountryInfo();
  }, [language, countriesList, setActiveCountryInfo]);

  useEffect(() => {
    // Auto Scroll to quantity input.
    if (quantityRef?.current && !fields.quantity?.value) {
      quantityRef?.current?.scrollIntoView?.({
        behavior: "smooth",
        block: "center",
      });
    }

    // Auto Scroll to tender input.
    if (tenderRef?.current && fields.quantity?.value && !fields.start_date?.value) {
      tenderRef.current?.scrollIntoView?.({
        behavior: "smooth",
        block: "center",
      });
    }
  });

  return (
    <div className="v3-inquiry-form__container" onClick={handleFormContainerClick}>
      {token && countriesList.length > 0 && (
        <Autocomplete
          id="jobsite-name"
          name="jobsite-name"
          containerClass={
            isJobsiteFocused || fields.address?.value
              ? "v3-inquiry-form__jobsite-input-wrapper v3-inquiry-form__jobsite-input--focussed"
              : "v3-inquiry-form__jobsite-input-wrapper"
          }
          label={t("CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_SITE_FORM_SITE_ADDRESS")}
          labelClass="v3-inquiry-form__jobsite-label"
          inputClass={
            fields.address?.value
              ? "v3-inquiry-form__job-site-input v3-inquiry-form__job-site-input--filled"
              : "v3-inquiry-form__job-site-input"
          }
          menuClassName="v3-inquiry-form__menu"
          menuItemClassName="v3-inquiry-form__menu-item"
          suggestions={jobsites.map((jobsite) => jobsite.address.label)}
          value={jobsiteName}
          isLoading={isJobsiteSuggestionsLoading}
          onChange={handleSiteChange}
          onSelect={handleSiteSelection}
          onFocus={() => setJobsiteInputFocus(true)}
          onBlur={() => {
            if (!jobsiteName) {
              setJobsiteInputFocus(false);
            }
            if (jobsiteName && jobsites.length === 1) {
              setJobsiteName(jobsites[0].address.label);
              handleSiteSelection(jobsites[0].address.label);
            }
          }}
        />
      )}
      {isCategoryLoading && (
        <div className="v3-inquiry-form__category-loading">
          <PulseLoader color="#004c7f" size={15} />
        </div>
      )}
      {fields.address?.value && Object.keys(requestCategories).length > 0 && (
        <EnquiryForm
          onMount={handleSelectionOptionsMount}
          serviceRef={serviceRef}
          productTypeRef={productTypeRef}
          productRef={productRef}
          quantityRef={quantityRef}
          usageRef={usageRef}
          requestCategories={requestCategories}
          countryId={countryId}
          hereCountryId={getCountryCode(hereCountryCode)}
          fields={fields}
          isCategoryLoading={isCategoryLoading}
          updateFormData={updateFormData}
        />
      )}
      {jobsiteName && !isCountryInService && (
        <div className="v3-inquiry-form__no-service">
          <p className="v3-inquiry-form__no-service-message">
            {t("CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_NO_COUNTRY")}
          </p>
          <div>
            <img src={noServiceIcon} alt="no service" />
            <span className="v3-inquiry-form__no-service-description">
              {t("CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_NO_COUNTRY_SOON")}
            </span>
          </div>
        </div>
      )}
      {fields.categoryForm && (
        <>
          <div className="row v3-inquiry-form__tender-date-wrapper">
            <div className="col-sm-12 col-md-6" ref={tenderRef}>
              <CustomCheckBox
                id="call-for-tender"
                name="call-for-tender"
                onChange={onChange}
                checked={fields.isCallForTender?.value}
                label={t("CLIENT_PLATFORM_ONBOARDING_CALL_FOR_TENDER")}
              />
            </div>
            <div className="col-sm-12 col-md-6" ref={dateRef}>
              <CustomDatePicker
                countryId={String(countryId)}
                placeholder={t("")}
                divClass="v3-inquiry-form__date-wrapper"
                labelClass="form-label required"
                labelName={
                  !fields.isCallForTender?.value
                    ? t("CLIENT_PLATFORM_ONBOARDING_SITE_START_DATE")
                    : t("CLIENT_PLATFORM_ONBOARDING_SITE_EXPECTED_REPLY_DATE")
                }
                inputClass={
                  fields.start_date?.value
                    ? "v3-inquiry-form__date-input form-input v3-inquiry-form__date-input--filled"
                    : fields.start_date?.error
                    ? "v3-inquiry-form__date-input form-input border border-danger"
                    : "v3-inquiry-form__date-input form-input"
                }
                required
                id="start_date"
                name="start_date"
                value={fields.start_date?.value.toString()}
                onChange={onChange}
                dateFormat="dd-MM-yyyy"
                minDate={new Date().toDateString()}
                calendarIconClass=""
                error={fields.start_date?.error !== ""}
                errorMsg={fields.start_date ? t(fields.start_date?.error) : ""}
              />
            </div>
          </div>
          <div className="v3-inquiry-form__range-slider-wrapper">
            <h2 className="v3-inquiry-form__range-slider-title" ref={hoursRef}>
              {t("CLIENT_PLATFORM_ONBOARDING_SITE_OPENING_HOURS")}
            </h2>
            <RangeSlider
              defaultValue={[fields.minHour?.value, fields.maxHour?.value]}
              step={0.5}
              max={24}
              min={0}
              value={[Number(fields.minHour?.value), Number(fields.maxHour?.value)]}
              onChange={onChange}
              tooltip
              name="range-slider"
              barClassName="v3-inquiry-form__range-slider"
            />
          </div>
          <div ref={commentsRef}>
            <TextAreaInput
              labelName={t("CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_SITE_INFORMATION")}
              inputClass={
                fields.jobsite_comment?.value
                  ? "v3-inquiry-form__comment-input w-100 v3-inquiry-form__comment-input--filled"
                  : "v3-inquiry-form__comment-input w-100"
              }
              divClass={
                fields.jobsite_comment?.value || isCommentInputFocused
                  ? "v3-inquiry-form__comment v3-inquiry-form__comment--focussed"
                  : "v3-inquiry-form__comment"
              }
              labelClass="v3-inquiry-form__comment-label"
              required={false}
              rows={5}
              columns={100}
              id="comment"
              name="comment"
              value={fields.jobsite_comment?.value}
              onChange={(e) => onChange(e)}
              onFocus={() => setCommentInputFocus(true)}
              onBlur={() => {
                if (!fields.jobsite_comment?.value) {
                  setCommentInputFocus(false);
                }
              }}
            />
          </div>
          {documentLabels && (
            <FileInputGroup
              data={{ document: prepareDocumentList(documents) }}
              labelNameData={documentLabels}
              fileGroupName="request"
              handleData={handleFiles}
              deleteFile={() => {}}
            />
          )}
        </>
      )}
    </div>
  );
};

InquiryForm.propTypes = {
  fields: PropTypes.shape({
    categoryForm: PropTypes.number,
    categoryFormData: PropTypes.shape({
      value: PropTypes.number,
      error: PropTypes.string,
      required: PropTypes.bool,
    }),
    address: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.string,
      required: PropTypes.bool,
    }),
    quantity: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.string,
      required: PropTypes.bool,
    }),
    start_date: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.string,
      required: PropTypes.bool,
    }),
    selectedItems: PropTypes.shape({
      service: PropTypes.number,
      category: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    documents: PropTypes.shape({
      value: PropTypes.arrayOf(PropTypes.object),
      error: PropTypes.string,
    }),
    countryId: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.string,
    }),
    isCallForTender: PropTypes.shape({
      value: PropTypes.bool,
      error: PropTypes.string,
    }),
    minHour: PropTypes.shape({
      value: PropTypes.number,
      error: PropTypes.string,
    }),
    maxHour: PropTypes.shape({
      value: PropTypes.number,
      error: PropTypes.string,
    }),
    jobsite_comment: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.string,
    }),
  }).isRequired,
  countryId: PropTypes.number.isRequired,
  language: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  onFormUpdate: PropTypes.func.isRequired,
};

export default withTranslation()(InquiryForm);
