import React from "react";
import ReactDOM from "react-dom";
import "rsuite/dist/rsuite.min.css";

import App from "./app/App";

import reportWebVitals from "./reportWebVitals";

import "bootstrap/dist/js/bootstrap.min.js";

if (process.env.NODE_ENV === "development") {
  window.__DEV__ = true;
  window.debugMode = true;
} else {
  window.__DEV__ = false;
  window.debugMode = false;
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
