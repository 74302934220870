import React, { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { createSite } from "../../../../../modules/JobsiteForm/JobsiteForm.actionCreators";

import "./SiteHeader.scss";

const SiteHeader = (props) => {
  const { t } = props;
  const { pathname } = window.location;

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    let lng = "en";
    if (localStorage.getItem("userCountryId")) {
      const countryId = localStorage.getItem("userCountryId").toString();
      if (countryId === "1") {
        lng = "en";
      } else if (countryId === "2") {
        lng = "fr";
      } else if (countryId === "3") {
        lng = "pl";
      } else if (countryId === "6") {
        lng = "de";
      } else {
        lng = "ch";
      }
    }
    setTimeout(() => {
      props.i18n.changeLanguage(lng);
    }, 200);
  }, [props.i18n]);

  const handleScroll = (e) => {
    const header = document.getElementById("tableHeader");
    const sticky = header ? header.offsetTop : 0;

    if (window.pageYOffset > sticky) {
      header?.classList.add("sticky");
    } else {
      header?.classList.remove("sticky");
    }
  };

  return (
    <div className="listing-content add-site site-btn-max">
      <div className="list-header" id="tableHeader">
        <NavLink to="/createjobsite" onClick={() => props.createSite()}>
          <button className="btn btn-primary btn-blue btn-sm mt-2 mb-3 list-site-btn">
            <i className="fa fa-plus mr-2" />
            {t("CLIENT_PLATFORM_SITE_LIST_BUTTON_NEW_SITE")}
          </button>
        </NavLink>
        <div className="d-none d-md-flex row header-row data-table">
          <div className="col-xs-12 col-sm-12 col-md-6">
            <span className="title title-site">
              {t("CLIENT_PLATFORM_SITE_LIST_TABLE_SITE_NAME")}
            </span>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-2">
            <span className="title">
              {t("CLIENT_PLATFORM_SITE_LIST_TABLE_SITE_START_DURATION")}
            </span>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-2">
            <span className="title">
              {pathname === "/sitesandenquiries"
                ? t("CLIENT_PLATFORM_SITE_LIST_TABLE_SITE_INQUIRIES")
                : "call-off orders"}
            </span>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-2">
            <span className="title">
              {t("CLIENT_PLATFORM_SITE_LIST_TABLE_SITE_QUICK_ACTIONS")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

SiteHeader.propTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  createSite: () => createSite(),
};

export default connect(null, mapDispatchToProps)(withTranslation()(SiteHeader));
