import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { storage } from "../../../../store/dataStore/api";

import EnquiryCategories from "./components/EnquiryCategories/EnquiryCategories";
import JobsiteSelector from "./components/JobsiteSelector";
import RequestDetails from "./components/RequestDetails";
import ErrorPopUp from "../../../../shared/baseUI/ErrorPopUp";

import TextInput from "../../../../shared/baseUI/TextInput";
import SelectInput from "../../../../shared/baseUI/CustomSelectBox";

import {
  prepareUnits,
  prepareDocumentLabel,
  prepareProduct,
  getDurationOptions,
  getAllowedDocTypes,
  getAllowedFileTypes,
  isEmpty,
  getUnits,
} from "../../../../shared/utils/methods";

import "./EnquiryForm.scss";

const EnquiryForm = (props) => {
  const {
    t,
    siteMasterData,
    selectedJobSite,
    categoryForm,
    selectedService,
    subCategory,
    data,
    transport,
    requestSelectedItems,
    userCountry,
    loginUserCountry,
    productData,
    selectedMaterialProductData,
    countryId,
    isRequestCategoriesLoading,
    isSiteMasterListLoading,
    handleRequestFormDetails,
    selectedJobSiteForRequestCreation,
    siteMasterDataRaw,
    deleteDocument,
    updateRowValue,
    getSiteMasterListData,
  } = props;

  const [isJobSiteSelected, setJobsiteSelected] = useState(false);
  const [isJobSiteIdExists, setJobsiteExistStatus] = useState(false);
  const [selectedSite, setSelectedSite] = useState({ id: "", name: "" });
  const [productUnit, setProductUnit] = useState();
  const [documentNames, setDocumentsName] = useState();
  const [products, setProducts] = useState();
  const [productInput, setProductInput] = useState();
  const [isAnalysisEnabled, setAnalysisFlag] = useState();
  const [analysis_flag, setAnalysisVal] = useState();
  const [priceInput, setPriceInput] = useState();
  const [showErrorPopup, setErrorPopupvisibility] = useState(false);
  const [apiError, setApiError] = useState("");
  const [fields, setFields] = useState({
    quantity: { value: "", error: "" },
    unit: { value: "", error: "" },
    service: { value: "", error: "" },
    selectedTransport: { value: [], error: "" },
    truck_per_day: { value: "", error: "" },
    start_date: { value: "", error: "" },
    duration: { value: "", error: "" },
    analysis_flag: { value: "", error: "" },
    promocode: { value: "", error: "" },
    comment: { value: "", error: "" },
    terms: { value: "", error: "" },
    categoryForm: { value: "", error: "" },
    document: { value: "", error: "" },
    initialRow: { value: "", error: "" },
    is_call_for_tender: { value: "", error: "" },
    price: { value: "", error: "" },
    product: { value: "", error: "" },
    sub_category_id: { value: "", error: "" },
    usage_id: { value: "", error: "" },
    transport: { value: "", error: "" },
    jobsite_document: { value: "", error: "" },
    isRequestEdit: { value: "", error: "" },
    id: { value: "", error: "" },
  });

  const durationOptions = getDurationOptions(t);

  const units = prepareUnits(getUnits(t));

  useEffect(() => {
    storage();

    if (selectedJobSite.id === "") {
      // New request.
      setJobsiteSelected(true);
      setJobsiteExistStatus(true);
      // If only single jobsite available, then select the site by default.
      if (siteMasterDataRaw.length === 1) {
        const { id, name } = siteMasterDataRaw[0];
        // Show only once, on form load.
        setSelectedSite({ id, name });
        updateSelectedSiteStatus(id, name);
      }
      // pre-fill data from localStorage if available.
      preFillData();
    } else {
      // Edit request.
      const updatedData = { ...data };

      handleRequestFormDetails({
        updatedData: {
          ...updatedData,
          terms: updatedData.editData ? 1 : updatedData.terms,
          start_date: updatedData.start_date
            ? updatedData.start_date
            : selectedJobSite.start_date,
          duration: updatedData.duration
            ? updatedData.duration
            : selectedJobSite.duration,
          document:
            updatedData.document.length > 0
              ? updatedData.document
              : selectedJobSite.document,
          jobsite_document:
            updatedData.document.length > 0
              ? updatedData.document
              : selectedJobSite.document,
        },
      });
    }
    if (siteMasterData.length === 0) {
      getSiteMasterListData();
    }
    updateFields(data, categoryForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setProductUnit(loginUserCountry.currency);
  }, [loginUserCountry.currency]);

  useEffect(() => {
    let documentNamesLocal;
    let productsLocal;
    let productInputLocal;
    let productLabel;
    let priceInputLocal;
    if (fields.categoryForm.value !== null && fields.categoryForm.value !== "") {
      if (selectedService === 1 && subCategory.length > 0) {
        if (subCategory.some((x) => x.sub_category.category_id === 1)) {
          documentNamesLocal = prepareDocumentLabel([
            {
              type: 1,
              name: t("CLIENT_PLATFORM_REQUEST_FORM_DOCUMENT_SOIL_REPORT"),
              accept: getAllowedFileTypes(),
              allowedFileTypes: getAllowedDocTypes(),
            },
            {
              type: 2,
              name: t("CLIENT_PLATFORM_REQUEST_FORM_DOCUMENT_GEOTECHNICAL_ANALYSIS"),
              accept: getAllowedFileTypes(),
              allowedFileTypes: getAllowedDocTypes(),
            },
            {
              type: 3,
              name: t("CLIENT_PLATFORM_REQUEST_FORM_DOCUMENT_OTHER_DOCUMENTS"),
              accept: getAllowedFileTypes(),
              allowedFileTypes: getAllowedDocTypes(),
            },
          ]);
          if (productData.length > 0) {
            const selectedProductData =
              productData.filter((i) => i.id === fields.categoryForm.value).length >
              0
                ? productData.filter((i) => i.id === fields.categoryForm.value)
                : [];
            productsLocal =
              selectedProductData.length > 0 ? selectedProductData[0].data : [];
          }
          setAnalysisFlag(true);
          if (parseInt(fields.categoryForm.value, 10) === 1) {
            productLabel = t("CLIENT_PLATFORM_REQUEST_FORM_PRODUCT_SOIL");
          } else if (parseInt(fields.categoryForm.value, 10) === 2) {
            productLabel = t("CLIENT_PLATFORM_REQUEST_FORM_PRODUCT_SOIL");
          } else if (parseInt(fields.categoryForm.value, 10) === 5) {
            productLabel = t("CLIENT_PLATFORM_REQUEST_FORM_PRODUCT_WASTE");
          } else {
            productLabel = "";
          }
          if (parseInt(fields.categoryForm.value, 10) !== 5) {
            productInputLocal = (
              <SelectInput
                value={fields.product.value}
                inputClass={
                  fields.product.error
                    ? "request-details__section-input border-danger"
                    : "request-details__section-input"
                }
                divClass="request-details__input-wrapper"
                labelClass="request-details__section-input-label required"
                required
                labelName={productLabel}
                name="product"
                error={!!fields.product.error}
                errorMsg={fields.product.error}
                defaultOption={t("CLIENT_PLATFORM_REQUEST_FORM_PRODUCT_PLACEHOLDER")}
                onChange={(e) => onChange(e)}
                options={productsLocal}
              />
            );
          }
        } else if (subCategory.some((x) => x.sub_category.category_id === 2)) {
          documentNamesLocal = prepareDocumentLabel([
            {
              type: 3,
              name: t("MATERIAL_DATA_DOCUMENTS"),
              accept: getAllowedFileTypes(),
              allowedFileTypes: getAllowedDocTypes(),
            },
          ]);
          if (productData.length > 0) {
            const selectedProductData =
              productData.filter(
                (i) => i.id === parseInt(fields.categoryForm.value, 10)
              ).length > 0
                ? productData.filter(
                    (i) => i.id === parseInt(fields.categoryForm.value, 10)
                  )
                : [];
            productsLocal =
              selectedProductData.length > 0 ? selectedProductData[0].data : [];
          }
          productInputLocal = (
            <SelectInput
              value={fields.product.value}
              inputClass={
                fields.product.error
                  ? "request-details__section-input border-danger"
                  : "request-details__section-input"
              }
              divClass="request-details__input-wrapper"
              labelClass="request-details__section-input-label required"
              required
              labelName={t("CLIENT_PLATFORM_REQUEST_FORM_PRODUCT_MATERIAL")}
              name="product"
              error={!!fields.product.error}
              errorMsg={fields.product.error}
              defaultOption={t("CLIENT_PLATFORM_REQUEST_FORM_PRODUCT_PLACEHOLDER")}
              onChange={(e) => onChange(e)}
              options={productsLocal}
            />
          );
          priceInputLocal = (
            <div className="row m-0">
              <div className="col-10">
                <TextInput
                  type="text"
                  placeholder={t("CLIENT_PLATFORM_REQUEST_FORM_PRICE_PLACEHOLDER")}
                  inputClass={
                    fields.price.error
                      ? "request-details__section-input border-danger"
                      : "request-details__section-input"
                  }
                  divClass="request-details__input-wrapper"
                  labelClass="request-details__section-input-label"
                  labelName={t("CLIENT_PLATFORM_REQUEST_FORM_PRICE")}
                  required={false}
                  id="price"
                  name="price"
                  value={fields.price.value}
                  error={!!fields.price.error}
                  errorMsg={fields.price.error}
                  onChange={(e) => onChange(e)}
                />
              </div>
              <div className="col-2 d-flex align-items-center fontLato16 gray-text p-0">
                {productUnit}/m<sup>3</sup>
              </div>
            </div>
          );
        } else {
          documentNamesLocal = prepareDocumentLabel([
            {
              type: 3,
              name: t("WASTE_DOCUMENT"),
              accept: getAllowedFileTypes(),
              allowedFileTypes: getAllowedDocTypes(),
            },
          ]);
          if (productData.length > 0) {
            const selectedProductData =
              productData.filter(
                (i) => i.id === parseInt(fields.categoryForm.value, 10)
              ).length > 0
                ? productData.filter(
                    (i) => i.id === parseInt(fields.categoryForm.value, 10)
                  )
                : [];
            productsLocal =
              selectedProductData.length > 0 ? selectedProductData[0].data : [];
          }
          productInputLocal = (
            <SelectInput
              value={fields.product.value}
              inputClass={
                fields.product.error
                  ? "request-details__section-input border-danger"
                  : "request-details__section-input"
              }
              divClass="request-details__input-wrapper"
              labelClass="request-details__section-input-label required"
              required
              labelName={t("PRODUCT")}
              name="product"
              error={!!fields.product.error}
              errorMsg={fields.product.error}
              defaultOption={t("CLIENT_PLATFORM_REQUEST_FORM_PRODUCT_PLACEHOLDER")}
              onChange={(e) => onChange(e)}
              options={productsLocal}
            />
          );
        } /* END OF SERVICE1 */
      } else {
        if (
          subCategory.length > 0 &&
          (subCategory.some((x) => x.hasOwnProperty("products"))
            ? subCategory.some((x) => x.id === 3) ||
              subCategory.some((x) => x.id === 4)
            : subCategory.some((x) => x.sub_category.category_id === 1) ||
              subCategory.some((x) => x.sub_category.category_id === 2))
        ) {
          documentNamesLocal = prepareDocumentLabel([
            {
              type: 3,
              name: t("CLIENT_PLATFORM_REQUEST_FORM_DOCUMENT_OTHER_DOCUMENTS"),
              accept: getAllowedFileTypes(),
              allowedFileTypes: getAllowedDocTypes(),
            },
            {
              type: 4,
              name: t("SPECIFICATIONS"),
              accept: getAllowedFileTypes(),
              allowedFileTypes: getAllowedDocTypes(),
            },
          ]);
        }

        if (subCategory.some((x) => x.hasOwnProperty("products"))) {
          const selectedUsageProducts = [].concat.apply(
            [],
            selectedMaterialProductData.map((x) => x.data)
          );

          const selectedMaterialProduct = selectedUsageProducts.filter(
            (x) => x.id === parseInt(fields.categoryForm.value, 10)
          );
          if (subCategory.some((x) => x.id === 3)) {
            productsLocal =
              selectedMaterialProduct.length > 0
                ? prepareProduct(selectedMaterialProduct[0].products)
                : [];
          }

          productInputLocal = (
            <SelectInput
              value={fields.product.value}
              inputClass={
                fields.product.error
                  ? "request-details__section-input border-danger"
                  : "request-details__section-input"
              }
              divClass="request-details__input-wrapper"
              labelClass="request-details__section-input-label required"
              required
              labelName={t("MATERIAL_TYPE")}
              name="product"
              error={!!fields.product.error}
              errorMsg={fields.product.error}
              defaultOption={t("CLIENT_PLATFORM_REQUEST_FORM_PRODUCT_PLACEHOLDER")}
              onChange={(e) => onChange(e)}
              options={productsLocal}
            />
          );
        } else {
          if (subCategory.some((x) => x.sub_category.category_id === 1)) {
            if (productData.length > 0) {
              const selectedProductData =
                productData.filter(
                  (i) => i.id === parseInt(fields.categoryForm.value, 10)
                ).length > 0
                  ? productData.filter(
                      (i) => i.id === parseInt(fields.categoryForm.value, 10)
                    )
                  : [];
              productsLocal =
                selectedProductData.length > 0 ? selectedProductData[0].data : [];
            }
          }
          if (subCategory.some((x) => x.sub_category.category_id === 2)) {
            if (productData.length > 0) {
              const selectedProductData =
                productData.filter(
                  (i) => i.id === parseInt(fields.categoryForm.value, 10)
                ).length > 0
                  ? productData.filter(
                      (i) => i.id === parseInt(fields.categoryForm.value, 10)
                    )
                  : [];
              productsLocal =
                selectedProductData.length > 0 ? selectedProductData[0].data : [];
            }
            productInputLocal = (
              <SelectInput
                value={fields.product.value}
                inputClass={
                  fields.product.error
                    ? "request-details__section-input border-danger"
                    : "request-details__section-input"
                }
                divClass="request-details__input-wrapper"
                labelClass="request-details__section-input-label required"
                required
                labelName={t("MATERIAL_TYPE")}
                name="product"
                error={!!fields.product.error}
                errorMsg={fields.product.error}
                defaultOption={t("CLIENT_PLATFORM_REQUEST_FORM_PRODUCT_PLACEHOLDER")}
                onChange={(e) => onChange(e)}
                options={productsLocal}
              />
            );
          }
          priceInputLocal = (
            <div className="row m-0">
              <div className="col-10">
                <TextInput
                  type="text"
                  placeholder={t("CLIENT_PLATFORM_REQUEST_FORM_PRICE_PLACEHOLDER")}
                  inputClass={
                    fields.price.error
                      ? "request-details__section-input border-danger"
                      : "request-details__section-input"
                  }
                  divClass="request-details__input-wrapper"
                  labelClass="request-details__section-input-label"
                  labelName={t("CLIENT_PLATFORM_REQUEST_FORM_PRICE")}
                  required={false}
                  id="price"
                  name="price"
                  value={fields.price.value}
                  error={!!fields.price.error}
                  errorMsg={fields.price.error}
                  onChange={(e) => onChange(e)}
                />
              </div>
              <div className="col-2 d-flex align-items-center fontLato16 gray-text p-0">
                {productUnit}/m<sup>3</sup>
              </div>
            </div>
          );
        }
      }
    }
    setDocumentsName(documentNamesLocal);
    setProducts(productsLocal);
    setProductInput(productInputLocal);
    setPriceInput(priceInputLocal);
    setFields(fields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fields,
    fields.categoryForm.value,
    fields.price.value,
    fields.price.error,
    fields.product.value,
    fields.product.error,
    subCategory,
    selectedMaterialProductData,
    selectedService,
    productData,
    productUnit,
    t,
  ]);

  useEffect(() => {
    updateFields(data, categoryForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, categoryForm]);

  useEffect(() => {
    if (!isRequestCategoriesLoading && !isSiteMasterListLoading) {
      // this is called when page is reloaded.
      preFillData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequestCategoriesLoading, isSiteMasterListLoading]);

  // for handling errors from api response.
  useEffect(() => {
    const errorData = data?.error?.data;
    const localFields = { ...fields };
    if (data?.error?.status && data?.error?.status !== 200) {
      setErrorPopupvisibility(true);
      setApiError(t(data?.error?.message));
    }
    if (errorData && errorData.product) {
      if (errorData.product[0] === "validation.required") {
        localFields.product.error = t(
          "CLIENT_PLATFORM_REQUEST_FORM_PRODUCT_MISSING_ERROR"
        );
      }
    }
    if (errorData && errorData.quantity) {
      if (errorData.quantity[0] === "validation.required") {
        localFields.quantity.error = t(
          "CLIENT_PLATFORM_REQUEST_FORM_QUANTITY_MISSING_ERROR"
        );
      }
    }
    if (errorData && errorData.transport) {
      if (errorData.transport[0] === "validation.required") {
        localFields.transport.error = t(
          "CLIENT_PLATFORM_REQUEST_FORM_TRANSPORT_TYPE_MISSING_ERROR"
        );
      }
    }
    if (errorData && errorData.unit) {
      if (errorData.unit[0] === "validation.required") {
        localFields.unit.error = t(
          "CLIENT_PLATFORM_REQUEST_FORM_UNIT_MISSING_ERROR"
        );
      }
    }
    if (errorData && errorData.truck_per_day) {
      if (errorData.truck_per_day[0] === "validation.required") {
        localFields.truck_per_day.error = t(
          "CLIENT_PLATFORM_REQUEST_FORM_LOADS_PER_DAY_MISSING_ERROR"
        );
      }
    }
    if (errorData && errorData.start_date) {
      if (errorData.start_date[0] === "validation.required") {
        localFields.start_date.error = t(
          "CLIENT_PLATFORM_SITE_FORM_REQUEST_START_DATE_MISSING_ERROR"
        );
      }
    }
    if (errorData && errorData.duration) {
      if (errorData.duration[0] === "validation.required") {
        localFields.duration.error = t(
          "CLIENT_PLATFORM_SITE_FORM_REQUEST_DURATION_MISSING_ERROR"
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.error, t]);

  const preFillData = () => {
    const storedFields = getStoredData("requestData");
    const storedJobSite = getStoredData("selectedJobsiteForRequest");
    const storedService = getStoredData("requestService");
    const storedCategory = getStoredData("requestCategory");
    const storedSubCategory = getStoredData("requestSubCategory");

    if (storedFields.usage_id) {
      props.getMaterialProductData({ id: storedFields.usage_id });
    }
    if (!isEmpty(storedFields)) {
      updateFields(storedFields, categoryForm);
      handleRequestFormDetails({ updatedData: storedFields });
    }
    if (!isEmpty(storedJobSite)) {
      updateSelectedSiteStatus(storedJobSite.id, storedJobSite.name);
    }
    if (!isEmpty(storedService)) {
      props.selectCategory(storedService.id);
    }
    if (!isEmpty(storedCategory)) {
      props.selectSubCategory(storedCategory.id);
    }
    if (!isEmpty(storedSubCategory)) {
      if (!productData.some((prod) => prod.id === storedSubCategory.id)) {
        props.getProductData({
          subCatId: storedSubCategory.id,
          service: storedService.id,
        });
      }
      props.selectCategoryForm(storedSubCategory.id);
    }
  };

  const getStoredData = (itemName) => {
    const localStoredData = localStorage.getItem(itemName);

    if (localStoredData && JSON.parse(localStoredData)) {
      return JSON.parse(localStoredData);
    }

    return {};
  };

  const updateFields = (formData, categoryFormVal) => {
    const fieldsLocal = { ...fields };
    fieldsLocal.quantity.value = formData.quantity;
    fieldsLocal.unit.value = formData.unit;
    fieldsLocal.service.value = formData.service;
    fieldsLocal.selectedTransport.value = formData.selectedTransport;
    fieldsLocal.truck_per_day.value = formData.truck_per_day;
    fieldsLocal.start_date.value = formData.start_date;
    fieldsLocal.duration.value = formData.duration;
    fieldsLocal.analysis_flag.value = formData.analysis_flag;
    fieldsLocal.promocode.value = formData.promocode;
    fieldsLocal.comment.value = formData.comment;
    fieldsLocal.terms.value = formData.terms;
    fieldsLocal.categoryForm.value = categoryFormVal;
    fieldsLocal.document.value = formData.document;
    fieldsLocal.initialRow.value = formData.initialRow;
    fieldsLocal.is_call_for_tender.value = formData.is_call_for_tender;
    fieldsLocal.price.value = formData.price;
    fieldsLocal.product.value = formData.product;
    fieldsLocal.sub_category_id.value = formData.sub_category_id;
    fieldsLocal.usage_id.value = formData.usage_id;
    fieldsLocal.transport.value = formData.transport;
    fieldsLocal.isRequestEdit.value = formData.editData;
    fieldsLocal.id.value = formData.id;
    if (formData.editData) {
      setJobsiteSelected(false);
      setJobsiteExistStatus(false);
    }
    setFields({ ...fieldsLocal });
  };

  const getEventName = (e) => {
    if (e.target) {
      if (e.target.name !== "") {
        return e.target.name;
      }
      return "";
    }
    return e.name;
  };

  const onChange = (e, i, id) => {
    const { getMaterialProductData, getProductData, materialProductData } = props;
    const name = getEventName(e);
    const listName = e.currentTarget?.attributes.name.value;
    const value = e.target ? e.target.value : e.value;
    const checked = e.target?.checked;
    const fieldsLocal = { ...fields };
    if (name) {
      fieldsLocal[name] = { value, error: "" };
    }

    if (name === "usage_id") {
      if (!materialProductData.some((material) => material.id === id)) {
        getMaterialProductData({ id: value });
      }
    }

    if (name === "truck_per_day") {
      const trucksVal = parseInt(value, 10) ? parseInt(value, 10) : "";
      fieldsLocal[name] = { value: trucksVal, error: "" };

      if (!parseInt(value, 10)) {
        if (value) {
          fieldsLocal[name] = {
            value: trucksVal,
            error: t("CLIENT_PLATFORM_REQUEST_FORM_LOADS_PER_DAY_WRONG_VALUE_ERROR"),
          };
        } else {
          fieldsLocal[name] = {
            value: trucksVal,
            error: t("CLIENT_PLATFORM_REQUEST_FORM_LOADS_PER_DAY_MISSING_ERROR"),
          };
        }
      }
    }

    if (name === "quantity") {
      const quantityVal = parseInt(value, 10) ? parseInt(value, 10) : "";
      fieldsLocal[name] = { value: quantityVal, error: "" };
      if (!value) {
        fieldsLocal[name] = {
          value: quantityVal,
          error: t("CLIENT_PLATFORM_REQUEST_FORM_QUANTITY_MISSING_ERROR"),
        };
      } else if (!/^\d+$/.test(value)) {
        fieldsLocal[name] = {
          value: quantityVal,
          error: t("CLIENT_PLATFORM_REQUEST_FORM_QUANTITY_WRONG_VALUE_ERROR"),
        };
      }
    }

    if (name === "product") {
      if (
        selectedService === 2 &&
        subCategory[0].sub_category_id &&
        subCategory.some((x) => x.sub_category.category_id === 1)
      ) {
        fieldsLocal[name] = { value: id, error: "" };
      } else {
        fieldsLocal[name] = {
          value: parseInt(value, 10) ? [parseInt(value, 10)] : [],
          error: "",
        };
      }
    }
    if (name === "analysis_flag") {
      setAnalysisVal(!analysis_flag);
      if (!analysis_flag) {
        fieldsLocal[name] = { value: 1, error: "" };
      } else {
        fieldsLocal[name] = { value: 0, error: "" };
      }
    }
    if (name === "duration") {
      if (fieldsLocal.start_date.value === "") {
        fieldsLocal[name] = {
          value: "",
          error: t("CLIENT_PLATFORM_SITE_FORM_REQUEST_START_DATE_MISSING_ERROR"),
        };
      } else {
        fieldsLocal[name] = {
          value,
          error: "",
        };
      }
    }

    if (name === "is_call_for_tender" || name === "terms") {
      if (checked) {
        fieldsLocal[name] = { value: 1, error: "" };
      } else {
        fieldsLocal[name] = { value: 0, error: "" };
      }
    }

    if (name === "price") {
      const isPriceValid = validatePrice(value);
      if (isPriceValid) {
        fieldsLocal[name] = { value, error: "" };
      } else {
        fieldsLocal[name] = {
          value: "",
          error: t("CLIENT_PLATFORM_REQUEST_FORM_PRICE_WRONG_VALUE_ERROR"),
        };
      }
    }

    if (listName === "service") {
      fieldsLocal.service = { value: id, error: "" };
      localStorage.setItem("requestService", JSON.stringify({ id }));
    }

    if (listName === "category") {
      localStorage.setItem("requestCategory", JSON.stringify({ id }));
    }

    if (listName === "subcategory") {
      if (!subCategory[0].hasOwnProperty("products")) {
        if (!productData.some((prod) => prod.id === id)) {
          getProductData({ subCatId: id, service: selectedService });
        }
      }

      fieldsLocal.sub_category_id = { value: id, error: "" };
      localStorage.setItem("requestSubCategory", JSON.stringify({ id }));
    }
    setFields({ ...fieldsLocal });
    saveDataToStore({ ...fieldsLocal }, fieldsLocal.isRequestEdit.value, listName);
  };

  /**
   * On selecting jobsite from dropdown, update the related info.
   */
  const onJobsiteSelect = (e) => {
    const id = e ? parseInt(e.split("-")[0], 10) : "";
    const name = e ? e.split("-")[1].trim() : "";

    setSelectedSite({ id: "", name: "" }); // on jobsite change reset the flags.
    updateSelectedSiteStatus(id, name);

    localStorage.setItem("selectedJobsiteForRequest", JSON.stringify({ id, name }));
  };

  const updateSelectedSiteStatus = (id, name) => {
    selectedJobSiteForRequestCreation({ id, name });

    let startDateLocal = "";
    let durationLocal = "";
    let documents = [];

    const selectedSiteLocal = siteMasterDataRaw.filter((site) => site.id === id);
    if (selectedSiteLocal.length > 0) {
      startDateLocal = selectedSiteLocal[0].start_date;
      durationLocal = selectedSiteLocal[0].duration;
      documents = selectedSiteLocal[0].documents;
    }

    const localFields = { ...fields };
    localFields.start_date.value = startDateLocal;
    localFields.duration.value = durationLocal;
    localFields.document.value = documents;
    localFields.jobsite_document.value = documents;
    setFields({ ...localFields });
    setJobsiteSelected(false);

    saveDataToStore({ ...localFields }, localFields.isRequestEdit.value, "");
  };

  const validatePrice = (price) => {
    if (price === null || price === "") return true;
    if (Number.isNaN(price)) {
      return /^-?\d+(?:,\d+)?$/.test(price);
    }

    return true;
  };

  const onTransportSelect = (e) => {
    const existingOptions = data.transport;
    const existingOptionLabels = data.selectedTransport;
    const selectedOptions = e.map((x) => x.value);
    const selectedOptionLabels = e.map((x) => x.label);
    const fieldsLocal = { ...fields };

    if (selectedOptions.length > 0) {
      const finalOptions =
        selectedOptions[0] === undefined
          ? [...existingOptions, ...selectedOptions].filter(
              (option) => option !== undefined
            )
          : [...selectedOptions];
      const finalLabels =
        selectedOptionLabels[0] === undefined
          ? [...existingOptionLabels, ...selectedOptionLabels].filter(
              (option) => option !== undefined
            )
          : [...selectedOptionLabels];
      // Set object is used to remove duplicates.
      fieldsLocal.transport = {
        value: [...new Set(finalOptions)],
        error: "",
      };
      fieldsLocal.selectedTransport = {
        value: [...new Set(finalLabels)],
        error: "",
      };
    } else {
      fieldsLocal.transport = { value: [], error: "" };
      fieldsLocal.selectedTransport = {
        value: [],
        error: "",
      };
    }
    setFields({ ...fieldsLocal });
    saveDataToStore({ ...fieldsLocal }, fieldsLocal.isRequestEdit.value, "");
  };

  const deleteFile = (url) => {
    deleteDocument(url);
  };

  /**
   * For supply of inert soil.
   * service - 2
   * category - 1
   * sub_category_id - 2
   */
  const AddProductInput = (e, id) => {
    updateRowValue({ id, value: e.target.value });
  };

  const onDateChange = (e) => {
    const date = new Date(e);
    if (date.toDateString() !== "Invalid Date") {
      const validField = isDateValid(e);
      if (validField) {
        setFields({
          ...fields,
          start_date: { value: validField.value, error: validField.error },
        });
        saveDataToStore(
          {
            ...fields,
            start_date: { value: validField.value, error: validField.error },
          },
          fields.isRequestEdit.value,
          ""
        );
      }
    }
  };

  const isDateValid = (value) => {
    const start_date = {};
    const date = value.getDate() < 10 ? `0${value.getDate()}` : value.getDate();
    const month =
      value.getMonth() + 1 < 10 ? `0${value.getMonth() + 1}` : value.getMonth() + 1;
    start_date.value = `${value.getFullYear()}-${month}-${date}`;
    start_date.error = "";
    if (value === "") {
      start_date.error = "CLIENT_PLATFORM_SITE_FORM_SITE_START_DATE_MISSING_ERROR";
    }

    return start_date;
  };

  const saveDataToStore = (updatedFields, isFormEdit, listName) => {
    if (isFormEdit) {
      localStorage.setItem(
        "requestData",
        JSON.stringify({
          analysis_flag: updatedFields.analysis_flag.value
            ? updatedFields.analysis_flag.value
            : 0,
          comment: updatedFields.comment.value,
          document: updatedFields.document.value,
          duration: updatedFields.duration.value ? updatedFields.duration.value : "",
          initialRow: updatedFields.initialRow.value,
          is_call_for_tender: updatedFields.is_call_for_tender.value
            ? updatedFields.is_call_for_tender.value
            : 0,
          id: updatedFields.id.value,
          editData: isFormEdit,
          price: updatedFields.price.value,
          product: updatedFields.product.value,
          promocode: updatedFields.promocode.value,
          quantity: updatedFields.quantity.value,
          service: updatedFields.service.value,
          start_date: updatedFields.start_date.value
            ? updatedFields.start_date.value
            : "",
          sub_category_id: updatedFields.sub_category_id.value,
          terms: 1,
          transport: updatedFields.transport.value,
          truck_per_day: updatedFields.truck_per_day.value,
          unit: updatedFields.unit.value,
          usage_id: updatedFields.usage_id.value,
          selectedTransport: updatedFields.selectedTransport.value,
        })
      );
      handleRequestFormDetails({
        updatedData: {
          analysis_flag: updatedFields.analysis_flag.value
            ? updatedFields.analysis_flag.value
            : 0,
          comment: updatedFields.comment.value,
          document: updatedFields.document.value,
          duration: updatedFields.duration.value ? updatedFields.duration.value : "",
          initialRow: updatedFields.initialRow.value,
          is_call_for_tender: updatedFields.is_call_for_tender.value
            ? updatedFields.is_call_for_tender.value
            : 0,
          id: updatedFields.id.value,
          editData: isFormEdit,
          price: updatedFields.price.value,
          product: updatedFields.product.value,
          promocode: updatedFields.promocode.value,
          quantity: updatedFields.quantity.value,
          service: updatedFields.service.value,
          start_date: updatedFields.start_date.value
            ? updatedFields.start_date.value
            : "",
          sub_category_id: updatedFields.sub_category_id.value,
          terms: 1,
          transport: updatedFields.transport.value,
          truck_per_day: updatedFields.truck_per_day.value,
          unit: updatedFields.unit.value,
          usage_id: updatedFields.usage_id.value,
          selectedTransport: updatedFields.selectedTransport.value,
        },
      });
    } else if (listName === "service" || listName === "subcategory") {
      localStorage.setItem(
        "requestData",
        JSON.stringify({
          analysis_flag: updatedFields.analysis_flag.value
            ? updatedFields.analysis_flag.value
            : 0,
          comment: "",
          document: updatedFields.document.value,
          jobsite_document: updatedFields.jobsite_document.value
            ? updatedFields.jobsite_document.value
            : [],
          duration: updatedFields.duration.value ? updatedFields.duration.value : "",
          initialRow: updatedFields.initialRow.value,
          is_call_for_tender: updatedFields.is_call_for_tender.value
            ? updatedFields.is_call_for_tender.value
            : 0,
          price: "",
          product: [],
          promocode: "",
          quantity: "",
          service: updatedFields.service.value,
          start_date: updatedFields.start_date.value
            ? updatedFields.start_date.value
            : "",
          sub_category_id: updatedFields.sub_category_id.value,
          terms: "",
          transport: [],
          truck_per_day: "",
          unit: "",
          usage_id: updatedFields.usage_id.value,
          selectedTransport: [],
        })
      );
      handleRequestFormDetails({
        updatedData: {
          analysis_flag: updatedFields.analysis_flag.value
            ? updatedFields.analysis_flag.value
            : 0,
          comment: "",
          document: updatedFields.document.value,
          jobsite_document: updatedFields.jobsite_document.value
            ? updatedFields.jobsite_document.value
            : [],
          duration: updatedFields.duration.value ? updatedFields.duration.value : "",
          initialRow: updatedFields.initialRow.value,
          is_call_for_tender: updatedFields.is_call_for_tender.value
            ? updatedFields.is_call_for_tender.value
            : 0,
          price: "",
          product: [],
          promocode: "",
          quantity: "",
          service: updatedFields.service.value,
          start_date: updatedFields.start_date.value
            ? updatedFields.start_date.value
            : "",
          sub_category_id: updatedFields.sub_category_id.value,
          terms: "",
          transport: [],
          truck_per_day: "",
          unit: "",
          usage_id: updatedFields.usage_id.value,
          selectedTransport: [],
        },
      });
    } else {
      localStorage.setItem(
        "requestData",
        JSON.stringify({
          analysis_flag: updatedFields.analysis_flag.value
            ? updatedFields.analysis_flag.value
            : 0,
          comment: updatedFields.comment.value,
          document: updatedFields.document.value,
          jobsite_document: updatedFields.jobsite_document.value
            ? updatedFields.jobsite_document.value
            : [],
          duration: updatedFields.duration.value ? updatedFields.duration.value : "",
          initialRow: updatedFields.initialRow.value,
          is_call_for_tender: updatedFields.is_call_for_tender.value
            ? updatedFields.is_call_for_tender.value
            : 0,
          price: updatedFields.price.value,
          product: updatedFields.product.value,
          promocode: updatedFields.promocode.value,
          quantity: updatedFields.quantity.value,
          service: updatedFields.service.value,
          start_date: updatedFields.start_date.value
            ? updatedFields.start_date.value
            : "",
          sub_category_id: updatedFields.sub_category_id.value,
          terms: updatedFields.terms.value,
          transport: updatedFields.transport.value,
          truck_per_day: updatedFields.truck_per_day.value,
          unit: updatedFields.unit.value,
          usage_id: updatedFields.usage_id.value,
          selectedTransport: updatedFields.selectedTransport.value,
        })
      );
      handleRequestFormDetails({
        updatedData: {
          analysis_flag: updatedFields.analysis_flag.value
            ? updatedFields.analysis_flag.value
            : 0,
          comment: updatedFields.comment.value,
          document: updatedFields.document.value,
          jobsite_document: updatedFields.jobsite_document.value
            ? updatedFields.jobsite_document.value
            : [],
          duration: updatedFields.duration.value ? updatedFields.duration.value : "",
          initialRow: updatedFields.initialRow.value,
          is_call_for_tender: updatedFields.is_call_for_tender.value
            ? updatedFields.is_call_for_tender.value
            : 0,
          price: updatedFields.price.value,
          product: updatedFields.product.value,
          promocode: updatedFields.promocode.value,
          quantity: updatedFields.quantity.value,
          service: updatedFields.service.value,
          start_date: updatedFields.start_date.value
            ? updatedFields.start_date.value
            : "",
          sub_category_id: updatedFields.sub_category_id.value,
          terms: updatedFields.terms.value,
          transport: updatedFields.transport.value,
          truck_per_day: updatedFields.truck_per_day.value,
          unit: updatedFields.unit.value,
          usage_id: updatedFields.usage_id.value,
          selectedTransport: updatedFields.selectedTransport.value,
        },
      });
    }
  };

  const closePopup = () => {
    setErrorPopupvisibility(false);
  };

  return (
    <form className="enquiry-form" id="enquiry-form">
      <JobsiteSelector
        isJobSiteSelected={isJobSiteSelected}
        isJobSiteIdExists={isJobSiteIdExists}
        selectedSite={selectedSite}
        siteMasterData={siteMasterData}
        isSiteMasterListLoading={isSiteMasterListLoading}
        selectedJobSite={selectedJobSite}
        onJobsiteSelect={onJobsiteSelect}
      />
      {!isJobSiteSelected && (
        <div>
          <h5 className="enquiry-form__categories-title">
            {t("CLIENT_PLATFORM_REQUEST_FORM_REQUEST_INFO_TITLE")}
          </h5>
          <EnquiryCategories
            selectedJobSite={selectedJobSite}
            onChange={(e, name, id) => onChange(e, name, id)}
          />
        </div>
      )}
      {categoryForm !== null && categoryForm !== "" && (
        <RequestDetails
          fields={fields}
          productInput={productInput}
          selectedService={selectedService}
          subCategory={subCategory}
          requestSelectedItems={requestSelectedItems}
          isAnalysisEnabled={isAnalysisEnabled}
          data={data}
          products={products}
          countryId={countryId}
          transport={transport}
          durationOptions={durationOptions}
          documentNames={documentNames}
          userCountry={userCountry}
          priceInput={priceInput}
          units={units}
          onChange={onChange}
          handleRequestFormDetails={handleRequestFormDetails}
          AddProductInput={AddProductInput}
          onDateChange={onDateChange}
          onTransportSelect={onTransportSelect}
          deleteFile={deleteFile}
        />
      )}
      {showErrorPopup && (
        <ErrorPopUp
          title={t("KICK_OFF_ERROR_BOX_TITLE")}
          btnText={t("KICK_OFF_ERROR_BOX_OK")}
          message={apiError}
          onOkClick={closePopup}
        />
      )}
    </form>
  );
};

EnquiryForm.propTypes = {
  siteMasterData: PropTypes.array,
  siteMasterDataRaw: PropTypes.array,
  selectedJobSite: PropTypes.object,
  categoryForm: PropTypes.number,
  selectedService: PropTypes.number,
  subCategory: PropTypes.array,
  data: PropTypes.object,
  transport: PropTypes.array,
  requestSelectedItems: PropTypes.object,
  userCountry: PropTypes.number,
  loginUserCountry: PropTypes.object,
  productData: PropTypes.array,
  selectedMaterialProductData: PropTypes.array,
  countryId: PropTypes.string,
  isRequestCategoriesLoading: PropTypes.bool,
  isSiteMasterListLoading: PropTypes.bool,
  handleRequestFormDetails: PropTypes.func.isRequired,
  selectedJobSiteForRequestCreation: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  updateRowValue: PropTypes.func.isRequired,
  getSiteMasterListData: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  selectCategory: PropTypes.func.isRequired,
  selectSubCategory: PropTypes.func.isRequired,
  getProductData: PropTypes.func.isRequired,
  selectCategoryForm: PropTypes.func.isRequired,
};

export default withTranslation()(EnquiryForm);
