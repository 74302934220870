export const INIT = "INIT";
export const LOADING = "LOADING";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";

export const ACTIVE_USER = 1;
export const PENDING_USER = 3;
export const TO_BE_PROCESSED_LEADS = 0;
export const TO_BE_PROCESS_COMPLETE_LEADS = 5;
export const ADDRESS_NOT_FOUND = "ADDRESS_NOT_FOUND";

let url = window.location.host;

if (url.includes("localhost")) {
  url = "http://127.0.0.1:3000/api/v1";
} else if (url.includes("staging")) {
  url = "https://staging.api.user.hesus-store.com/api/v1";
} else if (url.includes("dev")) {
  url = "https://dev.api.user.hesus-store.com/api/v1";
} else if (url.includes("revamp")) {
  url = "https://revamp.api.user.hesus-store.com/api/v1";
} else if (url.includes("my.hesus-store")) {
  url = "https://api.user.hesus-store.com/api/v1";
} else if (url.includes("revamp.user.hesus-store")) {
  url = "https://revamp.user.hesus-store.com/api/v1";
}

const baseURL = url;

export default baseURL;
