import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Form, Radio, RadioGroup, Tooltip } from "rsuite";
import axios from "axios";
import PulseLoader from "react-spinners/PulseLoader";
import PropTypes from "prop-types";

import EnquiryService from "../EnquiryService/EnquiryService";
import FloatingTextInput from "../../../shared/baseUI/FloatingTextInput";

import baseURL from "../../../shared/utils/constant";
import { prepareUsage } from "../../../shared/utils/methods";

import "./EnquiryForm.scss";

const EnquiryForm = (props) => {
  const {
    t,
    onMount,
    serviceRef,
    productTypeRef,
    productRef,
    usageRef,
    quantityRef,
    requestCategories,
    fields,
    hereCountryId,
    updateFormData,
    isCategoryLoading,
  } = props;

  const [selectedService, setSelectedService] = useState(-1);
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState([]);
  const [selectedItems, setSelectedItems] = useState({});
  const [categoryForm, setCategoryForm] = useState(null);
  const [usage, setUsage] = useState([]);
  const [requestData, setRequestData] = useState(fields);
  const [isUsageLoading, setUsageDataLoadingStatus] = useState(false);

  const validateField = (name, value) => {
    if (name === "quantity") {
      const quantity = {};
      quantity.value = value.replace(/\D/g, "");
      quantity.error = "";
      quantity.required = true;
      if (value === "") {
        quantity.error =
          "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_FORM_PRODUCT_QUANTITY_MISSING_ERROR";
      } else if (!/^\d+$/.test(quantity.value)) {
        quantity.error =
          "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_FORM_PRODUCT_QUANTITY_FORMAT_ERROR";
      }
      return quantity;
    }

    return "";
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    let localData;
    if (name) {
      const validField = validateField(name, value);
      if (validField) {
        localData = { ...requestData, [name]: validField };
        setRequestData({ ...requestData, [name]: validField });
      } else {
        localData = { ...requestData, [name]: { value, error: "", required: true } };
        setRequestData({
          ...requestData,
          [name]: { value, error: "", required: true },
        });
      }
    }

    if (name === "quantity" || name === "usage_id") {
      updateFormData(localData);
    }
  };

  const handleUnitChange = (value, checked) => {
    const localData = { ...requestData, unit: { value, error: "", required: true } };
    setRequestData({ ...requestData, unit: { value, error: "", required: true } });
    updateFormData(localData);
  };

  // Handle selected service
  const onTypeOfServiceSelect = (id) => {
    const selectedCategory = requestCategories.category.filter((i) => i.id === id);
    setSelectedService(id);
    setSubCategory([]);
    setSelectedItems({ ...selectedItems, service: id, category: "" });
    setCategory(selectedCategory[0].category);
    setCategoryForm(-1);
  };

  // Handle selected category
  const onCategorySelect = (id) => {
    const selectedSubCategory = category.filter((i) => i.id === id);
    const subCat =
      selectedSubCategory.length > 0 ? selectedSubCategory[0].sub_category : [];
    const usageLocal =
      selectedSubCategory[0].sub_category.length === 0
        ? prepareUsage(selectedSubCategory[0].usage)
        : [];
    setRequestData({
      ...requestData,
      usage_id: {
        value: "",
        error: "",
      },
    });
    setSubCategory(subCat);
    setCategoryForm(-1);
    setUsage(usageLocal);
    setSelectedItems({ ...selectedItems, subcategory: "", category: id });
  };

  // Handle selected sub category
  const onSubCategorySelect = (id) => {
    setCategoryForm(id);
    setSelectedItems({ ...selectedItems, subcategory: id });
  };

  const getUsageData = (usageId) => {
    setUsageDataLoadingStatus(true);
    axios
      .get(`${baseURL}/products/usages/${usageId}/${hereCountryId}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${
            localStorage.getItem("token") !== "" ? localStorage.getItem("token") : ""
          }`,
        },
      })
      .then((response) => {
        setUsageDataLoadingStatus(false);
        setSubCategory(response.data);
      })
      .catch(() => {
        setUsageDataLoadingStatus(false);
        setSubCategory([]);
      });
  };

  useEffect(() => {
    setRequestData({ ...requestData, ...fields });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  useEffect(() => {
    setSelectedService(fields.selectedItems ? fields.selectedItems.service : -1);
    setSubCategory(fields.subCategory ? fields.subCategory : []);
    setSelectedItems(fields.selectedItems ? fields.selectedItems : {});
    setCategory(fields.category ? fields.category : []);
    setCategoryForm(fields.categoryForm ? fields.categoryForm : -1);
  }, [
    fields.subCategory,
    fields.selectedItems,
    fields.categoryForm,
    fields.category,
  ]);

  useEffect(() => {
    if (requestData.usage_id && requestData.usage_id.value) {
      getUsageData(requestData.usage_id.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestData.usage_id]);

  useEffect(() => {
    updateFormData({
      ...requestData,
      category,
      subCategory,
      selectedService,
      selectedItems,
      categoryForm,
      usage,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, subCategory, selectedService, selectedItems, usage, categoryForm]);

  return (
    <div className="enquiry-form__wrapper onboarding-v3__enquiry-form-container">
      <h1 className="enquiry-form__title">
        {t("CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_FORM_TITLE")}
      </h1>
      {isCategoryLoading && (
        <div className="loader-wrapper">
          <PulseLoader color="#004c7f" />
        </div>
      )}
      {!isCategoryLoading && (
        <>
          <section className="enquiry-form__categories">
            <div className="enquiry-form__categories-wrapper">
              <EnquiryService
                onMount={onMount}
                serviceRef={serviceRef}
                productTypeRef={productTypeRef}
                productRef={productRef}
                usageRef={usageRef}
                requestCategories={requestCategories}
                category={category}
                selectedService={selectedService}
                selectedItems={selectedItems ?? {}}
                categoryForm={categoryForm}
                subCategory={subCategory}
                usage={usage}
                isUsageLoading={isUsageLoading}
                requestData={requestData}
                onChange={onChange}
                onCategorySelect={onTypeOfServiceSelect}
                onSubCategorySelect={onCategorySelect}
                onCategoryFormSelect={onSubCategorySelect}
              />
            </div>
          </section>
          {categoryForm && categoryForm !== -1 && (
            <section className="row enquiry-form__quantity-unit-wrapper">
              <div className="col-sm-6 col-md-6 col-lg-4" ref={quantityRef}>
                <FloatingTextInput
                  label={t(
                    "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_FORM_PRODUCT_QUANTITY"
                  )}
                  name="quantity"
                  id="quantity"
                  inputClass={
                    requestData.quantity && requestData.quantity?.error !== ""
                      ? "border border-danger"
                      : ""
                  }
                  required
                  value={requestData.quantity?.value ?? ""}
                  onChange={onChange}
                  error={requestData.quantity && requestData.quantity?.error !== ""}
                  errorMsg={
                    requestData.quantity ? t(requestData.quantity?.error) : ""
                  }
                />
              </div>
              <div className="col-sm-6 col-md-6 col-lg-8">
                <Form.Group controlId="unitList">
                  <RadioGroup name="unitList" inline>
                    <Radio value="M3" onChange={handleUnitChange}>
                      {t("CLIENT_PLATFORM_GENERAL_UNIT_M3")}
                    </Radio>
                    <Radio value="TONS" onChange={handleUnitChange}>
                      {t("CLIENT_PLATFORM_GENERAL_UNIT_TONS")}
                    </Radio>
                    <Radio value="LOADS" onChange={handleUnitChange}>
                      {t("CLIENT_PLATFORM_GENERAL_UNIT_LOADS")}
                    </Radio>
                  </RadioGroup>
                  {fields.unit?.error && (
                    <Tooltip visible>{t(fields.unit?.error)}</Tooltip>
                  )}
                </Form.Group>
              </div>
            </section>
          )}
        </>
      )}
    </div>
  );
};

EnquiryForm.propTypes = {
  serviceRef: PropTypes.shape({}),
  productTypeRef: PropTypes.shape({}),
  productRef: PropTypes.shape({}),
  usageRef: PropTypes.shape({}),
  quantityRef: PropTypes.shape({}),
  hereCountryId: PropTypes.number,
  isCategoryLoading: PropTypes.bool.isRequired,
  fields: PropTypes.shape({
    categoryForm: PropTypes.number,
    selectedItems: PropTypes.shape({
      service: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    subCategory: PropTypes.arrayOf(PropTypes.object),
    category: PropTypes.arrayOf(PropTypes.object),
    quantity: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.string,
      required: PropTypes.bool,
    }),
    unit: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.string,
      required: PropTypes.bool,
    }),
  }),
  requestCategories: PropTypes.shape({
    category: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  onMount: PropTypes.func,
  t: PropTypes.func.isRequired,
  updateFormData: PropTypes.func.isRequired,
};

EnquiryForm.defaultProps = {
  serviceRef: null,
  productTypeRef: null,
  productRef: null,
  usageRef: null,
  quantityRef: null,
  fields: PropTypes.shape({}),
  hereCountryId: null,
  onMount: null,
};

export default withTranslation()(EnquiryForm);
