import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import RequestViewPopUp from "../../../RequestViewPopUp";
import EnquiryTile from "./components/EnquiryTile";
import { getRequestViewData, showPopUp } from "../../../../../store/dataStore";

import "./SiteData.scss";

const SiteData = (props) => {
  const { data, count, id, showPopup } = props;
  const history = useHistory();

  useEffect(() => {
    if (!Object.prototype.hasOwnProperty.call(data, "request_count")) {
      return <Redirect to="/viewsite" />;
    }
  }, [data]);

  const handleViewData = async (siteId) => {
    localStorage.setItem("jobsiteId", siteId);
    history.push("/viewsite");
  };

  const viewRequest = async (siteId, requestId) => {
    props.showPopUp();
    await props.getRequestViewData({ siteId, id: requestId });
  };

  return (
    <div className="row site-data-container">
      <div className="col-xs-12 col-sm-12 col-md-9 enquiry-tile-container">
        {data.length > 0 && (
          <EnquiryTile id={id} data={data[0]} viewRequest={viewRequest} />
        )}
        {data.length >= 2 && (
          <EnquiryTile id={id} data={data[1]} viewRequest={viewRequest} />
        )}
      </div>
      <div className="col-xs-12 col-sm-12 col-md-3 more-enquiries">
        <div className="more-enquiries__text-wrapper">
          <span className="more-enquiries__count">
            {count > 0 && count - 2 > 0 && `+ ${count - 2}`}
          </span>
          {count > 0 && count - 2 > 0 && (
            <div onClick={() => handleViewData(id)}>
              <span className="more-enquiries__text">
                {props.t("CLIENT_PLATFORM_SITE_LIST_INQUIRIES_SEE_MORE")}
                <i className="fa fa-arrow-right pl-1" aria-hidden="true" />
              </span>
            </div>
          )}
        </div>
      </div>
      {showPopup && <RequestViewPopUp />}
    </div>
  );
};

SiteData.propTypes = {
  data: PropTypes.array.isRequired,
  id: PropTypes.number.isRequired,
  showPopup: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    showPopup: state.dataStore.showPopup,
  };
};

const mapDispatchToProps = {
  showPopUp: () => showPopUp(),
  getRequestViewData: (payload) => getRequestViewData(payload),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SiteData));
