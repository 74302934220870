import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import "./CustomDropdown.scss";

const CustomDropdown = (props) => {
  const { t, selectedSite, selectedJobSite } = props;
  const [isOpen, setOpenStatus] = useState(false);
  const [options, setOptions] = useState(props.data);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    setOptions(props.data);
  }, [props.data]);

  useEffect(() => {
    const selectedOption = selectedSite.id
      ? `${selectedSite.id} - ${selectedSite.name}`
      : `${selectedJobSite.id} - ${selectedJobSite.name}`;
    setSelectedOption(selectedOption);
  }, [selectedSite.id, selectedSite.name, selectedJobSite.id, selectedJobSite.name]);

  const toggleDropdown = () => setOpenStatus(!isOpen);

  const handleItemClick = (value) => {
    if (value && value.split("-")[0]) {
      setSelectedOption(value);
    } else {
      setSelectedOption(null);
    }
    setOpenStatus(false);
    props.onChange(value);
  };

  const getLabel = () => {
    const option = options.find((item) => item.value === selectedOption);
    if (option) {
      return option.label;
    }
    return t("CLIENT_PLATFORM_REQUEST_FORM_JOBSITE_PLACEHOLDER");
  };

  const closeDropdown = () => {
    setOpenStatus(false);
  };

  return (
    <div
      className={isOpen ? "custom-dropdown open" : "custom-dropdown"}
      onMouseLeave={closeDropdown}
    >
      <div className="dropdown-header" onClick={toggleDropdown}>
        {selectedOption ? (
          <div>
            <h2 className="site-title">{getLabel().split("-")[1]}</h2>
            <h3
              className={
                getLabel().split("-")[1] ? "site-id" : "site-id placeholder"
              }
            >
              {getLabel().split("-")[0]}
            </h3>
          </div>
        ) : (
          t("CLIENT_PLATFORM_REQUEST_FORM_JOBSITE_PLACEHOLDER")
        )}
        <i className={`fa fa-chevron-right icon ${isOpen && "open"}`} />
      </div>
      <ul className={isOpen ? "dropdown-body open" : "dropdown-body"}>
        {options.map((item) => (
          <li
            className="dropdown-item"
            onClick={(e) => handleItemClick(item.value)}
            id={item.value}
            key={item.value}
          >
            <h2 className="site-title">{item.label.split("-")[1]}</h2>
            <h3 className="site-id">{item.label.split("-")[0]}</h3>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default withTranslation()(CustomDropdown);
