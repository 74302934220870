import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import soilImg from "../../../assets/shared/soil.png";
import removalImg from "../../../assets/shared/removal.svg";
import supplyImg from "../../../assets/shared/supply.svg";
import materialImg from "../../../assets/shared/material.png";
import trashImg from "../../../assets/shared/trash.png";
import materialNatural from "../../../assets/shared/material-natural.png";
import materialRecycle from "../../../assets/shared/material-recycle.png";
import pollutedSoil from "../../../assets/shared/polluted-soil.png";
import unpollutedSoil from "../../../assets/shared/unpolluted-soil.png";
import notSureImg from "../../../assets/shared/not-sure.png";
import hazardousImg from "../../../assets/shared/hazardous.png";
import nonHazardousImg from "../../../assets/shared/non-hazardous.png";
import asbestosImg from "../../../assets/shared/asbestos.png";

import "./CustomRadioButton.scss";

const CustomRadioButton = (props) => {
  const {
    data,
    label,
    titleRef,
    altName,
    selected,
    onChange,
    name,
    required,
    disabled,
    optionsListClass,
    optionsContainerClass,
    t,
  } = props;

  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(data);
  }, [data]);

  const getCategoryImage = (imgName) => {
    if (imgName.indexOf("removal") > -1) {
      return removalImg;
    }
    if (imgName.indexOf("supply") > -1) {
      return supplyImg;
    }
    if (imgName.indexOf("unpolluted-soil") > -1) {
      return unpollutedSoil;
    }
    if (imgName.indexOf("polluted-soil") > -1) {
      return pollutedSoil;
    }
    if (imgName.indexOf("soil") > -1) {
      return soilImg;
    }
    if (imgName.indexOf("material-natural") > -1) {
      return materialNatural;
    }
    if (imgName.indexOf("material-recycle") > -1) {
      return materialRecycle;
    }
    if (imgName.indexOf("material") > -1) {
      return materialImg;
    }
    if (imgName.indexOf("trash") > -1) {
      return trashImg;
    }
    if (imgName.indexOf("non-hazardous") > -1) {
      return nonHazardousImg;
    }
    if (imgName.indexOf("hazardous") > -1) {
      return hazardousImg;
    }
    if (imgName.indexOf("asbestos") > -1) {
      return asbestosImg;
    }
    return notSureImg;
  };

  const getCategoryName = (imgName) => {
    if (imgName.indexOf("removal") > -1) {
      return "CLIENT_PLATFORM_REQUEST_FORM_SERVICE_REMOVAL";
    }
    if (imgName.indexOf("supply") > -1) {
      return "CLIENT_PLATFORM_REQUEST_FORM_SERVICE_SUPPLY";
    }
    if (imgName.indexOf("unpolluted-soil") > -1) {
      return "CLIENT_PLATFORM_REQUEST_FORM_SUB_CATEGORY_INERT_SOIL";
    }
    if (imgName.indexOf("polluted-soil") > -1) {
      return "CLIENT_PLATFORM_REQUEST_FORM_SUB_CATEGORY_CONTAMINATED_SOIL";
    }
    if (imgName.indexOf("soil") > -1) {
      return "CLIENT_PLATFORM_REQUEST_FORM_CATEGORY_SOIL";
    }
    if (imgName.indexOf("material-natural") > -1) {
      return "CLIENT_PLATFORM_REQUEST_FORM_SUB_CATEGORY_PRIMARY_MATERIAL";
    }
    if (imgName.indexOf("material-recycle") > -1) {
      return "CLIENT_PLATFORM_REQUEST_FORM_SUB_CATEGORY_RECYCLED_MATERIAL";
    }
    if (imgName.indexOf("material") > -1) {
      return "CLIENT_PLATFORM_REQUEST_FORM_CATEGORY_MATERIAL";
    }
    if (imgName.indexOf("trash") > -1) {
      return "CLIENT_PLATFORM_REQUEST_FORM_CATEGORY_WASTE";
    }
    if (imgName.indexOf("non-hazardous") > -1) {
      return "CLIENT_PLATFORM_REQUEST_FORM_SUB_CATEGORY_NON_HAZARDOUS_WASTE";
    }
    if (imgName.indexOf("hazardous") > -1) {
      return "CLIENT_PLATFORM_REQUEST_FORM_SUB_CATEGORY_HAZARDOUS_WASTE";
    }
    if (imgName.indexOf("asbestos") > -1) {
      return "CLIENT_PLATFORM_REQUEST_FORM_SUB_CATEGORY_ASBESTOS";
    }
    return "CLIENT_PLATFORM_REQUEST_FORM_SUB_CATEGORY_I_AM_NOT_SURE";
  };

  return (
    <div
      className={`custom-radio-options__container ${optionsContainerClass || ""}`}
    >
      <h2
        className={
          required
            ? "custom-radio-options__title required"
            : "custom-radio-options__title"
        }
        ref={titleRef}
      >
        {label}
      </h2>
      <ul
        key={label + name}
        className={`custom-radio-options__options-list ${
          disabled ? "disabled" : ""
        } ${optionsListClass || ""}`}
      >
        {options.map((item) => {
          return (
            <li
              className={`custom-radio-options__options-item ${
                selected.id === item.id ? "active" : ""
              }  ${disabled ? "disabled" : ""}`}
              key={item.id + item.name}
              data-id={item.id}
              onClick={() => {
                onChange(item);
              }}
              name={item.name}
            >
              {item.image !== "" && name !== "waste_quantity" && (
                <img
                  className="custom-radio-options__option-icon"
                  src={getCategoryImage(item.image)}
                  alt={altName}
                />
              )}
              {item.image !== "" && name === "waste_quantity" && (
                <img
                  className="custom-radio-options__option-icon"
                  src={item.image}
                  alt={altName}
                />
              )}
              <span className="custom-radio-options__option-name">
                {item.image && name !== "waste_quantity"
                  ? t(getCategoryName(item.image))
                  : item.name}
              </span>
              <span className="custom-radio-options__option-dot" />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

CustomRadioButton.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  optionsListClass: PropTypes.string,
  optionsContainerClass: PropTypes.string,
  disabled: PropTypes.bool,
  altName: PropTypes.string,
  selected: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(CustomRadioButton);
