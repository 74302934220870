import React from "react";
import { withTranslation } from "react-i18next";

const TabItem = (props) => {
  const { index, title, id, role, controls, link, t } = props;

  return (
    <li className="nav-item">
      <a
        className={index === 0 ? "link active" : "link"}
        id={id}
        data-toggle="pill"
        href={link}
        role={role}
        aria-controls={controls}
        aria-selected="true"
      >
        {t(title)}
      </a>
    </li>
  );
};

export default withTranslation()(TabItem);
