import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import PulseLoader from "react-spinners/PulseLoader";

import { storage } from "../../../../../../store/dataStore/api";

import RequestCategorySelection from "../../../../../../shared/baseUI/RequestCategorySelection";
import SelectInput from "../../../../../../shared/baseUI/CustomSelectBox";
import { prepareCategories } from "../../../../../../shared/utils/methods";

import {
  getCategoriesData,
  getProductData,
} from "../../../../../../store/dataStore";

import {
  getMaterialProductData,
  selectCategory,
  selectSubCategory,
  selectCategoryForm,
  getEditedRequestData,
} from "../../../../InquiryForm.actionCreators";

class EnquiryCategories extends Component {
  componentDidMount() {
    storage();
    if (this.props.data.length === 0) {
      this.props.getCategoriesData();
    }
  }

  componentWillUpdate(prevProps) {
    const { data, requestData, selectedItems } = this.props;

    if (prevProps.data !== data && requestData.hasOwnProperty("editData")) {
      this.props.getEditedRequestData(requestData);
      requestData.usage_id !== null &&
      selectedItems.service === 2 &&
      selectedItems.category === 2
        ? this.props.getMaterialProductData({ id: requestData.usage_id })
        : this.props.getProductData({
            subCatId: selectedItems.subcategory,
            service: selectedItems.service,
          });
    }
  }

  handleCategory(id) {
    this.props.selectCategory(id);
  }

  handleSubCategory(id) {
    this.props.selectSubCategory(id);
  }

  handleCategoryForm(id) {
    this.props.selectCategoryForm(id);
  }

  render() {
    const {
      data,
      category,
      subCategory,
      onChange,
      usage,
      selectedService,
      selectedItems,
      requestData,
      t,
    } = this.props;

    return (
      <div>
        {data && (
          <>
            {this.props.isRequestCategoriesLoading && (
              <div className="loader-wrapper">
                <PulseLoader color="#004c7f" />
              </div>
            )}
            {data.length > 0 && !this.props.isRequestCategoriesLoading && (
              <RequestCategorySelection
                data={prepareCategories(data)}
                labelName={t("CLIENT_PLATFORM_REQUEST_FORM_SERVICE")}
                selectCategory={(id) => this.handleCategory(id)}
                name="service"
                onChange={(e, name, id) => onChange(e, name, id)}
                selectedId={selectedItems}
                disabled={!!requestData.hasOwnProperty("editData")}
              />
            )}
            {category.length > 0 && !this.props.isRequestCategoriesLoading && (
              <RequestCategorySelection
                data={prepareCategories(category)}
                labelName={t("CLIENT_PLATFORM_REQUEST_FORM_CATEGORY")}
                selectCategory={(id) => this.handleSubCategory(id)}
                onChange={(e, name, id) => {
                  onChange(e, name, id);
                }}
                disabled={!!requestData.hasOwnProperty("editData")}
                name="category"
                selectedId={selectedItems}
              />
            )}
            {selectedService === 2 &&
              usage.length > 0 &&
              !this.props.isRequestCategoriesLoading && (
                <SelectInput
                  value={requestData.usage_id}
                  inputClass="request-details__section-input"
                  divClass="request-details__input-wrapper close-time-list"
                  labelClass="request-details__section-input-label required"
                  required
                  labelName={t("CLIENT_PLATFORM_REQUEST_FORM_USAGE")}
                  name="usage_id"
                  defaultOption={t("SELECT_MATERIAL_USAGE")}
                  disabled={!!requestData.hasOwnProperty("editData")}
                  onChange={(e) => onChange(e)}
                  options={usage}
                />
              )}
            {this.props.isMaterialProductsLoading && (
              <div className="loader-wrapper">
                <PulseLoader color="#004c7f" />
              </div>
            )}
            {subCategory.length > 0 && !this.props.isMaterialProductsLoading && (
              <RequestCategorySelection
                data={prepareCategories(subCategory)}
                labelName={t("CLIENT_PLATFORM_REQUEST_FORM_SUB_CATEGORY")}
                selectCategory={(id) => this.handleCategoryForm(id)}
                onChange={(e, name, id) => {
                  onChange(e, name, id);
                }}
                name="subcategory"
                disabled={!!requestData.hasOwnProperty("editData")}
                selectedId={selectedItems}
              />
            )}
          </>
        )}
      </div>
    );
  }
}

EnquiryCategories.propTypes = {
  data: PropTypes.array.isRequired,
  requestData: PropTypes.object,
  category: PropTypes.array,
  subCategory: PropTypes.array,
  usage: PropTypes.array,
  selectedService: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectedItems: PropTypes.object,
  isRequestCategoriesLoading: PropTypes.bool,
  isMaterialProductsLoading: PropTypes.bool,
  getCategoriesData: PropTypes.func.isRequired,
  selectCategory: PropTypes.func.isRequired,
  selectSubCategory: PropTypes.func.isRequired,
  selectCategoryForm: PropTypes.func.isRequired,
  getEditedRequestData: PropTypes.func.isRequired,
  getProductData: PropTypes.func.isRequired,
  getMaterialProductData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    data: state.inquiryForm.requestCategoriesData.category,
    requestData: state.inquiryForm.requestCreationData,
    category: state.inquiryForm.category,
    subCategory: state.inquiryForm.subCategory,
    usage: state.inquiryForm.usage,
    selectedService: state.inquiryForm.selectedService,
    selectedItems: state.inquiryForm.requestSelectedItems,
    isRequestCategoriesLoading: state.inquiryForm.isRequestCategoriesLoading,
    isMaterialProductsLoading: state.inquiryForm.isMaterialProductsLoading,
  };
};

const mapDispatchToProps = {
  getCategoriesData: () => getCategoriesData(),
  selectCategory: (payload) => selectCategory(payload),
  selectSubCategory: (payload) => selectSubCategory(payload),
  selectCategoryForm: (payload) => selectCategoryForm(payload),
  getEditedRequestData: (payload) => getEditedRequestData(payload),
  getProductData: (payload) => getProductData(payload),
  getMaterialProductData: (payload) => getMaterialProductData(payload),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(EnquiryCategories));
