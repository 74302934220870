import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";

import reducers from "./rootReducer";

import sagas from "./rootSaga";

const middleWares = [];

const sagaMiddleware = createSagaMiddleware();
middleWares.push(sagaMiddleware);

const logger = createLogger({
  predicate: () => process.env.NODE_ENV === "development",
});
middleWares.push(logger);

const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(...middleWares))
);

sagaMiddleware.run(sagas);

export default store;
