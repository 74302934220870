import React, { useState } from "react";
import { Range, getTrackBackground } from "react-range";
import PropTypes from "prop-types";

const RangeSlider = (props) => {
  const { defaultValue, step, max, min, onChange } = props;
  const [values, setValues] = useState(defaultValue);
  const STEP = step;
  const MIN = min;
  const MAX = max;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        margin: "2em 0",
      }}
    >
      <Range
        values={values}
        step={STEP}
        min={MIN}
        max={MAX}
        onChange={(updatedValues) => {
          setValues(updatedValues);
          onChange(updatedValues);
        }}
        renderTrack={({ props, children }) => (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              borderRadius: "5px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "14px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values,
                  colors: ["#ccc", "#C9E8FB", "#ccc"],
                  min: MIN,
                  max: MAX,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged, index }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "24px",
              width: "24px",
              borderRadius: "100%",
              backgroundColor: "#1BACE4",
              boxShadow:
                "0px 4px 4px rgba(0, 0, 0, 0.25), 0px 1px 1px rgba(0, 0, 0, 0.2376)",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "-43px",
                width: "auto",
                left: "-10px",
                padding: "5px",
                fontSize: "16px",
                backgroundColor: "transparent",
                borderRadius: "4px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span>{`${parseInt(values[index], 10)}h${
                values[index] - parseInt(values[index], 10) === 0.5 ? "30" : "00"
              }`}</span>
            </div>
          </div>
        )}
      />
    </div>
  );
};

RangeSlider.propTypes = {
  defaultValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  step: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  ref: PropTypes.shape(PropTypes.object),
  style: PropTypes.shape(PropTypes.object),
  onMouseDown: PropTypes.func,
  onTouchStart: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

RangeSlider.defaultProps = {
  ref: null,
  style: null,
  onMouseDown: null,
  onTouchStart: null,
};

export default RangeSlider;
