import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

import auth from "./auth";

const ProtectedRoute = ({ component: Component, path, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (auth.isAuthenticated()) {
        if (path === "/") {
          return <Redirect to="/dashboard" />;
        }
        if (
          path === "/sitesandenquiries" ||
          path === "/createcallofforder" ||
          path === "/transportonly" ||
          path === "/callofforder"
        ) {
          if (auth.isAllowed()) {
            return <Component {...props} />;
          }
          return <Redirect to="/dashboard" />;
        }
        return <Component {...props} />;
      }
      return <Redirect to="/login" />;
    }}
  />
);

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default ProtectedRoute;
