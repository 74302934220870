/**
 * Onboarding V3
 * Doesn't require user authentication.
 * https://hesusstore.atlassian.net/wiki/spaces/HS/pages/2796191747/Step1+-+Inquiry+form
 */

import React, { useState, useEffect, useCallback } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import detectBrowserLanguage from "detect-browser-language";
import PropTypes from "prop-types";
import axios from "axios";

import Header from "./Header";
import InquiryForm from "./InquiryForm";
import ContactForm from "./ContactForm";
import RequestSuccess from "./RequestSuccess";
import Footer from "./Footer";
import ErrorPopUp from "../../shared/baseUI/ErrorPopUp";
import Popup from "../../shared/baseUI/Popup";
import Button from "../../shared/baseUI/Button";

import baseURL from "../../shared/utils/constant";
import { isEmpty, rejectObj } from "../../shared/utils/methods";
import { validateOnboardingForm, getFormData } from "./OnboardingV3.utils";

import "./OnboardingV3.scss";

const OnboardingV3 = (props) => {
  const { i18n, selectedCountryId, t } = props;

  const defaultFormFields = {
    address: {
      value: "",
      error: "",
      required: true,
    },
    postal: {
      value: "",
      error: "",
      required: false,
    },
    city: {
      value: "",
      error: "",
      required: false,
    },
    quantity: {
      value: null,
      error: "",
      required: true,
    },
    unit: {
      value: "",
      error: "",
      required: true,
    },
    start_date: {
      value: "",
      error: "",
      required: true,
    },
    email: {
      value: "",
      error: "",
      required: true,
    },
    jobsite_comment: {
      value: "",
      error: "",
      required: false,
    },
    client_type: {
      value: "",
      error: "",
      required: true,
    },
    waste_quantity: {
      value: "",
      error: "",
      required: false,
    },
    first_name: {
      value: "",
      error: "",
      required: true,
    },
    last_name: {
      value: "",
      error: "",
      required: true,
    },
    company_name: {
      value: "",
      error: "",
      required: true,
    },
    phone: {
      value: "",
      error: "",
      required: true,
    },
    isCallForTender: {
      value: false,
      error: "",
      required: false,
    },
    minHour: {
      value: 7,
      error: "",
      required: false,
    },
    maxHour: {
      value: 15.5,
      error: "",
      required: false,
    },
    categoryFormData: { value: null, error: "", required: true },
    documents: { value: [], error: "", required: false },
  };
  const [language, setLanguageState] = useState("");
  const [countryId, setCountryId] = useState(0);
  const [currentLanguage, updateLanguage] = useState("");
  const [currentCountryId, updateCountryId] = useState(0);
  const [progress, setProgressbar] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [fields, setFormFields] = useState({});
  const [isFormValid, setFormValidity] = useState(false);
  const [isLeadBeingSaved, setLeadSaveStatus] = useState(false);
  const [showErrorPopup, setErrorPopupvisibility] = useState(false);
  const [apiError, setApiError] = useState("");
  const [isEmailExists, setEmailStatus] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const history = useHistory();
  const HESUS_WEBSITE = "https://hesus.com";
  const productionURL = "my.hesus-store.com";

  /* istanbul ignore next */
  const validateForm = (data) => {
    const isValid = validateOnboardingForm(data, currentStep);
    setFormValidity(isValid);
  };

  /* istanbul ignore next */
  const updateFormFields = (data) => {
    let currentProgress = 0;
    let totalRequiredFields = 0;
    Object.values(data).forEach((value) => {
      if (value?.required === true) {
        totalRequiredFields += 1;
      }
      if (value?.required === true && value?.value && value?.error === "") {
        currentProgress += 100;
      }
    });
    setProgressbar(currentProgress / totalRequiredFields);
    setFormFields(data);
    validateForm(data);
  };

  /* istanbul ignore next */
  const getFormValidity = (data) => {
    if (!isEmpty(data)) {
      let requiredFields;

      let updatedFields = data;
      if (!data.quantity?.value) {
        updatedFields = {
          ...updatedFields,
          quantity: {
            ...updatedFields.quantity,
            error:
              "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_FORM_PRODUCT_QUANTITY_MISSING_ERROR",
          },
        };
      }
      if (!data.unit?.value) {
        updatedFields = {
          ...updatedFields,
          unit: {
            ...updatedFields.unit,
            error:
              "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_FORM_PRODUCT_UNIT_MISSING_ERROR",
          },
        };
      }
      if (!data.start_date?.value) {
        updatedFields = {
          ...updatedFields,
          start_date: {
            ...updatedFields.start_date,
            error: "CLIENT_PLATFORM_ONBOARDING_JOBSITE_FORM_DATE_MISSING_ERROR",
          },
        };
      }
      if (currentStep === 1) {
        if (!data.first_name?.value) {
          updatedFields = {
            ...updatedFields,
            first_name: {
              ...updatedFields.first_name,
              error:
                "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_PERSONAL_FORM_PERSONAL_FIRST_NAME_MISSING_ERROR",
            },
          };
        }
        if (!data.last_name?.value) {
          updatedFields = {
            ...updatedFields,
            last_name: {
              ...updatedFields.last_name,
              error:
                "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_PERSONAL_FORM_PERSONAL_LAST_NAME_MISSING_ERROR",
            },
          };
        }
        if (!data.email?.value) {
          updatedFields = {
            ...updatedFields,
            email: {
              ...updatedFields.email,
              error:
                "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_EMAIL_FORM_EMAIL_MISSING_ERROR",
            },
          };
        }
        if (!data.phone?.value) {
          updatedFields = {
            ...updatedFields,
            phone: {
              ...updatedFields.phone,
              error:
                "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_EMAIL_FORM_PHONE_MISSING_ERROR",
            },
          };
        }
        if (!data.company_name?.value) {
          updatedFields = {
            ...updatedFields,
            company_name: {
              ...updatedFields.company_name,
              error:
                "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_PERSONAL_FORM_COMPANY_NAME_MISSING_ERROR",
            },
          };
        }
      }
      setFormFields(updatedFields);
      if (currentStep === 0) {
        requiredFields = rejectObj(updatedFields, [
          "city",
          "client_type",
          "email",
          "isCallForTender",
          "categoryFormData",
          "countryId",
          "phone",
          "postal",
          "maxHour",
          "minHour",
          "comment",
          "category",
          "selectedItems",
          "selectedService",
          "subCategory",
          "waste_quantity",
          "products",
          "documents",
          "uploadDocs",
          "jobsite_comment",
          "first_name",
          "last_name",
          "company_name",
          "company_reg",
          "usage_id",
          "usage",
          "latitude",
          "longitude",
          "categoryForm",
        ]);
      } else {
        requiredFields = rejectObj(updatedFields, [
          "address",
          "quantity",
          "categoryFormData",
          "unit",
          "start_date",
          "countryId",
          "isCallForTender",
          "postal",
          "maxHour",
          "minHour",
          "comment",
          "category",
          "selectedItems",
          "selectedService",
          "subCategory",
          "waste_quantity",
          "products",
          "documents",
          "uploadDocs",
          "jobsite_comment",
          "company_reg",
          "usage_id",
          "usage",
          "latitude",
          "longitude",
          "categoryForm",
        ]);
      }
      const emptyFields = Object.values(requiredFields).filter(
        (field) => !field.value || (field.value !== "" && field.error !== "")
      );
      if (emptyFields.length === 0) {
        return true;
      }
      return false;
    }
  };

  /* istanbul ignore next */
  const handleStepChange = () => {
    const isValid = getFormValidity(fields);
    if (isValid) {
      setCurrentStep(1);
      setFormValidity(false);
    }
  };

  const closePopup = () => {
    setErrorPopupvisibility(false);
    setApiError("");
  };

  /* istanbul ignore next */
  const createLead = (data, token) => {
    const form = document.getElementById("lead-form");
    setLeadSaveStatus(true);
    axios
      .post(`${baseURL}/leads`, getFormData(data, token, form), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${
            localStorage.getItem("token") !== "" ? localStorage.getItem("token") : ""
          }`,
        },
      })
      .then((response) => {
        setLeadSaveStatus(false);
        setErrorPopupvisibility(false);
        setApiError("");
        setCurrentStep(2);
        if (response.data.lead.status === 2) {
          // user email already exists.
          // Rule 7
          setEmailStatus(true);
        }
      })
      .catch((error) => {
        setLeadSaveStatus(false);
        setErrorPopupvisibility(true);
        setApiError(error.response.data.message);
      });
  };

  /* istanbul ignore next */
  const saveLead = (data) => {
    const isValid = getFormValidity(data);
    if (isValid) {
      setLeadSaveStatus(true);
      // For production url, use recaptcha to secure data update.
      if (window.location.host === productionURL && executeRecaptcha) {
        executeRecaptcha()
          .then((token) => {
            createLead(data, token);
          })
          .catch((error) => {
            setLeadSaveStatus(true);
            setErrorPopupvisibility(true);
            setApiError(error);
          });
      } else {
        createLead(data, "");
      }
    }
  };

  /* istanbul ignore next */
  const backToHesus = () => {
    window.location.href = HESUS_WEBSITE;
  };

  const onLoginRedirect = () => {
    history.push("/login");
  };

  const onForgotPasswordRedirect = () => {
    history.push("/forgotpassword");
  };

  const emailStatusData = () => (
    <div className="row">
      <div className="col-5 offset-1">
        <div className="icon-col p-2">
          <Button
            onClick={onLoginRedirect}
            className="link text-decoration-none border-0"
            name={
              <span>
                <i className="fa fa-sign-in fa-2x d-block mb-2" />
                {t(
                  "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_EMAIL_FORM_POPUP_EMAIL_ALREADY_USED_CONNECT"
                )}
              </span>
            }
          />
        </div>
      </div>
      <div className="col-5">
        <div className="icon-col p-2">
          <div data-dismiss="modal">
            <Button
              onClick={onForgotPasswordRedirect}
              className="link text-decoration-none border-0"
              name={
                <span>
                  <i className="fa fa-unlock-alt fa-2x d-block mb-2" />
                  {t(
                    "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_EMAIL_FORM_POPUP_EMAIL_ALREADY_USED_FORGOT_PWD"
                  )}
                </span>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );

  const getLanguage = (id) => {
    switch (id) {
      case 1:
        return "en";
      case 2:
        return "fr";
      case 3:
        return "pl";
      case 6:
        return "de";
      case 8:
        return "ch";
      default:
    }
    return "";
  };

  const setLanguage = useCallback(() => {
    try {
      let browserLng = detectBrowserLanguage();
      browserLng = browserLng.toLowerCase();
      const multiSwissLng = ["it-ch", "de-ch"];
      if (browserLng === "fr-ch") {
        browserLng = "ch";
      }
      if (multiSwissLng.includes(browserLng)) {
        browserLng = "en";
      }
      browserLng = browserLng.split("-")[0];
      let lngFlag;
      let countryIdLocal;
      if (browserLng === "fr") {
        lngFlag = "fr";
        countryIdLocal = 2;
      } else if (browserLng === "pl") {
        lngFlag = "pl";
        countryIdLocal = 3;
      } else if (browserLng === "ch") {
        lngFlag = "ch";
        countryIdLocal = 8;
      } else if (browserLng === "de") {
        lngFlag = "de";
        countryIdLocal = 6;
      } else {
        lngFlag = "en";
        countryIdLocal = 1;
      }

      setLanguageState(lngFlag);
      setCountryId(countryIdLocal);
      updateLanguage(lngFlag);
      updateCountryId(countryIdLocal);
      i18n.changeLanguage(lngFlag);
    } catch (error) {
      setLanguageState("en");
      setCountryId(1);
      updateLanguage("en");
      updateCountryId(1);
      i18n.changeLanguage("en");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const userCountryId = localStorage.getItem("userCountryId");
    if (!userCountryId) {
      setLanguage();
    } else {
      const lng = getLanguage(parseInt(userCountryId, 10));
      setLanguage(lng);
      updateLanguage(lng);
      setCountryId(parseInt(userCountryId, 10));
      updateCountryId(parseInt(userCountryId, 10));
    }
  }, [setLanguage]);

  useEffect(() => {
    if (selectedCountryId) {
      let lngFlag;
      if (selectedCountryId === "2") {
        lngFlag = "fr";
      } else if (selectedCountryId === "3") {
        lngFlag = "pl";
      } else if (selectedCountryId === "8") {
        lngFlag = "ch";
      } else if (selectedCountryId === "6") {
        lngFlag = "de";
      } else {
        lngFlag = "en";
      }

      updateLanguage(lngFlag);
      updateCountryId(Number(selectedCountryId));
    }
  }, [selectedCountryId]);

  useEffect(() => {
    if (selectedCountryId) {
      let lngFlag;
      if (selectedCountryId === "2") {
        lngFlag = "fr";
      } else if (selectedCountryId === "3") {
        lngFlag = "pl";
      } else if (selectedCountryId === "8") {
        lngFlag = "ch";
      } else if (selectedCountryId === "6") {
        lngFlag = "de";
      } else {
        lngFlag = "en";
      }
      setLanguageState(lngFlag);
      setCountryId(Number(selectedCountryId));
      i18n.changeLanguage(lngFlag);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFormFields(defaultFormFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="onboarding-v3__container">
      <div className="onboarding-v3__wrapper">
        <Header
          progress={progress}
          language={language}
          countryId={countryId}
          currentStep={currentStep}
        />
        {currentStep === 0 && (
          <InquiryForm
            fields={fields}
            onFormUpdate={updateFormFields}
            language={currentLanguage}
            countryId={currentCountryId}
          />
        )}
        {currentStep === 1 && (
          <ContactForm
            fields={fields}
            onFormUpdate={updateFormFields}
            countryId={countryId}
          />
        )}
        {currentStep === 2 && <RequestSuccess onSuccess={backToHesus} />}
        {currentStep !== 2 && (
          <div className="onboarding-v3__footer">
            <Footer
              disabled={!isFormValid}
              currentStep={currentStep}
              onChange={handleStepChange}
              onSubmit={() => saveLead(fields)}
              isLoading={isLeadBeingSaved}
            />
          </div>
        )}
        {showErrorPopup && (
          <ErrorPopUp
            title={t("KICK_OFF_ERROR_BOX_TITLE")}
            btnText={t("KICK_OFF_ERROR_BOX_OK")}
            message={apiError}
            onOkClick={closePopup}
          />
        )}
        {isEmailExists && (
          <div>
            <Popup
              modalParentClass="modal-parent email-unicity"
              modalClass="modal-box"
              title={t(
                "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_EMAIL_FORM_POPUP_EMAIL_ALREADY_USED_TITLE"
              )}
              name="email"
              content={emailStatusData()}
              id="emailStatusPopup"
            />
          </div>
        )}
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedCountryId: state.loginStore.countryId,
  };
};

OnboardingV3.propTypes = {
  selectedCountryId: PropTypes.string,
  i18n: PropTypes.shape({
    changeLanguage: PropTypes.func,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, null)(withTranslation()(OnboardingV3));
