export const ADD_NEW_JOBSITE = {
  LOADING: "ADD_NEW_JOBSITE_LOADING",
  SUCCESS: "ADD_NEW_JOBSITE_SUCCESS",
  ERROR: "ADD_NEW_JOBSITE_FAILURE",
};

export const UPDATE_JOBSITE = {
  LOADING: "UPDATE_JOBSITE_LOADING",
  SUCCESS: "UPDATE_JOBSITE_SUCCESS",
  ERROR: "UPDATE_JOBSITE_FAILURE",
};

export const DELETE_DOCUMENT = {
  LOADING: "DELETE_DOCUMENT_LOADING",
  SUCCESS: "DELETE_DOCUMENT_SUCCESS",
  ERROR: "DELETE_DOCUMENT_FAILURE",
};

export const GET_SITE_MASTER_LIST_DATA = {
  LOADING: "GET_SITE_MASTER_LIST_DATA_LOADING",
  SUCCESS: "GET_SITE_MASTER_LIST_DATA_SUCCESS",
  ERROR: "GET_SITE_MASTER_LIST_DATA_FAILURE",
};

export const HANDLE_SITE_FORM_DETAILS = "HANDLE_SITE_FORM_DETAILS";
export const HANDLE_EDIT_JOBSITE = "HANDLE_EDIT_JOBSITE";
export const CREATE_SITE_FORM = "CREATE_SITE_FORM";
export const USER_VIEWED_LEAD = "USER_VIEWED_LEAD";
