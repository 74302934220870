import React from "react";
import { withTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import PropTypes from "prop-types";

import CustomDropdown from "../../../../../../shared/baseUI/CustomDropdown";
import sitelogo from "../../../../../../assets/shared/site.svg";

import "./JobsiteSelector.scss";

const JobsiteSelector = (props) => {
  const {
    isJobSiteSelected,
    isJobSiteIdExists,
    selectedSite,
    siteMasterData,
    selectedJobSite,
    isSiteMasterListLoading,
    t,
    onJobsiteSelect,
  } = props;

  return (
    <>
      {(isJobSiteSelected || isJobSiteIdExists) && (
        <div className="jobsite-selector__dropdown-container">
          <h6 className="jobsite-selector__title">
            {t("CLIENT_PLATFORM_REQUEST_FORM_SITE_INFO_TITLE")}
          </h6>
          {isSiteMasterListLoading && (
            <div className="jobsite-selector__loader">
              <PulseLoader color="#004c7f" />
            </div>
          )}
          {!isSiteMasterListLoading && (
            <div className="row">
              <div className="col-12">
                <div className="jobsite-selector__dropdown-wrapper">
                  <div className="jobsite-selector__img-wrapper">
                    <img
                      className="jobsite-selector__img"
                      src={sitelogo}
                      alt="enquiryBtn"
                    />
                  </div>
                  <div className="jobsite-selector__input-wrapper">
                    <CustomDropdown
                      data={siteMasterData}
                      t={t}
                      selectedSite={selectedSite}
                      selectedJobSite={selectedJobSite}
                      onChange={(e) => onJobsiteSelect(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}{" "}
        </div>
      )}
      {!isJobSiteIdExists && (
        <div className="jobsite-selector__dropdown-wrapper">
          <div className="jobsite-selector__img-wrapper">
            <img className="jobsite-selector__img" src={sitelogo} alt="Site Logo" />
          </div>
          <div className="jobsite-selector__jobsite-info-wrapper">
            <h5 className="jobsite-selector__jobsite-name">
              {selectedJobSite.name}
            </h5>
            <p className="jobsite-selector__jobsite-id">
              {`#${selectedJobSite.id}`}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

JobsiteSelector.propTypes = {
  isJobSiteSelected: PropTypes.bool,
  isJobSiteIdExists: PropTypes.bool,
  selectedSite: PropTypes.object,
  siteMasterData: PropTypes.array,
  selectedJobSite: PropTypes.object,
  isSiteMasterListLoading: PropTypes.bool,
  t: PropTypes.func.isRequired,
  onJobsiteSelect: PropTypes.func.isRequired,
};

export default withTranslation()(JobsiteSelector);
