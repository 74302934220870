/**
 * Enquiry Creation/edit.
 *
 * For documentation, please refer below link,
 * https://hesusstore.atlassian.net/wiki/spaces/HS/pages/1913159689/5.1.+Inquiry+creation+edition+form
 */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import axios from "axios";
import PropTypes from "prop-types";

import EnquiryForm from "./components/EnquiryForm/EnquiryFormHook";
import FormContainer from "../../shared/components/FormContainer";

import enquiryLogo from "../../assets/shared/enquiry.svg";

import baseURL from "../../shared/utils/constant";

import { rejectObj } from "../../shared/utils/methods";

import {
  addNewRequest,
  editEnquiry,
  updateRequestData,
  handleRequestFormDetails,
  getMaterialProductData,
  updateRowValue,
  selectCategory,
  selectSubCategory,
  selectCategoryForm,
} from "./InquiryForm.actionCreators";

import {
  getSiteMasterListData,
  getProductData,
  showPopUp,
  hidePopUp,
  deleteDocument,
  selectedJobSiteForRequestCreation,
} from "../../store/dataStore";
import { handleLogOut } from "../../store/loginData";

const InquiryForm = (props) => {
  const {
    requestData,
    requestSelectedItems,
    countryId,
    userId,
    t,
    apiUnAuthenticated,
    newRequestLoading,
    lead,
  } = props;

  const [formMetaData, setFormMetaData] = useState({});
  const history = useHistory();

  useEffect(() => {
    let lng = "en";
    if (localStorage.getItem("userCountryId")) {
      const countryIdLocal = localStorage.getItem("userCountryId").toString();
      if (countryIdLocal === "1") {
        lng = "en";
      } else if (countryIdLocal === "2") {
        lng = "fr";
      } else if (countryIdLocal === "3") {
        lng = "pl";
      } else if (countryIdLocal === "6") {
        lng = "de";
      } else if (countryIdLocal === "8") {
        lng = "ch";
      }
    }
    props.i18n.changeLanguage(lng);
  }, [props.i18n]);

  const getEditStatus = (requestFormData, requiredKeyObj) => {
    if (!requestFormData.hasOwnProperty("editData")) {
      if (requiredKeyObj.hasOwnProperty("terms")) {
        return requiredKeyObj.hasOwnProperty("terms") && requestFormData.terms === 1;
      }
      return true;
    }
    return requestFormData.hasOwnProperty("editData");
  };

  useEffect(() => {
    if (userId === null || apiUnAuthenticated) {
      props.handleLogOut();
      history.replace("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, apiUnAuthenticated]);

  useEffect(() => {
    let disabledBtn;
    let requiredKeyObj;

    requiredKeyObj = rejectObj(requestData, [
      "is_call_for_tender",
      "initialRow",
      "document",
      "comment",
      "price",
      "study_type",
      "phase_id",
      "new",
      requestSelectedItems.subcategory === 5 ? "product" : "",
      countryId === 2 &&
      requestSelectedItems.service === 2 &&
      requestSelectedItems.category !== 1
        ? ""
        : "usage_id",
      "analysis_flag",
      countryId === 2 &&
      requestSelectedItems.service === 2 &&
      requestSelectedItems.category === 1 &&
      requestSelectedItems.subcategory === 2
        ? ""
        : "terms",
      "promocode",
    ]);

    const isPriceValid = validatePrice(requestData.price);
    if (!isPriceValid) {
      disabledBtn = true;
    } else if (requiredKeyObj.transport && requiredKeyObj.transport.length === 0) {
      disabledBtn = true;
    } else if (requiredKeyObj.hasOwnProperty("product")) {
      const allDataPresent =
        Object.values(requiredKeyObj).filter(
          (x) => x === "" || Object.is(x, NaN) || x === null || x === undefined
        ).length === 0 &&
        requiredKeyObj.product.length !== 0 &&
        requiredKeyObj.product.filter((x) => Number.isNaN(x)).length === 0 &&
        requiredKeyObj.product.filter((x) => x === "" || x === null).length === 0 &&
        requiredKeyObj.transport.length !== 0;

      const isEdit = getEditStatus(requestData, requiredKeyObj);
      disabledBtn = !(allDataPresent && isEdit);
    } else {
      disabledBtn = !(
        Object.values(requiredKeyObj).filter((x) => x === "" || Object.is(x, NaN))
          .length === 0 && requiredKeyObj.transport.length !== 0
      );
    }

    setFormMetaData({ disabledBtn });
  }, [requestData, requestSelectedItems, countryId]);

  // For request creation
  useEffect(() => {
    if ((requestData.new || requestData.updated) && !requestData.error) {
      if (requestData.jobsite_id) {
        localStorage.setItem("jobsiteId", requestData.jobsite_id);
      } else if (requestData.lead_id) {
        localStorage.setItem("jobsiteId", requestData.lead_id);
      } else if (requestData.leadId) {
        localStorage.setItem("jobsiteId", requestData.leadId);
      } else if (requestData.id) {
        localStorage.setItem("jobsiteId", requestData.id);
      }
      props.getSiteMasterListData();
      history.push("/viewsite");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestData]);

  useEffect(() => {
    if (lead && lead.leadInfo && lead.leadInfo.id) {
      updateEnquiryData(lead.leadInfo.id);
    }
  }, [lead]);

  const validatePrice = (price) => {
    if (price === null || price === "") return true;
    if (Number.isNaN(price)) {
      return /^-?\d+(?:,\d+)?$/.test(price);
    }
    return true;
  };

  const updateEnquiryData = (leadId) => {
    axios
      .post(
        `${baseURL}/leads/${leadId}`,
        {
          user_viewed: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${
              localStorage.getItem("token") !== ""
                ? localStorage.getItem("token")
                : ""
            }`,
          },
        }
      )
      .then((response) => {
        if (window.__DEV__) {
          console.log("Leads: successfully updated enquiry data", response);
        }
      })
      .catch((error) => {
        if (window.__DEV__) {
          console.log("Leads: error while updating enquiry data", error);
        }
      });
  };

  const removeStoredData = () => {
    localStorage.removeItem("requestData");
    localStorage.removeItem("selectedJobsiteForRequest");
    localStorage.removeItem("requestService");
    localStorage.removeItem("requestCategory");
    localStorage.removeItem("requestSubCategory");
  };

  const submitRequestDetails = async () => {
    const { token, selectedJobSite, rowItem } = props;
    if (!requestData.hasOwnProperty("editData")) {
      await props.addNewRequest({
        token,
        requestData,
        siteId: selectedJobSite.id,
        rowItem,
      });
    } else {
      await props.updateRequestData({
        token,
        requestData,
        siteId: selectedJobSite.id,
        rowItem,
      });
    }
    localStorage.setItem("jobsiteId", selectedJobSite.id);
    removeStoredData();
  };

  return (
    <FormContainer
      icon={enquiryLogo}
      isLoading={newRequestLoading}
      onSubmit={submitRequestDetails}
      isSubmitBtnDisabled={formMetaData.disabledBtn}
      title={t("CLIENT_PLATFORM_REQUEST_FORM_TITLE")}
      description={t("CLIENT_PLATFORM_REQUEST_FORM_DESCRIPTION")}
      submitBtnLabel={t("CLIENT_PLATFORM_REQUEST_FORM_BUTTON_SAVE")}
      onCancel={() => {
        removeStoredData();
        if (requestData.lead_id || requestData.leadId) {
          history.replace("/viewsite");
        } else {
          history.goBack();
        }
      }}
    >
      <EnquiryForm
        userCountry={props.userCountry}
        loginUserCountry={props.loginUserCountry}
        selectedJobSite={props.selectedJobSite}
        transport={props.transport}
        categoryForm={props.categoryForm ? parseInt(props.categoryForm, 10) : null}
        category={props.category}
        data={props.data}
        subCategory={props.subCategory}
        productData={props.productData}
        selectedService={
          props.selectedService ? parseInt(props.selectedService, 10) : null
        }
        requestSelectedItems={props.requestSelectedItems}
        selectedMaterialProductData={props.selectedMaterialProductData}
        materialProductData={props.materialProductData}
        initialRow={props.initialRow}
        siteMasterData={props.siteMasterData}
        siteMasterDataRaw={props.siteMasterDataRaw}
        countryId={props.countryIdLogin}
        isRequestCategoriesLoading={props.isRequestCategoriesLoading}
        isSiteMasterListLoading={props.isSiteMasterListLoading}
        getSiteMasterListData={props.getSiteMasterListData}
        handleRequestFormDetails={props.handleRequestFormDetails}
        handleShowPopUp={props.showPopUp}
        handlePopUp={props.hidePopUp}
        getProductData={props.getProductData}
        getMaterialProductData={props.getMaterialProductData}
        updateRowValue={props.updateRowValue}
        deleteDocument={props.deleteDocument}
        selectedJobSiteForRequestCreation={props.selectedJobSiteForRequestCreation}
        selectCategory={props.selectCategory}
        selectSubCategory={props.selectSubCategory}
        selectCategoryForm={props.selectCategoryForm}
      />
    </FormContainer>
  );
};

InquiryForm.propTypes = {
  countryId: PropTypes.number,
  userId: PropTypes.string.isRequired,
  token: PropTypes.string,
  lead: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  requestData: PropTypes.object,
  requestSelectedItems: PropTypes.object,
  rowItem: PropTypes.array.isRequired,
  newRequestLoading: PropTypes.bool,
  apiUnAuthenticated: PropTypes.bool,
  userCountry: PropTypes.number,
  loginUserCountry: PropTypes.object,
  selectedJobSite: PropTypes.object,
  transport: PropTypes.array,
  categoryForm: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  category: PropTypes.array,
  data: PropTypes.object,
  subCategory: PropTypes.array,
  productData: PropTypes.array,
  selectedService: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectedMaterialProductData: PropTypes.array,
  materialProductData: PropTypes.array,
  initialRow: PropTypes.array,
  siteMasterData: PropTypes.array,
  siteMasterDataRaw: PropTypes.array,
  countryIdLogin: PropTypes.string,
  isRequestCategoriesLoading: PropTypes.bool,
  isSiteMasterListLoading: PropTypes.bool,
  addNewRequest: PropTypes.func.isRequired,
  editEnquiry: PropTypes.func.isRequired,
  updateRequestData: PropTypes.func.isRequired,
  getSiteMasterListData: PropTypes.func.isRequired,
  handleLogOut: PropTypes.func.isRequired,
  handleRequestFormDetails: PropTypes.func.isRequired,
  showPopUp: PropTypes.func.isRequired,
  hidePopUp: PropTypes.func.isRequired,
  getProductData: PropTypes.func.isRequired,
  getMaterialProductData: PropTypes.func.isRequired,
  updateRowValue: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  selectedJobSiteForRequestCreation: PropTypes.func.isRequired,
  selectCategory: PropTypes.func.isRequired,
  selectSubCategory: PropTypes.func.isRequired,
  selectCategoryForm: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    countryId:
      localStorage.getItem("countryId") !== ""
        ? parseInt(localStorage.getItem("countryId"), 10)
        : "",
    userId:
      localStorage.getItem("userId") !== "" ? localStorage.getItem("userId") : "",
    token: localStorage.getItem("token") !== "" ? localStorage.getItem("token") : "",
    lead: state.dashboard.lead,
    requestData: state.inquiryForm.requestCreationData,
    requestSelectedItems: state.inquiryForm.requestSelectedItems,
    rowItem: state.inquiryForm.rowItem,
    newRequestLoading: state.inquiryForm.newRequestLoading,
    apiUnAuthenticated: state.inquiryForm.apiUnAuthenticated,
    userCountry:
      localStorage.getItem("countryId") !== ""
        ? parseInt(localStorage.getItem("countryId"), 10)
        : "",
    loginUserCountry:
      JSON.parse(localStorage.getItem("userCountry")) !== {}
        ? JSON.parse(localStorage.getItem("userCountry"))
        : "",
    selectedJobSite: state.inquiryForm.selectedJobSiteForRequest,
    transport: state.inquiryForm.requestCategoriesData.transport,
    categoryForm: state.inquiryForm.categoryForm,
    category: state.inquiryForm.category,
    data: state.inquiryForm.requestCreationData,
    subCategory: state.inquiryForm.subCategory,
    productData: state.dataStore.productData,
    selectedService: state.inquiryForm.selectedService,
    selectedMaterialProductData: state.inquiryForm.materialProductData,
    materialProductData: state.inquiryForm.materialAllProductsData,
    initialRow: state.inquiryForm.initialRow,
    siteMasterData: state.inquiryForm.siteMasterData,
    siteMasterDataRaw: state.inquiryForm.siteMasterDataRaw,
    countryIdLogin: state.loginStore.countryId,
    isRequestCategoriesLoading: state.dataStore.isRequestCategoriesLoading,
    isSiteMasterListLoading: state.dataStore.isSiteMasterListLoading,
  };
};

const mapDispatchToProps = {
  addNewRequest: (payload) => addNewRequest(payload),
  editEnquiry: (payload) => editEnquiry(payload),
  updateRequestData: (payload) => updateRequestData(payload),
  getSiteMasterListData: () => getSiteMasterListData(),
  handleLogOut: () => handleLogOut(),
  handleRequestFormDetails: (payload) => handleRequestFormDetails(payload),
  showPopUp: () => showPopUp(),
  hidePopUp: () => hidePopUp(),
  getProductData: (payload) => getProductData(payload),
  getMaterialProductData: (payload) => getMaterialProductData(payload),
  updateRowValue: (payload) => updateRowValue(payload),
  deleteDocument: (payload) => deleteDocument(payload),
  selectedJobSiteForRequestCreation: (payload) =>
    selectedJobSiteForRequestCreation(payload),
  selectCategory: (payload) => selectCategory(payload),
  selectSubCategory: (payload) => selectSubCategory(payload),
  selectCategoryForm: (payload) => selectCategoryForm(payload),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(InquiryForm));
