import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import * as moment from "moment";
import PropTypes from "prop-types";
import PulseLoader from "react-spinners/PulseLoader";

import ErrorPopUp from "../../../../shared/baseUI/ErrorPopUp";
import TextInput from "../../../../shared/baseUI/TextInput";
import TextAreaInput from "../../../../shared/baseUI/TextAreaInput";
import SelectInput from "../../../../shared/baseUI/CustomSelectBox";
import CustomDatePicker from "../../../../shared/baseUI/DatePicker";

import sitelogo from "../../../../assets/shared/site.svg";
import removalIcon from "../../../../assets/shared/removal.svg";

import { storage } from "../../../../store/dataStore/api";

import { isEmpty } from "../../../../shared/utils/methods";

import "./CallOffOrderFormDetails.scss";

const CallOffOrderFormDetails = (props) => {
  const {
    selectedJobSite,
    data,
    siteMasterData,
    requestMasterData,
    transport,
    callOffOrderSuccess,
    isSiteMasterListLoading,
    isRequestMasterListLoading,
    t,
  } = props;

  const [formState, setFormState] = useState({
    errorDateStatus: false,
    isJobSiteSelected: false,
    isJobSiteIdExists: false,
    request: {
      id: "",
      name: "",
      category: "",
      subcategory: "",
      service: "",
    },
  });
  const [requestOptions, setRequestOptions] = useState([]);
  const [showErrorPopup, setErrorPopupvisibility] = useState(false);
  const [apiError, setApiError] = useState("");
  const [fields, setFormFields] = useState({
    jobsite_id: { value: "", error: "" },
    request: { value: "", error: "" },
    truck_id: { value: "", error: "" },
    loads: { value: "", error: "" },
    date: { value: "", error: "" },
    comment: {
      value: "",
      error: "",
    },
  });

  const saveDataToStore = (formData) => {
    const updatedData = { ...data };
    updatedData.jobsite_id = formData.jobsite_id.value;
    updatedData.request = formData.request.value;
    updatedData.truck_id = formData.truck_id.value;
    updatedData.loads = formData.loads.value;
    updatedData.date = formData.date.value;
    updatedData.comment = formData.comment.value;

    localStorage.setItem("callOffOrderData", JSON.stringify(updatedData));
    props.handleCallOrderData({ updatedData });
  };

  const updateFields = (formData) => {
    const localFields = { ...fields };
    localFields.jobsite_id.value = formData.jobsite_id;
    localFields.request.value = formData.request;
    localFields.truck_id.value = formData.truck_id;
    localFields.loads.value = formData.loads;
    localFields.date.value = formData.date;
    localFields.comment.value = formData.comment;
    setFormFields(localFields);

    if (isEmpty(data)) {
      // On page reload, update redux store.
      props.handleCallOrderData({ updatedData: formData });
    }
  };

  useEffect(() => {
    if (selectedJobSite.id === "") {
      setFormState({
        ...formState,
        isJobSiteSelected: true,
        isJobSiteIdExists: true,
      });
      storage();
      props.getSiteMasterListDataCallOfOrder();
    }

    if (!transport || transport.length === 0) {
      storage();
      props.getTrucks();
    }

    const storedData = localStorage.getItem("callOffOrderData");
    if (isEmpty(data) && storedData) {
      // On page refresh,
      const parsedData = JSON.parse(storedData);
      const jobsiteData = localStorage.getItem("callOffOrderJobsite");
      const parseJobsiteData = jobsiteData && JSON.parse(jobsiteData);
      updateFields(parsedData);
      if (parseJobsiteData?.id) {
        setFormState({ ...formState, isJobSiteSelected: true });
        props.selectedJobSiteForRequestCreation({
          id: parseJobsiteData?.id,
          name: parseJobsiteData?.name,
        });
        props.getRequestListData(parseJobsiteData?.id);
      }
    } else {
      updateFields(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Modify request option labels.
    if (requestMasterData.length > 0) {
      const modifiedRequests = requestMasterData.map((option) => {
        const modifiedOption = option;
        modifiedOption.label = `${t(option.label.split("#")[0].trim())} #${
          option.label.split("#")[1]
        }`;
        return modifiedOption;
      });
      setRequestOptions(modifiedRequests);
    }
  }, [requestMasterData, t]);

  /**
   * Handle API failure.
   */
  useEffect(() => {
    if (callOffOrderSuccess.status && callOffOrderSuccess.status !== 200) {
      setErrorPopupvisibility(true);
      setApiError(callOffOrderSuccess.error?.message);
      const errorData = callOffOrderSuccess?.error?.errors;
      const localFields = { ...fields };
      if (errorData && errorData.date) {
        if (errorData.date[0] === "validation.required") {
          localFields.date.error = t(
            "CLIENT_PLATFORM_CALL_OFF_ORDER_FORM_DATES_INFO_DATE_MISSING_ERROR"
          );
        }
      }
      if (errorData && errorData.loads) {
        if (errorData.loads[0] === "validation.required") {
          localFields.loads.error = t(
            "CLIENT_PLATFORM_CALL_OFF_ORDER_FORM_NEEDS_INFO_LOADS_PER_DAY_MISSING_ERROR"
          );
        }
      }
      if (errorData && errorData.truck_id) {
        if (errorData.truck_id[0] === "validation.required") {
          localFields.truck_id.error = t(
            "CLIENT_PLATFORM_CALL_OFF_ORDER_FORM_NEEDS_INFO_TRANSPORT_MISSING_ERROR"
          );
        }
      }
      setFormFields(localFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callOffOrderSuccess]);

  /**
   * Handle input change.
   * @param {*} e
   */
  const onChange = (e) => {
    const name = e.target ? e.target.name : e.name;
    const value = e.target ? e.target.value : e.value;
    const localFields = { ...fields };

    localFields[name] = { value, error: "" };

    if (name === "truck_id") {
      if (value) {
        localFields[name] = { value, error: "" };
      } else {
        localFields[name] = {
          value,
          error: t(
            "CLIENT_PLATFORM_CALL_OFF_ORDER_FORM_NEEDS_INFO_TRANSPORT_MISSING_ERROR"
          ),
        };
      }
    }
    if (name === "loads") {
      if (!value) {
        localFields[name] = {
          value,
          error: t(
            "CLIENT_PLATFORM_CALL_OFF_ORDER_FORM_NEEDS_INFO_LOADS_PER_DAY_MISSING_ERROR"
          ),
        };
      } else if (!/^\d+$/.test(value)) {
        localFields[name].error = t(
          "CLIENT_PLATFORM_CALL_OFF_ORDER_FORM_NEEDS_INFO_LOADS_PER_DAY_WRONG_VALUE_ERROR"
        );
      } else {
        localFields[name] = { value, error: "" };
      }
    }
    setFormFields(localFields);
    saveDataToStore(localFields);
  };
  const isDateValid = (value) => {
    const dateObj = {};
    const date = value.getDate() < 10 ? `0${value.getDate()}` : value.getDate();
    const month =
      value.getMonth() + 1 < 10 ? `0${value.getMonth() + 1}` : value.getMonth() + 1;
    dateObj.value = `${value.getFullYear()}-${month}-${date}`;
    dateObj.error = "";
    if (value === "") {
      dateObj.error =
        "CLIENT_PLATFORM_CALL_OFF_ORDER_FORM_DATES_INFO_DATE_MISSING_ERROR";
    }

    return dateObj;
  };

  const onDateChange = (e) => {
    const date = new Date(e);
    if (date.toDateString() !== "Invalid Date") {
      const validField = isDateValid(e);
      if (validField) {
        setFormFields({
          ...fields,
          date: { value: validField.value, error: validField.error },
        });
        saveDataToStore({
          ...fields,
          date: { value: validField.value, error: validField.error },
        });
      }
    }
  };

  /**
   * Handle jobsite select.
   * @param {*} e
   */
  const onJobsiteSelect = (e) => {
    const selectedSiteDetails = e.target
      ? e.target.value.split("-")
      : e.value.split("-");
    const id = parseInt(selectedSiteDetails[0], 10);
    let jobsiteName = "";
    let name = "";
    const localFields = { ...fields };

    selectedSiteDetails.forEach((item, i) => {
      if (i > 0) {
        if (i === 1) {
          jobsiteName += item;
        } else {
          jobsiteName += `-${item}`;
        }
      }
    });

    name = jobsiteName;
    localFields.jobsite_id = { value: id, errors: "" };
    localFields.request = { value: "", errors: "" };

    localStorage.setItem("callOffOrderJobsite", JSON.stringify({ id, name }));
    props.selectedJobSiteForRequestCreation({ id, name });
    setFormState({ ...formState, isJobSiteSelected: true });
    props.getRequestListData(id);
    setFormFields(localFields);
    saveDataToStore(localFields);
  };

  const closePopup = () => {
    setErrorPopupvisibility(false);
    setApiError("");
  };

  return (
    <form id="createCallOfOrder" className="call-order-form">
      <div className="row">
        <div className="col-12">
          <h6 className="call-order-form__section-title">
            {t("CLIENT_PLATFORM_CALL_OFF_ORDER_FORM_REQUEST_INFO_TITLE")}
          </h6>
          {isSiteMasterListLoading && (
            <div className="loader-wrapper">
              <PulseLoader color="#004c7f" />
            </div>
          )}
          {!isSiteMasterListLoading && (
            <div className="call-order-form__site-selector-container">
              <img
                className="call-order-form__site-icon"
                src={sitelogo}
                alt="Jobsite select"
              />
              <div className="call-order-form__site-selector-wrapper">
                <SelectInput
                  value={`${selectedJobSite.id} - ${selectedJobSite.name.trim()}`}
                  inputClass="call-order-form__input"
                  divClass="p-0 call-order-form__input-wrapper"
                  labelClass="call-order-form__input-label--hide"
                  required
                  name="jobsite_id"
                  defaultOption={t(
                    "CLIENT_PLATFORM_CALL_OFF_ORDER_FORM_REQUEST_INFO_JOBSITE_PLACEHOLDER"
                  )}
                  onChange={(e) => onJobsiteSelect(e)}
                  options={siteMasterData}
                />
              </div>
            </div>
          )}
        </div>
        <div className="col-12">
          {isRequestMasterListLoading && (
            <div className="loader-wrapper">
              <PulseLoader color="#004c7f" />
            </div>
          )}
          {!isRequestMasterListLoading && (
            <div className="call-order-form__request-selector-container">
              <img
                className="call-order-form__request-icon"
                src={removalIcon}
                alt="Enquiry select"
              />
              <div className="call-order-form__request-selector-wrapper">
                <SelectInput
                  value={fields.request.value}
                  inputClass="call-order-form__input"
                  divClass="p-0 call-order-form__input-wrapper"
                  labelClass="call-order-form__input-label--hide"
                  required
                  name="request"
                  defaultOption={t(
                    "CLIENT_PLATFORM_CALL_OFF_ORDER_FORM_REQUEST_INFO_REQUEST_PLACEHOLDER"
                  )}
                  onChange={(e) => onChange(e)}
                  options={requestOptions}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <section className="mt-3">
        <h6 className="call-order-form__section-title">
          {t("CLIENT_PLATFORM_CALL_OFF_ORDER_FORM_NEEDS_INFO_TITLE")}
        </h6>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6">
            <SelectInput
              value={fields.truck_id.value}
              inputClass={
                fields.truck_id.error
                  ? "call-order-form__input border border-danger"
                  : "call-order-form__input"
              }
              divClass="call-order-form__input-wrapper"
              labelName={t(
                "CLIENT_PLATFORM_CALL_OFF_ORDER_FORM_NEEDS_INFO_TRANSPORT"
              )}
              labelClass="call-order-form__input-label required"
              required
              error={!!fields.truck_id.error}
              errorMsg={fields.truck_id.error}
              name="truck_id"
              onChange={(e) => onChange(e)}
              defaultOption={t(
                "CLIENT_PLATFORM_CALL_OFF_ORDER_FORM_NEEDS_INFO_TRANSPORT_PLACEHOLDER"
              )}
              options={transport}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6">
            <TextInput
              type="text"
              placeholder={t(
                "CLIENT_PLATFORM_CALL_OFF_ORDER_FORM_NEEDS_INFO_LOADS_PER_DAY_PLACEHOLDER"
              )}
              inputClass={
                fields.loads.error
                  ? "call-order-form__input border border-danger"
                  : "call-order-form__input"
              }
              divClass="call-order-form__input-wrapper"
              labelClass="call-order-form__input-label required"
              labelName={t(
                "CLIENT_PLATFORM_CALL_OFF_ORDER_FORM_NEEDS_INFO_LOADS_PER_DAY"
              )}
              required
              error={!!fields.loads.error}
              errorMsg={fields.loads.error}
              id="loads"
              name="loads"
              value={fields.loads.value}
              onChange={(e) => onChange(e)}
            />
          </div>
        </div>
      </section>
      <section>
        <h6 className="call-order-form__section-title">
          {t("CLIENT_PLATFORM_CALL_OFF_ORDER_FORM_DATES_INFO_TITLE")}
        </h6>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6">
            <CustomDatePicker
              placeholder={t(
                "CLIENT_PLATFORM_CALL_OFF_ORDER_FORM_DATES_INFO_DATE_PLACEHOLDER"
              )}
              inputClass={
                fields.date.error
                  ? "call-order-form__input border border-danger"
                  : "call-order-form__input"
              }
              divClass="call-order-form__input-wrapper"
              labelClass="call-order-form__input-label required"
              labelName={t("CLIENT_PLATFORM_CALL_OFF_ORDER_FORM_DATES_INFO_DATE")}
              required
              error={!!fields.date.error}
              errorMsg={fields.date.error}
              id="date"
              name="date"
              minDate={
                new Date().getHours() > 12
                  ? moment(moment.now()).add(2, "days").format("YYYY-MM-DD")
                  : moment(moment.now()).add(1, "days").format("YYYY-MM-DD")
              }
              value={fields.date.value}
              onChange={(e) => onDateChange(e)}
              dateFormat="dd-MM-yyyy"
            />
          </div>
        </div>
      </section>
      <section>
        <h6 className="call-order-form__section-title">
          {t("CLIENT_PLATFORM_CALL_OFF_ORDER_FORM_COMMENTS_INFO_TITLE")}
        </h6>
        <TextAreaInput
          placeholder={t(
            "CLIENT_PLATFORM_CALL_OFF_ORDER_FORM_COMMENTS_INFO_COMMENT_PLACEHOLDER"
          )}
          inputClass="call-order-form__input call-order-form__input--comment"
          divClass="call-order-form__input-wrapper"
          labelClass="call-order-form__input-label"
          labelName={t("CLIENT_PLATFORM_CALL_OFF_ORDER_FORM_COMMENTS_INFO_COMMENT")}
          required={false}
          rows={5}
          columns={100}
          id="comment"
          name="comment"
          value={fields.comment.value ? fields.comment.value : ""}
          onChange={(e) => onChange(e)}
        />
      </section>
      {showErrorPopup && (
        <ErrorPopUp
          title={t("KICK_OFF_ERROR_BOX_TITLE")}
          btnText={t("KICK_OFF_ERROR_BOX_OK")}
          message={apiError}
          onOkClick={closePopup}
        />
      )}
    </form>
  );
};

CallOffOrderFormDetails.propTypes = {
  selectedJobSite: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  siteMasterData: PropTypes.arrayOf(PropTypes.object).isRequired,
  requestMasterData: PropTypes.arrayOf(PropTypes.object).isRequired,
  transport: PropTypes.arrayOf(PropTypes.object),
  callOffOrderSuccess: PropTypes.object.isRequired,
  isSiteMasterListLoading: PropTypes.bool.isRequired,
  isRequestMasterListLoading: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  getSiteMasterListDataCallOfOrder: PropTypes.func.isRequired,
  getTrucks: PropTypes.func.isRequired,
  handleCallOrderData: PropTypes.func.isRequired,
  selectedJobSiteForRequestCreation: PropTypes.func.isRequired,
  getRequestListData: PropTypes.func.isRequired,
};

export default withTranslation()(CallOffOrderFormDetails);
