import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import ClipLoader from "react-spinners/ClipLoader";
import PropTypes from "prop-types";

import { storage } from "../../../../store/dataStore/api";

import Button from "../../../../shared/baseUI/Button";
import TextInput from "../../../../shared/baseUI/TextInput";

import "./AccountDetails.scss";

const AccountDetails = (props) => {
  const {
    t,
    userAccountData,
    selectedCountryId,
    emailExists,
    isAccountUpdate,
    phase,
  } = props;

  const [isEdit, setEditStatus] = useState(false);
  const [fields, setFormFields] = useState({});
  const [isFormValid, setFormValidity] = useState(false);
  const [emailVal, setEmailVal] = useState("");
  const phoneNumberFormat = /^(?:[0-9 .\-+])*$/;

  // Fetch account details on first render.
  useEffect(() => {
    storage();
    props.getUserAccountData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userAccountData) {
      updateFormFields(userAccountData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccountData]);

  useEffect(() => {
    const fieldsLocal = { ...fields };
    const emailRegEx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (fieldsLocal.email && isEdit) {
      if (fieldsLocal.email.value.length === 0) {
        fieldsLocal.email.error =
          "CLIENT_PLATFORM_MY_ACCOUNT_FORM_CONTACT_INFORMATION_EMAIL_MISSING_ERROR";
        setFormValidity(false);
      }
      if (emailExists === 200) {
        fieldsLocal.email.error =
          "CLIENT_PLATFORM_REGISTRATION_FIELD_EMAIL_UNICITY_ERROR";
        setFormValidity(false);
      } else if (!emailRegEx.test(fieldsLocal.email.value)) {
        fieldsLocal.email.error =
          "CLIENT_PLATFORM_MY_ACCOUNT_FORM_CONTACT_INFORMATION_EMAIL_WRONG_VALUE_ERROR";
        setFormValidity(false);
      } else {
        fieldsLocal.email.error = "";
        setFormValidity(true);
      }
      setFormFields(fieldsLocal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailExists, emailVal]);

  useEffect(() => {
    let lng = "en";
    if (localStorage.getItem("userCountryId")) {
      const countryId = localStorage.getItem("userCountryId").toString();
      if (countryId === "1") {
        lng = "en";
      } else if (countryId === "2") {
        lng = "fr";
      } else if (countryId === "3") {
        lng = "pl";
      } else if (countryId === "6") {
        lng = "de";
      } else if (countryId === "8") {
        lng = "ch";
      }
    }
    props.i18n.changeLanguage(lng);
  }, [props.i18n]);

  // Save user details
  const submitUserAccountData = (data) => {
    if (isFormValid) {
      props.submitMyAccountData(data);
    }
  };

  const getDisableStatus = (data) => {
    if (!data) {
      return false;
    }

    const disable = !(
      Object.values(data.user).filter((x) => x === "").length === 0 &&
      ((data.user.can_edit &&
        Object.values(data.company).filter((x) => x === "").length === 0) ||
        !data.user.can_edit)
    );

    return disable;
  };

  const onChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setEditStatus(true);
    validateField(name, value);
  };

  const updateFormFields = (accountData) => {
    const fieldsLocal = { ...fields };
    fieldsLocal.first_name = {
      value: accountData.user.first_name,
      error: "",
      canEdit: true,
    };

    fieldsLocal.last_name = {
      value: accountData.user.last_name,
      error: "",
      canEdit: true,
    };

    fieldsLocal.job = {
      value: accountData.user.job,
      error: "",
      canEdit: true,
    };

    fieldsLocal.name = {
      value: accountData.company.name,
      error: "",
      canEdit: accountData.user.can_edit,
    };

    fieldsLocal.shipping_address = {
      value: accountData.company.shipping_address
        ? accountData.company.shipping_address
        : "",
      error: "",
      canEdit: accountData.user.can_edit,
    };

    fieldsLocal.shipping_city = {
      value: accountData.company.shipping_city
        ? accountData.company.shipping_city
        : "",
      error: "",
      canEdit: accountData.user.can_edit,
    };

    fieldsLocal.email = {
      value: accountData.user.email,
      error: "",
      canEdit: true,
    };

    fieldsLocal.phone = {
      value: accountData.user.phone,
      error: "",
      canEdit: true,
    };

    fieldsLocal.company_phone = {
      value: accountData.company.company_phone,
      error: "",
      canEdit: accountData.user.can_edit,
    };

    fieldsLocal.identification_number = {
      value: accountData.company.identification_number,
      error: "",
      canEdit: accountData.user.can_edit,
    };

    fieldsLocal.shipping_postal = {
      value: accountData.company.shipping_postal
        ? accountData.company.shipping_postal
        : "",
      error: "",
      canEdit: accountData.user.can_edit,
    };

    setFormFields(fieldsLocal);
  };

  const validateField = (name, value) => {
    const fieldsLocal = { ...fields };

    switch (name) {
      case "first_name":
        fieldsLocal[name] = {
          value,
          error: isValidName(name, value),
          canEdit: true,
        };
        break;
      case "last_name":
        fieldsLocal[name] = {
          value,
          error: isValidName(name, value),
          canEdit: true,
        };
        break;
      case "job":
        fieldsLocal[name] = {
          value,
          error:
            value.length === 0
              ? "CLIENT_PLATFORM_MY_ACCOUNT_FORM_CONTACT_INFORMATION_POSITION_MISSING_ERROR"
              : "",
          canEdit: true,
        };
        break;
      case "name": // company name
        fieldsLocal[name] = {
          value,
          error:
            value.length === 0
              ? "CLIENT_PLATFORM_MY_ACCOUNT_FORM_COMPANY_DETAILS_NAME_MISSING_ERROR"
              : "",
          canEdit: true,
        };
        break;
      case "shipping_address":
        fieldsLocal[name] = {
          value,
          error:
            value.length === 0
              ? "CLIENT_PLATFORM_MY_ACCOUNT_FORM_COMPANY_DETAILS_ADDRESS_MISSING_ERROR"
              : "",
          canEdit: true,
        };
        break;
      case "shipping_city":
        fieldsLocal[name] = {
          value,
          error: isCityValid(value),
          canEdit: true,
        };
        break;
      case "email":
        fieldsLocal[name] = {
          value: getEmailVal(value),
          error:
            value.length === 0
              ? "CLIENT_PLATFORM_MY_ACCOUNT_FORM_CONTACT_INFORMATION_EMAIL_MISSING_ERROR"
              : "",
          canEdit: true,
        };
        break;
      case "phone":
      case "company_phone":
        fieldsLocal[name] = {
          value,
          error: isValidPhone(value),
          canEdit: true,
        };
        break;
      case "identification_number":
        fieldsLocal[name] = {
          value: getIdentificationNumber(value),
          error: isValidIdentificationNum(value),
          canEdit: true,
        };
        break;
      case "shipping_postal":
        fieldsLocal[name] = {
          value: getPostalCode(value),
          error: isValidPostalCode(value),
          canEdit: true,
        };
        break;
      default:
        fieldsLocal[name] = {
          value,
          error: "",
          canEdit: true,
        };
    }

    setFormFields(fieldsLocal);
    const errorFields = Object.values(fieldsLocal).filter(
      (field) => field.error !== ""
    );
    if (errorFields.length === 0) {
      setFormValidity(true);
      updateAccountDetails(fieldsLocal);
    } else {
      setFormValidity(false);
    }
  };

  const updateAccountDetails = (details) => {
    const accountData = { ...userAccountData };
    accountData.user.first_name = details.first_name.value;
    accountData.user.last_name = details.last_name.value;
    accountData.user.job = details.job.value;
    accountData.user.email = details.email.value;
    accountData.user.phone = details.phone.value;
    accountData.company.company_phone = details.company_phone.value;
    accountData.company.identification_number = details.identification_number.value;
    accountData.company.name = details.name.value;
    accountData.company.shipping_address = details.shipping_address.value;
    accountData.company.shipping_city = details.shipping_city.value;
    accountData.company.shipping_postal = details.shipping_postal.value;

    props.handleMyAccountDetails({ userAccountDetails: accountData });
  };

  const isValidName = (name, value) => {
    if (name === "first_name") {
      if (value.length === 0) {
        return "CLIENT_PLATFORM_MY_ACCOUNT_FORM_CONTACT_INFORMATION_FIRST_NAME_MISSING_ERROR";
      }
      if (value.length > 0 && /\d/.test(value)) {
        return "CLIENT_PLATFORM_MY_ACCOUNT_FORM_CONTACT_INFORMATION_FIRST_NAME_WRONG_VALUE_ERROR";
      }
    }
    if (name === "last_name") {
      if (value.length === 0) {
        return "CLIENT_PLATFORM_MY_ACCOUNT_FORM_CONTACT_INFORMATION_LAST_NAME_MISSING_ERROR";
      }
      if (value.length > 0 && /\d/.test(value)) {
        return "CLIENT_PLATFORM_MY_ACCOUNT_FORM_CONTACT_INFORMATION_LAST_NAME_WRONG_VALUE_ERROR";
      }
    }
    return "";
  };

  const getEmailVal = (email) => {
    const emailRegEx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setEmailVal(email); // to run useEffect for email validation
    if (email.length > 0 && emailRegEx.test(email)) {
      props.checkIsEmailExists(email);
    }
    return email;
  };

  const isValidPhone = (phNumber) => {
    if (phNumber === "" || phNumber === null) {
      return "CLIENT_PLATFORM_MY_ACCOUNT_FORM_CONTACT_INFORMATION_PHONE_MISSING_ERROR";
    }
    if (phNumber.length < 10 || phNumber.length > 14) {
      return "CLIENT_PLATFORM_ERROR_MESSAGE_PHONE_NUMBER_FIELD_WRONG_LENGTH";
    }

    return !phoneNumberFormat.test(phNumber)
      ? "CLIENT_PLATFORM_MY_ACCOUNT_FORM_CONTACT_INFORMATION_PHONE_FORMAT_ERROR"
      : "";
  };

  const getIdentificationNumber = (identificationNum) => {
    if (
      selectedCountryId === 1 ||
      selectedCountryId === 2 ||
      selectedCountryId === 3 ||
      selectedCountryId === 6
    ) {
      return identificationNum;
    }
    if (selectedCountryId === 8) {
      if (identificationNum.length < 3) return "CHE-";
    }
    const val = identificationNum.split("-").join("");
    const finalVal = val.match(/.{1,3}/g).join("-");
    return finalVal;
  };

  const isValidIdentificationNum = (identificationNum) => {
    if (selectedCountryId === 2) {
      // fr
      if (identificationNum.length !== 14) {
        return "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_PERSONAL_FORM_COMPANY_NUMBER_FORMAT_ERROR";
      }
      if (!/^\d+$/.test(identificationNum)) {
        return "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_PERSONAL_FORM_COMPANY_NUMBER_FORMAT_ERROR";
      }
    } else if (selectedCountryId === 3) {
      // pl
      if (identificationNum.length !== 10) {
        return "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_PERSONAL_FORM_COMPANY_NUMBER_FORMAT_ERROR";
      }
      if (!/^\d+$/.test(identificationNum)) {
        return "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_PERSONAL_FORM_COMPANY_NUMBER_FORMAT_ERROR";
      }
    } else if (selectedCountryId === 6) {
      // Germany
      if (identificationNum.length !== 9) {
        return "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_PERSONAL_FORM_COMPANY_NUMBER_FORMAT_ERROR";
      }
      if (!/^[a-zA-Z0-9]{9}$/.test(identificationNum)) {
        return "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_PERSONAL_FORM_COMPANY_NUMBER_FORMAT_ERROR";
      }
    } else if (selectedCountryId === 8) {
      // ch
      let registrationNum;
      if (identificationNum.length < 3) {
        registrationNum = `CHE-${identificationNum}`;
      } else {
        const val = identificationNum.split("-").join("");
        const matchArr = val.match(/.{1,3}/g);
        const finalVal = matchArr.join("-");
        const digits = matchArr.filter((d, i) => i !== 0).join("");
        const isDigit = /^\d{9}$/.test(digits);
        if (!isDigit) {
          return "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_PERSONAL_FORM_COMPANY_NUMBER_FORMAT_ERROR";
        }
        registrationNum = finalVal;
      }
      if (registrationNum.length !== 15) {
        return "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_PERSONAL_FORM_COMPANY_NUMBER_FORMAT_ERROR";
      }
    }

    return "";
  };

  const getPostalCode = (postalCode) => {
    if (
      props.selectedCountryId === 1 ||
      props.selectedCountryId === 2 ||
      props.selectedCountryId === 8 ||
      props.selectedCountryId === 6
    ) {
      return postalCode;
    }
    // For poland format as XX-XXX
    if (props.selectedCountryId === 3) {
      let str = postalCode;
      str = str.replace("-", "");
      if (str.length > 2) {
        str = `${str.substring(0, 2)}-${str.substring(2)}`;
      }
      return str;
    }
  };

  const isValidPostalCode = (postalCode) => {
    if (selectedCountryId === 1) {
      // en
      if (postalCode.length < 1 || postalCode.length > 8) {
        return "CLIENT_PLATFORM_MY_ACCOUNT_FORM_COMPANY_DETAILS_POSTCODE_FORMAT_ERROR";
      }
    } else if (selectedCountryId === 2 || selectedCountryId === 6) {
      // fr, Germany
      if (postalCode.length !== 5) {
        return "CLIENT_PLATFORM_MY_ACCOUNT_FORM_COMPANY_DETAILS_POSTCODE_FORMAT_ERROR";
      }
      if (!/^\d+$/.test(postalCode)) {
        return "CLIENT_PLATFORM_MY_ACCOUNT_FORM_COMPANY_DETAILS_POSTCODE_FORMAT_ERROR";
      }
    } else if (selectedCountryId === 3) {
      // pl
      if (postalCode.length !== 6) {
        return "CLIENT_PLATFORM_MY_ACCOUNT_FORM_COMPANY_DETAILS_POSTCODE_FORMAT_ERROR";
      }
    } else if (selectedCountryId === 8) {
      // ch
      if (postalCode.length !== 4) {
        return "CLIENT_PLATFORM_MY_ACCOUNT_FORM_COMPANY_DETAILS_POSTCODE_FORMAT_ERROR";
      }
    }

    return "";
  };

  const isCityValid = (city) => {
    if (city.length === 0) {
      return "CLIENT_PLATFORM_MY_ACCOUNT_FORM_COMPANY_DETAILS_CITY_MISSING_ERROR";
    }
    // check for number.
    if (/\d/.test(city)) {
      return "CLIENT_PLATFORM_MY_ACCOUNT_FORM_COMPANY_DETAILS_CITY__FORMAT_ERROR";
    }
    return "";
  };

  return (
    <>
      <div className="registration-form">
        <h1 className="text-center title m-0">
          {t("CLIENT_PLATFORM_MY_ACCOUNT_FORM_TITLE")}
        </h1>
        {phase === "LOADING" && (
          <div className="loader-wrapper">
            <PulseLoader color="#004c7f" />
          </div>
        )}
        {phase === "SUCCESS" && (
          <form className="account-details-form">
            <div className="form-section pt-4">
              <h6>
                {t("CLIENT_PLATFORM_MY_ACCOUNT_FORM_CONTACT_INFORMATION_TITLE")}
              </h6>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6">
                  <TextInput
                    type="text"
                    placeholder={t(
                      "CLIENT_PLATFORM_MY_ACCOUNT_FORM_CONTACT_INFORMATION_FIRST_NAME_PLACEHOLDER"
                    )}
                    inputClass={
                      fields.first_name && fields.first_name.error !== ""
                        ? "form-input w-100 border border-danger"
                        : "form-input w-100"
                    }
                    divClass="form-field"
                    labelClass="form-label required"
                    labelName={t(
                      "CLIENT_PLATFORM_MY_ACCOUNT_FORM_CONTACT_INFORMATION_FIRST_NAME"
                    )}
                    required
                    id="first_name"
                    name="first_name"
                    error={fields.first_name && fields.first_name.error !== ""}
                    errorMsg={fields.first_name ? t(fields.first_name.error) : ""}
                    value={fields.first_name ? fields.first_name.value : ""}
                    onChange={(e) => onChange(e)}
                  />
                  <TextInput
                    type="email"
                    placeholder={t(
                      "CLIENT_PLATFORM_MY_ACCOUNT_FORM_CONTACT_INFORMATION_EMAIL_PLACEHOLDER"
                    )}
                    inputClass={
                      fields.email && fields.email.error !== ""
                        ? "form-input w-100 border border-danger"
                        : "form-input w-100"
                    }
                    divClass="form-field"
                    labelClass="form-label required"
                    labelName={t(
                      "CLIENT_PLATFORM_MY_ACCOUNT_FORM_CONTACT_INFORMATION_EMAIL"
                    )}
                    required
                    error={fields.email && fields.email.error !== ""}
                    errorMsg={fields.email ? t(fields.email.error) : ""}
                    id="email"
                    name="email"
                    value={fields.email ? fields.email.value : ""}
                    onChange={(e) => onChange(e)}
                  />
                  <TextInput
                    type="text"
                    placeholder={t(
                      "CLIENT_PLATFORM_MY_ACCOUNT_FORM_CONTACT_INFORMATION_POSITION_PLACEHOLDER"
                    )}
                    inputClass={
                      fields.job && fields.job.error !== ""
                        ? "form-input w-100 border border-danger"
                        : "form-input w-100"
                    }
                    divClass="form-field"
                    labelClass="form-label required"
                    labelName={t(
                      "CLIENT_PLATFORM_MY_ACCOUNT_FORM_CONTACT_INFORMATION_POSITION"
                    )}
                    required
                    id="job"
                    name="job"
                    error={fields.job && fields.job.error !== ""}
                    errorMsg={fields.job ? t(fields.job.error) : ""}
                    value={fields.job ? fields.job.value : ""}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6">
                  <TextInput
                    type="text"
                    placeholder={t(
                      "CLIENT_PLATFORM_MY_ACCOUNT_FORM_CONTACT_INFORMATION_LAST_NAME_PLACEHOLDER"
                    )}
                    inputClass={
                      fields.last_name && fields.last_name.error !== ""
                        ? "form-input w-100 border border-danger"
                        : "form-input w-100"
                    }
                    divClass="form-field"
                    labelClass="form-label required"
                    labelName={t(
                      "CLIENT_PLATFORM_MY_ACCOUNT_FORM_CONTACT_INFORMATION_LAST_NAME"
                    )}
                    required
                    id="last_name"
                    name="last_name"
                    error={fields.last_name && fields.last_name.error !== ""}
                    errorMsg={fields.last_name ? t(fields.last_name.error) : ""}
                    value={fields.last_name ? fields.last_name.value : ""}
                    onChange={(e) => onChange(e)}
                  />
                  <TextInput
                    type="text"
                    placeholder={t(
                      "CLIENT_PLATFORM_MY_ACCOUNT_FORM_CONTACT_INFORMATION_PHONE_PLACEHOLDER"
                    )}
                    inputClass={
                      fields.phone && fields.phone.error !== ""
                        ? "form-input w-100 border border-danger"
                        : "form-input w-100"
                    }
                    divClass="form-field"
                    labelClass="form-label required"
                    labelName={t(
                      "CLIENT_PLATFORM_MY_ACCOUNT_FORM_CONTACT_INFORMATION_PHONE"
                    )}
                    required
                    id="user_phone"
                    name="phone"
                    minLength="10"
                    maxlength="14"
                    value={fields.phone ? fields.phone.value : ""}
                    error={fields.phone && fields.phone.error !== ""}
                    errorMsg={fields.phone ? t(fields.phone.error) : ""}
                    onChange={(e) => onChange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="form-section pt-4">
              <h6>{t("CLIENT_PLATFORM_MY_ACCOUNT_FORM_COMPANY_DETAILS_TITLE")}</h6>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6">
                  <TextInput
                    type="text"
                    placeholder={t(
                      "CLIENT_PLATFORM_MY_ACCOUNT_FORM_COMPANY_DETAILS_NAME_PLACEHOLDER"
                    )}
                    inputClass={
                      fields.name && fields.name.error !== ""
                        ? "form-input w-100 border border-danger"
                        : "form-input w-100"
                    }
                    divClass={
                      fields.name && fields.name.canEdit
                        ? "form-field"
                        : "form-field disabled"
                    }
                    labelClass="form-label required"
                    labelName={t(
                      "CLIENT_PLATFORM_MY_ACCOUNT_FORM_COMPANY_DETAILS_NAME"
                    )}
                    error={fields.name && fields.name.error !== ""}
                    errorMsg={fields.name ? t(fields.name.error) : ""}
                    required
                    id="name"
                    name="name"
                    value={fields.name ? fields.name.value : ""}
                    onChange={(e) => onChange(e)}
                    disabled={!(fields.name && fields.name.canEdit)}
                  />
                  <TextInput
                    type="text"
                    placeholder={t(
                      "CLIENT_PLATFORM_MY_ACCOUNT_FORM_COMPANY_DETAILS_NUMBER_PLACEHOLDER"
                    )}
                    inputClass={
                      fields.identification_number &&
                      fields.identification_number.error !== ""
                        ? "form-input w-100 border border-danger"
                        : "form-input w-100"
                    }
                    divClass={
                      fields.identification_number &&
                      fields.identification_number.canEdit
                        ? "form-field"
                        : "form-field disabled"
                    }
                    labelClass="form-label required"
                    labelName={t(
                      "CLIENT_PLATFORM_MY_ACCOUNT_FORM_COMPANY_DETAILS_NUMBER"
                    )}
                    required
                    id="identification_number"
                    name="identification_number"
                    error={
                      fields.identification_number &&
                      fields.identification_number.error !== ""
                    }
                    errorMsg={
                      fields.identification_number
                        ? t(fields.identification_number.error)
                        : ""
                    }
                    value={
                      fields.identification_number
                        ? fields.identification_number.value
                        : ""
                    }
                    onChange={(e) => onChange(e)}
                    maxlength={
                      selectedCountryId !== 1
                        ? selectedCountryId === 2
                          ? 14
                          : selectedCountryId === 8
                          ? 15
                          : 20
                        : 10
                    }
                    disabled={
                      !(
                        fields.identification_number &&
                        fields.identification_number.canEdit
                      )
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6">
                  <TextInput
                    type="tel"
                    placeholder={t(
                      "CLIENT_PLATFORM_MY_ACCOUNT_FORM_COMPANY_DETAILS_PHONE_PLACEHOLDER"
                    )}
                    inputClass={
                      fields.company_phone && fields.company_phone.error !== ""
                        ? "form-input w-100 border border-danger"
                        : "form-input w-100"
                    }
                    divClass={
                      fields.company_phone && fields.company_phone.canEdit
                        ? "form-field"
                        : "form-field disabled"
                    }
                    labelClass="form-label required"
                    labelName={t(
                      "CLIENT_PLATFORM_MY_ACCOUNT_FORM_COMPANY_DETAILS_PHONE"
                    )}
                    required
                    id="company_phone"
                    name="company_phone"
                    minLength="10"
                    maxlength="14"
                    error={fields.company_phone && fields.company_phone.error !== ""}
                    errorMsg={
                      fields.company_phone ? t(fields.company_phone.error) : ""
                    }
                    value={fields.company_phone ? fields.company_phone.value : ""}
                    onChange={(e) => onChange(e)}
                    disabled={
                      !(fields.company_phone && fields.company_phone.canEdit)
                    }
                  />
                  <TextInput
                    type="text"
                    placeholder={t(
                      "CLIENT_PLATFORM_MY_ACCOUNT_FORM_COMPANY_DETAILS_CITY_PLACEHOLDER"
                    )}
                    inputClass={
                      fields.shipping_city && fields.shipping_city.error !== ""
                        ? "form-input w-100 border border-danger"
                        : "form-input w-100"
                    }
                    divClass={
                      fields.shipping_city && fields.shipping_city.canEdit
                        ? "form-field"
                        : "form-field disabled"
                    }
                    labelClass="form-label required"
                    labelName={t(
                      "CLIENT_PLATFORM_MY_ACCOUNT_FORM_COMPANY_DETAILS_CITY"
                    )}
                    required
                    id="shipping_city"
                    name="shipping_city"
                    error={fields.shipping_city && fields.shipping_city.error !== ""}
                    errorMsg={
                      fields.shipping_city ? t(fields.shipping_city.error) : ""
                    }
                    value={fields.shipping_city ? fields.shipping_city.value : ""}
                    onChange={(e) => onChange(e)}
                    disabled={
                      !(fields.shipping_city && fields.shipping_city.canEdit)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6">
                  <TextInput
                    type="text"
                    placeholder={t(
                      "CLIENT_PLATFORM_MY_ACCOUNT_FORM_COMPANY_DETAILS_ADDRESS_PLACEHOLDER"
                    )}
                    inputClass={
                      fields.shipping_address && fields.shipping_address.error !== ""
                        ? "form-input w-100 border border-danger"
                        : "form-input w-100"
                    }
                    divClass={
                      fields.shipping_address && fields.shipping_address.canEdit
                        ? "form-field"
                        : "form-field disabled"
                    }
                    labelClass="form-label required"
                    labelName={t(
                      "CLIENT_PLATFORM_MY_ACCOUNT_FORM_COMPANY_DETAILS_ADDRESS"
                    )}
                    required
                    id="shipping_address"
                    name="shipping_address"
                    value={
                      fields.shipping_address ? fields.shipping_address.value : ""
                    }
                    error={
                      fields.shipping_address && fields.shipping_address.error !== ""
                    }
                    errorMsg={
                      fields.shipping_address ? t(fields.shipping_address.error) : ""
                    }
                    onChange={(e) => onChange(e)}
                    disabled={
                      !(fields.shipping_address && fields.shipping_address.canEdit)
                    }
                  />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6">
                  <TextInput
                    type="text"
                    placeholder={t(
                      "CLIENT_PLATFORM_MY_ACCOUNT_FORM_COMPANY_DETAILS_POSTCODE_PLACEHOLDER"
                    )}
                    inputClass={
                      fields.shipping_postal && fields.shipping_postal.error !== ""
                        ? "form-input w-100 border border-danger"
                        : "form-input w-100"
                    }
                    divClass={
                      fields.shipping_postal && fields.shipping_postal.canEdit
                        ? "form-field"
                        : "form-field disabled"
                    }
                    labelClass="form-label required"
                    labelName={t(
                      "CLIENT_PLATFORM_MY_ACCOUNT_FORM_COMPANY_DETAILS_POSTCODE"
                    )}
                    required
                    id="shipping_postal"
                    name="shipping_postal"
                    error={
                      fields.shipping_postal && fields.shipping_postal.error !== ""
                    }
                    errorMsg={
                      fields.shipping_postal ? t(fields.shipping_postal.error) : ""
                    }
                    value={
                      fields.shipping_postal ? fields.shipping_postal.value : ""
                    }
                    onChange={(e) => onChange(e)}
                    pattern={
                      selectedCountryId === 3
                        ? "^[0-9-]*$"
                        : selectedCountryId === 1
                        ? "^(!.,-*$)[a-zA-Z0-9s_ ]+$"
                        : "[0-9]*"
                    }
                    maxlength={
                      selectedCountryId !== 1
                        ? selectedCountryId === 2
                          ? 5
                          : selectedCountryId === 8
                          ? 4
                          : 6
                        : 8
                    }
                    disabled={
                      !(fields.shipping_postal && fields.shipping_postal.canEdit)
                    }
                  />
                </div>
              </div>
            </div>
          </form>
        )}
        {phase === "ERROR" && !userAccountData && (
          <div>
            There is an error while fetching account details. Please try again later.
          </div>
        )}
      </div>
      <div className="registration-footer">
        <NavLink to="/dashboard">
          <Button
            className="link text-decoration-none border-0 float-left d-inline-block mt-2"
            name={t("CLIENT_PLATFORM_MY_ACCOUNT_FORM_BUTTON_CANCEL")}
          />
        </NavLink>
        {isAccountUpdate && (
          <div className="btn btn-primary btn-blue float-right">
            <ClipLoader size={20} color="#fff" />
          </div>
        )}
        {!isAccountUpdate && (
          <Button
            className="btn btn-primary btn-blue float-right"
            id="submit-onboard"
            name={t("CLIENT_PLATFORM_MY_ACCOUNT_FORM_BUTTON_SAVE")}
            disabled={getDisableStatus(userAccountData) || !isFormValid}
            onClick={(e) => submitUserAccountData(userAccountData)}
          />
        )}
      </div>
    </>
  );
};

AccountDetails.propTypes = {
  isAccountUpdate: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(AccountDetails);
