import React from "react";
import TabItem from "../TabItem";

const TabList = ({ className, id, data }) => {
  return (
    <ul className={`nav nav-pills ${className}`} id={id} role="tablist">
      {data.map((tab, index) => (
        <TabItem
          key={tab.id}
          index={index}
          title={tab.title}
          id={tab.id}
          role={tab.role}
          link={tab.link}
          controls={tab.controls}
        />
      ))}
    </ul>
  );
};

export default TabList;
