import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as moment from "moment";
import "moment/locale/fr";
import "moment/locale/pl";
import "moment/locale/fr-ch";
import "moment/locale/en-gb";
import "moment/locale/de";

import en from "../../../assets/shared/ic-gb-rounded.svg";
import fr from "../../../assets/shared/ic-fr-rounded.png";
import pl from "../../../assets/shared/ic-pl-rounded.png";
import ch from "../../../assets/shared/ic-ch-rounded.png";
import de from "../../../assets/shared/ic-de-rounded.png";

import { handleLang, changeLang, changeCountry } from "../../../store/loginData";

import "./LanguageSelection.scss";

const LanguageSelection = (props) => {
  const { divClassName, userCountry } = props;
  const [_countryId, setCountryId] = useState("2");
  const [isLangChanged, setLanguageChange] = useState({
    id: "",
    updated: false,
  });

  const getLangData = [
    {
      id: "1",
      lang: "en",
      img: { en },
      title: "UK",
    },
    {
      id: "2",
      lang: "fr",
      img: { fr },
      title: "France",
    },
    {
      id: "3",
      lang: "pl",
      img: { pl },
      title: "Poland",
    },
    {
      id: "6",
      lang: "de",
      img: { de },
      title: "Germany",
    },
    {
      id: "8",
      lang: "ch",
      img: { ch },
      title: "Switzerland",
    },
  ];

  useEffect(() => {
    const { userDetails } = props;
    const isLangUpdated = localStorage.getItem("isLangUpdated");

    let lng;
    if (!props.isLangChanged) {
      setLanguageChange({ id: "", updated: false });
    }
    if (isLangUpdated === null) {
      if (userDetails && userDetails.country) {
        const languageSelected = getLanguageFlag(userDetails.country.id.toString());
        lng = languageSelected.lng;
        setLanguageChange({ id: languageSelected.countryId, updated: true });
        setCountryId(userDetails.country.id.toString());
      } else {
        let countryId;
        if (props.userCountry === "en") {
          countryId = "1";
          lng = "en";
          setLanguageChange({ id: "1", updated: true });
        } else if (props.userCountry === "fr") {
          countryId = "2";
          lng = "fr";
          setLanguageChange({ id: "2", updated: true });
        } else if (props.userCountry === "pl") {
          countryId = "3";
          lng = "pl";
          setLanguageChange({ id: "3", updated: true });
        } else if (props.userCountry === "de") {
          countryId = "6";
          lng = "de";
          setLanguageChange({ id: "6", updated: true });
        } else if (props.userCountry === "ch") {
          countryId = "8";
          lng = "ch";
          setLanguageChange({ id: "8", updated: true });
        }
        setCountryId(countryId);
      }
    } else {
      const countryId = localStorage.getItem("userCountryId").toString();
      const languageSelected = getLanguageFlag(countryId);
      lng = languageSelected.lng;
      setLanguageChange({ id: languageSelected.countryId, updated: true });
      setCountryId(countryId);
    }
    if (lng === "ch") {
      moment.locale("fr-ch");
    } else {
      moment.locale(lng);
    }
    props.i18n.changeLanguage(lng);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let countryId;
    if (props.userCountry) {
      if (props.userCountry === "en") {
        countryId = "1";
        setLanguageChange({ id: "1", updated: true });
      } else if (props.userCountry === "fr") {
        countryId = "2";
        setLanguageChange({ id: "2", updated: true });
      } else if (props.userCountry === "pl") {
        countryId = "3";
        setLanguageChange({ id: "3", updated: true });
      } else if (props.userCountry === "de") {
        countryId = "6";
        setLanguageChange({ id: "6", updated: true });
      } else if (props.userCountry === "ch") {
        countryId = "8";
        setLanguageChange({ id: "8", updated: true });
      }
      props.changeLang(true);
      setCountryId(countryId);
      props.changeCountry(countryId);
      props.i18n.changeLanguage(props.userCountry);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userCountry]);

  const getLanguageFlag = (countryId) => {
    const flag = {
      lng: "fr",
      countryId: "2",
    };
    if (countryId === "1") {
      flag.lng = "en";
      flag.countryId = "1";
    } else if (countryId === "2") {
      flag.lng = "fr";
      flag.countryId = "2";
    } else if (countryId === "3") {
      flag.lng = "pl";
      flag.countryId = "3";
    } else if (countryId === "6") {
      flag.lng = "de";
      flag.countryId = "6";
    } else if (countryId === "8") {
      flag.lng = "ch";
      flag.countryId = "8";
    }

    return flag;
  };

  const changeLanguage = (id, lng) => {
    props.changeLang(true);
    setCountryId(id);
    props.changeCountry(id);
    const languageSelected = getLanguageFlag(id);
    setLanguageChange({ id: languageSelected.countryId, updated: true });
    if (lng === "ch") {
      moment.locale("fr-ch");
    } else {
      moment.locale(lng);
    }
    props.i18n.changeLanguage(lng);
  };

  return (
    <div className={divClassName}>
      <div>
        <button
          className="dropdown-toggle border-0"
          id="translateLang"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          type="button"
        >
          <div className="rounded-circle d-inline-block flag-div bottom-lang">
            {getLangData &&
              !isLangChanged.updated &&
              getLangData
                .filter((x) => parseInt(x.id, 10) === parseInt(_countryId, 10))
                .map((item) => (
                  <img
                    key={item.id}
                    src={item.img[item.lang]}
                    title={item.title}
                    className="flag crsr-ptr"
                    width="16"
                    height="16"
                    alt="flag"
                  />
                ))}
            {isLangChanged.updated &&
              getLangData
                .filter((x) => parseInt(x.id, 10) === parseInt(isLangChanged.id, 10))
                .map((item) => (
                  <img
                    key={item.id}
                    src={item.img[item.lang]}
                    title={item.title}
                    className="flag crsr-ptr"
                    alt="flag"
                  />
                ))}
          </div>
        </button>
        <div className="dropdown-menu" aria-labelledby="translateLang">
          <ul className="m-0 p-0">
            {getLangData &&
              getLangData.map((item) => (
                <li
                  key={item.id + item.lang}
                  className="dropdown-item"
                  onClick={() => {
                    props.handleLang(item.id);
                    changeLanguage(item.id, item.lang);
                  }}
                >
                  <img
                    key={item.id}
                    src={item.img[item.lang]}
                    title={item.title}
                    className="flag crsr-ptr"
                    alt="flag"
                  />
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLangChanged: state.loginStore.isLangChanged,
    isLangUpdated: state.loginStore.isLangUpdated,
    userDetails:
      JSON.parse(localStorage.getItem("userInfo")) !== {}
        ? JSON.parse(localStorage.getItem("userInfo"))
        : "",
  };
};

const mapDispatchToProps = {
  handleLang: (payload) => handleLang(payload),
  changeLang: (payload) => changeLang(payload),
  changeCountry: (payload) => changeCountry(payload),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(LanguageSelection));
