import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import hesusLogo from "../../assets/shared/logo.png";

import "./PageNotFound.screen.scss";

const PageNotFound = (props) => (
  <section className="page-not-found__container">
    <div className="page-not-found__info">
      <img src={hesusLogo} alt="Hesus logo" className="page-not-found__logo" />
      <p className="page-not-found__error-code">404</p>
      <p className="page-not-found__error-message">
        {props.t("CLIENT_PLATFORM_PAGE_NOT_FOUND")}
      </p>
      <Link to="/" className="page-not-found__home-link">
        {props.t("CLIENT_PLATFORM_PAGE_NOT_FOUND_BACK")}
      </Link>
    </div>
    <div className="page-not-found__bg-img-container" />
  </section>
);

export default withTranslation()(PageNotFound);
