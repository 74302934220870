import React from "react";
import PropTypes from "prop-types";

const TextInput = (props) => {
  const {
    divClass,
    labelClass,
    labelName,
    name,
    value,
    id,
    required,
    placeholder,
    type,
    inputClass,
    checked,
    onChange,
    onBlur,
    onKeyPress,
    error,
    errorMsg,
    minlength,
    maxlength,
    min,
    max,
    disabled,
    pattern,
    autoComplete,
  } = props;

  return (
    <div className={divClass}>
      <label className={labelClass} htmlFor={name}>
        {labelName}
      </label>
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        minLength={minlength}
        maxLength={maxlength}
        min={min}
        max={max}
        className={inputClass}
        id={id}
        required={required}
        checked={checked}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        disabled={disabled}
        pattern={pattern}
        autoComplete={autoComplete || ""}
      />
      {error && (
        <div className="error-text text-danger position-absolute w-100 text-nowrap">
          {errorMsg}
        </div>
      )}
    </div>
  );
};

TextInput.propTypes = {
  divClass: PropTypes.string.isRequired,
  labelClass: PropTypes.string,
  labelName: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  id: PropTypes.any,
  required: PropTypes.bool,
  type: PropTypes.any.isRequired,
  placeholder: PropTypes.string.isRequired,
  inputClass: PropTypes.string,
  checked: PropTypes.bool,
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
  minlength: PropTypes.number,
  maxlength: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool,
  pattern: PropTypes.string,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
};

export default TextInput;
