import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  addRowItem,
  updateRowItem,
  delRowItem,
} from "../../../../InquiryForm.actionCreators.js";

import SelectInput from "../../../../../../shared/baseUI/SelectInput";

const AddRow = (props) => {
  const { rowItem, divClassName, data, onChange, options } = props;

  const [product, setProductsSelected] = useState([]);

  useEffect(() => {
    const { initialRow, updateRowItem, requestData } = props;
    if (!requestData.hasOwnProperty("editData")) {
      // new enquiry should have only one item.
      const rows = { ...initialRow };
      const id = uuidv4();
      const item = {
        ...rows,
        id,
      };
      updateRowItem([item]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (rowItem.length > 0) {
      const products = rowItem.map((prod) => {
        const updatedProd = {};
        updatedProd.id = prod.id;
        updatedProd.val = prod.rowId;
        return updatedProd;
      });
      setProductsSelected(products);
    } else {
      setProductsSelected([]);
    }
  }, [rowItem]);

  const addProductRow = () => {
    const { addRowItem, rowItem, initialRow } = props;
    const row = { ...initialRow };
    const id = uuidv4();
    const item = {
      ...row,
      id,
    };
    addRowItem([...rowItem, item]);
  };

  const deleteProductRow = (id) => {
    const { delRowItem, rowItem } = props;
    let rows = Object.assign([], rowItem);
    const updatedProducts = product.filter((prod) => prod.id !== id);
    rows = rows.filter((row) => row.id !== id);
    setProductsSelected(updatedProducts);
    delRowItem(rows);
  };

  const onProductChange = (e, id) => {
    const prodVal = {
      id,
      val: e.target.value,
    };
    let found = false;
    const updatedProducts = product.map((prod) => {
      if (prod.id === id) {
        found = true;
        prod.val = e.target.value;
      }
      return prod;
    });
    const productLocal = found ? updatedProducts : [...product, prodVal];
    const productSelected = productLocal.map((prod) => prod.val);
    setProductsSelected(productLocal);
    onChange(e, id, productSelected);
  };

  return (
    <>
      {rowItem.length > 0 &&
        rowItem.map((row, i) => {
          return (
            <div className={divClassName} key={row.id}>
              {i !== 0 && (
                <i
                  className="fa fa-trash-o delete-icon"
                  aria-hidden="true"
                  onClick={() => deleteProductRow(row.id)}
                />
              )}
              <i
                className="fa fa-plus-circle add-icon"
                aria-hidden="true"
                onClick={() => addProductRow()}
              />
              <SelectInput
                value={!data.hasOwnProperty("editData") ? rowItem.rowId : row.rowId}
                inputClass="request-details__section-input"
                divClass="request-details__input-wrapper"
                labelClass="request-details__section-input-label required"
                required
                labelName={props.t("CLIENT_PLATFORM_REQUEST_FORM_PRODUCT_SOIL")}
                name="product"
                defaultOption={props.t(
                  "CLIENT_PLATFORM_REQUEST_FORM_PRODUCT_PLACEHOLDER"
                )}
                onChange={(e) => onProductChange(e, row.id)}
                options={options}
              />
            </div>
          );
        })}
    </>
  );
};

AddRow.propTypes = {
  initialRow: PropTypes.object,
  rowItem: PropTypes.array,
  requestData: PropTypes.object,
  addRowItem: PropTypes.func.isRequired,
  updateRowItem: PropTypes.func.isRequired,
  delRowItem: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    initialRow: state.inquiryForm.initialRow,
    rowItem: state.inquiryForm.rowItem,
    requestData: state.inquiryForm.requestCreationData,
  };
};

const mapDispatchToProps = {
  addRowItem: (payload) => addRowItem(payload),
  updateRowItem: (payload) => updateRowItem(payload),
  delRowItem: (payload) => delRowItem(payload),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AddRow));
