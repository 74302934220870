import React from "react";
import PropTypes from "prop-types";
import PulseLoader from "react-spinners/PulseLoader";
import { withTranslation } from "react-i18next";

import RequestCategorySelection from "../../../shared/baseUI/RequestCategorySelection";
import SelectInput from "../../../shared/baseUI/SelectInput";

import { prepareCategories } from "../../../shared/utils/methods";

const EnquiryService = (props) => {
  const {
    productTypeRef,
    productRef,
    serviceRef,
    usageRef,
    onMount,
    category,
    subCategory,
    onChange,
    usage,
    isUsageLoading,
    selectedService,
    selectedItems,
    requestData,
    requestCategories,
    onCategorySelect,
    onSubCategorySelect,
    onCategoryFormSelect,
    t,
  } = props;

  const handleCategory = (id) => {
    onCategorySelect(id);
  };

  const handleSubCategory = (id) => {
    onSubCategorySelect(id);
  };

  const handleCategoryForm = (id) => {
    onCategoryFormSelect(id);
  };

  return (
    <div>
      {requestCategories.category && (
        <>
          {requestCategories.category.length > 0 && (
            <RequestCategorySelection
              onMount={onMount}
              selectionRef={serviceRef}
              data={prepareCategories(requestCategories.category)}
              labelName={t("CLIENT_PLATFORM_REQUEST_FORM_SERVICE")}
              selectCategory={(id) => handleCategory(id)}
              name="service"
              onChange={(e, name, id) => onChange(e, name, id)}
              selectedId={selectedItems}
              disabled={
                !!(
                  requestData &&
                  Object.prototype.hasOwnProperty.call(requestData, "editData")
                )
              }
            />
          )}
          {category && category.length > 0 && (
            <RequestCategorySelection
              selectionRef={productRef}
              onMount={onMount}
              data={prepareCategories(category)}
              labelName={t("CLIENT_PLATFORM_REQUEST_FORM_CATEGORY")}
              selectCategory={(id) => handleSubCategory(id)}
              onChange={(e, name, id) => {
                onChange(e, name, id);
              }}
              disabled={
                !!(
                  requestData &&
                  Object.prototype.hasOwnProperty.call(requestData, "editData")
                )
              }
              name="category"
              selectedId={selectedItems}
            />
          )}
          {selectedService && selectedService === 2 && usage.length > 0 && (
            <SelectInput
              inputRef={usageRef}
              onMount={onMount}
              value={
                requestData && requestData.usage_id ? requestData.usage_id.value : ""
              }
              inputClass={
                requestData?.usage_id?.value
                  ? "v3-inquiry-form__usage-input form-input w-100 v3-inquiry-form__usage-input--filled"
                  : "v3-inquiry-form__usage-input form-input w-100"
              }
              divClass="form-field pb-0 mb-3 close-time-list"
              labelClass="form-label required"
              required
              labelName={t("CLIENT_PLATFORM_REQUEST_FORM_USAGE")}
              name="usage_id"
              defaultOption={t("SELECT_MATERIAL_USAGE")}
              disabled={
                !!(
                  requestData &&
                  Object.prototype.hasOwnProperty.call(requestData, "editData")
                )
              }
              onChange={(e) => onChange(e)}
              options={usage}
            />
          )}
          {isUsageLoading && (
            <div className="loader-wrapper">
              <PulseLoader color="#004c7f" />
            </div>
          )}
          {!isUsageLoading && subCategory && subCategory.length > 0 && (
            <RequestCategorySelection
              selectionRef={productTypeRef}
              onMount={onMount}
              data={prepareCategories(subCategory)}
              labelName={t("CLIENT_PLATFORM_REQUEST_FORM_SUB_CATEGORY")}
              selectCategory={(id) => handleCategoryForm(id)}
              onChange={(e, name, id) => {
                onChange(e, name, id);
              }}
              name="subcategory"
              disabled={
                !!(
                  requestData &&
                  Object.prototype.hasOwnProperty.call(requestData, "editData")
                )
              }
              selectedId={selectedItems}
            />
          )}
        </>
      )}
    </div>
  );
};

EnquiryService.propTypes = {
  productTypeRef: PropTypes.shape({}),
  productRef: PropTypes.shape({}),
  serviceRef: PropTypes.shape({}),
  usageRef: PropTypes.shape({}),
  selectedService: PropTypes.number,
  selectedItems: PropTypes.shape({}),
  requestCategories: PropTypes.shape({
    category: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  category: PropTypes.arrayOf(PropTypes.object),
  subCategory: PropTypes.arrayOf(PropTypes.object),
  requestData: PropTypes.shape({
    usage_id: PropTypes.shape({
      value: PropTypes.string,
    }),
  }).isRequired,
  isUsageLoading: PropTypes.bool.isRequired,
  usage: PropTypes.arrayOf(PropTypes.object),
  onMount: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onCategorySelect: PropTypes.func.isRequired,
  onSubCategorySelect: PropTypes.func.isRequired,
  onCategoryFormSelect: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

EnquiryService.defaultProps = {
  serviceRef: null,
  productTypeRef: null,
  productRef: null,
  usageRef: null,
  selectedService: null,
  selectedItems: {},
  category: [],
  subCategory: [],
  usage: [],
  onMount: null,
};

export default withTranslation()(EnquiryService);
