import React, { Component } from "react";
import { connect } from "react-redux";
import ClockLoader from "react-spinners/ClockLoader";

import { LOADING } from "../../utils/constant";

class Loader extends Component {
  render() {
    if (this.props.phase === LOADING) {
      return (
        <div className="loader">
          <ClockLoader size={50} color="#1bace4" />
        </div>
      );
    }
    return null;
  }
}

// eslint-disable-next-line no-sequences
const mapStateToProps = ({ loginStore, dataStore }) => (loginStore, dataStore);

export default connect(mapStateToProps)(Loader);
