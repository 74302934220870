import {
  GET_VIEW_DATA,
  GET_REQUEST_LISTING_DATA,
  GET_QUOTES,
} from "./SiteOverview.constants";

export const getViewData = (payload) => ({
  type: GET_VIEW_DATA.LOADING,
  payload,
});

export const getRequestListingData = (payload) => ({
  type: GET_REQUEST_LISTING_DATA.LOADING,
  payload,
});

export const getQuotes = (payload) => ({
  type: GET_QUOTES.LOADING,
  payload,
});
