import { combineReducers } from "redux";

import { loginStore } from "./loginData";
import { dataStore } from "./dataStore";
import myAccountReducer from "../modules/MyAccount/MyAccount.reducer";
import jobsiteFormReducer from "../modules/JobsiteForm/JobsiteForm.reducer";
import {
  dashboardReducer,
  dashboardLeadsReducer,
} from "../modules/Dashboard/Dashboard.reducer";
import jobsiteOverviewReducer from "../modules/SiteOverview/SiteOverview.reducer";
import sitesListReducer from "../modules/SitesList/SitesList.reducer";
import transportOnlyFormReducer from "../modules/TransportOnlyForm/TransportOnlyForm.reducer";
import callOffOrderFormReducer from "../modules/CallOffOrderForm/CallOffOrderForm.reducer";
import inquiryFormReducer from "../modules/InquiryForm/InquiryForm.reducer";

const rootReducer = combineReducers({
  loginStore,
  dataStore,
  account: myAccountReducer,
  jobsiteForm: jobsiteFormReducer,
  dashboard: dashboardReducer,
  dashboardLeads: dashboardLeadsReducer,
  jobsiteOverview: jobsiteOverviewReducer,
  sitesList: sitesListReducer,
  transportOnlyForm: transportOnlyFormReducer,
  callOffOrderForm: callOffOrderFormReducer,
  inquiryForm: inquiryFormReducer,
});

export default rootReducer;
