import React, { useEffect, useState, useCallback } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import detectBrowserLanguage from "detect-browser-language";
import PropTypes from "prop-types";

import {
  handleLang,
  changeLang,
  changeCountry,
  loginData,
  handleLoginDetails,
  handleForgotPasswordData,
  forgotPasswordData,
  checkEmailValidation,
} from "../../store/loginData";
import { hidePopUp } from "../../store/dataStore";

import LoginDetails from "./components/LoginDetails";
import ForgotPassword from "./components/ForgotPassword";
import LanguageSelection from "../../shared/components/LanguageSelection";
import Popup from "../../shared/baseUI/Popup";

import logo from "../../assets/shared/logo.png";

import "./Login.screen.scss";

const LoginHome = (props) => {
  const {
    t,
    loginUserId,
    loginStatus,
    phase,
    showPopup,
    loginDetails,
    disabledBtn,
    errorObj,
    forgotPasswordDetails,
    emailError,
    forgotPasswordStatus,
    countryId,
  } = props;

  const [userCountry, setUserCountry] = useState("en");
  const [isLangChanged, setLanguageChange] = useState({
    id: "",
    updated: false,
  });
  const { pathname } = window.location;
  const history = useHistory();

  const setLanguage = useCallback(() => {
    try {
      let browserLng = detectBrowserLanguage();
      browserLng = browserLng.toLowerCase();
      const multiSwissLng = ["it-ch", "de-ch"];
      if (browserLng === "fr-ch") {
        browserLng = "ch";
      }
      if (multiSwissLng.includes(browserLng)) {
        browserLng = "en";
      }
      browserLng = browserLng.split("-")[0];
      let lngFlag;
      let countryId;
      localStorage.setItem("userCountryId", 2);
      if (browserLng === "fr") {
        lngFlag = "fr";
        countryId = "2";
        localStorage.setItem("userCountryId", 2);
        setLanguageChange({ id: "2", updated: true });
        props.handleLang("2");
      } else if (browserLng === "pl") {
        lngFlag = "pl";
        countryId = "3";
        localStorage.setItem("userCountryId", 3);
        setLanguageChange({ id: "3", updated: true });
        props.handleLang("3");
      } else if (browserLng === "ch") {
        lngFlag = "ch";
        countryId = "8";
        localStorage.setItem("userCountryId", 8);
        setLanguageChange({ id: "8", updated: true });
        props.handleLang("8");
      } else if (browserLng === "de") {
        lngFlag = "de";
        countryId = "6";
        localStorage.setItem("userCountryId", 6);
        setLanguageChange({ id: "6", updated: true });
        props.handleLang("6");
      } else {
        lngFlag = "en";
        countryId = "1";
        localStorage.setItem("userCountryId", 1);
        setLanguageChange({ id: "1", updated: true });
        props.handleLang("1");
      }
      localStorage.setItem("langFlag", lngFlag);
      props.changeLang(true);
      setUserCountry(lngFlag);
      props.changeCountry(countryId);
    } catch (error) {
      localStorage.setItem("langFlag", "fr");
      localStorage.setItem("userCountryId", 2);
      setUserCountry("fr");
      setLanguageChange({ id: "2", updated: true });
      props.handleLang("2");
      props.changeLang(true);
      props.changeCountry("2");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLanguage();
  }, [setLanguage]);

  useEffect(() => {
    if (loginStatus && loginUserId !== "") {
      const userInfo = localStorage.getItem("userInfo");
      if (userInfo && phase !== "LOADING") {
        history.replace("/dashboard");
      }
    }
  }, [loginStatus, loginUserId, phase, history]);

  const closePopUp = () => {
    props.hidePopUp();
  };

  const renderUserInActivePopupContent = () => {
    return (
      <div className="popup-user-inactive">
        {props.t("CLIENT_PLATFORM_LOGIN_INACTIVE_ERROR_POP_UP_CONTENT")}
      </div>
    );
  };

  const renderUserInActivePopupTitle = () => {
    return (
      <span>{props.t("CLIENT_PLATFORM_LOGIN_INACTIVE_ERROR_POP_UP_TITLE")}</span>
    );
  };

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="d-xs-block d-md-none mobile-img w-100">
          <div className="login-img" />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-5 login-container">
          <div className="login-content">
            <div className="login-header d-flex justify-content-between">
              <NavLink to="/login">
                <img
                  src={logo}
                  className="logo crsr-ptr "
                  width="64"
                  height="64"
                  alt="logo"
                />
              </NavLink>
              <LanguageSelection
                userCountry={userCountry}
                isLangChanged={isLangChanged}
                divClassName="login translate dropdown"
              />
            </div>
            <div className="login-form">
              {(pathname === "/" || pathname === "/login") && (
                <>
                  <h1> {t("CLIENT_PLATFORM_LOGIN_TITLE")} </h1>
                  <p>{t("CLIENT_PLATFORM_LOGIN_TEXT")}</p>
                  <LoginDetails
                    loginDetails={loginDetails}
                    disabledBtn={disabledBtn}
                    errorObj={errorObj}
                    loginData={props.loginData}
                    handleLoginDetails={props.handleLoginDetails}
                    countryId={countryId}
                  />
                  {showPopup && (
                    <Popup
                      modalParentClass="modal-parent"
                      modalClass="modal-box"
                      title={renderUserInActivePopupTitle()}
                      name="UserInactive"
                      content={renderUserInActivePopupContent()}
                      id="userInactivePopup"
                      onClose={closePopUp}
                    />
                  )}
                </>
              )}
              {pathname === "/forgotpassword" && (
                <>
                  <h1>{t("CLIENT_PLATFORM_FORGOTPWD_TITLE")}</h1>
                  <p>{t("CLIENT_PLATFORM_FORGOTPWD_TEXT")}</p>
                  <ForgotPassword
                    forgotPasswordDetails={forgotPasswordDetails}
                    emailError={emailError}
                    showPopup={showPopup}
                    forgotPasswordStatus={forgotPasswordStatus}
                    handleForgotPasswordData={props.handleForgotPasswordData}
                    forgotPasswordData={props.forgotPasswordData}
                    checkEmailValidation={props.checkEmailValidation}
                    handlePopUp={props.hidePopUp}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-7 d-none d-md-block vh-100">
          <div className="row pl-3 login-img" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    countryId: state.loginStore.countryId,
    isLangChanged: state.loginStore.isLangChanged,
    loginStatus: state.loginStore.loginStatus,
    loginUserId: state.loginStore.loginUserId,
    showPopup: state.loginStore.showPopup,
    loginDetails: state.loginStore.loginData,
    disabledBtn: state.loginStore.loginDisabledBtn,
    errorObj: state.loginStore.errorObj,
    forgotPasswordDetails: state.loginStore.forgotPasswordData,
    emailError: state.loginStore.emailError,
    forgotPasswordStatus: state.loginStore.forgotPasswordStatus,
    phase: state.loginStore.phase,
  };
};

const mapDispatchToProps = {
  handleLang: (payload) => handleLang(payload),
  handleLoginDetails: (payload) => handleLoginDetails(payload),
  loginData: (payload) => loginData(payload),
  hidePopUp: () => hidePopUp(),
  handleForgotPasswordData: (payload) => handleForgotPasswordData(payload),
  forgotPasswordData: (payload) => forgotPasswordData(payload),
  checkEmailValidation: (payload) => checkEmailValidation(payload),
  changeLang: (payload) => changeLang(payload),
  changeCountry: (payload) => changeCountry(payload),
};

LoginHome.propTypes = {
  countryId: PropTypes.string,
  disabledBtn: PropTypes.bool,
  loginDetails: PropTypes.shape({}),
  forgotPasswordDetails: PropTypes.shape({}),
  forgotPasswordStatus: PropTypes.shape({}),
  showPopup: PropTypes.bool,
  emailError: PropTypes.string,
  errorObj: PropTypes.shape({}).isRequired,
  loginStatus: PropTypes.bool,
  phase: PropTypes.string,
  loginUserId: PropTypes.string,
  hidePopUp: PropTypes.func.isRequired,
  changeLang: PropTypes.func.isRequired,
  changeCountry: PropTypes.func.isRequired,
  handleLang: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(LoginHome));
