import React from "react";
import PropTypes from "prop-types";

import "./CustomCheckBox.scss";

const CustomCheckBox = (props) => {
  const {
    id,
    onChange,
    checked,
    title,
    dataId,
    dataParentId,
    dataTestId,
    disabled,
    name,
    label,
  } = props;

  return (
    <>
      <input
        type="checkbox"
        className="custom-checkbox"
        id={id}
        name={name}
        aria-label={name}
        checked={checked}
        onChange={onChange}
        data-id={dataId}
        data-parentid={dataParentId}
        title={title}
        data-testid={dataTestId}
        disabled={disabled}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor={id}>{label}</label>
    </>
  );
};

CustomCheckBox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired])
    .isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  dataTestId: PropTypes.string,
  dataId: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]),
  dataParentId: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]),
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
};

CustomCheckBox.defaultProps = {
  title: null,
  label: "",
  name: "",
  dataId: null,
  dataTestId: null,
  dataParentId: "",
  disabled: false,
};

export default CustomCheckBox;
