import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import OnboardingV3 from "./OnboardingV3.screen";

const OnboardingSimplifiedWithCaptcha = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}
      language="['fr','ch','pl','en-GB','de']"
    >
      <OnboardingV3 />
    </GoogleReCaptchaProvider>
  );
};

export default OnboardingSimplifiedWithCaptcha;
