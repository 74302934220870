import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";
import PropTypes from "prop-types";

import TextInput from "../../../../shared/baseUI/TextInput";
import Button from "../../../../shared/baseUI/Button";

import "./LoginDetails.scss";

const LoginDetails = (props) => {
  const { loginDetails, disabledBtn, errorObj, countryId, t } = props;
  const [showPassword, setPasswordVisibility] = useState(false);

  useEffect(() => {
    ReactGA.pageview("/login");
  }, []);

  const onChange = (e) => {
    const { handleLoginDetails } = props;
    const logindata = { ...props.loginDetails };
    const { name } = e.target;
    const { value } = e.target;
    logindata[name] = value;

    handleLoginDetails({ logindata });
  };

  const submitData = (e) => {
    e.preventDefault();
    props.loginData({ loginDetails: props.loginDetails });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisibility(!showPassword);
  };

  return (
    <form>
      {errorObj &&
        errorObj.message !== "" &&
        errorObj.message !== "USER_NOT_ACTIVE" && (
          <span className="text-danger">
            {t("CLIENT_PLATFORM_LOGIN_TEXT_ERROR_MAIL_PWD")}
          </span>
        )}
      <TextInput
        type="email"
        placeholder={t("CLIENT_PLATFORM_REGISTRATION_FIELD_EMAIL_PLACEHOLDER")}
        inputClass="form-input w-100 email-input"
        divClass="form-field mt-3"
        labelClass="form-label required login-input-labels"
        labelName={t("CLIENT_PLATFORM_LOGIN_FIELD_EMAIL")}
        required
        id="email"
        name="email"
        value={loginDetails.email}
        onChange={(e) => onChange(e)}
      />
      <div className="position-relative">
        <TextInput
          type={showPassword ? "text" : "password"}
          placeholder={t("CLIENT_PLATFORM_REGISTRATION_FIELD_PASSWORD_PLACEHOLDER")}
          inputClass="form-input w-100"
          divClass="form-field"
          labelClass="form-label required"
          labelName={t("CLIENT_PLATFORM_LOGIN_FIELD_PWD")}
          required
          id="password"
          name="password"
          value={loginDetails.password}
          onChange={(e) => onChange(e)}
        />
        <i
          className={
            showPassword
              ? "fa fa-eye-slash position-absolute eye-icon"
              : "fa fa-eye position-absolute eye-icon"
          }
          aria-hidden="true"
          onClick={togglePasswordVisibility}
        />
      </div>
      <div className="login-footer pt-2">
        <div className="password-wrapper justify-content-between d-flex">
          <NavLink
            to="/forgotpassword"
            className="reset-password text-decoration-none"
          >
            {t("CLIENT_PLATFORM_LOGIN_LINK_FORGOT_PWD")}
          </NavLink>
        </div>
        <div className="login-btn-wrapper justify-content-center d-flex">
          <Button
            className="btn btn-primary btn-blue btn-login"
            type="submit"
            id="submit-btn"
            onClick={(e) => submitData(e)}
            name={t("CLIENT_PLATFORM_LOGIN_BUTTON_LOGIN")}
            disabled={disabledBtn}
          />
        </div>
        <NavLink to="/onboarding" className="onboarding-link">
          {t("CLIENT_PLATFORM_LOGIN_LINK_TO_REGISTRATION")}
        </NavLink>
      </div>
    </form>
  );
};

LoginDetails.propTypes = {
  countryId: PropTypes.string.isRequired,
  loginDetails: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
  disabledBtn: PropTypes.bool.isRequired,
  errorObj: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }).isRequired,
  handleLoginDetails: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  loginData: PropTypes.func.isRequired,
};

export default withTranslation()(LoginDetails);
