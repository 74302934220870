import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import hesusLogo from "../assets/shared/logo.png";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (window.debugMode) {
      console.log("Error", error);
      console.log("Error Info", errorInfo);
    }
  }

  render() {
    const { hasError } = this.state;
    const { t, children } = this.props;
    if (hasError) {
      // fallback UI
      return (
        <div className="error-box">
          <div className="error-box__logo">
            <a href="/kickoff/list">
              <img src={hesusLogo} alt="Hesus Logo" />
            </a>
          </div>
          <div className="error-box__details">
            <h1 className="error-box__details-title">{t("OOPS")}</h1>
            <p className="error-box__details-description">
              {t("CONTACT_HESUS_ADMINISTRATIVE")}
            </p>
          </div>
        </div>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  t: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default withTranslation()(ErrorBoundary);
