export const MY_ACCOUNT_DETAILS = "MY_ACCOUNT_DETAILS";
export const UPDATE_PASSWORD_CREDENTIALS = "UPDATE_PASSWORD_CREDENTIALS";
export const SHOW_POPUP = "SHOW_POPUP";
export const HANDLE_POPUP = "HANDLE_POPUP";
export const CLEAR_UPDATE_PASSWORD_FORM = "CLEAR_UPDATE_PASSWORD_FORM";

export const GET_USERACCOUNT_DATA = {
  LOADING: "GET_USERACCOUNT_DATA_LOADING",
  SUCCESS: "GET_USERACCOUNT_DATA_SUCCESS",
  ERROR: "GET_USERACCOUNT_DATA_FAILURE",
};

export const UPDATE_MYACCOUNT_DATA = {
  LOADING: "UPDATE_MYACCOUNT_DATA_LOADING",
  SUCCESS: "UPDATE_MYACCOUNT_DATA_SUCCESS",
  ERROR: "UPDATE_MYACCOUNT_DATA_FAILURE",
};

export const UPDATE_PASSWORD_DATA = {
  LOADING: "UPDATE_PASSWORD_DATA_LOADING",
  SUCCESS: "UPDATE_PASSWORD_DATA_SUCCESS",
  ERROR: "UPDATE_PASSWORD_DATA_FAILURE",
};
