import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import PulseLoader from "react-spinners/PulseLoader";
import PropTypes from "prop-types";

import "./AutoComplete.scss";

const AutoComplete = (props) => {
  const {
    label,
    id,
    name,
    suggestions,
    value,
    isLoading,
    containerClass,
    labelClass,
    inputClass,
    menuClassName,
    menuItemClassName,
    onChange,
    onFocus,
    onBlur,
    onMouseLeave,
    onSelect,
    t,
  } = props;

  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [input, setInput] = useState("");
  const [isInputChanging, setInputChangeStatus] = useState(false);

  const DEBOUNCE_TIMEOUT = 450;
  const noSuggestionMessage = t("CLIENT_PLATFORM_ONBOARDING_ADDRESS_NO_SUGGESTION");

  const onInputChange = (e) => {
    const userInput = e.target?.value ?? e;

    // Filter our suggestions that don't contain the user's input
    const unLinked = suggestions.filter(
      (suggestion, index) => index > 0 && !suggestion?.includes("?")
    );

    setInput(userInput);
    setInputChangeStatus(true);
    setFilteredSuggestions(
      suggestions[0]?.includes("?") ? [suggestions[0], ...unLinked] : suggestions
    );
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
    onChange(userInput);
  };

  /**
   * On Suggestion click. Select the suggestion and use its data.
   * @param {*} e
   */
  const onClick = (e) => {
    setFilteredSuggestions([]);
    setInput("");
    setInputChangeStatus(false);
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
    onSelect(e.target.innerText);
  };

  /**
   * Handle keyboard events.
   * @param {*} e
   * @returns
   */
  const onKeyDown = (e) => {
    // User pressed the enter key
    if (e.keyCode === 13) {
      setInput(filteredSuggestions[activeSuggestionIndex]);
      setActiveSuggestionIndex(0);
      setShowSuggestions(false);
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestionIndex === 0) {
        return;
      }

      setActiveSuggestionIndex(activeSuggestionIndex - 1);
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestionIndex - 1 === filteredSuggestions.length) {
        return;
      }

      setActiveSuggestionIndex(activeSuggestionIndex + 1);
    }
  };

  const renderNoSuggestionsMessage = (isInputChange, message) => {
    if (!isInputChange) {
      return (
        <div className="no-suggestions">
          <em>{message}</em>
        </div>
      );
    }
    return "";
  };

  const SuggestionsListComponent = () => {
    if (isLoading) {
      return (
        <div className="suggestions-list-loading">
          <PulseLoader color="#1bace4" size={15} />
        </div>
      );
    }
    return filteredSuggestions.length ? (
      <ul className={menuClassName}>
        {filteredSuggestions.map((suggestion, index) => {
          let className;

          // Flag the active suggestion with a class
          if (index === activeSuggestionIndex) {
            className = "active";
          }

          return (
            <li
              className={`${menuItemClassName} ${className ?? ""} ${
                suggestion.includes("?") ? "error" : ""
              }`}
              key={suggestion}
              onClick={(e) => {
                if (!suggestion.includes("?")) {
                  onClick(e);
                }
              }}
            >
              {suggestion}
            </li>
          );
        })}
      </ul>
    ) : (
      renderNoSuggestionsMessage(isInputChanging, noSuggestionMessage)
    );
  };

  useEffect(() => {
    if (input) {
      onInputChange(input);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestions]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setInputChangeStatus(false);
    }, DEBOUNCE_TIMEOUT);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [input, isLoading]);

  return (
    <div className={containerClass}>
      <label htmlFor={id} className={labelClass}>
        {label}
      </label>
      <input
        type="text"
        id={id}
        name={name}
        aria-label={name}
        onChange={onInputChange}
        onKeyDown={onKeyDown}
        value={input || value}
        onFocus={onFocus}
        onBlur={(e) => {
          if (filteredSuggestions.length === 1) {
            setFilteredSuggestions([]);
            setInput("");
            setActiveSuggestionIndex(0);
            setShowSuggestions(false);
          }
          onBlur?.(e);
        }}
        onMouseLeave={(e) => {
          if (filteredSuggestions.length > 0) {
            onMouseLeave?.(e);
          }
        }}
        className={inputClass}
      />
      {showSuggestions && input && (
        <SuggestionsListComponent
          menuClassName={menuClassName}
          menuItemClassName={menuItemClassName}
        />
      )}
    </div>
  );
};

AutoComplete.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  labelClass: PropTypes.string.isRequired,
  containerClass: PropTypes.string.isRequired,
  inputClass: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  menuClassName: PropTypes.string.isRequired,
  menuItemClassName: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

AutoComplete.defaultProps = {
  value: "",
  onFocus: null,
  onBlur: null,
  onMouseLeave: null,
};

export default withTranslation()(AutoComplete);
