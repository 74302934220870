import {
  CREATE_TRANSPORT_REQUEST,
  UPDATE_TRANSPORT_REQUEST,
  CREATE_TRANSPORT_REQUEST_FORM,
  EDIT_TRANSPORT_ENQUIRY,
  HANDLE_TRANSPORT_DETAILS,
} from "./TransportOnlyForm.constants";

export const createTransportRequest = (payload) => ({
  type: CREATE_TRANSPORT_REQUEST.LOADING,
  payload,
});

export const updateTransportRequest = (payload) => ({
  type: UPDATE_TRANSPORT_REQUEST.LOADING,
  payload,
});

export const editTransportEnquiry = (payload) => ({
  type: EDIT_TRANSPORT_ENQUIRY,
  payload,
});

export const handleTransportData = (payload) => ({
  type: HANDLE_TRANSPORT_DETAILS,
  payload,
});

export const createTransportRequestForm = () => ({
  type: CREATE_TRANSPORT_REQUEST_FORM,
});
