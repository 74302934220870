import React from "react";

const TabContent = ({ className, id, data, components }) => {
  return (
    <div className={className} id={id}>
      {data.map((tab, index) => {
        return (
          <div
            key={tab.id}
            className={`tab-pane fade ${index === 0 ? "show active" : ""}`}
            id={tab.link.split("#")[1]}
            role="tabpanel"
            aria-labelledby={tab.id}
          >
            {components[index]}
          </div>
        );
      })}
    </div>
  );
};

export default TabContent;
