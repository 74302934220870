import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import AddRow from "../AddRow";

import TextInput from "../../../../../../shared/baseUI/TextInput";
import SelectInput from "../../../../../../shared/baseUI/CustomSelectBox";
import MultiSelectDropdown from "../../../../../../shared/baseUI/MultiSelectDropdown";
import FileInputGroup from "../../../../../../shared/baseUI/FileInputGroup";
import TextAreaInput from "../../../../../../shared/baseUI/TextAreaInput";
import CustomDatePicker from "../../../../../../shared/baseUI/DatePicker";

import "./RequestDetails.scss";

const RequestDetails = (props) => {
  const {
    t,
    fields,
    productInput,
    selectedService,
    subCategory,
    requestSelectedItems,
    isAnalysisEnabled,
    data,
    products,
    countryId,
    transport,
    durationOptions,
    documentNames,
    userCountry,
    priceInput,
    onChange,
    units,
    handleRequestFormDetails,
    AddProductInput,
    onDateChange,
    onTransportSelect,
    deleteFile,
  } = props;

  const isTermsAccepted = (terms, isRequestEdit) => {
    if (parseInt(terms, 10) === 1) {
      return true;
    }
    if (isRequestEdit) {
      return true;
    }
    return false;
  };

  return (
    <>
      <section className="request-details__section">
        {fields.categoryForm.value !== 5 && (
          <h6 className="request-details__section-title">
            {t("CLIENT_PLATFORM_REQUEST_FORM_PRODUCT_INFO_TITLE")}
          </h6>
        )}
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6">
            {productInput}
            {selectedService === 2 &&
              subCategory.length > 0 &&
              subCategory[0].sub_category_id &&
              subCategory.some((x) => x.sub_category.category_id === 1) && (
                <AddRow
                  onChange={(e, i, id) => {
                    AddProductInput(e, i, id);
                    onChange(e, i, id);
                  }}
                  divClassName="request-details__product-input"
                  options={products}
                  data={data}
                />
              )}
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6">
            <div className="row">
              <div className="col-6">
                <TextInput
                  type="text"
                  placeholder={t(
                    "CLIENT_PLATFORM_REQUEST_FORM_QUANTITY_PLACEHOLDER"
                  )}
                  inputClass={
                    fields.quantity.error
                      ? "request-details__section-input border-danger"
                      : "request-details__section-input"
                  }
                  divClass="request-details__input-wrapper"
                  labelClass="request-details__section-input-label required"
                  labelName={t("CLIENT_PLATFORM_REQUEST_FORM_QUANTITY")}
                  required
                  error={!!fields.quantity.error}
                  pattern={/[0-9]*/}
                  errorMsg={fields.quantity.error}
                  id="quantity"
                  name="quantity"
                  value={fields.quantity.value}
                  onChange={(e) => onChange(e)}
                />
              </div>
              <div className="col-6">
                <SelectInput
                  value={fields.unit.value}
                  inputClass={
                    fields.unit.error
                      ? "request-details__section-input border-danger"
                      : "request-details__section-input"
                  }
                  divClass="request-details__input-wrapper"
                  labelClass="request-details__section-input-label required"
                  required
                  error={!!fields.unit.error}
                  errorMsg={fields.unit.error}
                  labelName={t("CLIENT_PLATFORM_REQUEST_FORM_UNIT")}
                  name="unit"
                  onChange={(e) => onChange(e)}
                  defaultOption={t("CLIENT_PLATFORM_REQUEST_FORM_UNIT_PLACEHOLDER")}
                  options={units}
                />
              </div>
              {priceInput}
            </div>
          </div>
        </div>
      </section>
      <section className="request-details__section">
        <h6 className="request-details__section-title">
          {fields.service.value === 1
            ? t("CLIENT_PLATFORM_REQUEST_FORM_REMOVAL_INFO_TITLE")
            : t("CLIENT_PLATFORM_REQUEST_FORM_SUPPLY_INFO_TITLE")}
        </h6>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6">
            <MultiSelectDropdown
              divClass="request-details__transport-container"
              labelClass="request-details__section-input-label required"
              labelName={t("CLIENT_PLATFORM_REQUEST_FORM_TRANSPORT_TYPE")}
              defaultValue={fields.selectedTransport.value}
              error={!!fields.transport.error}
              errorMsg={fields.transport.error}
              name="transport"
              onChange={(e) => onTransportSelect(e)}
              placeholder={t(
                "CLIENT_PLATFORM_REQUEST_FORM_TRANSPORT_TYPE_PLACEHOLDER"
              )}
              data={transport}
              groupBy={transport}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6">
            <TextInput
              type="text"
              placeholder={t(
                "CLIENT_PLATFORM_REQUEST_FORM_LOADS_PER_DAY_PLACEHOLDER"
              )}
              divClass="request-details__input-wrapper"
              labelClass="request-details__section-input-label required"
              labelName={t("CLIENT_PLATFORM_REQUEST_FORM_LOADS_PER_DAY")}
              required
              inputClass={
                fields.truck_per_day.error
                  ? "request-details__section-input border-danger"
                  : "request-details__section-input"
              }
              error={!!fields.truck_per_day.error}
              errorMsg={fields.truck_per_day.error}
              id="truck_per_day"
              name="truck_per_day"
              pattern={/[0-9]*/}
              value={fields.truck_per_day.value}
              onChange={(e) => onChange(e)}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6">
            <CustomDatePicker
              countryId={parseInt(countryId, 10)}
              placeholder={t("CLIENT_PLATFORM_REQUEST_FORM_START_DATE_PLACEHOLDER")}
              divClass="request-details__input-wrapper"
              labelClass="request-details__section-input-label required"
              labelName={t("CLIENT_PLATFORM_REQUEST_FORM_START_DATE")}
              inputClass={
                fields.start_date.error
                  ? "request-details__section-input border-danger"
                  : "request-details__section-input"
              }
              error={!!fields.start_date.error}
              errorMsg={fields.start_date.error}
              required
              id="start_date"
              name="start_date"
              value={fields.start_date.value ? fields.start_date.value : ""}
              onChange={(e) => onDateChange(e)}
              dateFormat="dd-MM-yyyy"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6">
            <SelectInput
              value={fields.duration.value ? fields.duration.value : ""}
              inputClass={
                fields.duration.error
                  ? "request-details__section-input border-danger"
                  : "request-details__section-input"
              }
              divClass="request-details__input-wrapper"
              labelName={t("CLIENT_PLATFORM_SITE_FORM_REQUEST_DURATION")}
              labelClass="request-details__section-input-label required"
              required
              error={!!fields.duration.error}
              errorMsg={fields.duration.error}
              name="duration"
              onChange={(e) => onChange(e)}
              defaultOption={t(
                "CLIENT_PLATFORM_SITE_FORM_REQUEST_DURATION_PLACEHOLDER"
              )}
              options={durationOptions}
            />
          </div>
        </div>
      </section>
      <section className="request-details__section">
        <h6 className="request-details__section-title">
          {t("CLIENT_PLATFORM_REQUEST_FORM_DOCUMENTS_INFO_TITLE")}
        </h6>
        {documentNames && (
          <FileInputGroup
            data={data}
            labelNameData={documentNames}
            deleteFile={(url) => deleteFile(url)}
            fileGroupName="request"
            handleData={handleRequestFormDetails}
          />
        )}
      </section>
      <section className="request-details__section">
        <h6 className="request-details__section-title">
          {t("CLIENT_PLATFORM_REQUEST_FORM_COMPLEMENTARY_INFORMATION")}
        </h6>
        <div className="row">
          {isAnalysisEnabled && (
            <div className="col-12">
              <div className="d-block">
                <label className="custom-checkbox tender-checkbox p-2">
                  <span className="checkbox-label mb-0">
                    {t("CLIENT_PLATFORM_SITE_FORM_REQUEST_FURTHER_ANALYSIS")}
                  </span>
                  <input
                    type="checkbox"
                    name="analysis_flag"
                    value={fields.analysis_flag.value}
                    onChange={(e) => onChange(e)}
                    checked={fields.analysis_flag.value === 1}
                  />
                  <span className="checkmark" />
                </label>
              </div>
            </div>
          )}
          {!data.hasOwnProperty("editData") && (
            <div className="col-xs-12 col-sm-6 col-md-4">
              <TextInput
                type="text"
                placeholder={t(
                  "CLIENT_PLATFORM_SITE_FORM_REQUEST_PROMO_CODE_PLACEHOLDER"
                )}
                inputClass="request-details__section-input"
                divClass="request-details__input-wrapper"
                labelClass="request-details__section-input-label"
                labelName={t("CLIENT_PLATFORM_SITE_FORM_REQUEST_PROMO_CODE")}
                required={false}
                id="promocode"
                name="promocode"
                value={fields.promocode.value}
                onChange={(e) => onChange(e)}
              />
            </div>
          )}
          <div
            className={
              !data.hasOwnProperty("editData")
                ? "col-xs-12 col-sm-6 col-md-8"
                : "col-12"
            }
          >
            <TextAreaInput
              placeholder={t(
                "CLIENT_PLATFORM_SITE_FORM_REQUEST_COMMENT_PLACEHOLDER"
              )}
              inputClass="request-details__section-input"
              divClass="request-details__input-wrapper"
              labelClass="request-details__section-input-label"
              labelName={t("CLIENT_PLATFORM_SITE_FORM_REQUEST_COMMENT")}
              required={false}
              rows={5}
              columns={100}
              id="comment"
              name="comment"
              value={fields.comment.value ? fields.comment.value : ""}
              onChange={(e) => onChange(e)}
            />
          </div>
        </div>
        {userCountry === 2 &&
          requestSelectedItems.service === 2 &&
          requestSelectedItems.category === 1 &&
          requestSelectedItems.subcategory === 2 && (
            <div>
              <label className="custom-checkbox tender-checkbox p-2">
                <span className="checkbox-label mb-0">
                  {t("CLIENT_PLATFORM_REQUEST_FORM_NOT_AGRICULTURAL_AREA")}
                </span>
                <input
                  type="checkbox"
                  name="terms"
                  value={fields.terms.value}
                  onChange={(e) => onChange(e)}
                  checked={isTermsAccepted(fields.terms.value, data.editData)}
                />
                <span className="checkmark" />
              </label>
            </div>
          )}
      </section>
    </>
  );
};

RequestDetails.propTypes = {
  fields: PropTypes.object.isRequired,
  productInput: PropTypes.object,
  selectedService: PropTypes.number,
  subCategory: PropTypes.array,
  requestSelectedItems: PropTypes.object,
  isAnalysisEnabled: PropTypes.bool,
  data: PropTypes.object,
  products: PropTypes.array,
  countryId: PropTypes.string,
  transport: PropTypes.array,
  durationOptions: PropTypes.array,
  documentNames: PropTypes.array,
  userCountry: PropTypes.number,
  priceInput: PropTypes.object,
  units: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  handleRequestFormDetails: PropTypes.func.isRequired,
  AddProductInput: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onTransportSelect: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
};

export default withTranslation()(RequestDetails);
