export const GET_REQUEST_CATEGORIES = {
  LOADING: "GET_REQUEST_CATEGORIES_LOADING",
  SUCCESS: "GET_REQUEST_CATEGORIES_SUCCESS",
  ERROR: "GET_REQUEST_CATEGORIES_FAILURE",
};

export const GET_PRODUCT_DETAILS = {
  LOADING: "GET_PRODUCT_DETAILS_LOADING",
  SUCCESS: "GET_PRODUCT_DETAILS_SUCCESS",
  ERROR: "GET_PRODUCT_DETAILS_FAILURE",
};

export const GET_REQUEST_VIEW_DATA = {
  LOADING: "GET_REQUEST_VIEW_DATA_LOADING",
  SUCCESS: "GET_REQUEST_VIEW_DATA_SUCCESS",
  ERROR: "GET_REQUEST_VIEW_DATA_FAILURE",
};

export const DELETE_DOCUMENT = {
  LOADING: "DELETE_DOCUMENT_LOADING",
  SUCCESS: "DELETE_DOCUMENT_SUCCESS",
  ERROR: "DELETE_DOCUMENT_FAILURE",
};

export const GET_SITE_MASTER_LIST_DATA = {
  LOADING: "GET_SITE_MASTER_LIST_DATA_LOADING",
  SUCCESS: "GET_SITE_MASTER_LIST_DATA_SUCCESS",
  ERROR: "GET_SITE_MASTER_LIST_DATA_FAILURE",
};

export const GET_REQUEST_MASTER_LIST_DATA = {
  LOADING: "GET_REQUEST_MASTER_LIST_DATA_LOADING",
  SUCCESS: "GET_REQUEST_MASTER_LIST_DATA_SUCCESS",
  ERROR: "GET_REQUEST_MASTER_LIST_DATA_FAILURE",
};

export const HANDLE_SITE_FORM_DETAILS = "HANDLE_SITE_FORM_DETAILS";
export const SELECTED_JOB_SITE_FOR_REQUEST = "SELECTED_JOB_SITE_FOR_REQUEST";
export const UPDATE_ROW_ITEM = "UPDATE_ROW_ITEM";
export const CLEAR_SELECTED_SITE = "CLEAR_SELECTED_SITE";
export const CLEAR_UPDATE_PASSWORD_FORM = "CLEAR_UPDATE_PASSWORD_FORM";
export const GET_CALL_OF_ORDER_POPUP_VISIBLE = "GET_CALL_OF_ORDER_POPUP_VISIBLE";
