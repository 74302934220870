import {
  GET_SITE_LISTING_DATA,
  GET_SITE_LISTING_INACTIVE_DATA,
} from "./SitesList.constants";

export const getSiteListingData = (payload) => ({
  type: GET_SITE_LISTING_DATA.LOADING,
  payload,
});

export const getSiteListingInactiveData = (payload) => ({
  type: GET_SITE_LISTING_INACTIVE_DATA.LOADING,
  payload,
});
