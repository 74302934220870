import {
  GET_USERACCOUNT_DATA,
  MY_ACCOUNT_DETAILS,
  UPDATE_MYACCOUNT_DATA,
  UPDATE_PASSWORD_CREDENTIALS,
  UPDATE_PASSWORD_DATA,
  HANDLE_POPUP,
  CLEAR_UPDATE_PASSWORD_FORM,
} from "./MyAccount.constants";

import { LOADING, SUCCESS, ERROR } from "../../shared/utils/constant";

const initialState = {
  userAccountData: [
    {
      user: {
        first_name: "",
        last_name: "",
        email: "",
        job: "",
        phone: "",
        mobile_phone: "",
        courtesy: "",
        company_id: "",
        status: "",
        comment: "",
        created_from: 1,
        salesforce_contact_id: "",
        salesforce_account_id: "",
        updated_at: "",
        created_at: "",
        created_by: "",
        can_edit: "",
      },
      company: {
        id: "",
        name: "",
        identification_number: "",
        shipping_city: "",
        shipping_postal: "",
        shipping_address: "",
        sector_id: "",
        platform_country_id: "",
        company_phone: "",
      },
    },
  ],
  updateStatus: {
    code: "",
    message: "",
    data: [],
  },
  userPasswordData: {
    old_password: "",
    password: "",
  },
  updatedPasswordStatus: {
    code: "",
    message: "",
    data: [],
  },
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USERACCOUNT_DATA.LOADING: {
      return {
        ...state,
        phase: LOADING,
        apiUnAuthenticated: false,
      };
    }
    case GET_USERACCOUNT_DATA.SUCCESS: {
      const data = { ...action.data };
      const { user, company } = data;

      return {
        ...state,
        phase: SUCCESS,
        userAccountData: [
          {
            user,
            company,
            country: action.data.country,
          },
        ],
      };
    }
    case GET_USERACCOUNT_DATA.ERROR: {
      return {
        ...state,
        phase: ERROR,
        userAccountData: [],
      };
    }
    case MY_ACCOUNT_DETAILS: {
      const { user, company, country } = action.payload.userAccountDetails;

      return {
        ...state,
        userAccountData: [
          {
            user,
            company,
            country,
          },
        ],
      };
    }
    case UPDATE_MYACCOUNT_DATA.LOADING: {
      return {
        ...state,
        isAccountUpdate: true,
        updateStatus: {
          code: "",
          message: "",
          data: "",
        },
        showPopup: false,
      };
    }
    case UPDATE_MYACCOUNT_DATA.SUCCESS: {
      return {
        ...state,
        isAccountUpdate: false,
        updateStatus: {
          code: 200,
          message: action.data.message ? action.data.message : "",
          data: action.data,
        },

        showPopup: true,
      };
    }
    case UPDATE_MYACCOUNT_DATA.ERROR: {
      const error = action.data;
      const { code, message, data } = error;
      return {
        ...state,
        isAccountUpdate: false,
        updateStatus: {
          code,
          message,
          data,
        },
        showPopup: true,
      };
    }
    case UPDATE_PASSWORD_CREDENTIALS: {
      const data = action.payload.userPasswordDetails;
      const btnCheck = !(data.old_password.length >= 6 && data.password.length >= 6);
      return {
        ...state,
        userPasswordData: action.payload.userPasswordDetails,
        disabledPasswordBtn: btnCheck,
      };
    }
    case UPDATE_PASSWORD_DATA.LOADING: {
      return {
        ...state,
        isPasswordUpdate: true,
        updatedPasswordStatus: {
          code: "",
          message: "",
          data: [],
        },
        showPopup: false,
      };
    }
    case UPDATE_PASSWORD_DATA.SUCCESS: {
      return {
        ...state,
        isPasswordUpdate: false,
        updatedPasswordStatus: {
          code: 200,
          message: action.data.success,
          data: [],
        },
        showPopup: true,
        userPasswordData: {
          old_password: "",
          password: "",
        },
      };
    }
    case UPDATE_PASSWORD_DATA.ERROR: {
      const response = action.data;
      return {
        ...state,
        isPasswordUpdate: false,
        updatedPasswordStatus: {
          code: response.code,
          message: response.message,
          data: response.data,
        },
        showPopup: true,
      };
    }
    case HANDLE_POPUP: {
      return {
        ...state,
        showPopup: false,
      };
    }
    case CLEAR_UPDATE_PASSWORD_FORM: {
      return { ...state, userPasswordData: { old_password: "", password: "" } };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
