import {
  ADD_NEW_JOBSITE,
  UPDATE_JOBSITE,
  HANDLE_SITE_FORM_DETAILS,
  DELETE_DOCUMENT,
  GET_SITE_MASTER_LIST_DATA,
  USER_VIEWED_LEAD,
  HANDLE_EDIT_JOBSITE,
  CREATE_SITE_FORM,
} from "./JobsiteForm.constants";

export const addNewJobSite = (payload) => ({
  type: ADD_NEW_JOBSITE.LOADING,
  payload,
});

export const updateJobSite = (payload) => ({
  type: UPDATE_JOBSITE.LOADING,
  payload,
});

export const handleSiteFormDetails = (payload) => ({
  type: HANDLE_SITE_FORM_DETAILS,
  payload,
});

export const handleEditJobSite = (payload) => ({
  type: HANDLE_EDIT_JOBSITE,
  payload,
});

export const deleteDocument = (payload) => ({
  type: DELETE_DOCUMENT.LOADING,
  payload,
});

export const getSiteMasterListData = () => ({
  type: GET_SITE_MASTER_LIST_DATA.LOADING,
});

export const setLeadViewStatus = (payload) => ({
  type: USER_VIEWED_LEAD,
  payload,
});

export const createSite = (payload) => ({
  type: CREATE_SITE_FORM,
  payload,
});
