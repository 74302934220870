export const ADD_NEW_REQUEST = {
  LOADING: "ADD_NEW_REQUEST_LOADING",
  SUCCESS: "ADD_NEW_REQUEST_SUCCESS",
  ERROR: "ADD_NEW_REQUEST_FAILURE",
};

export const UPDATE_REQUEST_DATA = {
  LOADING: "UPDATE_REQUEST_DATA_LOADING",
  SUCCESS: "UPDATE_REQUEST_DATA_SUCCESS",
  ERROR: "UPDATE_REQUEST_DATA_FAILURE",
};

export const GET_MATERIAL_PRODUCT_DETAILS = {
  LOADING: "GET_MATERIAL_PRODUCT_DETAILS_LOADING",
  SUCCESS: "GET_MATERIAL_PRODUCT_DETAILS_SUCCESS",
  ERROR: "GET_MATERIAL_PRODUCT_DETAILS_FAILURE",
};

export const EDIT_ENQUIRY = "EDIT_ENQUIRY";
export const HANDLE_REQUEST_FORM_DETAILS = "HANDLE_REQUEST_FORM_DETAILS";
export const UPDATE_ROW_VALUE = "UPDATE_ROW_VALUE";
export const ADD_ROW_ITEM = "ADD_ROW_ITEM";
export const DELETE_ROW_ITEM = "DELETE_ROW_ITEM";
export const UPDATE_ROW_ITEM = "UPDATE_ROW_ITEM";
export const CREATE_ENQUIRY_FORM = "CREATE_ENQUIRY_FORM";
export const SELECT_CATEGORY = "SELECT_CATEGORY";
export const SELECT_SUB_CATEGORY = "SELECT_SUB_CATEGORY";
export const SELECT_CATEGORY_FORM = "SELECT_CATEGORY_FORM";
export const GET_EDIT_REQUEST_DATA = "GET_EDIT_REQUEST_DATA";
