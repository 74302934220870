import axios from "axios";
import { getStorageInfo } from "../../shared/utils/methods";

import baseURL from "../../shared/utils/constant";

const axiosInstance = (token) => {
  return axios.create({
    baseURL,
    timeout: 36000,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getSiteListingDataAPI = (payload) => {
  let url = "";
  const userData = getStorageInfo();
  if (payload === undefined) {
    url = `${baseURL}/sites`;
  } else url = `${baseURL}/sites?page=${payload.pageNum}`;
  return axiosInstance(userData.token)
    .get(url)
    .then((res) => res.data);
};

export const getSiteListingInactiveDataAPI = (payload) => {
  let url = "";
  const userData = getStorageInfo();
  if (payload === undefined) {
    url = `${baseURL}/sites/inactive/list`;
  } else url = `${baseURL}/sites/inactive/list?page=${payload.pageNum}`;
  return axiosInstance(userData.token)
    .get(url)
    .then((res) => res.data);
};
