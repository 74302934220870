/**
 * Acts as wrapper component to hold forms.
 */
import React from "react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ClipLoader from "react-spinners/ClipLoader";
import PropTypes from "prop-types";

import Button from "../../baseUI/Button";

import "./FormContainer.scss";

const FormContainer = (props) => {
  const {
    t,
    icon,
    isLoading,
    isSubmitBtnDisabled,
    title,
    description,
    submitBtnLabel,
    onCancel,
    onSubmit,
  } = props;

  return (
    <section className="container-fluid form__container">
      <div className="row">
        <div className="col-12 form__wrapper">
          <header className="form__header-wrapper">
            <NavLink to="/dashboard" className="form__icon-link">
              <img src={icon} className="form__icon" alt="logo" />
            </NavLink>
            <div>
              <h5 className="form__title">{title}</h5>
              <p className="form__description">{description}</p>
            </div>
          </header>
          <section className="form__main-content">
            {props.children}
            <div className="form__footer">
              <Button
                onClick={onCancel}
                className="form__btn-cancel"
                name={t("CANCEL")}
              />
              <div>
                {isLoading && (
                  <div className="btn btn-primary btn-blue btn-loader form__btn-loader">
                    <ClipLoader size={20} color="#fff" />
                  </div>
                )}
                {!isLoading && (
                  <Button
                    className="btn btn-primary btn-blue form__btn-submit"
                    name={submitBtnLabel}
                    disabled={isSubmitBtnDisabled}
                    onClick={onSubmit}
                  />
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

FormContainer.propTypes = {
  icon: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSubmitBtnDisabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  submitBtnLabel: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(FormContainer);
