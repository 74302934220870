import {
  CREATE_CALL_OF_ORDER_FORM,
  CREATE_CALL_OF_ORDER,
  HANDLE_CALLOFF_DATA,
  EDIT_CALL_OF_ORDER_FORM,
  GET_SITE_MASTER_LIST_DATA_CALL_OF_ORDER,
  GET_REQUEST_MASTER_LIST_DATA_CALL_OF_ORDER,
  GET_TRANSPORT_TRUCKS,
} from "./CallOffOrderForm.constants";

export const createCalloffOrderForm = () => ({
  type: CREATE_CALL_OF_ORDER_FORM,
});

export const createCalloffOrder = (payload) => ({
  type: CREATE_CALL_OF_ORDER.LOADING,
  payload,
});

export const handleCallOrderData = (payload) => ({
  type: HANDLE_CALLOFF_DATA,
  payload,
});

export const editCallOffOrder = (payload) => ({
  type: EDIT_CALL_OF_ORDER_FORM,
  payload,
});

export const getSiteMasterListDataCallOfOrder = () => ({
  type: GET_SITE_MASTER_LIST_DATA_CALL_OF_ORDER.LOADING,
});

export const getRequestMasterDataCallOfOrder = (payload) => ({
  type: GET_REQUEST_MASTER_LIST_DATA_CALL_OF_ORDER.LOADING,
  payload,
});

export const getTrucks = (payload) => ({
  type: GET_TRANSPORT_TRUCKS.LOADING,
  payload,
});
