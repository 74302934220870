import axios from "axios";

import baseURL from "../../shared/utils/constant";

const axiosInstance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const loginDataAPI = (data) => {
  return axiosInstance.post(`${baseURL}/login`, data).then((res) => res.data);
};

export const checkEmailAPI = (data) => {
  return axiosInstance.head(`${baseURL}/identities/${data}`).then((res) => res.data);
};

export const forgotPasswordAPI = (data) => {
  return axiosInstance
    .post(`${baseURL}/forgot-password`, data)
    .then((res) => res.data);
};

export const logOutAPI = (token) => {
  let tokenLocal = token;
  if (!token) {
    tokenLocal = localStorage.getItem("token");
  }

  return axios
    .get(`${baseURL}/logout`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${tokenLocal}`,
      },
    })
    .then((res) => res.data);
};
