import React from "react";
import PropTypes from "prop-types";

const TextAreaInput = (props) => {
  const {
    divClass,
    labelClass,
    labelName,
    name,
    value,
    id,
    required,
    placeholder,
    inputClass,
    onChange,
    onBlur,
    onFocus,
    error,
    errorMsg,
    rows,
    columns,
    disabled,
  } = props;

  return (
    <div className={divClass}>
      <label className={labelClass} htmlFor={name}>
        {labelName}
      </label>
      <textarea
        className={inputClass}
        id={id}
        aria-label={name}
        required={required}
        onBlur={onBlur}
        onFocus={onFocus}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange}
        rows={rows}
        cols={columns}
        value={value}
      />

      {error && (
        <div className="error-text text-danger position-absolute w-100 text-nowrap">
          {errorMsg}
        </div>
      )}
    </div>
  );
};

TextAreaInput.propTypes = {
  divClass: PropTypes.string,
  labelClass: PropTypes.string,
  labelName: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  id: PropTypes.any,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  inputClass: PropTypes.string,
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
  rows: PropTypes.number,
  columns: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

export default TextAreaInput;
