import { takeLatest, put, call } from "redux-saga/effects";

import {
  GET_USERACCOUNT_DATA,
  UPDATE_MYACCOUNT_DATA,
  UPDATE_PASSWORD_DATA,
} from "./MyAccount.constants";

import {
  getUserAccountDataAPI,
  updateUserAccountDataAPI,
  updatePasswordDataAPI,
} from "./MyAccount.api";

// worker sagas.
function* getUserAccountData(action) {
  try {
    const data = yield call(getUserAccountDataAPI, action.payload);
    yield put({ type: GET_USERACCOUNT_DATA.SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_USERACCOUNT_DATA.ERROR, error });
  }
}

function* updateUserAccount(action) {
  const payload = { ...action.payload.user, ...action.payload.company };
  if (!payload.hasOwnProperty("company_edit")) {
    payload.company_edit = false;
  }

  try {
    const data = yield call(updateUserAccountDataAPI, payload);
    yield put({ type: UPDATE_MYACCOUNT_DATA.SUCCESS, data });
  } catch (error) {
    const data = {
      code: error?.response?.status,
      data: error?.response?.data.errors,
      message: error?.response?.data.message,
    };
    yield put({ type: UPDATE_MYACCOUNT_DATA.ERROR, data });
  }
}

function* updatePasswordData(action) {
  try {
    const data = yield call(updatePasswordDataAPI, action.payload);
    yield put({ type: UPDATE_PASSWORD_DATA.SUCCESS, data });
  } catch (error) {
    const data = {
      code: error?.response?.status,
      data: error?.response?.data.errors,
      message: error?.response?.data.message,
    };
    yield put({ type: UPDATE_PASSWORD_DATA.ERROR, data });
  }
}

// watcher sagas.
export function* getUserAccountDataSaga() {
  yield takeLatest(GET_USERACCOUNT_DATA.LOADING, getUserAccountData);
}

export function* updateUserAccountSaga() {
  yield takeLatest(UPDATE_MYACCOUNT_DATA.LOADING, updateUserAccount);
}

export function* updatePasswordDataSaga() {
  yield takeLatest(UPDATE_PASSWORD_DATA.LOADING, updatePasswordData);
}
