import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { storage } from "../../../../store/dataStore/api";

import {
  getCategoriesData,
  getCallOfOrderPopupVisible,
  getSiteMasterListData,
  showPopUp,
  getRequestViewData,
  selectedJobSiteForRequestCreation,
} from "../../../../store/dataStore";
import {
  getViewData,
  getQuotes,
  getRequestListingData,
} from "../../SiteOverview.actionCreators";
import { createCalloffOrderForm } from "../../../CallOffOrderForm/CallOffOrderForm.actionCreators";
import { handleEditJobSite } from "../../../JobsiteForm/JobsiteForm.actionCreators";
import { createEnquiry } from "../../../InquiryForm/InquiryForm.actionCreators";
import { handleLogOut } from "../../../../store/loginData";

import SiteInfo from "../SiteInfo";
import MyEnquiries from "../MyEnquiries";
import MyQuotes from "../MyQuotes";

import "./SiteDetails.scss";

const SiteDetailsHome = (props) => {
  const { jobSiteData, isRequestsLoading, isViewDataLoading, apiUnAuthenticated } =
    props;
  const [isSiteDetailsLoading, setSiteLoaderVisibility] = useState(false);
  const [isEnquiriesLoading, setEnquiriesLoading] = useState(false);
  const [userDetails, setUserDetails] = useState();

  const history = useHistory();
  const ACTIVE_USER = 1;

  useEffect(() => {
    window.scrollTo(0, 0);
    const userInfo =
      JSON.parse(localStorage.getItem("userInfo")) !== {}
        ? JSON.parse(localStorage.getItem("userInfo"))
        : "";
    setUserDetails(userInfo);

    if (localStorage.getItem("jobsiteId") !== "") {
      storage();
      loadJobSiteDetails(localStorage.getItem("jobsiteId"));
      if (parseInt(userInfo.status, 10) === ACTIVE_USER) {
        props.getQuotes({ id: localStorage.getItem("jobsiteId") });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (jobSiteData.name === "") {
      return <Redirect to="/sitesandenquiries" />;
    }
  }, [jobSiteData.name]);

  useEffect(() => {
    setSiteLoaderVisibility(isViewDataLoading);
  }, [isViewDataLoading]);

  useEffect(() => {
    if (isRequestsLoading) {
      setEnquiriesLoading(true);
    } else {
      setEnquiriesLoading(false);
    }
  }, [isRequestsLoading]);

  useEffect(() => {
    if (apiUnAuthenticated) {
      props.handleLogOut();
      history.replace("/login");
    }
  }, [apiUnAuthenticated, props, history]);

  const loadJobSiteDetails = async (id) => {
    setSiteLoaderVisibility(true);
    await props.getViewData(id);
    await props.getRequestListingData({ id });
  };

  return (
    <>
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 pl-0">
        <SiteInfo
          isLoading={isSiteDetailsLoading}
          data={props.siteDetails}
          document={props.document}
          stage={props.stage}
          quotes={props.quotes}
          isCallOfOrderPopupVisible={props.isCallOfOrderPopupVisible}
          handleEditJobSite={props.handleEditJobSite}
          getCategoriesData={props.getCategoriesData}
          createCalloffOrderForm={props.createCalloffOrderForm}
          getCallOfOrderPopupVisible={props.getCallOfOrderPopupVisible}
        />
      </div>
      <MyEnquiries
        siteId={jobSiteData.id}
        siteName={jobSiteData.name}
        isLoading={isEnquiriesLoading}
        user_id={jobSiteData.user_id}
        listingData={props.listingData}
        showPopup={props.showPopup}
        paginationData={props.enquiries}
        getSiteMasterListData={props.getSiteMasterListData}
        handleShowPopUp={props.showPopUp}
        getRequestViewData={props.getRequestViewData}
        createEnquiry={props.createEnquiry}
        getRequestListingData={props.getRequestListingData}
        selectedJobSiteForRequestCreation={props.selectedJobSiteForRequestCreation}
      />
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 view-page-col">
        {userDetails && parseInt(userDetails.status, 10) === ACTIVE_USER && (
          <MyQuotes
            siteId={jobSiteData.id}
            isLoading={props.isQuotesLoading}
            quotes={props.quotes}
            quotesList={props.quotesList}
            getQuotes={props.getQuotes}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    jobSiteData: state.jobsiteOverview.jobSiteFormData,
    isRequestsLoading: state.jobsiteOverview.isRequestListingLoading,
    isQuotesLoading: state.jobsiteOverview.isQuotesLoading,
    isViewDataLoading: state.jobsiteOverview.isViewDataLoading,
    apiUnAuthenticated: state.jobsiteOverview.apiUnAuthenticated,
    siteDetails: state.jobsiteOverview.jobSiteFormData,
    document: state.jobsiteOverview.relatedContact.jobsite.document,
    stage: state.inquiryForm.requestCategoriesData.stage,
    quotes: state.jobsiteOverview.quotes,
    isCallOfOrderPopupVisible: state.dataStore.isCallOfOrderPopupVisible,
    listingData: state.jobsiteOverview.requestListingData,
    showPopup: state.dataStore.showPopup,
    enquiries: state.jobsiteOverview.paginationData.request,
    quotesList: state.jobsiteOverview.paginationData.quotes,
  };
};

const mapDispatchToProps = {
  getViewData: (payload) => getViewData(payload),
  getRequestListingData: (payload) => getRequestListingData(payload),
  getQuotes: (payload) => getQuotes(payload),
  handleLogOut: () => handleLogOut(),
  handleEditJobSite: () => handleEditJobSite(),
  getCategoriesData: () => getCategoriesData(),
  createCalloffOrderForm: () => createCalloffOrderForm(),
  getCallOfOrderPopupVisible: (payload) => getCallOfOrderPopupVisible(payload),
  getSiteMasterListData: () => getSiteMasterListData(),
  showPopUp: () => showPopUp(),
  getRequestViewData: (payload) => getRequestViewData(payload),
  createEnquiry: () => createEnquiry(),
  selectedJobSiteForRequestCreation: (payload) =>
    selectedJobSiteForRequestCreation(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteDetailsHome);
