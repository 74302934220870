import React from "react";
import { Route, BrowserRouter as Router, Redirect, Switch } from "react-router-dom";

import LoginHome from "../modules/Login/Login.screen";
import DashboardHome from "../modules/Dashboard/Dashboard.screen";
import SiteOverview from "../modules/SiteOverview/SiteOverview.screen";
import InquiryForm from "../modules/InquiryForm/InquiryForm.screen";
import MyAccount from "../modules/MyAccount/MyAccount.screen";
import OnboardingV3 from "../modules/OnboardingV3/OnboardingV3.screen.withCaptcha";
import JobSiteForm from "../modules/JobsiteForm/JobsiteForm.screen";
import TransportOnlyForm from "../modules/TransportOnlyForm/TransportOnlyForm.screen";
import SitesList from "../modules/SitesList/SitesList.screen";
import CallOffOrder from "../modules/CallOffOrder/CallOffOrder.screen";
import CallOffOrderForm from "../modules/CallOffOrderForm/CallOffOrderForm.screen";
import PageNotFound from "../modules/PageNotFound/PageNotFound.screen";

import ProtectedRoute from "./ProtectedRoute";

const Routes = () => (
  <Router>
    <Switch>
      <Route exact path={["/login", "/forgotpassword"]} component={LoginHome} />
      <ProtectedRoute exact path={["/", "/dashboard"]} component={DashboardHome} />
      <ProtectedRoute exact path="/createenquiry" component={InquiryForm} />
      <ProtectedRoute exact path="/createjobsite" component={JobSiteForm} />
      <ProtectedRoute
        exact
        path="/createcallofforder"
        component={CallOffOrderForm}
      />
      <ProtectedRoute exact path="/transportonly" component={TransportOnlyForm} />
      <ProtectedRoute exact path="/myaccount" component={MyAccount} />
      <ProtectedRoute exact path="/viewsite" component={SiteOverview} />
      <ProtectedRoute exact path="/sitesandenquiries" component={SitesList} />
      <ProtectedRoute exact path="/callofforder" component={CallOffOrder} />
      <Route
        exact
        path={["/onboarding", "/onboarding/:campaign_code"]}
        component={OnboardingV3}
      />
      <Redirect from="/fr/onboarding" to="/onboarding" />
      <Redirect from="/sw/onboarding" to="/onboarding" />
      <Redirect from="/uk/onboarding" to="/onboarding" />
      <Redirect from="/pl/onboarding" to="/onboarding" />
      <Redirect from="/de/onboarding" to="/onboarding" />
      <Redirect from="/fr/onboarding/:campaign_code" to="/onboarding" />
      <Redirect from="/sw/onboarding/:campaign_code" to="/onboarding" />
      <Redirect from="/uk/onboarding/:campaign_code" to="/onboarding" />
      <Redirect from="/pl/onboarding/:campaign_code" to="/onboarding" />
      <Redirect from="/de/onboarding/:campaign_code" to="/onboarding" />
      <Route component={PageNotFound} />
    </Switch>
  </Router>
);

export default Routes;
