import React, { useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import en from "date-fns/locale/en-GB";
import pl from "date-fns/locale/pl";
import ch from "date-fns/locale/fr-CH";
import fr from "date-fns/locale/fr";

import calenderIcon from "../../../assets/shared/ic-calendar.svg";

import "./CustomDatePicker.scss";

const CustomDatePicker = (props) => {
  const {
    divClass,
    labelClass,
    labelName,
    name,
    value,
    id,
    required,
    placeholder,
    inputClass,
    onChange,
    error,
    errorMsg,
    disabled,
    dateFormat,
    countryId,
    minDate,
    calendarIconClass,
  } = props;
  let inputRef = useRef();

  const getLocale = (country) => {
    if (country === 1) {
      return en;
    }
    if (country === 2) {
      return fr;
    }
    if (country === 3) {
      return pl;
    }
    if (country === 8) {
      return ch;
    }
  };

  const getMaxDate = () => {
    const currentDate = new Date();
    const maxDate = new Date(
      currentDate.getFullYear() + 10,
      currentDate.getMonth(),
      currentDate.getDate()
    );
    return maxDate;
  };

  const getMinDate = () => {
    const currentDate = new Date();
    const maxDate = new Date(
      currentDate.getFullYear() - 10,
      currentDate.getMonth(),
      currentDate.getDate()
    );
    return maxDate;
  };

  return (
    <div className={divClass}>
      <label className={labelClass}>{labelName}</label>
      <DatePicker
        locale={getLocale(countryId)}
        onChange={(date) => onChange(date)}
        selected={value ? new Date(value) : ""}
        placeholderText={placeholder}
        className={inputClass}
        name={name}
        id={id}
        disabled={disabled}
        required={required}
        dateFormat={dateFormat}
        minDate={minDate ? new Date(minDate) : getMinDate()}
        maxDate={getMaxDate()}
        ref={(ref) => {
          inputRef = ref;
        }}
        dayClassName={() => "day-class"}
        calendarClassName="custom-datepicker-calendar"
        formatWeekDay={(weekName) => weekName.substring(0, 3)}
        dateFormatCalendar="MMMM"
        yearDropdownItemNumber={15}
        showYearDropdown
        dropdownMode="select"
        yearItemNumber={9}
      />
      <img
        src={calenderIcon}
        alt="calendar icon"
        className={calendarIconClass || "calendar-icon"}
        onClick={() => {
          if (inputRef) {
            inputRef.setFocus();
          }
        }}
      />
      {error && (
        <div className="error-text text-danger position-absolute w-100 text-nowrap">
          {errorMsg}
        </div>
      )}
    </div>
  );
};

export default CustomDatePicker;
