import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

import "./SectionHeader.scss";

const SectionHeader = (props) => {
  const { title, link, viewAllTitle, isViewAllVisible } = props;

  return (
    <div className="my-enquiries-title-wrapper">
      <h4 className="title">{title}</h4>
      {isViewAllVisible && (
        <NavLink to={link}>
          <button className="view-all" type="button">
            {viewAllTitle}
          </button>
        </NavLink>
      )}
    </div>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  viewAllTitle: PropTypes.string.isRequired,
  isViewAllVisible: PropTypes.bool.isRequired,
};

export default SectionHeader;
