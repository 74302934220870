import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import ReactGA from "react-ga";

import TextInput from "../../../../shared/baseUI/TextInput";
import Button from "../../../../shared/baseUI/Button";
import Popup from "../../../../shared/baseUI/Popup";

import "./ForgotPassword.scss";

const ForgotPassword = (props) => {
  const { forgotPasswordDetails, emailError, showPopup, forgotPasswordStatus, t } =
    props;

  useEffect(() => {
    ReactGA.pageview("/forgotpassword");
  }, []);

  const onChange = (e) => {
    const forgotpassworddata = { ...props.forgotPasswordDetails };
    const { name } = e.target;
    const { value } = e.target;
    forgotpassworddata[name] = value;
    props.checkEmailValidation({ value, emailExists: "" });
    props.handleForgotPasswordData({ forgotpassworddata });
  };

  const submitEmail = (e) => {
    e.preventDefault();
    props.forgotPasswordData(props.forgotPasswordDetails);
  };

  const renderPopUpContent = () => {
    if (
      props.forgotPasswordStatus.code === "" &&
      props.forgotPasswordStatus.message === ""
    ) {
      return props.t("CLIENT_PLATFORM_FORGOTPWD_POPUP_TEXT_SUCCESS");
    }

    if (props.forgotPasswordStatus.message !== "") {
      if (props.forgotPasswordStatus.code === 404) {
        return props.t("CLIENT_PLATFORM_FORGOTPWD_POPUP_TEXT_ERROR_NOT_REGISTERED");
      }
      if (props.forgotPasswordStatus.code === 403) {
        return props.t(
          "CLIENT_PLATFORM_FORGOT_PASSWORD_INACTIVATE_CONTACT_POP_UP_CONTENT"
        );
      }
      return props.forgotPasswordStatus.message;
    }
  };

  const renderFooter = () => {
    return (
      <NavLink to="/login" className="mx-auto" onClick={props.handlePopUp}>
        <Button
          className="btn btn-primary btn-sm btn-blue d-block text-center ok-btn"
          name="OK"
        />
      </NavLink>
    );
  };

  const renderPopUpTitle = () => {
    if (forgotPasswordStatus.code !== "") {
      if (forgotPasswordStatus.code === 403) {
        return t("CLIENT_PLATFORM_FORGOT_PASSWORD_INACTIVATE_CONTACT_POP_UP_TITLE");
      }
      return t("CLIENT_PLATFORM_FORGOTPWD_POPUP_TITLE_ERROR_NOT_REGISTERED");
    }

    return t("CLIENT_PLATFORM_FORGOTPWD_POPUP_TITLE_SUCCESS");
  };

  return (
    <form>
      <TextInput
        type="email"
        placeholder={t("CLIENT_PLATFORM_REGISTRATION_FIELD_EMAIL_PLACEHOLDER")}
        inputClass={
          emailError ? "form-input w-100 border border-danger" : "form-input w-100"
        }
        divClass="form-field mt-3"
        labelClass="form-label required"
        labelName={t("CLIENT_PLATFORM_FORGOTPWD_FIELD_EMAIL")}
        required
        id="email"
        name="email"
        value={forgotPasswordDetails.email}
        onChange={(e) => onChange(e)}
        error={emailError}
        errorMsg={t("CLIENT_PLATFORM_ERROR_MESSAGE_EMAIL_FIELD_WRONG_FORMAT")}
      />
      <div className="login-footer pt-2">
        <Button
          className="btn btn-primary btn-blue d-block m-auto"
          id="send-password"
          onClick={(e) => submitEmail(e)}
          name={t("CLIENT_PLATFORM_FORGOTPWD_BUTTON_SEND_PWD")}
          disabled={!!emailError}
        />
      </div>
      {showPopup && (
        <Popup
          modalParentClass="modal-parent forgot-password-popup"
          modalClass="modal-box"
          title={renderPopUpTitle()}
          name="forgotPassword"
          content={renderPopUpContent()}
          modalFooter={forgotPasswordStatus.code !== "" ? "" : renderFooter()}
          id="forgotPasswordPopup"
          onClose={props.handlePopUp}
        />
      )}
    </form>
  );
};

export default withTranslation()(ForgotPassword);
