import { takeLatest, put, call } from "redux-saga/effects";

import {
  GET_VIEW_DATA,
  GET_REQUEST_LISTING_DATA,
  GET_QUOTES,
} from "./SiteOverview.constants";
import {
  getViewDataAPI,
  getLeadViewDataAPI,
  getRequestListingAPI,
  getLeadRequestListingAPI,
  getQuotesAPI,
} from "./SiteOverview.api";

function* getViewData(action) {
  try {
    const userInfo = localStorage.getItem("userInfo");
    let userStatus;
    if (userInfo && JSON.parse(userInfo)) {
      userStatus = JSON.parse(userInfo).status;
    }
    const viewDataAPI =
      parseInt(userStatus, 10) === 3 ? getLeadViewDataAPI : getViewDataAPI;
    const data = yield call(viewDataAPI, action.payload);
    yield put({ type: GET_VIEW_DATA.SUCCESS, data });
  } catch (error) {
    const data = {
      code: error?.response?.status,
      data: error?.response?.data.errors,
      message: error?.response?.data.message,
    };
    yield put({ type: GET_VIEW_DATA.ERROR, data });
  }
}

function* getRequestListing(action) {
  let { payload } = action;
  if (Object.prototype.hasOwnProperty.call(payload, "pageNum")) {
    payload = {
      id: action.payload.id,
      pageNum: payload.pageNum,
    };
  } else payload = action.payload;

  try {
    const userInfo = localStorage.getItem("userInfo");
    let userStatus;
    if (userInfo && JSON.parse(userInfo)) {
      userStatus = JSON.parse(userInfo).status;
    }
    const requestListingAPI =
      parseInt(userStatus, 10) === 3
        ? getLeadRequestListingAPI
        : getRequestListingAPI;
    const data = yield call(requestListingAPI, payload);
    yield put({ type: GET_REQUEST_LISTING_DATA.SUCCESS, data });
  } catch (error) {
    const data = {
      code: error?.response?.status,
      data: error?.response?.data.errors,
      message: error?.response?.data.message,
    };
    yield put({ type: GET_REQUEST_LISTING_DATA.ERROR, data });
  }
}

function* getQuotes(action) {
  let { payload } = action;
  if (Object.prototype.hasOwnProperty.call(payload, "pageNum")) {
    payload = {
      id: action.payload.id,
      pageNum: payload.pageNum,
    };
  } else payload = action.payload;

  try {
    const data = yield call(getQuotesAPI, payload);
    yield put({ type: GET_QUOTES.SUCCESS, data });
  } catch (error) {
    let data = error.message;
    if (error.response) {
      data = {
        code: error?.response?.status,
        data: error?.response?.data.errors,
        message: error?.response?.data.message,
      };
    }
    yield put({ type: GET_QUOTES.ERROR, data });
  }
}

export function* getViewDataSaga() {
  yield takeLatest(GET_VIEW_DATA.LOADING, getViewData);
}

export function* getRequestListingSaga() {
  yield takeLatest(GET_REQUEST_LISTING_DATA.LOADING, getRequestListing);
}

export function* getQuotesSaga() {
  yield takeLatest(GET_QUOTES.LOADING, getQuotes);
}
