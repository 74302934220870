import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import TextInput from "../../../shared/baseUI/FloatingTextInput";

import "./ContactDetailsForm.scss";

const ContactDetailsForm = (props) => {
  const { t, fields, onFormUpdate, titleRef } = props;

  const phoneNumberFormat = /^(?:[0-9 .\-+])*$/;

  const getValidationInfo = (value, emptyError, formatError) => {
    const name = {};
    name.value = value;
    name.error = "";
    name.required = true;
    if (value === "") {
      name.error = emptyError;
    }
    if (/\d/.test(value)) {
      name.error = formatError;
    }
    return name;
  };

  const validateField = (name, value) => {
    if (name === "first_name") {
      return getValidationInfo(
        value,
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_PERSONAL_FORM_PERSONAL_FIRST_NAME_MISSING_ERROR",
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_PERSONAL_FORM_PERSONAL_FIRST_NAME_FORMAT_ERROR"
      );
    }

    if (name === "last_name") {
      return getValidationInfo(
        value,
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_PERSONAL_FORM_PERSONAL_LAST_NAME_MISSING_ERROR",
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_PERSONAL_FORM_PERSONAL_LAST_NAME_FORMAT_ERROR"
      );
    }

    if (name === "email") {
      const email = {};
      const checkEmail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      email.value = value;
      email.error = "";
      email.required = true;
      if (value === "") {
        email.error =
          "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_EMAIL_FORM_EMAIL_MISSING_ERROR";
      } else if (!checkEmail.test(value)) {
        email.error =
          "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_EMAIL_FORM_EMAIL_FORMAT_ERROR";
      }
      return email;
    }

    if (name === "phone") {
      const phone = {};
      const phoneFormat = phoneNumberFormat;
      phone.value = value;
      phone.error = "";
      phone.required = true;
      if (value === "") {
        phone.error =
          "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_EMAIL_FORM_PHONE_MISSING_ERROR";
      } else if (
        value.length < 10 ||
        value.length > 14 ||
        !phoneFormat.test(value)
      ) {
        phone.error =
          "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_PERSONAL_FORM_PHONE_FORMAT_ERROR";
      }
      return phone;
    }

    if (name === "company_name") {
      return getValidationInfo(
        value,
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_PERSONAL_FORM_COMPANY_NAME_MISSING_ERROR",
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_PERSONAL_FORM_COMPANY_NAME_FORMAT_ERROR"
      );
    }

    return "";
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    const validField = validateField(name, value);
    if (validField) {
      onFormUpdate({ ...fields, [name]: validField });
    }
  };

  return (
    <div className="enquiry-form__wrapper">
      <h1 className="enquiry-form__title" ref={titleRef}>
        {t("CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_PERSONAL_FORM_TITLE")}
      </h1>
      <section>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <TextInput
              type="text"
              label={t(
                "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_PERSONAL_FORM_PERSONAL_FIRST_NAME_PLACEHOLDER"
              )}
              inputClass={
                fields.first_name && fields.first_name.error !== ""
                  ? "form-input w-100 border border-danger"
                  : "form-input w-100"
              }
              divClass="form-field"
              labelClass="form-label"
              labelName={t(
                "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_PERSONAL_FORM_PERSONAL_FIRST_NAME"
              )}
              required
              id="first_name"
              name="first_name"
              value={fields.first_name ? fields.first_name.value : ""}
              error={fields.first_name && fields.first_name.error !== ""}
              errorMsg={fields.first_name ? t(fields.first_name.error) : ""}
              onChange={(e) => onChange(e)}
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <TextInput
              type="text"
              label={t(
                "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_PERSONAL_FORM_PERSONAL_LAST_NAME_PLACEHOLDER"
              )}
              inputClass={
                fields.last_name && fields.last_name.error !== ""
                  ? "form-input w-100 border border-danger"
                  : "form-input w-100"
              }
              divClass="form-field"
              labelClass="form-label"
              labelName={t(
                "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_PERSONAL_FORM_PERSONAL_LAST_NAME"
              )}
              required
              id="last_name"
              name="last_name"
              value={fields.last_name ? fields.last_name.value : ""}
              error={fields.last_name && fields.last_name.error !== ""}
              errorMsg={fields.last_name ? t(fields.last_name.error) : ""}
              onChange={(e) => onChange(e)}
            />
          </div>
        </div>
      </section>
      <section>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <TextInput
              type="email"
              label={t(
                "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_EMAIL_FORM_EMAIL_PLACEHOLDER"
              )}
              inputClass={
                fields.email && fields.email.error !== ""
                  ? "form-input w-100 border border-danger"
                  : "form-input w-100"
              }
              divClass="form-field"
              labelClass="form-label required"
              labelName={t("CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_EMAIL_FORM_EMAIL")}
              required
              id="email"
              name="email"
              value={fields.email ? fields.email.value : ""}
              error={fields.email && fields.email.error !== ""}
              errorMsg={fields.email ? t(fields.email.error) : ""}
              onChange={(e) => onChange(e)}
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <TextInput
              type="text"
              label={t(
                "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_PERSONAL_FORM_PHONE_PLACEHOLDER"
              )}
              inputClass={
                fields.phone && fields.phone.error !== ""
                  ? "form-input w-100 border border-danger"
                  : "form-input w-100"
              }
              divClass="form-field"
              labelClass="form-label required"
              labelName={t("CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_EMAIL_FORM_PHONE")}
              required
              id="phone"
              name="phone"
              minLength={10}
              maxlength={14}
              value={fields.phone ? fields.phone.value : ""}
              error={fields.phone && fields.phone.error !== ""}
              errorMsg={fields.phone ? t(fields.phone.error) : ""}
              onChange={(e) => onChange(e)}
            />
          </div>
        </div>
      </section>
      <section>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <TextInput
              type="text"
              label={t(
                "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_PERSONAL_FORM_COMPANY_NAME_PLACEHOLDER"
              )}
              inputClass={
                fields.company_name && fields.company_name.error !== ""
                  ? "form-input w-100 border border-danger"
                  : "form-input w-100"
              }
              divClass="form-field"
              labelClass="form-label"
              labelName={t(
                "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_PERSONAL_FORM_COMPANY_NAME"
              )}
              required
              id="company_name"
              name="company_name"
              value={fields.company_name ? fields.company_name.value : ""}
              error={fields.company_name && fields.company_name.error !== ""}
              errorMsg={fields.company_name ? t(fields.company_name.error) : ""}
              onChange={(e) => onChange(e)}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

ContactDetailsForm.propTypes = {
  titleRef: PropTypes.shape({}),
  fields: PropTypes.shape({
    first_name: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.string,
      required: PropTypes.bool,
    }),
    last_name: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.string,
      required: PropTypes.bool,
    }),
    email: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.string,
      required: PropTypes.bool,
    }),
    phone: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.string,
      required: PropTypes.bool,
    }),
    company_name: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.string,
      required: PropTypes.bool,
    }),
  }).isRequired,
  onFormUpdate: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

ContactDetailsForm.defaultProps = {
  titleRef: null,
};

export default withTranslation()(ContactDetailsForm);
