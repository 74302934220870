import { takeLatest, put, call } from "redux-saga/effects";

import {
  CREATE_TRANSPORT_REQUEST,
  UPDATE_TRANSPORT_REQUEST,
} from "./TransportOnlyForm.constants";

import {
  createTransportRequestAPI,
  updateTransportRequestAPI,
} from "./TransportOnlyForm.api";

// Worker sagas
function* createTransportRequest(action) {
  const modifyData = { ...action.payload };
  delete modifyData.days;
  modifyData.selected_days = modifyData.selected_days.toString();
  modifyData.product = [modifyData.product];
  if (Object.prototype.hasOwnProperty.call(modifyData, "truck_per_day")) {
    delete modifyData.truck_per_day;
  }
  if (Object.prototype.hasOwnProperty.call(modifyData, "error")) {
    delete modifyData.error;
  }
  if (Object.prototype.hasOwnProperty.call(modifyData, "new")) {
    delete modifyData.new;
  }
  if (Object.prototype.hasOwnProperty.call(modifyData, "editData")) {
    delete modifyData.editData;
  }
  try {
    const data = yield call(createTransportRequestAPI, modifyData);
    yield put({ type: CREATE_TRANSPORT_REQUEST.SUCCESS, data });
  } catch (error) {
    yield put({ type: CREATE_TRANSPORT_REQUEST.ERROR, error });
  }
}

function* updateTransportRequest(action) {
  const modifyData = { ...action.payload };
  delete modifyData.days;
  modifyData.selected_days = modifyData.selected_days.toString();
  modifyData.product = [modifyData.product];

  if (Object.prototype.hasOwnProperty.call(modifyData, "truck_per_day")) {
    delete modifyData.truck_per_day;
  }
  if (Object.prototype.hasOwnProperty.call(modifyData, "error")) {
    delete modifyData.error;
  }
  if (Object.prototype.hasOwnProperty.call(modifyData, "new")) {
    delete modifyData.new;
  }
  if (Object.prototype.hasOwnProperty.call(modifyData, "editData")) {
    delete modifyData.editData;
  }
  try {
    const data = yield call(updateTransportRequestAPI, modifyData);
    yield put({ type: UPDATE_TRANSPORT_REQUEST.SUCCESS, data });
  } catch (error) {
    yield put({ type: UPDATE_TRANSPORT_REQUEST.ERROR, error });
  }
}

// Watcher sagas
export function* createTransportRequestSaga() {
  yield takeLatest(CREATE_TRANSPORT_REQUEST.LOADING, createTransportRequest);
}

export function* updateTransportRequestSaga() {
  yield takeLatest(UPDATE_TRANSPORT_REQUEST.LOADING, updateTransportRequest);
}
