import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";

import ViewSites from "../ViewSites";

import { createSite } from "../../../../../modules/JobsiteForm/JobsiteForm.actionCreators";
import { getSiteListingInactiveData } from "../../../../../modules/SitesList/SitesList.actionCreators";

import { storage } from "../../../../../store/dataStore/api";

const InActiveSites = (props) => {
  const { siteListingInactiveData, calloffOrderData, paginationData } = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    const { pathname } = window.location;

    if (pathname === "/sitesandenquiries") {
      storage();
      props.getSiteListingInactiveData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { pathname } = window.location;

    if (pathname === "/sitesandenquiries") {
      setData(siteListingInactiveData);
    }
    if (pathname === "/callofforder") {
      setData(calloffOrderData);
    }
  }, [siteListingInactiveData, calloffOrderData]);

  return (
    <div className="data-table">
      <ViewSites
        data={data}
        paginationData={paginationData}
        inActiveData
        createSite={createSite}
      />

      {data.length === 0 && props.phase === "LOADING" && (
        <div className="loader-wrapper">
          <PulseLoader color="#004c7f" />
        </div>
      )}
      {props.phase === "ERROR" && (
        <div className="error-wrapper">
          An Error occured while fetching the Job Site Lists
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    siteListingInactiveData: state.sitesList.siteListingInactiveData,
    calloffOrderData: state.dataStore.calloffOrderData,
    paginationData: state.sitesList.paginationData.jobsiteInactive,
    phase: state.sitesList.phase,
  };
};

const mapDispatchToProps = {
  getSiteListingInactiveData: () => getSiteListingInactiveData(),
  createSite: () => createSite(),
};

export default connect(mapStateToProps, mapDispatchToProps)(InActiveSites);
