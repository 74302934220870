import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import * as moment from "moment";
import PropTypes from "prop-types";
import ErrorPopUp from "../../../../shared/baseUI/ErrorPopUp";

import { storage } from "../../../../store/dataStore/api";

import TextInput from "../../../../shared/baseUI/TextInput";
import SelectInput from "../../../../shared/baseUI/CustomSelectBox";
import TextAreaInput from "../../../../shared/baseUI/TextAreaInput";
import DateRangePicker from "../../../../shared/baseUI/DateRangePicker";
import CustomDatePicker from "../../../../shared/baseUI/DatePicker";

import siteLogo from "../../../../assets/shared/marker-icon-blue.png";
import destinationLogo from "../../../../assets/shared/marker-icon3.png";

import {
  prepareDateDifference,
  getDaysBetweenDates,
  isEmpty,
} from "../../../../shared/utils/methods";

import "./TransportOnlyFormDetails.scss";

const TransportOnlyFormDetails = (props) => {
  const { selectedCountryId, data, weekDayList, productData, t } = props;

  const [transportOptions, setTransportOptions] = useState([]);
  const [showErrorPopup, setErrorPopupvisibility] = useState(false);
  const [apiError, setApiError] = useState("");
  const [fields, setFields] = useState({
    address: { value: "", error: "" },
    city: { value: "", error: "" },
    postal: { value: "", error: "" },
    service: { value: "", error: "" },
    address_destination: { value: "", error: "" },
    city_destination: { value: "", error: "" },
    postal_destination: { value: "", error: "" },
    product: { value: "", error: "" },
    quantity: { value: "", error: "" },
    unit: { value: "", error: "" },
    date_type: { value: "", error: "" },
    start_date: { value: "", error: "" },
    end_date: { value: "", error: "" },
    selected_days: { value: "", error: "" },
    comment: { value: "", error: "" },
  });

  useEffect(() => {
    if (!fields.hasOwnProperty("editData")) {
      props.createTransportRequestForm();
    }
    storage();
    const transportOptionsLocal = productData.filter((product) => product.id === 9);
    if (transportOptionsLocal.length === 0) {
      props.getProductData({ subCatId: 9, service: 1 });
    }
    const storedFields = localStorage.getItem("transportData");
    if (!isEmpty(data)) {
      updateFields(data);
    }
    if (isEmpty(data) && storedFields && JSON.parse(storedFields)) {
      updateFields(JSON.parse(storedFields));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let lng = "en";
    if (localStorage.getItem("userCountryId")) {
      const countryId = localStorage.getItem("userCountryId").toString();
      if (countryId === "1") {
        lng = "en";
      } else if (countryId === "2") {
        lng = "fr";
      } else if (countryId === "3") {
        lng = "pl";
      } else if (countryId === "6") {
        lng = "de";
      } else if (countryId === "8") {
        lng = "ch";
      }
    }
    props.i18n.changeLanguage(lng);
  }, [props.i18n]);

  useEffect(() => {
    const transportOptionsLocal = productData.filter((product) => product.id === 9);
    if (transportOptionsLocal.length > 0) {
      setTransportOptions(transportOptionsLocal[0].data);
    }
  }, [productData]);

  useEffect(() => {
    const errorData = data?.error?.data?.errors;
    const localFields = { ...fields };
    if (data?.error?.status && data?.error?.status !== 200) {
      setErrorPopupvisibility(true);
      setApiError(t(data?.error?.data?.message));
    }
    if (errorData && errorData.postal) {
      if (errorData.postal[0] === "validation.required") {
        localFields.postal.error = t(
          "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_SITE_POSCODE_MISSING_ERROR"
        );
      } else {
        localFields.postal.error = t(
          "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_SITE_POSCODE_FORMAT_ERROR"
        );
      }
    }
    if (errorData && errorData.postal_destination) {
      if (errorData.postal_destination[0] === "validation.required") {
        localFields.postal_destination.error = t(
          "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_SITE_POSCODE_MISSING_ERROR"
        );
      } else {
        localFields.postal_destination.error = t(
          "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_SITE_POSCODE_FORMAT_ERROR"
        );
      }
    }
    if (errorData && errorData.city) {
      if (errorData.city[0] === "validation.required") {
        localFields.city.error = t(
          "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_SITE_CITY_MISSING_ERROR"
        );
      } else {
        localFields.city.error = t(
          "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_SITE_CITY_FORMAT_ERROR"
        );
      }
    }
    if (errorData && errorData.city_destination) {
      if (errorData.city_destination[0] === "validation.required") {
        localFields.city_destination.error = t(
          "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_DESTINATION_CITY_MISSING_ERROR"
        );
      } else {
        localFields.city_destination.error = t(
          "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_SITE_CITY_FORMAT_ERROR"
        );
      }
    }
    if (errorData && errorData.product) {
      if (errorData.product[0] === "validation.required") {
        localFields.product.error = t(
          "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_TRANSPORT_INFO_TRANSPORT_MISSING_ERROR"
        );
      }
    }
    if (errorData && errorData.quantity) {
      if (errorData.quantity[0] === "validation.required") {
        localFields.quantity.error = t(
          "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_TRANSPORT_INFO_QUANTITY_MISSING_ERROR"
        );
      }
    }
    if (errorData && errorData.unit) {
      if (errorData.unit[0] === "validation.required") {
        localFields.unit.error = t(
          "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_UNIT_MISSING_ERROR"
        );
      }
    }
    setFields(localFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.error, t]);

  const updateFields = (formData) => {
    const localFields = { ...fields };
    localFields.address.value = formData.address;
    localFields.city.value = formData.city;
    localFields.postal.value = formData.postal;
    localFields.service.value = formData.service;
    localFields.address_destination.value = formData.address_destination;
    localFields.city_destination.value = formData.city_destination;
    localFields.postal_destination.value = formData.postal_destination;
    localFields.product.value = formData.product;
    localFields.quantity.value = formData.quantity;
    localFields.unit.value = formData.unit;
    localFields.date_type.value = formData.date_type;
    localFields.start_date.value = formData.start_date;
    localFields.end_date.value = formData.end_date;
    localFields.selected_days.value = formData.selected_days;
    localFields.comment.value = formData.comment ? formData.comment : "";
    if (formData.hasOwnProperty("id")) {
      localFields.id = { value: formData.id, error: "" };
    }
    if (formData.hasOwnProperty("new")) {
      localFields.new = { value: formData.new, error: "" };
    }
    if (formData.hasOwnProperty("editData")) {
      localFields.editData = { value: formData.editData, error: "" };
    }
    if (formData.days) {
      localFields.days = { value: formData.days, error: "" };
    }
    setFields(localFields);

    // when page is refreshed - check if data is empty.
    // when request is edited - check for editData.
    if (isEmpty(data) || data.editData) {
      saveDataToStore(localFields);
    }
  };

  const getEventName = (e) => {
    if (e.target) {
      if (e.target.name !== "") {
        return e.target.name;
      }
      return "";
    }
    return e.name;
  };

  const onChange = (e, i, id) => {
    const name = getEventName(e);
    const value = e.target ? e.target.value : e.value;
    const checked = e.target?.checked;
    const localFields = { ...fields };

    if (name === "city" || name === "city_destination") {
      localFields[name] = { value, error: isCityValid(value) };
    }

    if (name === "postal_destination" || name === "postal") {
      localFields[name] = {
        value,
        error: isValidPostalCode(value, parseInt(selectedCountryId, 10)),
      };

      if (
        (name === "postal" || name === "postal_destination") &&
        parseInt(selectedCountryId, 10) === 3
      ) {
        let str = value;
        str = str.replace("-", "");
        if (str.length > 2) {
          str = `${str.substring(0, 2)}-${str.substring(2)}`;
        }
        const isError = isValidPostalCode(str, parseInt(selectedCountryId, 10));
        localFields[name] = { value: str, error: isError };
      }
    }

    if (name === "selected_days") {
      if (checked) {
        localFields[name] = {
          value: localFields.selected_days.value.concat(id),
          error: "",
        };
      } else {
        localFields[name] = {
          value: localFields.selected_days.value.filter((x) => x !== id),
          error: "",
        };
      }
    }

    if (name === "service" || name === "date_type") {
      if (id === 1) {
        localFields[name] = {
          value: 1,
          error: "",
        };
      } else {
        localFields[name] = {
          value: 2,
          error: "",
        };
      }
    }
    if (name === "address" || name === "address_destination" || name === "comment") {
      localFields[name] = { value, error: "" };
    }
    if (name === "product") {
      localFields[name] = {
        value,
        error: "",
      };
      if (!value) {
        localFields[name] = {
          value,
          error: t(
            "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_TRANSPORT_INFO_TRANSPORT_MISSING_ERROR"
          ),
        };
      }
    }
    if (name === "quantity") {
      localFields[name] = {
        value,
        error: "",
      };
      if (!value) {
        localFields[name] = {
          value,
          error: t(
            "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_TRANSPORT_INFO_QUANTITY_MISSING_ERROR"
          ),
        };
      } else if (!/^\d+$/.test(value)) {
        localFields[name].error = t(
          "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_TRANSPORT_INFO_QUANTITY_WRONG_VALUE_ERROR"
        );
      }
    }
    if (name === "unit") {
      localFields[name] = {
        value,
        error: "",
      };
      if (!value) {
        localFields[name] = {
          value,
          error: t("CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_UNIT_MISSING_ERROR"),
        };
      }
    }
    setFields({ ...localFields });
    saveDataToStore(localFields);
  };

  const isCityValid = (city, name) => {
    if (city.length === 0) {
      if (name === "city") {
        return t(
          "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_SITE_CITY_MISSING_ERROR"
        );
      }
      return t(
        "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_DESTINATION_CITY_MISSING_ERROR"
      );
    }
    // check for number.
    if (/\d/.test(city)) {
      return t("CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_SITE_CITY_FORMAT_ERROR");
    }
    return "";
  };

  const isValidPostalCode = (postalCode, countryIdVal) => {
    if (postalCode === "") {
      return t(
        "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_SITE_POSCODE_MISSING_ERROR"
      );
    }
    if (parseInt(countryIdVal, 10) === 1) {
      // en
      if (postalCode.length < 1 || postalCode.length > 8) {
        return t(
          "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_SITE_POSCODE_FORMAT_ERROR"
        );
      }
    } else if (
      parseInt(countryIdVal, 10) === 2 ||
      parseInt(countryIdVal, 10) === 6
    ) {
      // fr, Germany
      if (postalCode.length !== 5) {
        return t(
          "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_SITE_POSCODE_FORMAT_ERROR"
        );
      }
      if (!/^\d+$/.test(postalCode)) {
        return t(
          "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_SITE_POSCODE_FORMAT_ERROR"
        );
      }
    } else if (parseInt(countryIdVal, 10) === 3) {
      // pl
      if (postalCode.length !== 6) {
        return t(
          "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_SITE_POSCODE_FORMAT_ERROR"
        );
      }
    } else if (parseInt(countryIdVal, 10) === 8) {
      // ch
      if (postalCode.length !== 4) {
        return t(
          "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_SITE_POSCODE_FORMAT_ERROR"
        );
      }
    }

    return "";
  };

  const onDateChange = (e) => {
    const date = new Date(e);
    const localFields = { ...fields };
    if (date.toDateString() !== "Invalid Date") {
      const dateField = isDateValid(e);
      if (dateField) {
        localFields.selected_days = { value: [], errors: "" };
        localFields.days = {
          value: localFields.selected_days.value,
          errors: "",
        };

        if (localFields.date_type.value === 1) {
          const dateObj = moment(dateField.value);
          const now = moment();
          if (now < dateObj) {
            localFields.start_date = { value: dateField.value, errors: "" };
            localFields.selected_days = {
              value: moment(dateField.value).locale("en").format("dddd"),
              errors: "",
            };
            localFields.end_date = { value: dateField.value, errors: "" };
          } else {
            localFields.end_date = {
              value: localFields.start_date.value,
              errors: "",
            };
          }
        }
        setFields({ ...localFields });
        saveDataToStore(localFields);
      }
    }
  };

  const isDateValid = (value) => {
    const start_date = {};
    const date = value.getDate() < 10 ? `0${value.getDate()}` : value.getDate();
    const month =
      value.getMonth() + 1 < 10 ? `0${value.getMonth() + 1}` : value.getMonth() + 1;
    start_date.value = `${value.getFullYear()}-${month}-${date}`;
    start_date.error = "";
    if (value === "") {
      start_date.error = "CLIENT_PLATFORM_SITE_FORM_SITE_START_DATE_MISSING_ERROR";
    }
    return start_date;
  };

  const handleDateRangeChange = (dateRange) => {
    const localFields = { ...fields };
    const startDate = moment(dateRange.startDate).format("YYYY-MM-DD");
    const endDate = moment(dateRange.endDate).format("YYYY-MM-DD");
    const startDay = moment(dateRange.startDate).locale("en").format("dddd");
    const endDay = moment(dateRange.endDate).locale("en").format("dddd");

    if (startDate !== endDate) {
      const days = prepareDateDifference(startDate, endDate);

      localFields.start_date = { value: startDate, errors: "" };
      localFields.end_date = { value: endDate, errors: "" };
      if (days > 5) {
        localFields.selected_days = {
          value: props.weekDayList.slice(0, 5).map((day) => day.value),
          errors: "",
        };
        localFields.days = {
          value: getDaysBetweenDates(days, startDay, endDay).map((day) => day.value),
          errors: "",
        };
      } else {
        localFields.selected_days = {
          value: getDaysBetweenDates(days, startDay, endDay).map((day) => day.value),
          errors: "",
        };
        localFields.days = {
          value: localFields.selected_days.value,
          errors: "",
        };
        localFields.selected_days = {
          value: localFields.selected_days.value.filter((day) => {
            return day !== "Saturday" && day !== "Sunday";
          }),
          errors: "",
        };
      }
      setFields({ ...localFields });
      saveDataToStore(localFields);
    }
  };

  const saveDataToStore = (formData) => {
    const updatedData = { ...data };
    updatedData.address = formData.address.value;
    updatedData.city = formData.city.value;
    updatedData.postal = formData.postal.value;
    updatedData.service = formData.service.value;
    updatedData.address_destination = formData.address_destination.value;
    updatedData.postal_destination = formData.postal_destination.value;
    updatedData.city_destination = formData.city_destination.value;
    updatedData.start_date = formData.start_date.value;
    updatedData.end_date = formData.end_date.value;
    updatedData.selected_days = formData.selected_days.value;
    if (formData.days?.value) {
      updatedData.days = formData.days.value;
    }
    updatedData.date_type = formData.date_type.value;
    updatedData.product = formData.product.value;
    updatedData.quantity = formData.quantity.value;
    updatedData.unit = formData.unit.value;
    updatedData.comment = formData.comment.value;

    if (formData.hasOwnProperty("editData")) {
      updatedData.editData = formData.editData.value;
    }
    if (formData.hasOwnProperty("id")) {
      updatedData.id = formData.id.value;
    }
    if (formData.hasOwnProperty("new")) {
      updatedData.new = formData.new.value;
    }

    localStorage.setItem("transportData", JSON.stringify(updatedData));

    props.handleTransportData({ updatedData });
  };

  const getPostalCodePattern = () => {
    if (parseInt(selectedCountryId, 10) === 3) {
      // Poland
      return "^[0-9-]*$";
    }
    if (parseInt(selectedCountryId, 10) === 1) {
      // England
      return "^(?!.,-*$)[a-zA-Z0-9s_ ]+$";
    }
    // other countries
    return "[0-9]*";
  };

  const closePopup = () => {
    setErrorPopupvisibility(false);
    setApiError("");
  };

  return (
    <form className="transport-form">
      <div>
        <h6 className="transport-form__section-title">
          <img
            src={siteLogo}
            alt="Site logo"
            className="transport-form__section-icon"
          />
          {t("CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_SITE_INFO_TITLE")}
        </h6>
        <div className="row">
          <div className="col-12">
            <TextAreaInput
              type="text"
              placeholder={t(
                "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_SITE_INFO_ADDRESS_PLACEHOLDER"
              )}
              inputClass="transport-form__input input-height"
              divClass="transport-form__input-wrapper pb-0 mb-3"
              labelClass="transport-form__input-label"
              labelName={t(
                "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_SITE_INFO_ADDRESS"
              )}
              required={false}
              rows={5}
              columns={100}
              id="address"
              name="address"
              value={fields.address.value}
              onChange={(e) => onChange(e)}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6">
            <TextInput
              type="text"
              placeholder={t(
                "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_SITE_INFO_CITY_PLACEHOLDER"
              )}
              inputClass={
                fields.city.error
                  ? "transport-form__input border border-danger"
                  : "transport-form__input"
              }
              divClass="transport-form__input-wrapper"
              labelClass="transport-form__input-label required"
              labelName={t(
                "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_SITE_INFO_CITY"
              )}
              required
              error={!!fields.city.error}
              errorMsg={fields.city.error}
              id="city"
              name="city"
              value={fields.city.value}
              onChange={(e) => onChange(e)}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6">
            <TextInput
              type="text"
              placeholder={t(
                "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_SITE_INFO_POSTAL_PLACEHOLDER"
              )}
              inputClass={
                fields.postal.error
                  ? "transport-form__input border border-danger"
                  : "transport-form__input"
              }
              divClass="transport-form__input-wrapper"
              labelClass="transport-form__input-label required"
              labelName={t(
                "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_SITE_INFO_POSTAL"
              )}
              required
              error={!!fields.postal.error}
              errorMsg={fields.postal.error}
              id="postal"
              name="postal"
              value={fields.postal.value}
              onChange={(e) => onChange(e)}
              pattern={getPostalCodePattern()}
            />
          </div>
          <div className="col-12">
            <label className="transport-form__input-label required mb-2">
              {`${t(
                "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_SITE_INFO_SERVICE"
              )} : `}
            </label>
          </div>
          <div className="col-4">
            <label className="transport-form__radio-btn">
              {t(
                "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_SITE_INFO_SERVICE_DEPARTURE"
              )}
              <input
                type="radio"
                name="service"
                checked={fields.service.value === 1}
                onChange={(e, i, id) => onChange(e, i, (id = 1))}
              />
              <span className="checkmark" />
            </label>
          </div>
          <div className="col-4 transport-form__service-type-wrapper">
            <label className="transport-form__radio-btn">
              {t(
                "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_SITE_INFO_SERVICE_ARRIVAL"
              )}
              <input
                type="radio"
                name="service"
                checked={fields.service.value === 2}
                onChange={(e, i, id) => onChange(e, i, (id = 2))}
              />
              <span className="checkmark" />
            </label>
          </div>
        </div>
      </div>
      <div>
        <h6 className="d-flex">
          <img
            src={destinationLogo}
            alt="site"
            width="14"
            height="22"
            className="mr-2"
          />
          {!fields.service.value &&
            `${t(
              "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_DESTINATION_INFO_TITLE"
            )} : `}
          {fields.service.value === 1 &&
            `${t(
              "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_DESTINATION_INFO_TITLE"
            )} : `}
          {fields.service.value === 2 &&
            `${t(
              "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_DEPARTURE_INFO_TITLE"
            )} : `}
        </h6>
        <div className="row">
          <div className="col-12">
            <TextAreaInput
              type="text"
              placeholder={t(
                "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_DESTINATION_INFO_ADDRESS_PLACEHOLDER"
              )}
              inputClass="transport-form__input input-height"
              divClass="transport-form__input-wrapper pb-0 mb-3"
              labelClass="transport-form__input-label"
              labelName={t(
                "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_DESTINATION_INFO_ADDRESS"
              )}
              required={false}
              rows={5}
              columns={100}
              id="address_destination"
              name="address_destination"
              value={fields.address_destination.value}
              onChange={(e) => onChange(e)}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6">
            <TextInput
              type="text"
              placeholder={t(
                "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_DESTINATION_INFO_CITY_PLACEHOLDER"
              )}
              divClass="transport-form__input-wrapper"
              labelClass="transport-form__input-label required"
              labelName={t(
                "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_DESTINATION_INFO_CITY"
              )}
              inputClass={
                fields.city_destination.error
                  ? "transport-form__input border border-danger"
                  : "transport-form__input"
              }
              error={!!fields.city_destination.error}
              errorMsg={fields.city_destination.error}
              required
              id="city_destination"
              name="city_destination"
              value={fields.city_destination.value}
              onChange={(e) => onChange(e)}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6">
            <TextInput
              type="text"
              placeholder={t(
                "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_DESTINATION_INFO_POSTAL_PLACEHOLDER"
              )}
              inputClass={
                fields.postal_destination.error
                  ? "transport-form__input border border-danger"
                  : "transport-form__input"
              }
              divClass="transport-form__input-wrapper"
              labelClass="transport-form__input-label required"
              labelName={t(
                "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_DESTINATION_INFO_POSTAL"
              )}
              required
              error={!!fields.postal_destination.error}
              errorMsg={fields.postal_destination.error}
              id="postal_destination"
              name="postal_destination"
              value={fields.postal_destination.value}
              onChange={(e) => onChange(e)}
              pattern={getPostalCodePattern()}
            />
          </div>
        </div>
      </div>
      <div>
        <h6>
          {`${t(
            "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_TRANSPORT_INFO_TITLE"
          )} : `}
        </h6>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-4">
            <SelectInput
              value={fields.product.value}
              divClass="transport-form__input-wrapper pb-0 mb-3"
              labelClass="transport-form__input-label required"
              labelName={t(
                "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_TRANSPORT_INFO_TRANSPORT"
              )}
              inputClass={
                fields.product.error
                  ? "transport-form__input border border-danger"
                  : "transport-form__input"
              }
              error={!!fields.product.error}
              errorMsg={fields.product.error}
              required
              id="product"
              name="product"
              onChange={(e) => onChange(e)}
              defaultOption={t(
                "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_TRANSPORT_INFO_TRANSPORT_PLACEHOLDER"
              )}
              options={transportOptions}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-8">
            <div className="row">
              <div className="col-6">
                <TextInput
                  type="text"
                  placeholder={t(
                    "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_TRANSPORT_INFO_QUANTITY_PLACEHOLDER"
                  )}
                  divClass="transport-form__input-wrapper pb-0 mb-3"
                  labelClass="transport-form__input-label required"
                  labelName={t(
                    "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_TRANSPORT_INFO_QUANTITY"
                  )}
                  required
                  inputClass={
                    fields.quantity.error
                      ? "transport-form__input border border-danger"
                      : "transport-form__input"
                  }
                  error={!!fields.quantity.error}
                  errorMsg={fields.quantity.error}
                  id="quantity"
                  name="quantity"
                  value={fields.quantity.value}
                  onChange={(e) => onChange(e)}
                />
              </div>
              <div className="col-6">
                <SelectInput
                  value={fields.unit.value}
                  divClass="transport-form__input-wrapper pb-0 mb-3"
                  labelClass="transport-form__input-label required"
                  labelName={t(
                    "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_TRANSPORT_INFO_UNIT"
                  )}
                  required
                  inputClass={
                    fields.unit.error
                      ? "transport-form__input border border-danger"
                      : "transport-form__input"
                  }
                  error={!!fields.unit.error}
                  errorMsg={fields.unit.error}
                  id="unit"
                  name="unit"
                  onChange={(e) => onChange(e)}
                  defaultOption={t(
                    "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_TRANSPORT_INFO_UNIT_PLACEHOLDER"
                  )}
                  options={[
                    { label: t("M3"), value: "M3" },
                    { label: t("TONS"), value: "TONS" },
                    { label: t("LOADS"), value: "LOADS" },
                    { label: t("UNIT"), value: "UNIT" },
                    { label: t("PACKAGE"), value: "PACKAGE" },
                    { label: t("DAY"), value: "DAY" },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h6 className="transport-form__input-label required">
          {t("CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_DATES_INFO_TITLE")}
        </h6>
        <div className="row">
          <div className="col-xs-6 col-sm-4">
            <label className="transport-form__radio-btn">
              {t("CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_DATES_SINGLE_DATE")}
              <input
                type="radio"
                name="date_type"
                checked={fields.date_type.value === 1}
                onChange={(e, i, id) => onChange(e, i, (id = 1))}
              />
              <span className="checkmark" />
            </label>
          </div>
          <div className="col-xs-6 col-sm-8">
            <label className="transport-form__radio-btn">
              {t("CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_DATES_DEFINED_PERIOD")}
              <input
                type="radio"
                name="date_type"
                checked={fields.date_type.value === 2}
                onChange={(e, i, id) => {
                  onChange(e, i, (id = 2));
                }}
              />
              <span className="checkmark" />
            </label>
          </div>
          {fields.date_type.value === 1 && (
            <div className="col-6">
              <CustomDatePicker
                countryId={parseInt(props.selectedCountryId, 10)}
                placeholder={t(
                  "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_DATES_SINGLE_DATE_PLACEHOLDER"
                )}
                divClass="transport-form__input-wrapper"
                labelClass="transport-form__input-label"
                labelName=""
                inputClass="transport-form__input"
                calendarIconClass="calendar-icon"
                required
                id="start_date"
                name="start_date"
                value={fields.start_date.value}
                onChange={(e) => onDateChange(e)}
                dateFormat="dd-MM-yyyy"
                minDate={
                  new Date().getHours() > 12
                    ? moment(moment.now()).add(2, "days").format("YYYY-MM-DD")
                    : moment(moment.now()).add(1, "days").format("YYYY-MM-DD")
                }
              />
            </div>
          )}
          {fields.date_type.value === 2 && (
            <div className="col-12">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-7">
                  <DateRangePicker
                    handleDate={(item) => handleDateRangeChange(item)}
                    editData={!!fields.hasOwnProperty("editData")}
                    startDate={fields.start_date.value}
                    endDate={fields.end_date.value}
                  />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-5 d-flex justify-content-center">
                  <ul className="transport-form__weeks-list">
                    {weekDayList.map((x, i) => (
                      <li key={i}>
                        <label
                          className={`custom-checkbox ${
                            fields.selected_days.value.length !== 0 &&
                            (fields.selected_days.value.length <= 5 ||
                              (fields.selected_days.value.includes("Sunday") &&
                                !fields.selected_days.value.length === 7)) &&
                            fields.hasOwnProperty("days") &&
                            !fields.days.value.includes(x.value)
                              ? "disabled"
                              : ""
                          }`}
                        >
                          {t(x.label)}
                          <input
                            type="checkbox"
                            name="selected_days"
                            value={x}
                            checked={
                              !!(
                                fields.selected_days.value.length !== 0 &&
                                fields.selected_days.value.includes(x.value)
                              )
                            }
                            onChange={(e, i) => onChange(e, i, x.value)}
                          />

                          <span className="checkmark" />
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        <h6>
          {t("CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_COMMENT_INFO_TITLE")}
        </h6>
        <div className="row">
          <div className="col-12">
            <TextAreaInput
              placeholder={t(
                "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_COMMENT_FIELD_PLACEHOLDER"
              )}
              inputClass="transport-form__input transport-form__input-comment"
              divClass="transport-form__input-wrapper"
              labelClass="transport-form__input-label"
              labelName={t(
                "CLIENT_PLATFORM_TRANSPORT_ONLY_REQUEST_FORM_COMMENT_FIELD"
              )}
              required={false}
              id="comment"
              name="comment"
              value={fields.comment.value}
              rows={5}
              columns={100}
              onChange={(e) => onChange(e)}
            />
          </div>
        </div>
      </div>
      {showErrorPopup && (
        <ErrorPopUp
          title={t("KICK_OFF_ERROR_BOX_TITLE")}
          btnText={t("KICK_OFF_ERROR_BOX_OK")}
          message={apiError}
          onOkClick={closePopup}
        />
      )}
    </form>
  );
};

TransportOnlyFormDetails.propTypes = {
  selectedCountryId: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  weekDayList: PropTypes.array.isRequired,
  productData: PropTypes.array.isRequired,
  getProductData: PropTypes.func.isRequired,
  createTransportRequestForm: PropTypes.func.isRequired,
  handleTransportData: PropTypes.func.isRequired,
};

export default withTranslation()(TransportOnlyFormDetails);
