import React from "react";
import { withTranslation } from "react-i18next";
import Progress from "rsuite/Progress";
import PropTypes from "prop-types";

import LanguageSelection from "../../../shared/components/LanguageSelection";
import headerIcon from "../../../assets/shared/logo.png";
import "./Header.scss";

const Header = (props) => {
  const { progress, language, countryId, currentStep, t } = props;

  return (
    <div className="v3-header__container">
      <div className="v3-header__header-wrapper">
        <img src={headerIcon} alt="header" className="v3-header__logo" />
        <div className="v3-header__title-wrapper">
          <h2 className="v3-header__title">
            {t("CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_TITLE")}
          </h2>
          <LanguageSelection
            divClassName="select-language"
            userCountry={language}
            isLangChanged={{ id: String(countryId), updated: true }}
          />
        </div>
      </div>
      <div className="v3-header__step-container">
        <div className="v3-header__step">
          <div
            className={
              currentStep === 0
                ? "v3-header__step-count active"
                : "v3-header__step-count completed"
            }
          >
            1
          </div>
          <span className="v3-header__step-label">
            {t("CLIENT_PLATFORM_ONBOARDING_STEP_1")}
          </span>
        </div>
        <Progress.Line
          percent={progress}
          strokeColor="#1bace4"
          showInfo={false}
          strokeWidth={8}
        />
        <div className="v3-header__step">
          <div
            className={
              currentStep === 1
                ? "v3-header__step-count active"
                : "v3-header__step-count"
            }
          >
            2
          </div>
          <span className="v3-header__step-label">
            {t("CLIENT_PLATFORM_ONBOARDING_STEP_2")}
          </span>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  currentStep: PropTypes.number.isRequired,
  language: PropTypes.string.isRequired,
  countryId: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Header);
