import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Navbar from "../../shared/components/NavBar";
import ViewSitesList from "../../shared/components/ViewSitesList";

const CallOffOrder = (props) => {
  const { userId } = props;

  useEffect(() => {
    if (userId === null) {
      return <Redirect to="/login" />;
    }
  }, [userId]);

  return (
    <div className="container-fluid dashboard-container">
      <Navbar />
      <div id="content">
        <ViewSitesList />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId:
      localStorage.getItem("userId") !== "" ? localStorage.getItem("userId") : null,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CallOffOrder);
