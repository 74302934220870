import {
  ADD_NEW_JOBSITE,
  UPDATE_JOBSITE,
  HANDLE_SITE_FORM_DETAILS,
  GET_SITE_MASTER_LIST_DATA,
  HANDLE_EDIT_JOBSITE,
  CREATE_SITE_FORM,
} from "./JobsiteForm.constants";
import { GET_VIEW_DATA } from "../SiteOverview/SiteOverview.constants";

import { SELECT_CATEGORY_FORM } from "../InquiryForm/InquiryForm.constants";

import { LEADS } from "../Dashboard/Dashboard.constants";

import { LOADING, SUCCESS, INIT } from "../../shared/utils/constant";

import {
  prepareDocumentList,
  prepareSiteMasterData,
  generateSiteHours,
} from "../../shared/utils/methods";

const initialState = {
  phase: INIT,
  siteHrsOptions: generateSiteHours(0, 24, 15),
  newJobsiteLoading: false,
  jobSiteFormData: {
    name: "",
    opening_time: "",
    closing_time: "",
    start_date: "",
    end_date: "",
    is_call_for_tender: "",
    related_contact: [],
    postal: "",
    city: "",
    address: "",
    document: [],
    comment: "",
    initialRow: {
      contact_id: "",
      role: "",
    },
  },
  relatedContact: {
    jobsite: {
      related_contact: [],
      document: [],
      deleted_related_contact: [],
    },
    enquiry: {
      related_contact: [],
      document: [],
      deleted_related_contact: [],
    },
  },
  siteMasterData: [],
  siteMasterDataRaw: [],
  apiUnAuthenticated: false,
  leadsList: [],
  leadRequestList: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_NEW_JOBSITE.LOADING:
    case UPDATE_JOBSITE.LOADING: {
      return {
        ...state,
        phase: LOADING,
        newJobsiteLoading: true,
        apiUnAuthenticated: false,
      };
    }
    case ADD_NEW_JOBSITE.SUCCESS: {
      let leadInfo = {};
      if (action.data?.lead?.leadInfo) {
        leadInfo = {
          ...action.data.jobsite,
          initialRow: state.jobSiteFormData.initialRow,
          error: {},
          related_contact: [],
          leadDocuments: action.data.lead.document,
          leadInfo: action.data.lead.leadInfo,
        };
      } else if (action.data.lead) {
        leadInfo = {
          ...action.data.lead,
          initialRow: state.jobSiteFormData.initialRow,
          error: {},
          related_contact: [],
        };
      }
      return {
        ...state,
        phase: SUCCESS,
        newJobsiteLoading: false,
        jobSiteFormData: action.data.lead
          ? leadInfo
          : {
              ...action.data.jobsite,
              initialRow: state.jobSiteFormData.initialRow,
              error: {},
              related_contact: [],
            },
        relatedContact: {
          ...state.relatedContact,
          jobsite: {
            ...state.relatedContact.jobsite,
            related_contact: action.data.lead
              ? action.data.lead.related_contact
              : action.data.jobsite.related_contact,
            document: action.data.lead
              ? action.data.lead.document
              : action.data.jobsite.document,
          },
        },
      };
    }
    case ADD_NEW_JOBSITE.ERROR: {
      return {
        ...state,
        newJobsiteLoading: false,
        jobSiteFormData: {
          ...state.jobSiteFormData,
          error: action.error.response,
        },
      };
    }
    case UPDATE_JOBSITE.SUCCESS: {
      let { data } = action;
      if (action.data === "") {
        data = "204";
      } else if (action.data.lead) {
        data = { jobsite: action.data.lead, success: true };
      }
      return {
        ...state,
        phase: SUCCESS,
        newJobsiteLoading: false,
        editJobSiteStatus: data,
      };
    }
    case UPDATE_JOBSITE.ERROR: {
      return {
        ...state,
        newJobsiteLoading: false,
        jobSiteFormData: {
          ...state.jobSiteFormData,
          error: action.error.response,
        },
      };
    }
    case HANDLE_SITE_FORM_DETAILS: {
      return {
        ...state,
        jobSiteFormData: {
          ...action.payload.updatedData,
          document: prepareDocumentList(action.payload.updatedData.document),
        },
      };
    }
    case GET_SITE_MASTER_LIST_DATA.LOADING:
      return {
        ...state,
        phase: LOADING,
        apiUnAuthenticated: false,
      };

    case GET_SITE_MASTER_LIST_DATA.SUCCESS: {
      return {
        ...state,
        siteMasterData: prepareSiteMasterData(action.data),
        siteMasterDataRaw: action.data,
      };
    }
    case GET_SITE_MASTER_LIST_DATA.ERROR: {
      return {
        ...state,
        siteMasterData: [],
        siteMasterDataRaw: [],
        apiUnAuthenticated: action.data.code === 401,
      };
    }

    case HANDLE_EDIT_JOBSITE: {
      const data = state.jobSiteFormData;
      const openingTimeLocal = data.opening_time ? data.opening_time.split(":") : [];
      const opening_time =
        openingTimeLocal.length >= 2
          ? `${openingTimeLocal[0]}:${openingTimeLocal[1]}`
          : "";
      const closingTimeLocal = data.closing_time ? data.closing_time.split(":") : [];
      const closing_time =
        closingTimeLocal.length >= 2
          ? `${closingTimeLocal[0]}:${closingTimeLocal[1]}`
          : "";
      const jobsiteData = {
        ...state,
        jobSiteFormData: {
          edit: true,
          id: data.id,
          name: data.name,
          phase_id: data.phase_id,
          request_priority: data.request_priority,
          opening_time,
          closing_time,
          start_date: data.start_date,
          end_date: data.end_date,
          duration: data.duration,
          is_call_for_tender: data.is_call_for_tender,
          postal: data.postal,
          city: data.city,
          latitude: data.latitude,
          longitude: data.longitude,
          address: data.address,
          document: data.document,
          user_comment: data.jobsite_comment
            ? data.jobsite_comment
            : data.user_comment,
        },
        editJobSiteStatus: "",
      };
      let jobsiteDataFromLead = {};
      if (
        action.payload &&
        action.payload.leadInfo &&
        !Array.isArray(action.payload.leadInfo)
      ) {
        jobsiteDataFromLead = {
          ...state,
          jobSiteFormData: {
            edit: true,
            name: data.name,
            phase_id: data.phase_id,
            request_priority: data.request_priority,
            opening_time,
            closing_time,
            start_date: action.payload.leadInfo.start_date
              ? action.payload.leadInfo.start_date
              : "",
            end_date: action.payload.leadInfo.duration
              ? action.payload.leadInfo.duration
              : "",
            duration: action.payload.leadInfo.duration
              ? action.payload.leadInfo.duration
              : "",
            is_call_for_tender: data.is_call_for_tender,
            postal: action.payload.leadInfo.postal
              ? action.payload.leadInfo.postal
              : "",
            city: action.payload.leadInfo.city ? action.payload.leadInfo.city : "",
            latitude: data.latitude,
            longitude: data.longitude,
            address: action.payload.leadInfo.address
              ? action.payload.leadInfo.address
              : "",
            document: action.payload.leadDocuments,
            user_comment: action.payload.leadInfo.jobsite_comment
              ? action.payload.leadInfo.jobsite_comment
              : "",
            leadId: action.payload.leadInfo.id,
          },
          editJobSiteStatus: "",
        };
      }
      return action.payload &&
        action.payload.leadInfo &&
        !Array.isArray(action.payload.leadInfo)
        ? jobsiteDataFromLead
        : jobsiteData;
    }
    case SELECT_CATEGORY_FORM: {
      return {
        ...state,
        jobSiteFormData: {
          ...state.jobSiteFormData,
          related_contact: [],
        },
      };
    }
    case LEADS.SUCCESS: {
      return {
        ...state,
        phase: SUCCESS,
        leadsList: action.data.leadList,
        leadRequestList: action.data.leadRequestList,
      };
    }
    case CREATE_SITE_FORM: {
      const isLead = action.payload ? action.payload.isLead : false;
      const selectedLead = state.leadsList.find(
        (lead) => lead.id === action.payload.lead_id
      );
      return {
        ...state,
        jobSiteFormData: {
          edit: false,
          name: "",
          opening_time: "",
          closing_time: "",
          start_date: "",
          end_date: "",
          is_call_for_tender: "",
          related_contact: [],
          postal: "",
          city: "",
          address: "",
          document: isLead ? selectedLead.document : [],
          initialRow: {
            contact_id: "",
            role: "",
          },
          ...(isLead && { id: action.payload.lead_id }),
          ...(isLead && { lead_completed: true }),
        },
        editJobSiteStatus: "",
      };
    }

    case GET_VIEW_DATA.SUCCESS: {
      let viewData = {};
      if (action.data.lead) {
        viewData = {
          ...state,
          jobSiteFormData: {
            ...action.data.lead,
            initialRow: state.jobSiteFormData.initialRow,
            related_contact: [],
          },
          relatedContact: {
            ...state.relatedContact,
            jobsite: {
              ...state.relatedContact.jobsite,
              related_contact: action.data.lead.related_contact,
              document: action.data.lead.document,
            },
          },
        };
      } else {
        viewData = {
          ...state,
          jobSiteFormData: {
            ...action.data.jobsite,
            initialRow: state.jobSiteFormData.initialRow,
            related_contact: [],
          },
          relatedContact: {
            ...state.relatedContact,
            jobsite: {
              ...state.relatedContact.jobsite,
              related_contact: action.data.jobsite.related_contact,
              document: action.data.jobsite.document,
            },
          },
        };
      }
      return viewData;
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
