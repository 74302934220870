import React from "react";
import PropTypes from "prop-types";

const Button = (props) => {
  const { className, type, id, name, onClick, disabled } = props;

  return (
    <button
      className={className}
      type={type}
      id={id}
      onClick={onClick}
      disabled={disabled}
    >
      {name}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Button;
