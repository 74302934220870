import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { storage } from "../../../../store/dataStore/api";

import RequestViewPopUp from "../../../../shared/components/RequestViewPopUp";
import SectionHeader from "../../../../shared/components/SectionHeader/SectionHeader";
import EmptyView from "../../../../shared/components/EmptyView/EmptyView";
import AddNew from "../../../../shared/components/AddNew/AddNew";

import Button from "../../../../shared/baseUI/Button";

import MyEnquiryRowItem from "../MyEnquiryRowItem";

import enquiryBtn from "../../../../assets/shared/enquiry.svg";

import "./MyEnquiries.scss";

const RequestListing = (props) => {
  const {
    listingData,
    showPopup,
    siteId,
    siteName,
    paginationData,
    selectedJobSiteForRequestCreation,
    t,
  } = props;

  useEffect(() => {
    storage();
    // todo: call only if previous route is jobsite creation form. so that get updated master data.
    // update with newly created jobsite.
    props.getSiteMasterListData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let lng = "en";
    if (localStorage.getItem("userCountryId")) {
      const countryIdLocal = localStorage.getItem("userCountryId").toString();
      if (countryIdLocal === "1") {
        lng = "en";
      } else if (countryIdLocal === "2") {
        lng = "fr";
      } else if (countryIdLocal === "3") {
        lng = "pl";
      } else if (countryIdLocal === "6") {
        lng = "de";
      } else if (countryIdLocal === "8") {
        lng = "ch";
      }
    }
    props.i18n.changeLanguage(lng);
  }, [props.i18n]);

  const nextData = (pageNum) => {
    if (listingData.length < paginationData.total) {
      props.getRequestListingData({ id: siteId, pageNum: pageNum + 1 });
    }
  };

  const viewData = async (siteID, id) => {
    await props.getRequestViewData({ siteId: siteID, id });
    props.handleShowPopUp();
  };

  const onCreateEnquiry = () => {
    props.createEnquiry();
  };

  const showMoreData = () => {
    if (
      props.paginationData &&
      props.paginationData.current_page < props.paginationData.last_page
    ) {
      nextData(props.paginationData.current_page);
    }
  };

  const renderRequests = () => {
    return listingData.map((item, i) => {
      return (
        <MyEnquiryRowItem
          key={i}
          siteId={siteId}
          item={item}
          viewData={() => {
            viewData(siteId, item.id);
          }}
        />
      );
    });
  };

  return (
    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 request-view">
      <div>
        <SectionHeader
          title={
            t("CLIENT_PLATFORM_SITE_OVERVIEW_INQUIRIES_TITLE") +
            (paginationData && paginationData.total > 0
              ? ` (${paginationData.total})`
              : "")
          }
          link="/sitesandenquiries"
          viewAllTitle="All enquiries"
          isViewAllVisible={false}
        />
        {props.isLoading && (
          <>
            <div className="loader-item first">
              <Skeleton width={50} height={50} />
              <div className="loader-item-text">
                <Skeleton width={200} />
                <Skeleton width={200} />
                <Skeleton width={100} />
              </div>
            </div>
            <div className="loader-item">
              <Skeleton width={50} height={50} />
              <div className="loader-item-text">
                <Skeleton width={200} />
                <Skeleton width={200} />
                <Skeleton width={100} />
              </div>
            </div>
          </>
        )}
        {!props.isLoading &&
          listingData.length === 0 &&
          parseInt(localStorage.getItem("userId"), 10) ===
            parseInt(props.user_id, 10) && (
            <EmptyView
              link="/createenquiry"
              description={t(
                "CLIENT_PLATFORM_SITE_OVERVIEW_INQUIRIES_NO_INQUIRY_DESCRIPTION"
              )}
              title={t("CLIENT_PLATFORM_SITE_OVERVIEW_INQUIRIES_NO_INQUIRY_TITLE")}
              icon={enquiryBtn}
              buttonName={t(
                "CLIENT_PLATFORM_SITE_OVERVIEW_INQUIRIES_BUTTON_NEW_INQUIRY"
              )}
              isBtnVisible
              onBtnClick={() => {
                onCreateEnquiry();
                selectedJobSiteForRequestCreation({
                  id: siteId,
                  name: siteName,
                });
              }}
            />
          )}
        {!props.isLoading &&
          listingData.length > 0 &&
          parseInt(localStorage.getItem("userId"), 10) ===
            parseInt(props.user_id, 10) && (
            <div className="add-new-btn-container">
              <AddNew
                link="/createenquiry"
                name={t(
                  "CLIENT_PLATFORM_SITE_OVERVIEW_INQUIRIES_BUTTON_CREATE_INQUIRY"
                )}
                onAdd={() => {
                  onCreateEnquiry();
                  selectedJobSiteForRequestCreation({
                    id: siteId,
                    name: siteName,
                  });
                  localStorage.setItem(
                    "selectedJobsiteForRequest",
                    JSON.stringify({ id: siteId, name: siteName })
                  );
                }}
              />
            </div>
          )}
        {!props.isLoading && listingData.length > 0 && (
          <div id="scrollableDiv">{renderRequests()}</div>
        )}
        {!props.isLoading &&
          paginationData &&
          listingData.length < paginationData.total && (
            <Button
              className="btn btn-primary d-block btn-sm btn-dark-gray w-100"
              name={t("CLIENT_PLATFORM_SITE_OVERVIEW_INQUIRIES_BUTTON_SEE_MORE")}
              onClick={() => showMoreData()}
            />
          )}
      </div>

      {showPopup && <RequestViewPopUp />}
    </div>
  );
};

export default withTranslation()(RequestListing);
