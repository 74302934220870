import { isEmpty } from "../shared/utils/methods";

class Auth {
  isAuthenticated() {
    const userInfo =
      JSON.parse(localStorage.getItem("userInfo")) !== {}
        ? JSON.parse(localStorage.getItem("userInfo"))
        : "";
    const token = localStorage.getItem("token");
    if (
      token === null ||
      token === "" ||
      userInfo === null ||
      userInfo === "" ||
      isEmpty(userInfo)
    ) {
      return false;
    }

    return true;
  }

  isAllowed() {
    const userDetails = localStorage.getItem("userInfo");
    if (userDetails && JSON.parse(userDetails)) {
      const userInfo = JSON.parse(userDetails);
      // If pending user
      if (parseInt(userInfo.status, 10) === 3) {
        return false;
      }
    }
    return true;
  }
}

export default new Auth();
