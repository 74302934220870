import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import dropdown from "../../../assets/shared/ic-dropdown.svg";
import "./CustomSelectBox.scss";

const SelectInput = (props) => {
  const {
    labelClass,
    labelName,
    inputClass,
    name,
    value,
    options,
    defaultOption,
    disabled,
    onChange,
    error,
    errorMsg,
    required,
    divClass,
  } = props;
  const [isOpen, setOpenStatus] = useState(false);
  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    const val =
      name !== "enquiry-jobsite-select"
        ? Array.isArray(value)
          ? value[0]
          : value
        : value;
    if (options && options.length > 0) {
      const selectedOptionLocal = options.find((option) => option.value === val);
      if (selectedOptionLocal) {
        setSelectedOption(selectedOptionLocal.label);
      }
    }
  }, [value, name, options]);

  const toggleDropdown = () => {
    setOpenStatus(!isOpen);
  };

  const closeDropdown = () => {
    setOpenStatus(false);
  };

  const handleOptionClick = (label, value) => {
    setSelectedOption(label);
    setOpenStatus(false);
    onChange({ name, value });
  };

  return (
    <div className={`${divClass} custom-selectbox`} onMouseLeave={closeDropdown}>
      <label className={labelClass}>{labelName}</label>
      <div
        name={name}
        value={
          name !== "enquiry-jobsite-select"
            ? Array.isArray(value)
              ? value[0]
              : value
            : value
        }
        onChange={onChange}
        required={required}
        className={`${inputClass} custom-selectbox__option-display`}
        disabled={disabled}
        onClick={toggleDropdown}
      >
        <span className="custom-selectbox__option-name">
          {selectedOption || defaultOption}
        </span>
        <img
          src={dropdown}
          alt="Dropdown icon"
          className={`icon ${isOpen && "open"}`}
        />
      </div>
      {isOpen && (
        <ul className="custom-selectbox__options-list">
          {options &&
            options.length > 0 &&
            options.map((o) => (
              <li
                key={o.label + o.value}
                value={o.value}
                onClick={() => handleOptionClick(o.label, o.value)}
                className="custom-selectbox__options-item"
              >
                {o.label}
              </li>
            ))}
        </ul>
      )}
      {error && (
        <div
          className={
            name === "opening_time"
              ? "error-text text-danger w-100"
              : "error-text text-danger position-absolute w-100"
          }
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
};

SelectInput.propTypes = {
  inputClass: PropTypes.string,
  divClass: PropTypes.string,
  labelClass: PropTypes.string,
  labelName: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultOption: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default SelectInput;
