export const CREATE_TRANSPORT_REQUEST = {
  LOADING: "CREATE_TRANSPORT_REQUEST_LOADING",
  SUCCESS: "CREATE_TRANSPORT_REQUEST_SUCCESS",
  ERROR: "CREATE_TRANSPORT_REQUEST_FAILURE",
};

export const UPDATE_TRANSPORT_REQUEST = {
  LOADING: "UPDATE_TRANSPORT_REQUEST_LOADING",
  SUCCESS: "UPDATE_TRANSPORT_REQUEST_SUCCESS",
  ERROR: "UPDATE_TRANSPORT_REQUEST_FAILURE",
};

export const CREATE_TRANSPORT_REQUEST_FORM = "CREATE_TRANSPORT_REQUEST_FORM";
export const EDIT_TRANSPORT_ENQUIRY = "EDIT_TRANSPORT_ENQUIRY";
export const HANDLE_TRANSPORT_DETAILS = "HANDLE_TRANSPORT_DETAILS";
