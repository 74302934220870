import React, { useEffect } from "react";
import PropTypes from "prop-types";

const SelectInput = (props) => {
  const {
    inputRef,
    onMount,
    labelClass,
    labelName,
    inputClass,
    name,
    value,
    options,
    defaultOption,
    disabled,
    onChange,
    error,
    errorMsg,
    required,
    divClass,
  } = props;

  const getValue = (inputValue) => {
    if (name !== "enquiry-jobsite-select") {
      if (Array.isArray(inputValue)) {
        return inputValue[0];
      }
      return inputValue;
    }
    return inputValue;
  };

  useEffect(() => {
    onMount?.(name);
  }, []);

  return (
    <div className={divClass} ref={inputRef}>
      <label className={labelClass} htmlFor={name}>
        {labelName}
      </label>
      {/* TODO: check if value attr is required. */}
      <select
        name={name}
        value={getValue(value)}
        onChange={onChange}
        required={required}
        className={inputClass}
        disabled={disabled}
        aria-label={name}
      >
        <option value="">{defaultOption}</option>
        {options &&
          options.length > 0 &&
          options.map((o) => (
            <option key={o.label + o.value} value={o.value}>
              {o.label}
            </option>
          ))}
      </select>
      {error && (
        <div
          className={
            name === "opening_time"
              ? "error-text text-danger w-100"
              : "error-text text-danger position-absolute w-100"
          }
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
};

SelectInput.propTypes = {
  divClass: PropTypes.string,
  inputRef: PropTypes.object,
  labelClass: PropTypes.string,
  labelName: PropTypes.string,
  inputClass: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultOption: PropTypes.string.isRequired,
  onMount: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default SelectInput;
