import { takeLatest, put, call } from "redux-saga/effects";
import { loginDataAPI, checkEmailAPI, forgotPasswordAPI, logOutAPI } from "./api";

import { LOGINDATA, CHECK_EMAIL, FORGOTPASSWORDDATA, LOGOUTDATA } from "./constant";

function* loginDataSaga(action) {
  const response = action.payload.loginDetails;
  try {
    const data = yield call(loginDataAPI, response);
    yield put({ type: LOGINDATA.SUCCESS, data });
  } catch (error) {
    yield put({ type: LOGINDATA.ERROR, error });
  }
}

function* checkEmailSaga(action) {
  try {
    const data = yield call(checkEmailAPI, action.payload);
    yield put({ type: CHECK_EMAIL.SUCCESS, data });
  } catch (error) {
    yield put({ type: CHECK_EMAIL.ERROR, error });
  }
}

function* forgotPasswordDataSaga(action) {
  try {
    const data = yield call(forgotPasswordAPI, action.payload);
    yield put({ type: FORGOTPASSWORDDATA.SUCCESS, data });
  } catch (error) {
    yield put({ type: FORGOTPASSWORDDATA.ERROR, error });
  }
}

function* logOutData(action) {
  try {
    const data = yield call(logOutAPI, action.payload);
    yield put({ type: LOGOUTDATA.SUCCESS, data });
  } catch (error) {
    yield put({ type: LOGOUTDATA.ERROR, error });
  }
}

export function* postLoginDataSaga() {
  yield takeLatest(LOGINDATA.LOADING, loginDataSaga);
}

export function* postcheckEmailSaga() {
  yield takeLatest(CHECK_EMAIL.LOADING, checkEmailSaga);
}

export function* postForgotPasswordSaga() {
  yield takeLatest(FORGOTPASSWORDDATA.LOADING, forgotPasswordDataSaga);
}

export function* logOutDataSaga() {
  yield takeLatest(LOGOUTDATA.LOADING, logOutData);
}
