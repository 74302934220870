import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ClipLoader from "react-spinners/ClipLoader";

import Button from "../../../../shared/baseUI/Button";
import TextInput from "../../../../shared/baseUI/TextInput";

const UpdatePassword = (props) => {
  const {
    t,
    userPasswordData,
    disabledPasswordBtn,
    submitPasswordData,
    handleUpdatePasswordDetails,
    isPasswordUpdate,
  } = props;

  const [showPassword, setPasswordVisibility] = useState(false);

  const togglePassword = () => {
    setPasswordVisibility(!showPassword);
  };

  const onChange = (e) => {
    const userPasswordDetails = { ...userPasswordData };
    const { name } = e.target;
    const { value } = e.target;
    userPasswordDetails[name] = value;
    handleUpdatePasswordDetails({ userPasswordDetails });
  };

  return (
    <>
      <div className="registration-form h-100">
        <h1 className="text-center title m-0">
          {t("CLIENT_PLATFORM_MY_ACCOUNT_UPDATE_PASSWORD_FORM_TITLE")}
        </h1>
        <form>
          <div className="form-section pt-4">
            <h6>
              {t(
                "CLIENT_PLATFORM_MY_ACCOUNT_UPDATE_PASSWORD_FORM_PASSWORD_INFO_TITLE"
              )}
            </h6>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 position-relative">
                <TextInput
                  type={showPassword ? "text" : "password"}
                  placeholder={t(
                    "CLIENT_PLATFORM_MY_ACCOUNT_UPDATE_PASSWORD_FORM_PASSWORD_INFO_EXISTING_PLACEHOLDER"
                  )}
                  inputClass="form-input w-100"
                  divClass="form-field"
                  labelClass="form-label required"
                  labelName={t(
                    "CLIENT_PLATFORM_MY_ACCOUNT_UPDATE_PASSWORD_FORM_PASSWORD_INFO_EXISTING"
                  )}
                  required
                  id="old_password"
                  name="old_password"
                  value={userPasswordData.old_password}
                  onChange={(e) => onChange(e)}
                />
                <i
                  className={
                    showPassword
                      ? "fa fa fa-eye-slash position-absolute eye-icon"
                      : "fa fa fa-eye position-absolute eye-icon"
                  }
                  aria-hidden="true"
                  onClick={(e) => togglePassword(e)}
                />
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 position-relative">
                <TextInput
                  type={showPassword ? "text" : "password"}
                  placeholder={t(
                    "CLIENT_PLATFORM_MY_ACCOUNT_UPDATE_PASSWORD_FORM_PASSWORD_INFO_NEW_PLACEHOLDER"
                  )}
                  inputClass="form-input w-100"
                  divClass="form-field"
                  labelClass="form-label required"
                  labelName={t(
                    "CLIENT_PLATFORM_MY_ACCOUNT_UPDATE_PASSWORD_FORM_PASSWORD_INFO_NEW"
                  )}
                  required
                  id="password"
                  name="password"
                  value={userPasswordData.password}
                  onChange={(e) => onChange(e)}
                />
                <i
                  className={
                    showPassword
                      ? "fa fa fa-eye-slash position-absolute eye-icon"
                      : "fa fa fa-eye position-absolute eye-icon"
                  }
                  aria-hidden="true"
                  onClick={(e) => togglePassword(e)}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="registration-footer">
        <NavLink to="/dashboard">
          <Button
            className="link text-decoration-none border-0 float-left d-inline-block mt-2"
            name={t("CLIENT_PLATFORM_MY_ACCOUNT_UPDATE_PASSWORD_FORM_BUTTON_CANCEL")}
          />
        </NavLink>
        {isPasswordUpdate && (
          <div className="btn btn-primary btn-blue float-right">
            <ClipLoader size={20} color="#fff" />
          </div>
        )}
        {!isPasswordUpdate && (
          <Button
            className="btn btn-primary btn-blue float-right"
            id="submit-onboard"
            name={t("CLIENT_PLATFORM_MY_ACCOUNT_UPDATE_PASSWORD_FORM_BUTTON_SAVE")}
            disabled={disabledPasswordBtn}
            onClick={() => submitPasswordData(userPasswordData)}
          />
        )}
      </div>
    </>
  );
};

export default withTranslation()(UpdatePassword);
