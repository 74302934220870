import { takeLatest, put, call } from "redux-saga/effects";
import * as moment from "moment";

import {
  ADD_NEW_JOBSITE,
  UPDATE_JOBSITE,
  DELETE_DOCUMENT,
  GET_SITE_MASTER_LIST_DATA,
} from "./JobsiteForm.constants";

import {
  getSiteMasterDataAPI,
  addNewJobSiteAPI,
  addNewLeadAPI,
  updateJobSiteAPI,
  updateLeadAPI,
  deleteDocumentAPI,
} from "./JobsiteForm.api";

// Worker sagas
function* getSiteMasterData(action) {
  try {
    const data = yield call(getSiteMasterDataAPI, action.payload);
    yield put({ type: GET_SITE_MASTER_LIST_DATA.SUCCESS, data });
  } catch (error) {
    const data = {
      code: error?.response?.status,
      data: error?.response?.data.errors,
      message: error?.response?.data.message,
    };
    yield put({ type: GET_SITE_MASTER_LIST_DATA.ERROR, error: data });
  }
}

function* addNewJobSite(action) {
  const { token } = action.payload;
  const modifyData = { ...action.payload.data };

  modifyData.end_date = moment(modifyData.start_date)
    .add(modifyData.end_date.split(" ")[0], "months")
    .format("YYYY-MM-DD");
  // don't upload file again, if already uploaded
  modifyData.document = modifyData.document.filter(
    (x) => !Object.prototype.hasOwnProperty.call(x, "filename")
  );

  if (modifyData.is_call_for_tender === "") {
    modifyData.is_call_for_tender = 0;
  }
  if (modifyData.related_contact) {
    modifyData.related_contact.map((item) => delete item.id);
  }
  delete modifyData.initialRow;

  try {
    const userInfo = localStorage.getItem("userInfo");
    let userStatus;
    if (userInfo && JSON.parse(userInfo)) {
      userStatus = JSON.parse(userInfo).status;
    }
    const addJobsiteAPI =
      parseInt(userStatus, 10) === 3 ? addNewLeadAPI : addNewJobSiteAPI;
    const data = yield call(addJobsiteAPI, {
      token,
      data: modifyData,
      form: action.payload.form,
    });
    yield put({ type: ADD_NEW_JOBSITE.SUCCESS, data });
  } catch (error) {
    yield put({ type: ADD_NEW_JOBSITE.ERROR, error });
  }
}

function* updateJobSite(action) {
  const { token } = action.payload;
  const modifyData = { ...action.payload.data };

  modifyData.end_date = moment(modifyData.start_date)
    .add(modifyData.end_date.split(" ")[0], "months")
    .format("YYYY-MM-DD");
  modifyData.document = modifyData.document.filter(
    (x) => !Object.prototype.hasOwnProperty.call(x, "filename")
  );
  if (modifyData.is_call_for_tender === "") {
    modifyData.is_call_for_tender = 0;
  }
  try {
    const userInfo = localStorage.getItem("userInfo");
    let userStatus;
    if (userInfo && JSON.parse(userInfo)) {
      userStatus = JSON.parse(userInfo).status;
    }
    const editJobsiteAPI =
      parseInt(userStatus, 10) === 3 ? updateLeadAPI : updateJobSiteAPI;
    const data = yield call(editJobsiteAPI, {
      token,
      data: modifyData,
      form: action.payload.form,
    });
    yield put({ type: UPDATE_JOBSITE.SUCCESS, data });
  } catch (error) {
    yield put({ type: UPDATE_JOBSITE.ERROR, error });
  }
}

function* deleteDocument(action) {
  try {
    const data = yield call(deleteDocumentAPI, action.payload);
    yield put({ type: DELETE_DOCUMENT.SUCCESS, data });
  } catch (error) {
    yield put({ type: DELETE_DOCUMENT.ERROR, error });
  }
}

// Watcher sagas
export function* getSiteMasterDataSaga() {
  yield takeLatest(GET_SITE_MASTER_LIST_DATA.LOADING, getSiteMasterData);
}

export function* addNewJobSiteSaga() {
  yield takeLatest(ADD_NEW_JOBSITE.LOADING, addNewJobSite);
}

export function* updateJobSiteSaga() {
  yield takeLatest(UPDATE_JOBSITE.LOADING, updateJobSite);
}

export function* deleteDocumentSaga() {
  yield takeLatest(DELETE_DOCUMENT.LOADING, deleteDocument);
}
