import {
  CREATE_CALL_OF_ORDER_FORM,
  CREATE_CALL_OF_ORDER,
  HANDLE_CALLOFF_DATA,
  EDIT_CALL_OF_ORDER_FORM,
  GET_REQUEST_MASTER_LIST_DATA_CALL_OF_ORDER,
  GET_SITE_MASTER_LIST_DATA_CALL_OF_ORDER,
  GET_TRANSPORT_TRUCKS,
} from "./CallOffOrderForm.constants";

import {
  SELECTED_JOB_SITE_FOR_REQUEST,
  GET_REQUEST_VIEW_DATA,
} from "../../store/dataStore/constant";

import {
  prepareRequestMasterData,
  prepareSiteMasterData,
  prepareTransportProduct,
} from "../../shared/utils/methods";

const initialState = {
  newCallOffOrderRequestLoading: false,
  isSiteMasterListLoading: false,
  isRequestMasterListLoading: false,
  apiUnAuthenticated: false,
  callOffOrderSuccess: { status: "", error: "" },
  calloffOrderForm: {},
  requestMasterData: [],
  siteMasterCallOffOrderData: [],
  siteMasterCallOffOrderDataRaw: [],
  trucks: [],
  selectedJobSiteForRequest: {
    id: "",
    name: "",
    user_id: "",
    company_id: "",
    company_name: "",
    end_date: "",
    status_id: "",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CALL_OF_ORDER_FORM: {
      return {
        ...state,
        callOffOrderSuccess: { status: "", error: "" },
        calloffOrderForm: {
          comment: "",
          jobsite_id: "",
          date: "",
          loads: "",
          request: "",
          truck_id: "",
          edit: false,
        },
        selectedJobSiteForRequest: {
          id: "",
          name: "",
          user_id: "",
          company_id: "",
          company_name: "",
          end_date: "",
          status_id: "",
        },
      };
    }
    case SELECTED_JOB_SITE_FOR_REQUEST: {
      const selectedSite = state.siteMasterCallOffOrderDataRaw.filter(
        (site) => site.id === action.payload.id
      );

      return {
        ...state,
        selectedJobSiteForRequest: {
          id: action.payload.id,
          name: action.payload.name,
          start_date: selectedSite.length > 0 ? selectedSite[0].start_date : "",
          duration: selectedSite.length > 0 ? selectedSite[0].duration : "",
          document: selectedSite.length > 0 ? selectedSite[0].documents : [],
        },
      };
    }
    case GET_REQUEST_VIEW_DATA.SUCCESS: {
      let viewData = {};

      if (action.data.lead) {
        const selectedSite = state.siteMasterCallOffOrderDataRaw.filter(
          (site) => site.id === action.data.lead.id
        );
        viewData = {
          ...state,
          selectedJobSiteForRequest: {
            id: action.data.lead.id,
            name: action.data.lead.name,
            user_id: action.data.lead.user_id,
            company_id: action.data.lead.company_id,
            company_name: action.data.lead.company_name,
            end_date: action.data.lead.end_date,
            status_id: action.data.lead.status_id,
            start_date: selectedSite.length > 0 ? selectedSite[0].start_date : "",
            duration: selectedSite.length > 0 ? selectedSite[0].duration : "",
          },
        };
      } else {
        const selectedSite = state.siteMasterCallOffOrderDataRaw.filter(
          (site) => site.id === action.data.jobsite.id
        );
        viewData = {
          ...state,
          selectedJobSiteForRequest: {
            id: action.data.jobsite.id,
            name: action.data.jobsite.name,
            user_id: action.data.jobsite.user_id,
            company_id: action.data.jobsite.company_id,
            company_name: action.data.jobsite.company_name,
            end_date: action.data.jobsite.end_date,
            status_id: action.data.jobsite.status_id,
            start_date: selectedSite.length > 0 ? selectedSite[0].start_date : "",
            duration: selectedSite.length > 0 ? selectedSite[0].duration : "",
          },
        };
      }

      return viewData;
    }
    case CREATE_CALL_OF_ORDER.LOADING: {
      return {
        ...state,
        newCallOffOrderRequestLoading: true,
        apiUnAuthenticated: false,
      };
    }
    case CREATE_CALL_OF_ORDER.SUCCESS: {
      return {
        ...state,
        callOffOrderSuccess: { status: 200, error: "" },
        newCallOffOrderRequestLoading: false,
      };
    }

    case CREATE_CALL_OF_ORDER.ERROR: {
      return {
        ...state,
        callOffOrderSuccess: {
          status: action.error.response,
          error: action.error.response.data,
        },
        newCallOffOrderRequestLoading: false,
      };
    }
    case HANDLE_CALLOFF_DATA: {
      return {
        ...state,
        calloffOrderForm: {
          comment: action.payload.updatedData.comment,
          jobsite_id: action.payload.updatedData.jobsite_id,
          date: action.payload.updatedData.date,
          loads: action.payload.updatedData.loads,
          request: action.payload.updatedData.request,
          truck_id: action.payload.updatedData.truck_id,
        },
      };
    }
    case EDIT_CALL_OF_ORDER_FORM: {
      return {
        ...state,
        callOffOrderSuccess: { status: "", error: "" },
        calloffOrderForm: {
          comment: "",
          jobsite_id: action.payload.data.jobsite_id,
          date: "",
          loads: "",
          request: action.payload.data.request,
          truck_id: "",
        },
      };
    }
    case GET_REQUEST_MASTER_LIST_DATA_CALL_OF_ORDER.LOADING:
      return {
        ...state,
        isRequestMasterListLoading: true,
        apiUnAuthenticated: false,
      };
    case GET_REQUEST_MASTER_LIST_DATA_CALL_OF_ORDER.SUCCESS: {
      return {
        ...state,
        isRequestMasterListLoading: false,
        requestMasterData: prepareRequestMasterData(action.data),
      };
    }
    case GET_REQUEST_MASTER_LIST_DATA_CALL_OF_ORDER.ERROR: {
      return {
        ...state,
        isRequestMasterListLoading: false,
        requestMasterData: [],
      };
    }
    case GET_SITE_MASTER_LIST_DATA_CALL_OF_ORDER.LOADING:
      return {
        ...state,
        isSiteMasterListLoading: true,
        apiUnAuthenticated: false,
      };
    case GET_SITE_MASTER_LIST_DATA_CALL_OF_ORDER.SUCCESS: {
      return {
        ...state,
        isSiteMasterListLoading: false,
        siteMasterCallOffOrderData: prepareSiteMasterData(action.data),
        siteMasterCallOffOrderDataRaw: action.data,
      };
    }

    case GET_SITE_MASTER_LIST_DATA_CALL_OF_ORDER.ERROR: {
      return {
        ...state,
        isSiteMasterListLoading: false,
        siteMasterCallOffOrderData: [],
        siteMasterCallOffOrderDataRaw: [],
      };
    }
    case GET_TRANSPORT_TRUCKS.LOADING:
      return {
        ...state,
        apiUnAuthenticated: false,
      };
    case GET_TRANSPORT_TRUCKS.SUCCESS: {
      return {
        ...state,
        trucks: prepareTransportProduct(action.data),
      };
    }
    case GET_TRANSPORT_TRUCKS.ERROR: {
      return {
        ...state,
        trucks: [],
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
