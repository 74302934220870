import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import ReactGA from "react-ga";

import AccountDetails from "./components/AccountDetails";
import UpdatePassword from "./components/UpdatePassword";

import Popup from "../../shared/baseUI/Popup";

import {
  submitMyAccountData,
  handlePopUp,
  handleUpdatePasswordDetails,
  submitUpdatePasswordData,
  getUserAccountData,
  handleMyAccountDetails,
  clearUpdatePasswordForm,
} from "./MyAccount.actionCreators.js";

import { handleLogOut, checkIsEmailExists } from "../../store/loginData";

import "./MyAccount.screen.scss";

const MyAccount = (props) => {
  const {
    userId,
    userAccountData,
    updatedMyAccountStatus,
    showPopup,
    userPasswordData,
    updatedPasswordStatus,
    isAccountUpdate,
    disabledPasswordBtn,
    isPasswordUpdate,
    phase,
    t,
  } = props;
  const history = useHistory();

  useEffect(() => {
    ReactGA.pageview("/myaccount");

    return () => {
      // close popup if open.
      props.handlePopUp();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userId === null || updatedMyAccountStatus.code === 401) {
      props.handleLogOut();
      history.replace("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, updatedMyAccountStatus]);

  useEffect(() => {
    let lng = "en";
    if (localStorage.getItem("userCountryId")) {
      const countryId = localStorage.getItem("userCountryId").toString();
      if (countryId === "1") {
        lng = "en";
      } else if (countryId === "2") {
        lng = "fr";
      } else if (countryId === "3") {
        lng = "pl";
      } else if (countryId === "6") {
        lng = "de";
      } else if (countryId === "8") {
        lng = "ch";
      }
    }
    props.i18n.changeLanguage(lng);
  }, [props.i18n]);

  const updateAccountDetails = (details) => {
    const accountData = { ...userAccountData };
    accountData.user.first_name = details.first_name.value;
    accountData.user.last_name = details.last_name.value;
    accountData.user.job = details.job.value;
    accountData.user.email = details.email.value;
    accountData.user.phone = details.phone.value;
    accountData.company.company_phone = details.company_phone.value;
    accountData.company.identification_number = details.identification_number.value;
    accountData.company.name = details.name.value;
    accountData.company.shipping_address = details.shipping_address.value;
    accountData.company.shipping_city = details.shipping_city.value;
    accountData.company.shipping_postal = details.shipping_postal.value;

    props.handleMyAccountDetails({ userAccountDetails: accountData });
  };

  const submitUserAccountData = (data) => {
    props.submitMyAccountData(data);
  };

  const submitPasswordData = (data) => {
    props.submitUpdatePasswordData(data);
  };

  const closePopUp = () => {
    props.handlePopUp();
  };

  const onClickPassword = () => {
    props.clearUpdatePasswordForm();
  };

  return (
    <div className="container-fluid">
      <div className="row signup-page-bg my-account-page">
        <div className="col-sm-12 col-md-6 col-lg-7 col-xl-6 offset-xl-1">
          <div className="registration-content">
            <div className="tab-content" id="v-pills-tabContent">
              <div
                className="tab-pane show active"
                id="v-pills-my-account"
                role="tabpanel"
                aria-labelledby="v-pills-my-account-tab"
              >
                <AccountDetails
                  userAccountData={props.userAccountData}
                  emailExists={props.emailExists}
                  selectedCountryId={
                    localStorage.getItem("countryId") !== ""
                      ? parseInt(localStorage.getItem("countryId"), 10)
                      : ""
                  }
                  getUserAccountData={props.getUserAccountData}
                  handleMyAccountDetails={props.handleMyAccountDetails}
                  checkIsEmailExists={props.checkIsEmailExists}
                  updateAccountDetails={updateAccountDetails}
                  submitMyAccountData={submitUserAccountData}
                  phase={phase}
                  isAccountUpdate={isAccountUpdate}
                />
              </div>
              <div
                className="tab-pane"
                id="v-pills-update-password"
                role="tabpanel"
                aria-labelledby="v-pills-update-password-tab"
              >
                <UpdatePassword
                  userPasswordData={userPasswordData}
                  disabledPasswordBtn={disabledPasswordBtn}
                  submitPasswordData={submitPasswordData}
                  handleUpdatePasswordDetails={props.handleUpdatePasswordDetails}
                  isPasswordUpdate={isPasswordUpdate}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="my-account-switch col-sm-12 col-md-6 col-lg-5 col-xl-5 d-md-block">
          <div className="row signup-bg h-100">
            <div
              className="nav flex-column nav-pills step-details my-account-page-tabs"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <a
                className="media active"
                id="v-pills-my-account-tab"
                data-toggle="pill"
                href="#v-pills-my-account"
                role="tab"
                aria-controls="v-pills-my-account"
                aria-selected="true"
              >
                <span className="badge rounded-circle mr-3 float-left blue-bg mb-4 border-0">
                  <span className="position-relative number">
                    <i className="fa fa-user text-white " aria-hidden="true" />
                  </span>
                </span>
                <div className="media-body mt-2">
                  <h6 className="mt-0 nav-title">
                    {t("CLIENT_PLATFORM_MY_ACCOUNT_FORM_TITLE")}
                  </h6>
                </div>
              </a>
              <a
                className="media"
                id="v-pills-update-password-tab"
                data-toggle="pill"
                href="#v-pills-update-password"
                role="tab"
                aria-controls="v-pills-update-password"
                aria-selected="false"
                onClick={(e) => onClickPassword()}
              >
                <span className="badge rounded-circle mr-3 float-left blue-bg  border-0">
                  <span className="position-relative number">
                    <i
                      className="fa fa-pencil-square-o text-white"
                      aria-hidden="true"
                    />
                  </span>
                </span>
                <div className="media-body mt-2">
                  <h6 className="mt-0 nav-title">
                    {t("CLIENT_PLATFORM_MY_ACCOUNT_UPDATE_PASSWORD_FORM_TITLE")}{" "}
                  </h6>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      {(updatedMyAccountStatus.code === 422 ||
        updatedMyAccountStatus.code === 500 ||
        updatedMyAccountStatus.code === 200) &&
        showPopup && (
          <Popup
            modalParentClass="modal-parent"
            modalClass="modal-box"
            title={
              updatedMyAccountStatus.code === 200
                ? t("CLIENT_PLATFORM_MY_ACCOUNT_FORM_INFOS_UPDATED_TITLE")
                : t("CLIENT_PLATFORM_MY_ACCOUNT_FORM_ERROR_UPDATE_TITLE")
            }
            name="updateMyAccountStatus"
            content={
              updatedMyAccountStatus.code === 200
                ? t("CLIENT_PLATFORM_MY_ACCOUNT_FORM_INFOS_UPDATED_CONTENT")
                : t("CLIENT_PLATFORM_MY_ACCOUNT_FORM_ERROR_UPDATE_CONTENT")
            }
            id="updateMyAccountStatusPopup"
            onClose={closePopUp}
          />
        )}
      {(updatedPasswordStatus.code === 422 ||
        updatedMyAccountStatus.code === 500 ||
        updatedPasswordStatus.code === 200 ||
        updatedPasswordStatus.code === 409) &&
        showPopup && (
          <Popup
            modalParentClass="modal-parent"
            modalClass="modal-box"
            title={
              updatedPasswordStatus.code === 200
                ? t(
                    "CLIENT_PLATFORM_MY_ACCOUNT_UPDATE_PASSWORD_FORM_PASSWORD_UPDATED_TITLE"
                  )
                : t("CLIENT_PLATFORM_MY_ACCOUNT_FORM_ERROR_UPDATE_TITLE")
            }
            name="updatePasswordStatus"
            content={
              updatedPasswordStatus.code === 200
                ? t(
                    "CLIENT_PLATFORM_MY_ACCOUNT_UPDATE_PASSWORD_FORM_PASSWORD_UPDATED_CONTENT"
                  )
                : t("CLIENT_PLATFORM_MY_ACCOUNT_FORM_ERROR_UPDATE_CONTENT")
            }
            id="updatePasswordStatusPopup"
            onClose={closePopUp}
          />
        )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userAccountData: state.account.userAccountData[0],
    showPopup: state.account.showPopup,
    updatedMyAccountStatus: state.account.updateStatus,
    isAccountUpdate: state.account.isAccountUpdate,
    isPasswordUpdate: state.account.isPasswordUpdate,
    disabledPasswordBtn: state.account.disabledPasswordBtn,
    userPasswordData: state.account.userPasswordData,
    updatedPasswordStatus: state.account.updatedPasswordStatus,
    userId: state.loginStore.loginUserId,
    emailExists: state.loginStore.isEmailExists,
    phase: state.account.phase,
  };
};

const mapDispatchToProps = {
  submitMyAccountData: (payload) => submitMyAccountData(payload),
  handlePopUp: () => handlePopUp(),
  handleUpdatePasswordDetails: (payload) => handleUpdatePasswordDetails(payload),
  submitUpdatePasswordData: (payload) => submitUpdatePasswordData(payload),
  handleLogOut: () => handleLogOut(),
  getUserAccountData: (payload) => getUserAccountData(payload),
  handleMyAccountDetails: (payload) => handleMyAccountDetails(payload),
  clearUpdatePasswordForm: () => clearUpdatePasswordForm(),
  checkIsEmailExists: (payload) => checkIsEmailExists(payload),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(MyAccount));
