import {
  ADD_NEW_REQUEST,
  EDIT_ENQUIRY,
  UPDATE_REQUEST_DATA,
  HANDLE_REQUEST_FORM_DETAILS,
  GET_MATERIAL_PRODUCT_DETAILS,
  UPDATE_ROW_VALUE,
  UPDATE_ROW_ITEM,
  ADD_ROW_ITEM,
  DELETE_ROW_ITEM,
  CREATE_ENQUIRY_FORM,
  SELECT_CATEGORY,
  SELECT_SUB_CATEGORY,
  SELECT_CATEGORY_FORM,
  GET_EDIT_REQUEST_DATA,
} from "./InquiryForm.constants";

export const addNewRequest = (payload) => ({
  type: ADD_NEW_REQUEST.LOADING,
  payload,
});

export const editEnquiry = (payload) => ({
  type: EDIT_ENQUIRY,
  payload,
});

export const updateRequestData = (payload) => ({
  type: UPDATE_REQUEST_DATA.LOADING,
  payload,
});

export const handleRequestFormDetails = (payload) => ({
  type: HANDLE_REQUEST_FORM_DETAILS,
  payload,
});

export const getMaterialProductData = (payload) => ({
  type: GET_MATERIAL_PRODUCT_DETAILS.LOADING,
  payload,
});

export const updateRowValue = (payload) => ({
  type: UPDATE_ROW_VALUE,
  payload,
});

export const addRowItem = (payload) => ({
  type: ADD_ROW_ITEM,
  payload,
});

export const delRowItem = (payload) => ({
  type: DELETE_ROW_ITEM,
  payload,
});

export const updateRowItem = (payload) => ({
  type: UPDATE_ROW_ITEM,
  payload,
});

export const createEnquiry = () => ({
  type: CREATE_ENQUIRY_FORM,
});

export const selectCategory = (payload) => ({
  type: SELECT_CATEGORY,
  payload,
});

export const selectSubCategory = (payload) => ({
  type: SELECT_SUB_CATEGORY,
  payload,
});

export const selectCategoryForm = (payload) => ({
  type: SELECT_CATEGORY_FORM,
  payload,
});

export const getEditedRequestData = (payload) => ({
  type: GET_EDIT_REQUEST_DATA,
  payload,
});
