import React, { useEffect, useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import EmptyView from "../../../EmptyView";
import sitelogo from "../../../../../assets/shared/site-icon.svg";

import SiteRow from "../SiteRow";
import Button from "../../../../baseUI/Button";
import Popup from "../../../../baseUI/Popup";

import {
  getSiteListingData,
  getSiteListingInactiveData,
} from "../../../../../modules/SitesList/SitesList.actionCreators";

import { getCallOfOrderPopupVisible } from "../../../../../store/dataStore";

const ViewSites = (props) => {
  const {
    data,
    paginationData,
    inActiveData,
    isLoading,
    t,
    isCallOfOrderPopupVisible,
  } = props;
  const [hasMore, setHasMore] = useState(true);
  const { pathname } = window.location;
  const [showIsCallOfOrderPopup, setShowIsCallOfOrderPopup] = useState(false);

  useEffect(() => {
    if (pathname === "/callofforder") {
      return (
        <div className="data-table">
          <p>Coming soon...</p>
        </div>
      );
    }
  }, [pathname]);

  useEffect(() => {
    setShowIsCallOfOrderPopup(isCallOfOrderPopupVisible);
  }, [isCallOfOrderPopupVisible]);

  useEffect(() => {
    let lng = "en";
    if (localStorage.getItem("userCountryId")) {
      const countryIdLocal = localStorage.getItem("userCountryId").toString();
      if (countryIdLocal === "1") {
        lng = "en";
      } else if (countryIdLocal === "2") {
        lng = "fr";
      } else if (countryIdLocal === "3") {
        lng = "pl";
      } else if (countryIdLocal === "6") {
        lng = "de";
      } else if (countryIdLocal === "8") {
        lng = "ch";
      }
    }
    props.i18n.changeLanguage(lng);
  }, [props.i18n]);

  const closeCallOfOrderPopUp = () => {
    props.getCallOfOrderPopupVisible(false);
  };

  const nextData = (pageNum) => {
    if (pathname === "/sitesandenquiries") {
      if (props.data.length === props.paginationData.total) {
        setHasMore(false);
        return;
      }
      setHasMore(true);

      if (!props.inActiveData) {
        props.getSiteListingData({ pageNum: pageNum + 1 });
      } else {
        props.getSiteListingInactiveData({ pageNum: pageNum + 1 });
      }
    }
  };

  return (
    <div>
      {data.length === 0 && !isLoading && (
        <div className="no-sites-container">
          <EmptyView
            link="/createjobsite"
            description={t("CLIENT_PLATFORM_SITE_LIST_NO_SITE_DESCRIPTION")}
            title={t("CLIENT_PLATFORM_SITE_LIST_NO_SITE_TITLE")}
            icon={sitelogo}
            buttonName={t("CLIENT_PLATFORM_SITE_LIST_BUTTON_NEW_SITE")}
            isBtnVisible
            onBtnClick={() => {
              props.createSite();
            }}
          />
        </div>
      )}
      {data && data.length > 0 && (
        <InfiniteScroll
          dataLength={data.length} // This is important field to render the next data
          next={() =>
            paginationData &&
            Object.prototype.hasOwnProperty.call(paginationData, "current_page")
              ? nextData(paginationData.current_page)
              : {}
          }
          hasMore={hasMore}
          loader={
            <div className="text-center my-2">
              <PulseLoader color="#004c7f" />
            </div>
          }
          className="sites-scroll-view"
        >
          {data.map((details, i) => {
            return <SiteRow data={details} key={i} inActiveData={inActiveData} />;
          })}
        </InfiniteScroll>
      )}
      {data.length === 10 ? (
        <div className="load-more-wrapper">
          <Button
            name={t("CLIENT_PLATFORM_SITE_LIST_INQUIRIES_SEE_MORE")}
            className="btn btn-btn btn-blue btn-primary btn-sm float-right mx-2"
            onClick={() => nextData(paginationData.current_page)}
          />
        </div>
      ) : (
        ""
      )}

      {showIsCallOfOrderPopup && (
        <Popup
          modalParentClass="modal-parent"
          modalClass="modal-box"
          title={t(
            "CLIENT_PLATFORM_NAVIGATION_MENU_CALL_OFF_ORDER_NOT_AVAILABLE_TITLE"
          )}
          name="isCallOfOrderNav"
          content={t(
            "CLIENT_PLATFORM_NAVIGATION_MENU_CALL_OFF_ORDER_NOT_AVAILABLE_CONTENT"
          )}
          id="isCallOfOrderNavPopup"
          onClose={closeCallOfOrderPopUp}
        />
      )}
    </div>
  );
};

ViewSites.propTypes = {
  data: PropTypes.shape(PropTypes.array).isRequired,
  paginationData: PropTypes.shape(PropTypes.object),
  inActiveData: PropTypes.bool,
  isLoading: PropTypes.bool,
  isCallOfOrderPopupVisible: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isCallOfOrderPopupVisible: state.dataStore.isCallOfOrderPopupVisible,
  };
};

const mapDispatchToProps = {
  getSiteListingData: (payload) => getSiteListingData(payload),
  getSiteListingInactiveData: (payload) => getSiteListingInactiveData(payload),
  getCallOfOrderPopupVisible: (payload) => getCallOfOrderPopupVisible(payload),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ViewSites));
