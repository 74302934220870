import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

import MyCallOfOrders from "../../../Dashboard/components/MyCallOfOrders";
import ModalPopup from "../../../../shared/baseUI/Popup";
import Documents from "../../../../shared/components/Documents";

import {
  prepareShortDateFormat,
  prepareTimeFormat,
} from "../../../../shared/utils/methods";

import { storage } from "../../../../store/dataStore/api";

import "./SiteInfo.scss";

const ViewJobSite = (props) => {
  const { data, document, quotes, t, isCallOfOrderPopupVisible } = props;
  const [showCallOffOrder, setCallOffOrderStatus] = useState(false);
  const [showIsCallOfOrderPopup, setShowIsCallOfOrderPopup] = useState(false);
  const QUOTE_VALIDATED = 3;

  useEffect(() => {
    if (props.stage.length === 0) {
      storage();
      props.getCategoriesData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let lng = "en";
    if (localStorage.getItem("userCountryId")) {
      const countryIdLocal = localStorage.getItem("userCountryId").toString();
      if (countryIdLocal === "1") {
        lng = "en";
      } else if (countryIdLocal === "2") {
        lng = "fr";
      } else if (countryIdLocal === "3") {
        lng = "pl";
      } else if (countryIdLocal === "6") {
        lng = "de";
      } else if (countryIdLocal === "8") {
        lng = "ch";
      }
    }
    props.i18n.changeLanguage(lng);
  }, [props.i18n]);

  // Show call off order only if one of the quotes is validated.
  useEffect(() => {
    const validateQuote = quotes.filter(
      (quote) => parseInt(quote.status, 10) === QUOTE_VALIDATED
    );
    if (validateQuote.length > 0) {
      setCallOffOrderStatus(true);
    } else {
      setCallOffOrderStatus(false);
    }
  }, [quotes]);

  useEffect(() => {
    setShowIsCallOfOrderPopup(isCallOfOrderPopupVisible);
  }, [isCallOfOrderPopupVisible]);

  const closeCallOfOrderPopUp = () => {
    props.getCallOfOrderPopupVisible(false);
  };

  const getSitePhase = (phaseId) => {
    const sitePhase = props.stage.filter(
      (siteStage) => parseInt(siteStage.value, 10) === parseInt(phaseId, 10)
    );

    if (sitePhase.length === 0) {
      return "";
    }

    return sitePhase[0].label;
  };

  const editJobSite = () => {
    const jobsiteData = { ...data };
    const openingTimeLocal = jobsiteData.opening_time
      ? jobsiteData.opening_time.split(":")
      : [];
    const opening_time =
      openingTimeLocal.length >= 2
        ? `${openingTimeLocal[0]}:${openingTimeLocal[1]}`
        : "";
    const closingTimeLocal = jobsiteData.closing_time
      ? jobsiteData.closing_time.split(":")
      : [];
    const closing_time =
      closingTimeLocal.length >= 2
        ? `${closingTimeLocal[0]}:${closingTimeLocal[1]}`
        : "";
    const dataLocal = {
      name: {
        value: jobsiteData.name,
      },
      phase_id: {
        value: jobsiteData.phase_id,
      },
      opening_time: {
        value: opening_time,
      },
      closing_time: {
        value: closing_time,
      },
      address: {
        value: jobsiteData.address,
      },
      city: {
        value: jobsiteData.city,
      },
      postal: {
        value: jobsiteData.postal,
      },
      request_priority: {
        value: jobsiteData.request_priority,
      },
      is_call_for_tender: {
        value: jobsiteData.is_call_for_tender,
      },
      user_comment: {
        value: jobsiteData.jobsite_comment,
      },
      start_date: {
        value: jobsiteData.start_date,
      },
      duration: {
        value: jobsiteData.duration,
      },
      latitude: {
        value: jobsiteData.latitude,
      },
      longitude: {
        value: jobsiteData.longitude,
      },
      "other-info-comment": {
        value: jobsiteData.jobsite_comment,
      },
    };
    if (jobsiteData.edit) {
      localStorage.setItem("isExistingJobsiteEdit", JSON.stringify(true));
    }
    localStorage.setItem("jobsiteEditData", JSON.stringify(dataLocal));
    props.handleEditJobSite();
  };

  const renderDuration = (duration) => {
    const durationOptions = {
      "1_MONTH": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_1_MONTH"
      ),
      "2_MONTH": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_2_MONTHS"
      ),
      "3_MONTH": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_3_MONTHS"
      ),
      "6_MONTH": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_6_MONTHS"
      ),
      "1_YEAR": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_1_YEAR"
      ),
      "2_YEAR": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_2_YEARS"
      ),
      MORE: t("CLIENT_PLATFORM_SITE_FORM_SITE_DURATION_MORE"),
    };

    return durationOptions[duration] ? `- ${durationOptions[duration]}` : "";
  };

  return (
    <>
      <div className="site-info-card">
        {props.isLoading && (
          <>
            <div className="loader-title-wrapper">
              <Skeleton width={100} />
              <Skeleton width={50} />
            </div>
            <div className="loader-row-wrapper">
              <div className="loader-row">
                <Skeleton width={150} />
                <Skeleton width={150} />
              </div>
              <div className="loader-row">
                <Skeleton width={150} />
                <Skeleton width={150} />
              </div>
              <div className="loader-row margin-top">
                <Skeleton width={150} />
                <Skeleton width={150} />
              </div>
              <div className="loader-row margin-top">
                <Skeleton width={150} />
                <Skeleton width={150} />
              </div>
            </div>
          </>
        )}
        {!props.isLoading && (
          <>
            <div className="site-info-card__title-wrapper">
              <h5 className="site-info-card__title">
                {t("CLIENT_PLATFORM_SITE_OVERVIEW_INFO_TITLE")}
              </h5>
              {parseInt(localStorage.getItem("userId"), 10) ===
                parseInt(data.user_id, 10) && (
                <NavLink
                  to="/createjobsite"
                  className="site-info-card__edit-link"
                  onClick={editJobSite}
                >
                  <span className="site-info-card__edit-link-text">
                    <i className="fa fa-pencil pr-2" aria-hidden="true" />
                    {t("CLIENT_PLATFORM_SITE_OVERVIEW_INFO_BUTTON_EDIT")}
                  </span>
                </NavLink>
              )}
            </div>
            <div className="site-info-card__body">
              <div className="row site-info-card__details-wrapper">
                <div className="col-xs-12 col-sm-12 col-md-7">
                  <div className="site-detail-list pl-1 site-info-card__body-date-duration">
                    <label className="site-info-card__body-date-duration-label">
                      {t("CLIENT_PLATFORM_SITE_OVERVIEW_INFO_START_DURATION")} :
                    </label>
                    <p className="site-info-card__body-date-duration-text">
                      <span className="pr-1">
                        {prepareShortDateFormat(data.start_date)}
                      </span>
                      &nbsp;
                      {renderDuration(data.duration)}
                    </p>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-5">
                  <label className="site-info-card__body-site-phase-label">
                    {t("CLIENT_PLATFORM_SITE_OVERVIEW_INFO_SITE_PHASE")} :
                  </label>
                  <p className="gray-darker-text site-info-card__body-site-phase-text">
                    {data.phase_id ? getSitePhase(data.phase_id) : ""}
                  </p>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-7">
                  <div className="site-detail-list pl-1 site-info-card__body-site-address">
                    <label className="site-info-card__body-site-address-label">
                      {t("CLIENT_PLATFORM_SITE_OVERVIEW_INFO_SITE_LOCATION")} :
                    </label>
                    <p className="gray-darker-text ">
                      {data.address}
                      <br />
                      {`${data.postal}, ${data.city}`}
                    </p>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-5">
                  <label className="site-info-card__body-site-time-label">
                    {t("CLIENT_PLATFORM_SITE_OVERVIEW_INFO_OPENING_HOURS")} :
                  </label>
                  {data.opening_time !== null && data.closing_time !== null && (
                    <p className="site-info-card__body-site-time-text">
                      {`${prepareTimeFormat(
                        data.opening_time
                      )} - ${prepareTimeFormat(data.closing_time)}`}
                    </p>
                  )}
                  {(data.opening_time === null || data.closing_time === null) && (
                    <p className="gray-darker-text pl-3">-</p>
                  )}
                </div>
              </div>
              {data.latitude &&
                data.longitude &&
                data.longitude !== "undefined" &&
                data.latitude !== "undefined" && (
                  <div className="row site-info-card__map">
                    <div id="site-map">
                      <MapContainer
                        center={[data.latitude, data.longitude]}
                        zoom={13}
                        scrollWheelZoom={false}
                      >
                        <TileLayer
                          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={[data.latitude, data.longitude]}>
                          <Popup>
                            {`${data.address} ${data.postal} ${data.city}`}
                          </Popup>
                        </Marker>
                      </MapContainer>
                    </div>
                  </div>
                )}
            </div>
          </>
        )}
      </div>

      {showCallOffOrder && (
        <div className="site-overview-call-off-order">
          <MyCallOfOrders
            callOfOrders={[]}
            createCalloffOrderForm={props.createCalloffOrderForm}
            isButtonDisabled={!(data.isCallOfOrder > 0)}
          />
        </div>
      )}

      {document && (
        <div className="site-documents">
          <Documents
            documents={document}
            title={t("CLIENT_PLATFORM_SITE_OVERVIEW_INFO_DOCUMENTS")}
            noDocTitle={t(
              "CLIENT_PLATFORM_SITE_OVERVIEW_INFO_NO_DOCUMENTS_AVAILABLE"
            )}
            canDownload={false}
          />
        </div>
      )}

      {showIsCallOfOrderPopup && (
        <ModalPopup
          modalParentClass="modal-parent"
          modalClass="modal-box"
          title={t(
            "CLIENT_PLATFORM_NAVIGATION_MENU_CALL_OFF_ORDER_NOT_AVAILABLE_TITLE"
          )}
          name="isCallOfOrderNav"
          content={t(
            "CLIENT_PLATFORM_NAVIGATION_MENU_CALL_OFF_ORDER_NOT_AVAILABLE_CONTENT"
          )}
          id="isCallOfOrderNavPopup"
          onClose={closeCallOfOrderPopUp}
        />
      )}
    </>
  );
};

ViewJobSite.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.object,
  document: PropTypes.array,
  stage: PropTypes.array,
  quotes: PropTypes.array,
  isCallOfOrderPopupVisible: PropTypes.bool,
  handleEditJobSite: PropTypes.func.isRequired,
  getCategoriesData: PropTypes.func.isRequired,
  createCalloffOrderForm: PropTypes.func.isRequired,
  getCallOfOrderPopupVisible: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ViewJobSite);
