import {
  CLEAR_DASHBOARD,
  DASHBOARDATA,
  LEADS,
  CLEAR_SELECTED_SITE,
} from "./Dashboard.constants";

export const clearDashboardData = () => ({
  type: CLEAR_DASHBOARD,
});

export const getDashboardData = (payload) => ({
  type: DASHBOARDATA.LOADING,
  payload,
});

export const getDashboardLeads = (payload) => ({
  type: LEADS.LOADING,
  payload,
});

export const clearSelectedSite = () => ({
  type: CLEAR_SELECTED_SITE,
});
