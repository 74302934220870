import React, { Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import ReactGA from "react-ga";

import "normalize.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-widgets/dist/css/react-widgets.css";
import "font-awesome/css/font-awesome.min.css";
import "./App.css";
import "./shared.scss";

import "./i18n";

import store from "../store/main";
import Routes from "./Routes";
import ErrorBoundary from "./ErrorBoundary";

const App = () => {
  // Initialize google analytics for production url.
  useEffect(() => {
    const baseURL = window.location.host;
    if (baseURL.includes("my.hesus-store.com")) {
      ReactGA.initialize("UA-167201840-1");
    }
  }, []);

  return (
    <Suspense fallback={null}>
      <Provider store={store}>
        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
      </Provider>
    </Suspense>
  );
};

export default App;
