import { takeLatest, put, call } from "redux-saga/effects";

import {
  CREATE_CALL_OF_ORDER,
  GET_SITE_MASTER_LIST_DATA_CALL_OF_ORDER,
  GET_REQUEST_MASTER_LIST_DATA_CALL_OF_ORDER,
  GET_TRANSPORT_TRUCKS,
} from "./CallOffOrderForm.constants";

import {
  createCallOfOrderAPI,
  getSiteMasterDataCallOfOrderAPI,
  getRequestMasterDataCallOfOrderAPI,
  getTransportDataAPI,
} from "./CallOffOrderForm.api";

// Worker sagas.
function* createCalloffOrder(action) {
  const modifyData = {
    comment: action.payload.comment,
    jobsite_id: action.payload.jobsite_id,
    date: action.payload.date,
    loads: action.payload.loads,
    request: action.payload.request,
    truck_id: action.payload.truck_id,
  };

  try {
    const data = yield call(createCallOfOrderAPI, modifyData);
    yield put({ type: CREATE_CALL_OF_ORDER.SUCCESS, data });
  } catch (error) {
    yield put({ type: CREATE_CALL_OF_ORDER.ERROR, error });
  }
}

function* getSiteMasterDataCallOfOrder(action) {
  try {
    const data = yield call(getSiteMasterDataCallOfOrderAPI, action.payload);
    yield put({ type: GET_SITE_MASTER_LIST_DATA_CALL_OF_ORDER.SUCCESS, data });
  } catch (error) {
    yield put({
      type: GET_SITE_MASTER_LIST_DATA_CALL_OF_ORDER.ERROR,
      error,
    });
  }
}

function* getRequestMasterDataCallOfOrder(action) {
  try {
    const data = yield call(getRequestMasterDataCallOfOrderAPI, action.payload);
    yield put({
      type: GET_REQUEST_MASTER_LIST_DATA_CALL_OF_ORDER.SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: GET_REQUEST_MASTER_LIST_DATA_CALL_OF_ORDER.ERROR,
      error,
    });
  }
}

function* getTrucks(action) {
  try {
    const data = yield call(getTransportDataAPI, action.payload);
    yield put({ type: GET_TRANSPORT_TRUCKS.SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_TRANSPORT_TRUCKS.ERROR, error });
  }
}

// Watcher Sagas

export function* createCallOfOrderSaga() {
  yield takeLatest(CREATE_CALL_OF_ORDER.LOADING, createCalloffOrder);
}

export function* getSiteMasterDataCallOfOrderSaga() {
  yield takeLatest(
    GET_SITE_MASTER_LIST_DATA_CALL_OF_ORDER.LOADING,
    getSiteMasterDataCallOfOrder
  );
}

export function* getRequestMasterDataCallOfOrderSaga() {
  yield takeLatest(
    GET_REQUEST_MASTER_LIST_DATA_CALL_OF_ORDER.LOADING,
    getRequestMasterDataCallOfOrder
  );
}

export function* getTrucksSaga() {
  yield takeLatest(GET_TRANSPORT_TRUCKS.LOADING, getTrucks);
}
