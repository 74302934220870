import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import "./RequestSuccess.scss";

const RequestSuccess = (props) => {
  const { t, onSuccess } = props;

  return (
    <div>
      <div className="enquiry-form__wrapper enquiry-form__wrapper--center">
        <pre className="enquiry-form__success-msg">
          {t("CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_SUCCESS_MESSAGE")}
        </pre>
        <button
          className="btn btn-primary btn-blue enquiry-form__btn--success"
          onClick={onSuccess}
          type="button"
        >
          {t("CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_BUTTON_WEBSITE")}
          <i className="fa fa-chevron-right" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

RequestSuccess.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(RequestSuccess);
