import React, { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";
import en from "date-fns/locale/en-GB";
import pl from "date-fns/locale/pl";
import ch from "date-fns/locale/fr-CH";
import fr from "date-fns/locale/fr";

import "./FloatingTextDatePicker.scss";
import calenderIcon from "../../../assets/shared/ic-calendar.svg";

const CustomDatePicker = (props) => {
  const {
    divClass,
    labelClass,
    labelName,
    name,
    value,
    id,
    required,
    placeholder,
    inputClass,
    onChange,
    error,
    errorMsg,
    disabled,
    dateFormat,
    countryId,
    minDate,
    calendarIconClass,
  } = props;
  const [isDatePickerFocussed, setDatePickerFocusState] = useState(false);
  let inputRef = useRef();

  const getLocale = (country) => {
    let locale;
    switch (country) {
      case 1:
        locale = en;
        break;
      case 2:
        locale = fr;
        break;
      case 3:
        locale = pl;
        break;
      case 4:
        locale = ch;
        break;
      default:
        locale = fr;
    }
    return locale;
  };

  const getMaxDate = () => {
    const currentDate = new Date();
    return new Date(
      currentDate.getFullYear() + 10,
      currentDate.getMonth(),
      currentDate.getDate()
    );
  };

  const getMinDate = () => {
    const currentDate = new Date();
    return new Date(
      currentDate.getFullYear() - 10,
      currentDate.getMonth(),
      currentDate.getDate()
    );
  };

  return (
    <div
      className={
        isDatePickerFocussed || value
          ? `${divClass} floating-datepicker__container--focussed`
          : `${divClass} floating-datepicker__container`
      }
    >
      <label className={`${labelClass} datepicker-label`} htmlFor={id}>
        {labelName}
      </label>
      <DatePicker
        locale={getLocale(countryId)}
        onChange={(date) => onChange({ name, date })}
        selected={value ? new Date(value) : ""}
        placeholderText={placeholder}
        className={inputClass}
        name={name}
        id={id}
        disabled={disabled}
        required={required}
        dateFormat={dateFormat}
        minDate={minDate ? new Date(minDate) : getMinDate()}
        maxDate={getMaxDate()}
        ref={(ref) => {
          inputRef = ref;
        }}
        dayClassName={() => "day-class"}
        calendarClassName="custom-datepicker-calendar"
        formatWeekDay={(weekName) => weekName.substring(0, 3)}
        dateFormatCalendar="MMMM"
        yearDropdownItemNumber={15}
        showYearDropdown
        yearItemNumber={9}
        onFocus={(e) => {
          e.target.readOnly = true;
          setDatePickerFocusState(true);
        }}
        onBlur={() => {
          setDatePickerFocusState(false);
        }}
      />
      <img
        src={calenderIcon}
        alt="calendar icon"
        className={calendarIconClass || "calendar-icon"}
        onClick={() => {
          if (inputRef) {
            inputRef.setFocus();
          }
        }}
      />
      {error && (
        <div className="error-text text-danger position-absolute w-100 text-nowrap">
          {errorMsg}
        </div>
      )}
    </div>
  );
};

CustomDatePicker.propTypes = {
  divClass: PropTypes.string.isRequired,
  labelClass: PropTypes.string.isRequired,
  labelName: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  inputClass: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
  disabled: PropTypes.bool,
  dateFormat: PropTypes.string.isRequired,
  countryId: PropTypes.string.isRequired,
  minDate: PropTypes.string,
  calendarIconClass: PropTypes.string.isRequired,
};

export default CustomDatePicker;
