import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";

import ViewSites from "../ViewSites";

import { createSite } from "../../../../../modules/JobsiteForm/JobsiteForm.actionCreators";
import { getSiteListingData } from "../../../../../modules/SitesList/SitesList.actionCreators";
import { storage } from "../../../../../store/dataStore/api";

import "./ActiveSites.scss";

const ActiveSites = (props) => {
  const { siteListingData, calloffOrderData, paginationData } = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    const { pathname } = window.location;

    if (pathname === "/sitesandenquiries") {
      storage();
      props.getSiteListingData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { pathname } = window.location;

    if (pathname === "/sitesandenquiries") {
      setData(siteListingData);
    }
    if (pathname === "/callofforder") {
      setData(calloffOrderData);
    }
  }, [siteListingData, calloffOrderData]);

  return (
    <div className="data-table">
      <ViewSites
        data={data}
        paginationData={paginationData}
        isLoading={props.phase === "LOADING"}
        createSite={createSite}
      />

      {data.length === 0 && props.phase === "LOADING" && (
        <div className="loader-wrapper">
          <PulseLoader color="#004c7f" />
        </div>
      )}
      {props.phase === "ERROR" && (
        <div className="error-wrapper">
          An Error occured while fetching the Job Site Lists
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    siteListingData: state.sitesList.siteListingData,
    calloffOrderData: state.dataStore.calloffOrderData,
    paginationData: state.sitesList.paginationData.jobsite,
    phase: state.sitesList.phase,
  };
};

const mapDispatchToProps = {
  getSiteListingData: () => getSiteListingData(),
  createSite: () => createSite(),
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveSites);
