import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ClipLoader from "react-spinners/ClipLoader";

import "./Footer.scss";

const Footer = (props) => {
  const { t, onChange, onSubmit, disabled, isLoading, currentStep } = props;

  return (
    <div className="v3-footer__container">
      <button
        className="btn btn-primary btn-blue"
        disabled={disabled}
        onClick={(e) => {
          if (currentStep === 0) {
            onChange(e);
          } else {
            onSubmit(e);
          }
        }}
        type="button"
      >
        {currentStep === 0 && !isLoading && (
          <>
            {t("CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_BUTTON_NEXT")}
            <i className="fa fa-chevron-right" aria-hidden="true" />
          </>
        )}
        {currentStep === 1 &&
          !isLoading &&
          t("CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_BUTTON_CONFIRM")}
        {isLoading && <ClipLoader size={20} color="#fff" />}
      </button>
    </div>
  );
};

Footer.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  currentStep: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Footer);
