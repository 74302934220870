import { all } from "redux-saga/effects";

import {
  postLoginDataSaga,
  postcheckEmailSaga,
  postForgotPasswordSaga,
  logOutDataSaga,
} from "./loginData/saga";
import {
  getRequestCategoriesDataSaga,
  getProductsDataSaga,
  getRequestViewSaga,
  deleteDocumentSaga,
  getSiteMasterDataSaga,
} from "./dataStore/saga";

import {
  getDashboardDataSaga,
  getDashboardLeadsSaga,
} from "../modules/Dashboard/Dashboard.saga";

import {
  addNewJobSiteSaga,
  updateJobSiteSaga,
} from "../modules/JobsiteForm/JobsiteForm.saga";

import {
  getUserAccountDataSaga,
  updatePasswordDataSaga,
  updateUserAccountSaga,
} from "../modules/MyAccount/MyAccount.saga";

import {
  getViewDataSaga,
  getRequestListingSaga,
  getQuotesSaga,
} from "../modules/SiteOverview/SiteOverview.saga";

import {
  getSiteListingDataSaga,
  getSiteListingInactiveDataSaga,
} from "../modules/SitesList/SitesList.saga";

import {
  createTransportRequestSaga,
  updateTransportRequestSaga,
} from "../modules/TransportOnlyForm/TransportOnlyForm.saga";

import {
  createCallOfOrderSaga,
  getRequestMasterDataCallOfOrderSaga,
  getSiteMasterDataCallOfOrderSaga,
  getTrucksSaga,
} from "../modules/CallOffOrderForm/CallOffOrderForm.saga";

import {
  addNewRequestSaga,
  getMaterialProductsDataSaga,
  updateRequestDataSaga,
} from "../modules/InquiryForm/InquiryForm.saga";

const rootSaga = function* () {
  yield all([
    postLoginDataSaga(),
    postcheckEmailSaga(),
    postForgotPasswordSaga(),
    logOutDataSaga(),
    getDashboardDataSaga(),
    getDashboardLeadsSaga(),
    getUserAccountDataSaga(),
    updateUserAccountSaga(),
    updatePasswordDataSaga(),
    addNewJobSiteSaga(),
    updateJobSiteSaga(),
    getSiteListingDataSaga(),
    getSiteListingInactiveDataSaga(),
    getViewDataSaga(),
    getRequestCategoriesDataSaga(),
    getProductsDataSaga(),
    getMaterialProductsDataSaga(),
    addNewRequestSaga(),
    getRequestListingSaga(),
    getQuotesSaga(),
    getRequestViewSaga(),
    updateRequestDataSaga(),
    deleteDocumentSaga(),
    getTrucksSaga(),
    createTransportRequestSaga(),
    updateTransportRequestSaga(),
    createCallOfOrderSaga(),
    getSiteMasterDataSaga(),
    getSiteMasterDataCallOfOrderSaga(),
    getRequestMasterDataCallOfOrderSaga(),
  ]);
};

export default rootSaga;
