import {
  GET_SITE_LISTING_DATA,
  GET_SITE_LISTING_INACTIVE_DATA,
} from "./SitesList.constants";

import { LOGOUTDATA } from "../../store/loginData/constant";

import { LOADING, SUCCESS, INIT, ERROR } from "../../shared/utils/constant";
import { preparePaginationData } from "../../shared/utils/methods";

const initialState = {
  phase: INIT,
  isCallOfOrder: 0,
  apiUnAuthenticated: false,
  siteListingData: [],
  siteListingInactiveData: [],
  paginationData: {
    jobsite: {},
    jobsiteInactive: {},
    request: [],
    quotes: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SITE_LISTING_INACTIVE_DATA.LOADING:
    case GET_SITE_LISTING_DATA.LOADING: {
      return {
        ...state,
        phase: LOADING,
        apiUnAuthenticated: false,
      };
    }
    case GET_SITE_LISTING_DATA.SUCCESS: {
      const response = action.data;
      const paginationData = preparePaginationData(response);
      return {
        ...state,
        phase: SUCCESS,
        isCallOfOrder: response.isCallOfOrder,
        siteListingData: [...state.siteListingData, ...response.data].filter(
          function ({ id }) {
            return !this[id] && (this[id] = id);
          },
          {}
        ),
        paginationData: {
          ...state.paginationData,
          jobsite: paginationData,
          request: state.paginationData.request,
          quotes: state.paginationData.quotes,
        },
      };
    }
    case GET_SITE_LISTING_INACTIVE_DATA.SUCCESS: {
      const response = action.data;
      const paginationData = preparePaginationData(response);

      return {
        ...state,
        phase: SUCCESS,
        siteListingInactiveData: [
          ...state.siteListingInactiveData,
          ...response.data,
        ].filter(function ({ id }) {
          return !this[id] && (this[id] = id);
        }, {}),
        paginationData: {
          ...state.paginationData,
          jobsiteInactive: paginationData,
          request: state.paginationData.request,
          quotes: state.paginationData.quotes,
        },
      };
    }
    case GET_SITE_LISTING_INACTIVE_DATA.ERROR:
    case GET_SITE_LISTING_DATA.ERROR: {
      return {
        ...state,
        phase: ERROR,
      };
    }
    case LOGOUTDATA.SUCCESS: {
      return {
        ...state,
        phase: INIT,
        isCallOfOrder: 0,
        apiUnAuthenticated: false,
        siteListingData: [],
        siteListingInactiveData: [],
        paginationData: {
          jobsite: {},
          jobsiteInactive: {},
          request: [],
          quotes: [],
        },
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
