import { v4 as uuidv4 } from "uuid";

import {
  ADD_NEW_REQUEST,
  EDIT_ENQUIRY,
  UPDATE_REQUEST_DATA,
  HANDLE_REQUEST_FORM_DETAILS,
  GET_MATERIAL_PRODUCT_DETAILS,
  UPDATE_ROW_VALUE,
  ADD_ROW_ITEM,
  DELETE_ROW_ITEM,
  UPDATE_ROW_ITEM,
  CREATE_ENQUIRY_FORM,
  SELECT_CATEGORY,
  SELECT_SUB_CATEGORY,
  SELECT_CATEGORY_FORM,
  GET_EDIT_REQUEST_DATA,
} from "./InquiryForm.constants";
import { GET_SITE_MASTER_LIST_DATA } from "../JobsiteForm/JobsiteForm.constants";
import {
  GET_REQUEST_CATEGORIES,
  GET_REQUEST_VIEW_DATA,
  SELECTED_JOB_SITE_FOR_REQUEST,
} from "../../store/dataStore/constant";
import {
  prepareDocumentList,
  prepareSiteMasterData,
  prepareUsage,
  prepareRequestStages,
  prepareRequestTransport,
} from "../../shared/utils/methods";

const initialState = {
  newRequestLoading: false,
  apiUnAuthenticated: false,
  requestCategoriesData: {
    category: [],
    stage: [],
    transport: [],
  },
  requestSelectedItems: {
    service: "",
    category: "",
    subcategory: "",
  },
  category: [],
  selectedService: "",
  subCategory: [],
  usage: [],
  categoryForm: "",
  materialAllProductsData: [],
  isMaterialProductsLoading: false,
  requestCreationData: {
    service: "",
    sub_category_id: "",
    quantity: "",
    unit: "",
    transport: [],
    truck_per_day: "",
    start_date: "",
    end_date: "",
    comment: "",
    is_call_for_tender: "",
    phase_id: "",
    document: [],
    price: "",
    product: [],
    study_type: "",
    usage_id: "",
    analysis_flag: "",
    promocode: "",
    terms: "",
    selectedTransport: [],
    initialRow: {
      contact_id: "",
      role: "",
    },
  },
  selectedJobSiteForRequest: {
    id: "",
    name: "",
    user_id: "",
    company_id: "",
    company_name: "",
    end_date: "",
    status_id: "",
  },
  rowItem: [],
  siteMasterData: [],
  siteMasterDataRaw: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_NEW_REQUEST.LOADING:
    case UPDATE_REQUEST_DATA.LOADING: {
      return {
        ...state,
        newRequestLoading: true,
        apiUnAuthenticated: false,
      };
    }
    case ADD_NEW_REQUEST.SUCCESS: {
      const data = action.data.request;
      data.new = true;
      return {
        ...state,
        requestCreationData: data,
        newRequestLoading: false,
        rowItem: [],
        selectedJobSiteForRequest: {
          id: data.lead ? data.lead.id : data.jobsite.id,
          name: data.lead ? data.lead.name : data.jobsite.name,
          user_id: data.lead ? data.lead.user_id : data.jobsite.user_id,
          company_id: data.lead ? data.lead.company_id : data.jobsite.company_id,
          company_name: data.lead
            ? data.lead.company_name
            : data.jobsite.company_name,
          end_date: data.lead ? data.lead.end_date : data.jobsite.end_date,
          status_id: data.lead ? data.lead.status_id : data.jobsite.status_id,
          start_date: data.lead ? data.lead.start_date : data.jobsite.start_date,
          duration: data.lead ? data.lead.duration : data.jobsite.duration,
        },
      };
    }
    case ADD_NEW_REQUEST.ERROR: {
      return {
        ...state,
        newRequestLoading: false,
        requestCreationData: {
          ...state.requestCreationData,
          error: {
            status: action.error.response.status,
            data: action.error.response.data.errors,
            message: action.error.response.data.message,
          },
        },
      };
    }
    case GET_SITE_MASTER_LIST_DATA.LOADING:
      return {
        ...state,
        apiUnAuthenticated: false,
      };

    case GET_SITE_MASTER_LIST_DATA.SUCCESS: {
      return {
        ...state,
        siteMasterData: prepareSiteMasterData(action.data),
        siteMasterDataRaw: action.data,
      };
    }
    case GET_SITE_MASTER_LIST_DATA.ERROR: {
      return {
        ...state,
        siteMasterData: [],
        siteMasterDataRaw: [],
        apiUnAuthenticated: action.data.errorData.code === 401,
      };
    }
    case EDIT_ENQUIRY: {
      const data = action.payload.leadInfo
        ? action.payload.leadInfo
        : state.requestCreationData;

      const selectedSite = state.siteMasterDataRaw.filter(
        (site) => site.id === action.payload.jobsite.id
      );

      const requestCreationDataLocal = {
        id: action.payload.leadInfo ? "" : data.id,
        service: data.service_id,
        sub_category_id: data.sub_category_id,
        quantity: data.quantity,
        unit: data.unit,
        duration: data.duration,
        transport: data.transport ? data.transport.map((x) => x.transport_id) : [],
        truck_per_day: data.truck_per_day,
        start_date: data.start_date,
        end_date: data.end_date,
        comment: data.comment,
        is_call_for_tender: data.is_call_for_tender ? data.is_call_for_tender : 0,
        phase_id: data.phase_id,
        document: prepareDocumentList(action.payload.document),
        price: data.price ? data.price : "",
        product: data.product.map((x) => x.product_id),
        analysis_flag: data.analysis_flag ? data.analysis_flag : 0,
        promocode: data.promocode,
        terms: data.terms ? data.terms : "",
        editData: !action.payload.leadInfo,
        new: false,
        edit: false,
        selectedTransport:
          data.transport && data.transport.length > 0
            ? data.transport.map((x) => x.transport.name)
            : [],
        ...(data.product[0] &&
          data.product[0].usage_id &&
          parseInt(data.product[0].usage_id, 10) !== 0 && {
            usage_id: data.product[0].usage_id,
          }),
      };

      if (action.payload.leadInfo && action.payload.leadInfo.id) {
        requestCreationDataLocal.leadId = action.payload.leadInfo.id;
      }

      localStorage.setItem("requestData", JSON.stringify(requestCreationDataLocal));
      localStorage.setItem(
        "selectedJobsiteForRequest",
        JSON.stringify({
          id: action.payload.jobsite.id,
          name: action.payload.jobsite.name,
          start_date: selectedSite.length > 0 ? selectedSite[0].start_date : "",
          duration: selectedSite.length > 0 ? selectedSite[0].duration : "",
        })
      );
      localStorage.setItem(
        "requestService",
        JSON.stringify({ id: data.service_id })
      );
      localStorage.setItem(
        "requestCategory",
        JSON.stringify({ id: data.category_id })
      );
      localStorage.setItem(
        "requestSubCategory",
        JSON.stringify({ id: data.sub_category_id })
      );

      return {
        ...state,
        showPopup: false,
        requestCreationData: requestCreationDataLocal,
        rowItem:
          data.product.length >= 1
            ? data.product.map((x) => {
                return { id: uuidv4(), rowId: x.product_id };
              })
            : state.rowItem,
        selectedService: data.service_id,
        requestSelectedItems: {
          service: data.service_id,
          category: data.category_id,
          subcategory: data.sub_category_id,
        },
        selectedJobSiteForRequest: {
          id: action.payload.jobsite.id,
          name: action.payload.jobsite.name,
          start_date: selectedSite.length > 0 ? selectedSite[0].start_date : "",
          duration: selectedSite.length > 0 ? selectedSite[0].duration : "",
        },
        categoryForm: data.sub_category_id,
      };
    }
    case UPDATE_REQUEST_DATA.SUCCESS: {
      return {
        ...state,
        requestCreationData: {
          ...state.requestCreationData,
          jobsite_id: localStorage.getItem("jobsiteId"),
          updated: true,
        },
        newRequestLoading: false,
        showPopup: false,
      };
    }
    case UPDATE_REQUEST_DATA.ERROR: {
      return {
        ...state,
        requestCreationData: {
          ...state.requestCreationData,
          updated: false,
          error: action.error.response,
        },
        newRequestLoading: false,
        showPopup: false,
      };
    }

    case SELECTED_JOB_SITE_FOR_REQUEST: {
      const selectedSite = state.siteMasterDataRaw.filter(
        (site) => site.id === action.payload.id
      );

      return {
        ...state,
        selectedJobSiteForRequest: {
          id: action.payload.id,
          name: action.payload.name,
          start_date: selectedSite.length > 0 ? selectedSite[0].start_date : "",
          duration: selectedSite.length > 0 ? selectedSite[0].duration : "",
          document: selectedSite.length > 0 ? selectedSite[0].documents : [],
        },
      };
    }

    case HANDLE_REQUEST_FORM_DETAILS: {
      const requestCreationDataLocal = {
        ...action.payload.updatedData,
        document: prepareDocumentList(
          action.payload.updatedData.document
            ? action.payload.updatedData.document
            : []
        ),
        jobsite_document: action.payload.updatedData.jobsite_document
          ? action.payload.updatedData.jobsite_document
          : [],
      };
      if (state.requestCreationData.leadId) {
        requestCreationDataLocal.leadId = state.requestCreationData.leadId;
      }

      return {
        ...state,
        requestCreationData: requestCreationDataLocal,
        rowItem:
          state.rowItem.length > 0
            ? state.rowItem
            : requestCreationDataLocal.product.length >= 1
            ? requestCreationDataLocal.product.map((x) => {
                return { id: uuidv4(), rowId: x };
              })
            : [],
      };
    }

    case GET_MATERIAL_PRODUCT_DETAILS.LOADING:
      return {
        ...state,
        apiUnAuthenticated: false,
        isMaterialProductsLoading: true,
      };

    case GET_MATERIAL_PRODUCT_DETAILS.SUCCESS: {
      const response = [
        {
          id: parseInt(action.data.id, 10),
          data: action.data.data,
        },
      ];

      return {
        ...state,
        isMaterialProductsLoading: false,
        subCategory: action.data.data,
        materialProductData: response,
        materialAllProductsData: [...state.materialAllProductsData, ...response],
      };
    }
    case GET_MATERIAL_PRODUCT_DETAILS.ERROR: {
      return {
        ...state,
        isMaterialProductsLoading: false,
      };
    }

    case UPDATE_ROW_VALUE: {
      const row = state.rowItem.findIndex((x) => x.id === action.payload.id);
      const newArr = [...state.rowItem];
      newArr[row] = { ...newArr[row], rowId: action.payload.value };
      return {
        ...state,
        rowItem: newArr,
      };
    }

    case ADD_ROW_ITEM: {
      return {
        ...state,
        rowItem: action.payload,
        requestCreationData: {
          ...state.requestCreationData,
          product: [...state.requestCreationData.product, ""],
        },
      };
    }

    case DELETE_ROW_ITEM: {
      const updatedRows = JSON.parse(JSON.stringify(action.payload));

      return {
        ...state,
        rowItem: updatedRows,
      };
    }

    case UPDATE_ROW_ITEM: {
      return {
        ...state,
        rowItem: action.payload,
      };
    }

    case CREATE_ENQUIRY_FORM: {
      return {
        ...state,
        requestCreationData: {
          service: "",
          sub_category_id: "",
          quantity: "",
          unit: "",
          transport: [],
          truck_per_day: "",
          start_date: "",
          end_date: "",
          comment: "",
          is_call_for_tender: "",
          phase_id: "",
          related_contact: [],
          document: [],
          price: "",
          product: [],
          study_type: "",
          usage_id: "",
          analysis_flag: "",
          promocode: "",
          terms: "",
          updated: false,
          new: false,
          selectedTransport: [],
          initialRow: {
            contact_id: "",
            role: "",
          },
        },
        selectedJobSiteForRequest: {
          id: "",
          name: "",
          user_id: "",
          company_id: "",
          company_name: "",
          end_date: "",
          status_id: "",
          start_date: "",
          duration: "",
        },
        requestSelectedItems: {
          service: "",
          category: "",
          subcategory: "",
        },
        category: [],
        selectedService: "",
        subCategory: [],
        usage: [],
        categoryForm: "",
        productData: [],
        materialProductData: [{}],
        materialAllProductsData: [],
        rowItem: [],
      };
    }

    case SELECT_CATEGORY: {
      const selectedCategory = state.requestCategoriesData.category.filter(
        (i) => i.id === action.payload
      );
      return {
        ...state,
        category: selectedCategory.length > 0 ? selectedCategory[0].category : [],
        selectedService: action.payload,
        subCategory: [],
        requestCreationData: {
          ...state.requestCreationData,
          service: state.selectedService,
        },
        requestSelectedItems: {
          ...state.requestSelectedItems,
          service: action.payload,
          category: "",
        },
        categoryForm: "",
      };
    }

    case SELECT_SUB_CATEGORY: {
      const selectedSubCategory = state.category.filter(
        (i) => i.id === action.payload
      );
      return {
        ...state,
        subCategory:
          selectedSubCategory.length > 0
            ? selectedSubCategory[0].sub_category
            : state.subCategory,
        usage:
          selectedSubCategory.length > 0 &&
          selectedSubCategory[0].sub_category.length === 0
            ? prepareUsage(selectedSubCategory[0].usage)
            : [],
        categoryForm: "",
        requestSelectedItems: {
          ...state.requestSelectedItems,
          category: action.payload,
          subcategory: "",
        },
      };
    }
    case SELECT_CATEGORY_FORM: {
      return {
        ...state,
        categoryForm: action.payload,
        requestCreationData: {
          ...state.requestCreationData,
          sub_category_id: action.payload,
        },
        requestSelectedItems: {
          ...state.requestSelectedItems,
          subcategory: action.payload,
        },
      };
    }

    case GET_EDIT_REQUEST_DATA: {
      const selectedCategory = state.requestCategoriesData.category.filter(
        (i) => i.id === state.requestSelectedItems.service
      );
      const selectedSubCategory = selectedCategory[0].category.filter(
        (i) => i.id === state.requestSelectedItems.category
      );
      return {
        ...state,
        category: selectedCategory[0].category,
        subCategory:
          selectedSubCategory.length > 0
            ? selectedSubCategory[0].sub_category.length === 0
              ? state.subCategory
              : selectedSubCategory[0].sub_category
            : state.subCategory,
        usage:
          selectedSubCategory.length > 0
            ? selectedSubCategory[0].sub_category.length === 0
              ? prepareUsage(selectedSubCategory[0].usage)
              : []
            : [],
        categoryForm: state.requestSelectedItems.subcategory,
        selectedService: state.requestSelectedItems.service,
      };
    }
    case GET_REQUEST_CATEGORIES.SUCCESS: {
      const { data } = action;
      return {
        ...state,
        requestCategoriesData: {
          category: data.category,
          stage: prepareRequestStages(data.stage),
          transport: prepareRequestTransport([data.transport]),
        },
      };
    }

    case GET_REQUEST_VIEW_DATA.SUCCESS: {
      let viewData = {};

      if (action.data.lead) {
        const selectedSite = state.siteMasterDataRaw.filter(
          (site) => site.id === action.data.lead.id
        );
        viewData = {
          ...state,
          requestViewQuote: [],
          requestCreationData: {
            ...action.data.leadRequest,
            transport: action.data.relatedTransport,
          },
          selectedJobSiteForRequest: {
            id: action.data.lead.id,
            name: action.data.lead.name,
            user_id: action.data.lead.user_id,
            company_id: action.data.lead.company_id,
            company_name: action.data.lead.company_name,
            end_date: action.data.lead.end_date,
            status_id: action.data.lead.status_id,
            start_date: selectedSite.length > 0 ? selectedSite[0].start_date : "",
            duration: selectedSite.length > 0 ? selectedSite[0].duration : "",
          },
        };
      } else {
        const selectedSite = state.siteMasterDataRaw.filter(
          (site) => site.id === action.data.jobsite.id
        );
        viewData = {
          ...state,
          requestViewQuote: [...action.data.quote],
          requestCreationData: {
            ...action.data.request,
            transport: action.data.relatedTransport,
          },
          selectedJobSiteForRequest: {
            id: action.data.jobsite.id,
            name: action.data.jobsite.name,
            user_id: action.data.jobsite.user_id,
            company_id: action.data.jobsite.company_id,
            company_name: action.data.jobsite.company_name,
            end_date: action.data.jobsite.end_date,
            status_id: action.data.jobsite.status_id,
            start_date: selectedSite.length > 0 ? selectedSite[0].start_date : "",
            duration: selectedSite.length > 0 ? selectedSite[0].duration : "",
          },
        };
      }

      return viewData;
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
