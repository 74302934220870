import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";

import SiteDetailsHome from "./components/SiteDetails";

import Header from "../../shared/components/Header";
import Navbar from "../../shared/components/NavBar";

import { prepareTabListData } from "../../shared/utils/methods";

import "./SiteOverview.scss";

const SiteOverview = (props) => {
  const tabData = [props.t("CLIENT_PLATFORM_SITE_VIEW_HEADER_SITE_OVERVIEW")];
  const preparedTabData = prepareTabListData(tabData);

  useEffect(() => {
    ReactGA.pageview("/viewsite");
    // delete intermediate request form data if stored.
    localStorage.removeItem("requestData");
    localStorage.removeItem("selectedJobsiteForRequest");
    localStorage.removeItem("requestService");
    localStorage.removeItem("requestCategory");
    localStorage.removeItem("requestSubCategory");
  }, []);

  useEffect(() => {
    let lng = "en";
    if (localStorage.getItem("userCountryId")) {
      const countryId = localStorage.getItem("userCountryId").toString();
      if (countryId === "1") {
        lng = "en";
      } else if (countryId === "2") {
        lng = "fr";
      } else if (countryId === "3") {
        lng = "pl";
      } else if (countryId === "6") {
        lng = "de";
      } else if (countryId === "8") {
        lng = "ch";
      }
    }
    setTimeout(() => {
      props.i18n.changeLanguage(lng);
    }, 0);
  }, [props.i18n]);

  return (
    <div className="container-fluid view-site-container">
      <Navbar />
      <div className="container-fluid">
        <Header tabData={preparedTabData} />
        <div className="row site-details-container">
          <SiteDetailsHome />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(SiteOverview);
