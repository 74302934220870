import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import $ from "jquery";
import PropTypes from "prop-types";

import {
  clearDashboardData,
  getDashboardData,
  getDashboardLeads,
  clearSelectedSite,
} from "./Dashboard.actionCreators.js";

import {
  getCategoriesData,
  showPopUp,
  getRequestViewData,
  getCallOfOrderPopupVisible,
  getSiteMasterListData,
} from "../../store/dataStore";
import { handleLogOut } from "../../store/loginData";

import {
  handleEditJobSite,
  createSite,
} from "../JobsiteForm/JobsiteForm.actionCreators";
import { createCalloffOrderForm } from "../CallOffOrderForm/CallOffOrderForm.actionCreators";
import {
  editEnquiry,
  createEnquiry,
} from "../InquiryForm/InquiryForm.actionCreators";

import Navbar from "../../shared/components/NavBar";

import DashboardHome from "./components/DashboardHome";

import "./Dashboard.scss";

const Dashboard = (props) => {
  const { logoutStatus, apiUnAuthenticated } = props;
  const history = useHistory();

  useEffect(() => {
    $(function () {
      $("#sidebarCollapse").on("click", function () {
        $("#sidebar, #content").toggleClass("active");
        $(".collapse.in").toggleClass("in");
        $("a[aria-expanded=true]").attr("aria-expanded", "false");
      });
    });

    // delete intermediate request form data if stored.
    localStorage.removeItem("requestData");
    localStorage.removeItem("selectedJobsiteForRequest");
    localStorage.removeItem("requestService");
    localStorage.removeItem("requestCategory");
    localStorage.removeItem("requestSubCategory");
  }, []);

  useEffect(() => {
    if (logoutStatus || apiUnAuthenticated) {
      props.clearDashboardData();
      props.handleLogOut();
      history.replace("/login");
      return true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoutStatus, apiUnAuthenticated, history]);

  return (
    <div className="container-fluid dashboard-container">
      <Navbar />
      <div id="content">
        <DashboardHome
          userId={props.userId}
          siteData={props.siteData}
          isLoading={props.isLoading}
          enquiryData={props.enquiryData}
          quoteData={props.quoteData}
          lead={props.lead}
          leadList={props.leadList}
          leadRequestList={props.leadRequestList}
          isLeadListLoading={props.isLeadListLoading}
          isLeadFlow={props.isLeadFlow}
          isCallOfOrder={props.isCallOfOrder}
          showPopup={props.showPopup}
          apiUnAuthenticated={props.apiUnAuthenticated}
          userDetails={props.userDetails}
          isCallOfOrderPopupVisible={props.isCallOfOrderPopupVisible}
          getDashboardData={props.getDashboardData}
          getDashboardLeads={props.getDashboardLeads}
          getCategoriesData={props.getCategoriesData}
          createSite={props.createSite}
          createEnquiry={props.createEnquiry}
          createCalloffOrderForm={props.createCalloffOrderForm}
          editEnquiry={props.editEnquiry}
          handleShowPopUp={props.showPopUp}
          getRequestViewData={props.getRequestViewData}
          clearSelectedSite={props.clearSelectedSite}
          handleLogOut={props.handleLogOut}
          handleEditJobSite={props.handleEditJobSite}
          getCallOfOrderPopupVisible={props.getCallOfOrderPopupVisible}
          getSiteMasterListData={props.getSiteMasterListData}
        />
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  logoutStatus: PropTypes.bool,
  apiUnAuthenticated: PropTypes.bool,
  clearDashboardData: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  siteData: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  enquiryData: PropTypes.array.isRequired,
  quoteData: PropTypes.array.isRequired,
  lead: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  leadList: PropTypes.array,
  leadRequestList: PropTypes.array,
  isLeadListLoading: PropTypes.bool,
  isLeadFlow: PropTypes.bool,
  isCallOfOrder: PropTypes.number,
  showPopup: PropTypes.bool,
  userDetails: PropTypes.object.isRequired,
  isCallOfOrderPopupVisible: PropTypes.bool,
  getDashboardData: PropTypes.func.isRequired,
  getDashboardLeads: PropTypes.func,
  getCategoriesData: PropTypes.func.isRequired,
  createSite: PropTypes.func.isRequired,
  createEnquiry: PropTypes.func.isRequired,
  createCalloffOrderForm: PropTypes.func.isRequired,
  editEnquiry: PropTypes.func.isRequired,
  showPopUp: PropTypes.func.isRequired,
  getRequestViewData: PropTypes.func.isRequired,
  clearSelectedSite: PropTypes.func.isRequired,
  handleLogOut: PropTypes.func.isRequired,
  handleEditJobSite: PropTypes.func.isRequired,
  getCallOfOrderPopupVisible: PropTypes.func.isRequired,
  getSiteMasterListData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    logoutStatus: !!state.loginStore.logoutStatus,
    userId:
      localStorage.getItem("userId") !== "" ? localStorage.getItem("userId") : "",
    siteData: state.dashboard.jobSites,
    isLoading: state.dashboard.isLoading,
    enquiryData: state.dashboard.requests,
    quoteData: state.dashboard.quotes,
    lead: state.dashboard.lead,
    leadList: state.dashboardLeads.leadsList,
    isLeadListLoading: state.dashboardLeads.isLoading,
    leadRequestList: state.dashboardLeads.leadRequestList,
    isLeadFlow: state.dashboard.isLeadFlow,
    isCallOfOrder: state.dashboard.isCallOfOrder,
    apiUnAuthenticated: state.dashboard.apiUnAuthenticated,
    showPopup: state.dataStore.showPopup,
    userDetails:
      JSON.parse(localStorage.getItem("userInfo")) !== {}
        ? JSON.parse(localStorage.getItem("userInfo"))
        : "",
    isCallOfOrderPopupVisible: state.dataStore.isCallOfOrderPopupVisible,
  };
};

const mapDispatchToProps = {
  clearDashboardData: () => clearDashboardData(),
  getDashboardData: (payload) => getDashboardData(payload),
  getDashboardLeads: (payload) => getDashboardLeads(payload),
  getCategoriesData: () => getCategoriesData(),
  createSite: (payload) => createSite(payload),
  createEnquiry: () => createEnquiry(),
  createCalloffOrderForm: () => createCalloffOrderForm(),
  editEnquiry: (payload) => editEnquiry(payload),
  showPopUp: () => showPopUp(),
  getRequestViewData: (payload) => getRequestViewData(payload),
  clearSelectedSite: () => clearSelectedSite(),
  handleLogOut: () => handleLogOut(),
  handleEditJobSite: (payload) => handleEditJobSite(payload),
  getCallOfOrderPopupVisible: (payload) => getCallOfOrderPopupVisible(payload),
  getSiteMasterListData: () => getSiteMasterListData(),
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
