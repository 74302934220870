import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import axios from "axios";
import PropTypes from "prop-types";

import baseURL from "../../../../shared/utils/constant";
import ErrorPopUp from "../../../../shared/baseUI/ErrorPopUp";

import quoteIcon from "../../../../assets/shared/ic-quote.svg";
import pdfIcon from "../../../../assets/shared/ic-pdf.svg";

import { getQuoteStatusList } from "../../../../shared/utils/methods";

import "./QuoteItem.scss";

const QuoteItem = (props) => {
  const { data, t } = props;
  const [isDetailsVisible, setDetailsVisibility] = useState(false);
  const [showErrorPopup, setErrorPopupvisibility] = useState(false);
  const [apiError, setApiError] = useState("");
  const [requests, setRequests] = useState([]);
  const [otherItems, setOtherItems] = useState([]);

  const status = getQuoteStatusList();

  useEffect(() => {
    setRequests(Object.values(data.requests));
    const localRequests = Object.values(data.requests);
    localRequests.forEach((req) => {
      if (!req.info) {
        const items = Object.values(req).map((item) => item.items);
        setOtherItems(items);
      }
    });
  }, [data.requests]);

  const getQuoteStatusColor = (quoteStatus) => {
    if (quoteStatus === 2) {
      return "quote-item-header__status quote-item-header__status--submitted";
    }
    if (quoteStatus === 3) {
      return "quote-item-header__status quote-item-header__status--validated";
    }
    if (quoteStatus === 5) {
      return "quote-item-header__status quote-item-header__status--cancelled";
    }
    if (quoteStatus === 6 || quoteStatus === 7) {
      return "quote-item-header__status quote-item-header__status--lost";
    }
    return "quote-item-header__status";
  };

  const renderItem = (item) => {
    if (item[0] === "2") {
      return Object.values(Object.values(item[1])).map((quote, index) => {
        const info = quote[0];
        return (
          <div
            key={index}
            className="quote-item-details__request-item-details-info-container dot"
          >
            <div className="quote-item-details_request-item-details-info-wrapper">
              <div className="quote-item-details__request-item-details-info-wrapper">
                <div className="quote-item-details__request-item-details-info">
                  <span className="quote-item-details__request-item-details-info-service">
                    {`${t(info.service)} - `}
                  </span>
                  <span className="quote-item-details__request-item-details-info-product">
                    {info.product}
                  </span>
                </div>
                <span className="quote-item-details__request-item-details-info-price">
                  {info.euroCurrency
                    ? `${info.price + info.euroCurrency} / ${t(info.unit)}`
                    : `${info.price + info.ukCurrency} / ${t(info.unit)}`}
                </span>
              </div>
              <p className="quote-item-details__request-item-details-info-transport">
                {info.transport}
              </p>
            </div>
          </div>
        );
      });
    }
    if (item[0] === "4" || item[0] === "3") {
      return Object.values(Object.values(item[1])).map((quote, index) => {
        const info = quote[0];
        return (
          <div
            key={index}
            className="quote-item-details__request-item-details-info-container dot"
          >
            <div className="quote-item-details__request-item-details-info-wrapper quote-item-details__request-item-details-info-wrapper--dot">
              <div className="quote-item-details__request-item-details-info">
                <span className="quote-item-details__request-item-details-info-service">
                  {`${t(info.service)} - `}
                </span>
                <span className="quote-item-details__request-item-details-info-product">
                  {info.product}
                </span>
              </div>
              <span className="quote-item-details__request-item-details-info-price">
                {info.euroCurrency
                  ? `${info.price + info.euroCurrency} / ${t(info.unit)}`
                  : `${info.price + info.ukCurrency} / ${t(info.unit)}`}
              </span>
            </div>
          </div>
        );
      });
    }
    if (item[0] === "1") {
      return Object.values(Object.values(item[1])).map((quote) => {
        const firstRow = quote[0];
        const secondRow = quote[1];
        return (
          <>
            <div className="quote-item-details__request-item-details-info-container">
              <div className="quote-item-details__request-item-details-info-wrapper">
                <div className="quote-item-details__request-item-details-info">
                  <span className="quote-item-details__request-item-details-info-service">
                    {`${t(firstRow.service)} - `}
                  </span>
                  <span className="quote-item-details__request-item-details-info-product">
                    {firstRow.product}
                  </span>
                </div>
                <span className="quote-item-details__request-item-details-info-price">
                  {firstRow.euroCurrency
                    ? `${firstRow.price + firstRow.euroCurrency} / ${t(
                        firstRow.unit
                      )}`
                    : `${firstRow.price + firstRow.ukCurrency} / ${t(
                        firstRow.unit
                      )}`}
                </span>
              </div>
            </div>
            <div className="quote-item-details__request-item-details-info-container">
              <div className="quote-item-details__request-item-details-info-wrapper">
                <div className="quote-item-details__request-item-details-info">
                  <span className="quote-item-details__request-item-details-info-service">
                    {`${t(secondRow.service)} - `}
                  </span>
                  <span className="quote-item-details__request-item-details-info-product">
                    {secondRow.product}
                  </span>
                </div>
                <span className="quote-item-details__request-item-details-info-price">
                  {secondRow.euroCurrency
                    ? `${secondRow.price + secondRow.euroCurrency} / ${t(
                        secondRow.unit
                      )}`
                    : `${secondRow.price + secondRow.ukCurrency} / ${t(
                        secondRow.unit
                      )}`}
                </span>
              </div>
            </div>
          </>
        );
      });
    }
    if (item[5]) {
      return Object.values(Object.values(item[5])).map((quote, index) => {
        const info = quote[0];
        return (
          <div
            key={index}
            className="quote-item-details__request-item-details-info-container dot"
          >
            <div className="quote-item-details__request-item-details-info-wrapper quote-item-details__request-item-details-info-wrapper--dot">
              <div className="quote-item-details__request-item-details-info">
                <span className="quote-item-details__request-item-details-info-service">
                  {t(info.service)}
                </span>
              </div>
              <span className="quote-item-details__request-item-details-info-price">
                {info.euroCurrency
                  ? `${info.price + info.euroCurrency} / ${t(info.unit)}`
                  : `${info.price + info.ukCurrency} / ${t(info.unit)}`}
              </span>
            </div>
          </div>
        );
      });
    }
  };

  const renderRequestHeader = (info) => {
    return (
      <>
        <span>{`#${info.id} - `}</span>
        <span>{`${t(info.category)} - ${t(info.subCategory)} - `}</span>
        <span>{info.name}</span>
      </>
    );
  };

  const onHeaderClick = () => {
    setDetailsVisibility(!isDetailsVisible);
  };

  const axiosInstance = (token) => {
    return axios.create({
      baseURL,
      timeout: 36000,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const downloadPDFDoc = (id) => {
    setErrorPopupvisibility(false);
    setApiError("");
    axiosInstance(localStorage.getItem("token"))
      .get(`${baseURL}/documents/download/${id}`)
      .then((res) => {
        const { fileName } = res.data;
        fetch(res.data.data)
          .then((resp) => resp.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            // the filename you want
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .catch(() => {
            setErrorPopupvisibility(true);
            setApiError("some  err while downloading");
          });
      })
      .catch((err) => {
        setErrorPopupvisibility(true);
        setApiError(err.response.data.message);
      });
  };

  const closePopup = () => {
    setErrorPopupvisibility(false);
  };

  return (
    <section className="quote-item-wrapper">
      {showErrorPopup && (
        <ErrorPopUp
          title={t("KICK_OFF_ERROR_BOX_TITLE")}
          btnText={t("KICK_OFF_ERROR_BOX_OK")}
          message={apiError}
          onOkClick={closePopup}
        />
      )}
      <div
        className="quote-item-header"
        onClick={onHeaderClick}
        role="button"
        tabIndex="0"
      >
        <img src={quoteIcon} alt="quote icon" className="quote-item-header__icon" />
        <div className="quote-item-header__wrapper">
          <div className="quote-item-header__title-wrapper">
            <div className="quote-item-header__name-id-wrapper">
              <h4 className="quote-item-header__name">{data.name}</h4>
              <span className="quote-item-header__id">{`#${data.id}`}</span>
            </div>
            <span className={getQuoteStatusColor(parseInt(data.status, 10))}>
              {t(status[parseInt(data.status, 10)])}
            </span>
          </div>
          <div>
            <span className="quote-item-header__jobsite-name">
              {data.jobsite_name}
            </span>
            <span className="quote-item-header__enquiries">
              {`${data.total_requests} ${t(
                "CLIENT_PLATFORM_SITE_OVERVIEW_QUOTES_INQUIRY_COUNT"
              )}`}
            </span>
          </div>
        </div>
      </div>
      <div
        className={
          isDetailsVisible
            ? "quote-item-details quote-item-details--show"
            : "quote-item-details"
        }
      >
        <ul className="quote-item-details__requests">
          {requests.map((request, i) => {
            return (
              <li
                className="quote-item-details__request-item"
                key={`request-item${i}`}
              >
                <ul className="quote-item-details__request-item-list">
                  <li
                    className="quote-item-details__request-item-info"
                    key={`request-info${i}`}
                  >
                    <div>
                      <div className="quote-item-details__request-item-title">
                        {request.info &&
                          request.info.id !== 0 &&
                          renderRequestHeader(request.info)}
                      </div>
                    </div>
                    <ul className="quote-item-details__request-item-details">
                      {(request.items
                        ? Object.entries(request.items)
                        : otherItems
                      ).map((quoteInfo, quoteInfoIndex) => {
                        return (
                          <li
                            className={
                              quoteInfo[0] === "1"
                                ? "quote-item-details__request-item-details-row dot"
                                : "quote-item-details__request-item-details-row"
                            }
                            key={`row${quoteInfoIndex}`}
                          >
                            <div className="quote-item-details__request-item-details-row-wrapper">
                              {renderItem(quoteInfo)}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                </ul>
              </li>
            );
          })}
        </ul>
      </div>
      {data.quote_file_id && (
        <div className="quote-item-pdf-wrapper">
          <img src={pdfIcon} className="quote-item-pdf-icon" alt="quote pdf" />
          <span
            onClick={() => {
              if (data.quote_file_id) {
                downloadPDFDoc(data.quote_file_id);
              }
            }}
          >
            {t("CLIENT_PLATFORM_SITE_OVERVIEW_QUOTE_DOWNLOAD")}
          </span>
        </div>
      )}
    </section>
  );
};

QuoteItem.propTypes = {
  data: PropTypes.shape(PropTypes.object).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(QuoteItem);
