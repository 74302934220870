import axios from "axios";
import baseURL from "../../shared/utils/constant";

import { getStorageInfo } from "../../shared/utils/methods";

const axiosInstance = (token) => {
  return axios.create({
    baseURL,
    timeout: 36000,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUserAccountDataAPI = () => {
  const userData = getStorageInfo();
  return axiosInstance(userData.token)
    .get(`${baseURL}/account/${userData.id}`)
    .then((res) => res.data);
};

export const updateUserAccountDataAPI = (data) => {
  const userData = getStorageInfo();
  return axiosInstance(userData.token)
    .put(`${baseURL}/account/${userData.id}`, data)
    .then((res) => res.data);
};

export const updatePasswordDataAPI = (data) => {
  const userData = getStorageInfo();
  return axiosInstance(userData.token)
    .put(`${baseURL}/password/${userData.id}`, data)
    .then((res) => res.data);
};
