import React from "react";

import "./ErrorPopUp.scss";

const ErrorPopUp = (props) => {
  const { title, message, btnText } = props;

  return (
    <section className="error-popup">
      <div className="error-popup__box">
        <h2 className="error-popup__title">{title}</h2>
        <div className="error-popup__content-wrapper">
          <p className="error-popup__content">{message}</p>
        </div>
        <div className="error-popup__btn-wrapper">
          <button
            className="error-popup__btn error-popup__btn--ok"
            onClick={props.onOkClick}
          >
            {btnText}
          </button>
        </div>
      </div>
    </section>
  );
};

export default ErrorPopUp;
