import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { prepareShortDateFormat } from "../../utils/methods";

const JobsiteTile = (props) => {
  const { t, name, start_date, duration, requests_count, sitelogo } = props;

  const renderDuration = (durationVal) => {
    const durationOptions = {
      "1_MONTH": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_1_MONTH"
      ),
      "2_MONTH": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_2_MONTHS"
      ),
      "3_MONTH": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_3_MONTHS"
      ),
      "6_MONTH": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_6_MONTHS"
      ),
      "1_YEAR": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_1_YEAR"
      ),
      "2_YEAR": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_2_YEARS"
      ),
      MORE: t("CLIENT_PLATFORM_SITE_FORM_SITE_DURATION_MORE"),
    };

    return durationOptions[durationVal] ? ` - ${durationOptions[durationVal]}` : "";
  };

  return (
    <div className="site-item">
      <img className="site-img" src={sitelogo} alt="Construction Site" />
      <div className="site-info">
        <h5 className="site-name">{name}</h5>
        <p className="site-details">
          {prepareShortDateFormat(start_date)}
          {renderDuration(duration)}
          <span className="gray-darker-text pl-1">
            {requests_count <= 1 &&
              `- ${requests_count} ${t("CLIENT_PLATFORM_SITE_LIST_INQUIRY")}`}
            {requests_count > 1 &&
              `- ${requests_count} ${t("CLIENT_PLATFORM_SITE_LIST_INQUIRIES")}`}
          </span>
        </p>
      </div>
    </div>
  );
};

JobsiteTile.propTypes = {
  name: PropTypes.string.isRequired,
  start_date: PropTypes.string.isRequired,
  duration: PropTypes.string,
  requests_count: PropTypes.number.isRequired,
  sitelogo: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(JobsiteTile);
