import React from "react";

const Popup = ({
  modalParentClass,
  modalClass,
  name,
  id,
  title,
  content,
  onClose,
  modalFooter,
}) => {
  return (
    <div className={modalParentClass}>
      <div className={modalClass} id={id}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{content}</div>
            <div className="modal-footer">{modalFooter}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
