import axios from "axios";
import * as moment from "moment";

import baseURL from "../../shared/utils/constant";

import { getStorageInfo } from "../../shared/utils/methods";

const axiosInstance = (token) => {
  return axios.create({
    baseURL,
    timeout: 36000,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

const getEndDate = (startDate, duration) => {
  const currentDate = moment(startDate);
  const durationArr = duration === "MORE" ? ["2", "YEAR"] : duration.split("_");
  const durationVal = durationArr[0];
  const durationUnit = durationArr[1] === "YEAR" ? "Y" : "M";

  return moment(currentDate)
    .add(parseInt(durationVal, 10), durationUnit)
    .format("YYYY-MM-DD");
};

export const addNewRequestAPI = ({ token, data, id }) => {
  const form = document.getElementById("enquiry-form");
  const formData = new FormData(form);
  formData.delete("product");
  formData.delete("terms");
  formData.delete("start_date");

  formData.append("service", parseInt(data.service, 10));
  formData.append("analysis_flag", JSON.stringify(data.analysis_flag));
  formData.append("transport", JSON.stringify(data.transport));
  formData.append("product", JSON.stringify(data.product));
  formData.append("sub_category_id", JSON.stringify(data.sub_category_id));
  formData.append("is_call_for_tender", parseInt(data.is_call_for_tender, 10));
  formData.append("end_date", getEndDate(data.start_date, data.duration));
  formData.append("related_contact", JSON.stringify(data.related_contact));
  formData.append("terms", data.terms);
  formData.append("start_date", data.start_date);
  formData.append("unit", data.unit);
  formData.append("duration", data.duration);

  if (
    Object.prototype.hasOwnProperty.call(data, "usage_id") &&
    data.usage_id &&
    parseInt(data.usage_id, 10) !== 0
  ) {
    formData.append(
      "usage_id",
      parseInt(data.usage_id, 10) ? parseInt(data.usage_id, 10) : 0
    );
  }

  data.document.forEach((x) => {
    if (x.file) {
      formData.append("document[][type]", x.type);
      formData.append("document[][file]", x.file);
    }
  });
  data.jobsite_document.forEach((x) => {
    formData.append("jobsite_document[][id]", x.id);
  });
  if (data.leadId) {
    formData.append("lead_id", data.leadId);
  }
  formData.delete("document");

  return axios
    .post(`${baseURL}/sites/${id}/requests`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
};

export const addNewLeadRequestAPI = ({ token, data, id }) => {
  const form = document.getElementById("enquiry-form");
  const formData = new FormData(form);
  formData.delete("product");
  formData.delete("terms");
  formData.delete("start_date");

  formData.append("service", parseInt(data.service, 10));
  formData.append("analysis_flag", JSON.stringify(data.analysis_flag));
  formData.append("transport", JSON.stringify(data.transport));
  formData.append("product", JSON.stringify(data.product));
  formData.append("sub_category_id", JSON.stringify(data.sub_category_id));
  formData.append("is_call_for_tender", parseInt(data.is_call_for_tender, 10));
  formData.append("end_date", getEndDate(data.start_date, data.duration));
  formData.append("related_contact", JSON.stringify(data.related_contact));
  formData.append("terms", data.terms);
  formData.append("start_date", data.start_date);
  formData.append("unit", data.unit);
  formData.append("duration", data.duration);

  if (
    Object.prototype.hasOwnProperty.call(data, "usage_id") &&
    data.usage_id &&
    parseInt(data.usage_id, 10) !== 0
  ) {
    formData.append(
      "usage_id",
      parseInt(data.usage_id, 10) ? parseInt(data.usage_id, 10) : 0
    );
  }

  data.document.forEach((x) => {
    if (x.file) {
      formData.append("document[][type]", x.type);
      formData.append("document[][file]", x.file);
    }
  });
  data.jobsite_document.forEach((x) => {
    formData.append("jobsite_document[][id]", x.id);
  });
  if (data.leadId) {
    formData.append("lead_id", data.leadId);
  }
  formData.delete("document");

  return axios
    .post(`${baseURL}/leads/${id}/leadRequest`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
};

export const updateRequestAPI = ({ token, data, id }) => {
  const form = document.getElementById("enquiry-form");
  const formData = new FormData(form);
  formData.delete("product");
  formData.delete("terms");
  formData.delete("start_date");
  formData.delete("usage_id");

  if (Object.prototype.hasOwnProperty.call(data, "study_type")) {
    formData.append("study_type", parseInt(data.study_type, 10));
  }
  if (
    Object.prototype.hasOwnProperty.call(data, "usage_id", 10) &&
    data.usage_id &&
    parseInt(data.usage_id, 10) !== 0
  ) {
    formData.append(
      "usage_id",
      parseInt(data.usage_id, 10) ? parseInt(data.usage_id, 10) : 0
    );
  }
  formData.append("service", parseInt(data.service, 10));
  formData.append("analysis_flag", JSON.stringify(data.analysis_flag));
  formData.append("transport", JSON.stringify(data.transport));
  formData.append("product", JSON.stringify(data.product));
  formData.append("sub_category_id", JSON.stringify(data.sub_category_id));
  formData.append("is_call_for_tender", parseInt(data.is_call_for_tender, 10));
  formData.append("end_date", getEndDate(data.start_date, data.duration));
  formData.append("terms", data.terms);
  formData.append("start_date", data.start_date);
  formData.append("unit", data.unit);
  formData.append("duration", data.duration);

  data.document.forEach((x) => {
    formData.append("document[][type]", x.type);
    formData.append("document[][file]", x.file);
  });
  if (data.leadId) {
    formData.append("lead_id", data.leadId);
  }
  formData.delete("document");

  return axios
    .post(`${baseURL}/sites/${id}/requests/${data.id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
};

export const updateLeadRequestAPI = ({ token, data, id }) => {
  const form = document.getElementById("enquiry-form");
  const formData = new FormData(form);
  formData.delete("product");
  formData.delete("terms");
  formData.delete("start_date");
  formData.delete("usage_id");
  if (Object.prototype.hasOwnProperty.call(data, "study_type")) {
    formData.append("study_type", parseInt(data.study_type, 10));
  }
  if (
    Object.prototype.hasOwnProperty.call(data, "usage_id") &&
    data.usage_id &&
    parseInt(data.usage_id, 10) !== 0
  ) {
    formData.append(
      "usage_id",
      parseInt(data.usage_id, 10) ? parseInt(data.usage_id, 10) : 0
    );
  }
  formData.append("service", parseInt(data.service, 10));
  formData.append("analysis_flag", JSON.stringify(data.analysis_flag));
  formData.append("transport", JSON.stringify(data.transport));
  formData.append("product", JSON.stringify(data.product));
  formData.append("sub_category_id", JSON.stringify(data.sub_category_id));
  formData.append("is_call_for_tender", parseInt(data.is_call_for_tender, 10));
  formData.append("end_date", getEndDate(data.start_date, data.duration));
  formData.append("terms", data.terms);
  formData.append("start_date", data.start_date);
  formData.append("unit", data.unit);
  formData.append("duration", data.duration);

  data.document.forEach((x) => {
    formData.append("document[][type]", x.type);
    formData.append("document[][file]", x.file);
  });
  if (data.leadId) {
    formData.append("lead_id", data.leadId);
  }
  formData.delete("document");

  return axios
    .post(`${baseURL}/leads/${id}/leadRequests/${data.id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
};

export const getMaterialProductDataAPI = (data) => {
  const userData = getStorageInfo();
  return axiosInstance(userData.token)
    .get(`${baseURL}/products/usages/${data.id}`)
    .then((res) => {
      return { id: data.id, data: res.data };
    });
};
