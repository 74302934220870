import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import SectionHeader from "../../../../shared/components/SectionHeader";
import EmptyView from "../../../../shared/components/EmptyView";

import Button from "../../../../shared/baseUI/Button";

import QuoteItem from "../QuoteItem";

import enquiryBtn from "../../../../assets/shared/invoice-icon.svg";

const MyQuotes = (props) => {
  const { t, quotes, quotesList } = props;

  useEffect(() => {
    let lng = "en";
    if (localStorage.getItem("userCountryId")) {
      const countryIdLocal = localStorage.getItem("userCountryId").toString();
      if (countryIdLocal === "1") {
        lng = "en";
      } else if (countryIdLocal === "2") {
        lng = "fr";
      } else if (countryIdLocal === "3") {
        lng = "pl";
      } else if (countryIdLocal === "6") {
        lng = "de";
      } else if (countryIdLocal === "8") {
        lng = "ch";
      }
    }
    props.i18n.changeLanguage(lng);
  }, [props.i18n]);

  const nextData = (pageNum) => {
    const { siteId } = props;

    if (props.quotes.length !== props.quotesList.total) {
      props.getQuotes({ id: siteId, pageNum: pageNum + 1 });
    }
  };

  const showMoreData = () => {
    if (quotesList && quotesList.current_page < quotesList.last_page) {
      nextData(quotesList.current_page);
    }
  };

  const renderQuotes = () => {
    const quotesSection =
      quotes &&
      quotes.map((item) => {
        return <QuoteItem key={item.id} data={item} />;
      });

    return quotesSection;
  };

  return (
    <div className="quotes-wrapper">
      <SectionHeader
        title={
          t("CLIENT_PLATFORM_SITE_OVERVIEW_QUOTES_TITLE") +
          (quotesList && quotesList.total > 0 ? ` (${quotesList.total})` : "")
        }
        link="/sitesandenquiries"
        viewAllTitle="My call-off orders"
        isViewAllVisible={false}
      />
      {props.isLoading && (
        <>
          <div className="loader-item first">
            <Skeleton width={50} height={50} />
            <div className="loader-item-text">
              <Skeleton width={200} />
              <Skeleton width={200} />
              <Skeleton width={100} />
            </div>
          </div>
          <div className="loader-item">
            <Skeleton width={50} height={50} />
            <div className="loader-item-text">
              <Skeleton width={200} />
              <Skeleton width={200} />
              <Skeleton width={100} />
            </div>
          </div>
        </>
      )}
      {quotes.length > 0 && <div id="scrollableDiv">{renderQuotes()}</div>}
      {!props.isLoading && quotesList && quotes.length < quotesList.total && (
        <Button
          className="btn btn-primary d-block btn-sm btn-dark-gray w-100"
          name={t("CLIENT_PLATFORM_SITE_OVERVIEW_INQUIRIES_BUTTON_SEE_MORE")}
          onClick={() => showMoreData()}
        />
      )}
      {!props.isLoading && quotes.length === 0 && (
        <EmptyView
          link="/createenquiry"
          description={t(
            "CLIENT_PLATFORM_SITE_OVERVIEW_QUOTES_NO_QUOTE_DESCRIPTION"
          )}
          title={t("CLIENT_PLATFORM_SITE_OVERVIEW_QUOTES_NO_QUOTE_TITLE")}
          icon={enquiryBtn}
          buttonName="Create a new Enquiry"
          isBtnVisible={false}
          onBtnClick={() => {
            props.createEnquiry();
          }}
        />
      )}
    </div>
  );
};

export default withTranslation()(MyQuotes);
