import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: "en",
    backend: {
      loadPath: `https://${process.env.REACT_APP_BASE_ADMIN}/translations/messages.{{lng}}.json`,
      customHeaders: {
        "Cache-Control": "no-cache",
      },
    },
    fallbackLng: "en",
    debug: process.env.NODE_ENV === "development",
    ns: ["translations"],
    defaultNS: "translations",
    supportedLngs: ["en", "fr", "ch", "pl", "de"],
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      wait: true,
    },
  });

export default i18n;
