import React from "react";
import ReactGA from "react-ga";

import { useHistory } from "react-router-dom";

import addBtn from "../../../assets/shared/add-icon.svg";
import "./AddNew.scss";

const AddNew = (props) => {
  const { name, link } = props;
  const history = useHistory();

  const onAdd = () => {
    props.onAdd();
    ReactGA.pageview(link);
    history.push(link);
  };

  return (
    <div onClick={onAdd}>
      <div className="create-site-btn-wrapper">
        <img className="create-site-icon" src={addBtn} alt="Create Site" />
        <h5 className="create-site-text">{name}</h5>
      </div>
    </div>
  );
};

export default AddNew;
