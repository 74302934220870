import axios from "axios";
import { getStorageInfo } from "../../shared/utils/methods";

import baseURL from "../../shared/utils/constant";

const axiosInstance = (token) => {
  return axios.create({
    baseURL,
    timeout: 36000,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getViewDataAPI = (id) => {
  const userData = getStorageInfo();
  return axiosInstance(userData.token)
    .get(`${baseURL}/sites/${id}`)
    .then((res) => res.data);
};

export const getLeadViewDataAPI = (id) => {
  const userData = getStorageInfo();
  return axiosInstance(userData.token)
    .get(`${baseURL}/leads/${id}`)
    .then((res) => res.data);
};

export const getRequestListingAPI = (payload) => {
  let url = "";
  const userData = getStorageInfo();
  if (!Object.prototype.hasOwnProperty.call(payload, "pageNum")) {
    url = `${baseURL}/sites/${payload.id}/requests`;
  } else {
    url = `${baseURL}/sites/${payload.id}/requests?page=${payload.pageNum}`;
  }
  return axiosInstance(userData.token)
    .get(url)
    .then((res) => res.data);
};

export const getLeadRequestListingAPI = (payload) => {
  let url = "";
  const userData = getStorageInfo();
  if (!Object.prototype.hasOwnProperty.call(payload, "pageNum")) {
    url = `${baseURL}/leads/${payload.id}/leadRequest`;
  } else {
    url = `${baseURL}/leads/${payload.id}/leadRequest?page=${payload.pageNum}`;
  }
  return axiosInstance(userData.token)
    .get(url)
    .then((res) => res.data);
};

export const getQuotesAPI = (payload) => {
  let url = "";
  const userData = getStorageInfo();
  if (!Object.prototype.hasOwnProperty.call(payload, "pageNum")) {
    url = `${baseURL}/sites/${payload.id}/quotes`;
  } else {
    url = `${baseURL}/sites/${payload.id}/quotes?page=${payload.pageNum}`;
  }
  return axiosInstance(userData.token)
    .get(url)
    .then((res) => res.data);
};
