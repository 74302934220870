import { takeLatest, put, call } from "redux-saga/effects";
import * as moment from "moment";

import {
  ADD_NEW_REQUEST,
  UPDATE_REQUEST_DATA,
  GET_MATERIAL_PRODUCT_DETAILS,
} from "./InquiryForm.constants";

import {
  addNewRequestAPI,
  addNewLeadRequestAPI,
  updateLeadRequestAPI,
  updateRequestAPI,
  getMaterialProductDataAPI,
} from "./InquiryForm.api";

function* addNewRequest(action) {
  const { token } = action.payload;
  const modifyData = { ...action.payload.requestData };

  modifyData.end_date = moment(modifyData.start_date)
    .add(modifyData.duration, "months")
    .format("YYYY-MM-DD");

  if (modifyData.is_call_for_tender === "") {
    modifyData.is_call_for_tender = 0;
  }
  if (modifyData.product.length > 1) {
    const arr1 = action.payload.rowItem.map((x) => x.rowId);
    modifyData.product = arr1;
  }
  if (modifyData.analysis_flag === "") {
    modifyData.analysis_flag = 0;
  }

  if (Object.prototype.hasOwnProperty.call(modifyData, "editData")) {
    delete modifyData.editData;
  }

  if (modifyData.selectedTransport) {
    delete modifyData.selectedTransport;
  }

  delete modifyData.initialRow;

  try {
    const userInfo = localStorage.getItem("userInfo");
    let userStatus;
    if (userInfo && JSON.parse(userInfo)) {
      userStatus = JSON.parse(userInfo).status;
    }
    const createRequestAPI =
      parseInt(userStatus, 10) === 3 ? addNewLeadRequestAPI : addNewRequestAPI;
    const data = yield call(createRequestAPI, {
      token,
      data: modifyData,
      id: action.payload.siteId,
    });
    yield put({ type: ADD_NEW_REQUEST.SUCCESS, data });
  } catch (error) {
    yield put({ type: ADD_NEW_REQUEST.ERROR, error });
  }
}

function* updateRequestData(action) {
  const { token } = action.payload;
  const modifyData = { ...action.payload.requestData };

  modifyData.document = modifyData.document.filter(
    (x) => !Object.prototype.hasOwnProperty.call(x, "filename")
  );
  modifyData.end_date = moment(modifyData.start_date)
    .add(modifyData.duration, "months")
    .format("YYYY-MM-DD");

  if (!modifyData.is_call_for_tender) {
    modifyData.is_call_for_tender = 0;
  }
  if (modifyData.product.length > 1) {
    const arr1 = action.payload.rowItem.map((x) => x.rowId);
    modifyData.product = arr1;
  }
  if (modifyData.analysis_flag === "") {
    modifyData.analysis_flag = 0;
  }
  if (Object.prototype.hasOwnProperty.call(modifyData, "editData")) {
    delete modifyData.editData;
    delete modifyData.selectedTransport;
  }

  try {
    const userInfo = localStorage.getItem("userInfo");
    let userStatus;
    if (userInfo && JSON.parse(userInfo)) {
      userStatus = JSON.parse(userInfo).status;
    }
    const editRequestAPI =
      parseInt(userStatus, 10) === 3 ? updateLeadRequestAPI : updateRequestAPI;
    const data = yield call(editRequestAPI, {
      token,
      data: modifyData,
      id: action.payload.siteId,
    });
    yield put({ type: UPDATE_REQUEST_DATA.SUCCESS, data });
  } catch (error) {
    yield put({ type: UPDATE_REQUEST_DATA.ERROR, error });
  }
}

function* getMaterialProductsData(action) {
  try {
    const data = yield call(getMaterialProductDataAPI, action.payload);
    yield put({ type: GET_MATERIAL_PRODUCT_DETAILS.SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_MATERIAL_PRODUCT_DETAILS.ERROR, error });
  }
}

export function* addNewRequestSaga() {
  yield takeLatest(ADD_NEW_REQUEST.LOADING, addNewRequest);
}

export function* updateRequestDataSaga() {
  yield takeLatest(UPDATE_REQUEST_DATA.LOADING, updateRequestData);
}

export function* getMaterialProductsDataSaga() {
  yield takeLatest(GET_MATERIAL_PRODUCT_DETAILS.LOADING, getMaterialProductsData);
}
