import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Popup from "../../../../shared/baseUI/Popup";
import Button from "../../../../shared/baseUI/Button";
import ErrorPopUp from "../../../../shared/baseUI/ErrorPopUp";

import Documents from "../../../../shared/components/Documents";

import { prepareShortDateFormat } from "../../../../shared/utils/methods";

import requestQuotationIcon from "../../../../assets/shared/ic-request-quotation.svg";

import "./LeadViewPopup.scss";

const LeadViewPopup = (props) => {
  const { lead, t, hideLead } = props;
  const [showErrorPopup, setErrorPopupvisibility] = useState(false);
  const [apiError, setApiError] = useState("");

  const history = useHistory();

  const renderDuration = (durationVal) => {
    const durationOptions = {
      "1_MONTH": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_1_MONTH"
      ),
      "2_MONTH": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_2_MONTHS"
      ),
      "3_MONTH": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_3_MONTHS"
      ),
      "6_MONTH": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_6_MONTHS"
      ),
      "1_YEAR": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_1_YEAR"
      ),
      "2_YEAR": t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_DETAILS_FORM_DATES_DURATION_2_YEARS"
      ),
      MORE: t("CLIENT_PLATFORM_SITE_FORM_SITE_DURATION_MORE"),
    };

    return durationOptions[durationVal] ? ` - ${durationOptions[durationVal]}` : "";
  };

  const renderTitle = () => {
    return (
      <div className="lead-view-popup__header-wrapper">
        <div key={lead.id} className="lead-view-popup__lead-item">
          <img
            className="lead-view-popup__lead-img"
            src={requestQuotationIcon}
            alt="Lead"
          />
          <div className="lead-view-popup__lead-details-wrapper">
            <div className="lead-view-popup__lead-status-wrapper">
              <div className="lead-view-popup__lead-title-wrapper">
                <span className="lead-view-popup__lead-name">
                  {lead?.product_name && lead.product_name !== "Unknown"
                    ? lead.product_name
                    : t("CLIENT_PLATFORM_REQUEST_TYPE_UNKNOWN")}
                </span>
              </div>
            </div>
            <p className="lead-view-popup__lead-description">
              <span>
                {lead.service_id === 1
                  ? t("CLIENT_PLATFORM_DASHBOARD_INQUIRIES_REMOVAL")
                  : t("CLIENT_PLATFORM_DASHBOARD_INQUIRIES_SUPPLY")}
                &nbsp;:
              </span>
              <span className="lead-view-popup__lead-category">
                {t(lead.category_name)} - {t(lead.sub_category_name)}
              </span>
            </p>
          </div>
        </div>
        <Button
          name={t("CLIENT_PLATFORM_LEAD_COMPLETE_BUTTON")}
          onClick={() => {
            const openingTimeLocal = lead.opening_time
              ? lead.opening_time.split(":")
              : [];
            const opening_time =
              openingTimeLocal.length >= 2
                ? `${openingTimeLocal[0]}:${openingTimeLocal[1]}`
                : "";
            const closingTimeLocal = lead.closing_time
              ? lead.closing_time.split(":")
              : [];
            const closing_time =
              closingTimeLocal.length >= 2
                ? `${closingTimeLocal[0]}:${closingTimeLocal[1]}`
                : "";
            const dataLocal = {
              name: {
                value: lead.name ? lead.name : "",
              },
              phase_id: {
                value: lead.phase_id ? lead.phase_id : "",
              },
              opening_time: {
                value: opening_time,
              },
              closing_time: {
                value: closing_time,
              },
              address: {
                value: lead.address ? lead.address : "",
              },
              city: {
                value: lead.city ? lead.city : "",
              },
              postal: {
                value: lead.postal ? lead.postal : "",
              },
              request_priority: {
                value: lead.request_priority ? lead.request_priority : "",
              },
              is_call_for_tender: {
                value: lead.is_call_for_tender ? lead.is_call_for_tender : 0,
              },
              user_comment: {
                value: lead.user_comment ? lead.user_comment : "",
              },
              start_date: {
                value: lead.start_date ? lead.start_date : "",
              },
              duration: {
                value: lead.duration ? lead.duration : "",
              },
              latitude: {
                value: lead.latitude ? lead.latitude : "",
              },
              longitude: {
                value: lead.longitude ? lead.longitude : "",
              },
              "other-info-comment": {
                value: lead.jobsite_comment ? lead.jobsite_comment : "",
              },
            };

            localStorage.setItem("isExistingJobsiteEdit", JSON.stringify(false));

            localStorage.setItem("jobsiteEditData", JSON.stringify(dataLocal));
            props.createSite({ lead_id: lead.id, isLead: true });
            history.push("/createjobsite");
          }}
          className="btn btn-complete-request"
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <section className="lead-view-popup__info-wrapper">
        <div className="lead-view-popup__request-info-wrapper">
          <h2 className="lead-view-popup__request-info-title">
            {t("CLIENT_PLATFORM_LEAD_ENQUIRY_TITLE")}
          </h2>
          <div className="lead-view-popup__request-details">
            <div className="lead-view-popup__request-detail-item">
              <span className="lead-view-popup__request-detail-item-label">
                {`${t("CLIENT_PLATFORM_LEAD_ENQUIRY_SERVICE")} : `}
              </span>
              <div className="lead-view-popup__request-detail-item-value">
                {lead.service === "DELIVERY" &&
                  t("CLIENT_PLATFORM_DASHBOARD_INQUIRIES_SUPPLY")}
                {lead.service === "EVACUATION" &&
                  t("CLIENT_PLATFORM_DASHBOARD_INQUIRIES_REMOVAL")}
              </div>
            </div>
            <div className="lead-view-popup__request-detail-item">
              <span className="lead-view-popup__request-detail-item-label">
                {`${t("CLIENT_PLATFORM_LEAD_ENQUIRY_CATEGORY")} : `}
              </span>
              <div className="lead-view-popup__request-detail-item-value">
                {`${t(lead.category_name)} - ${t(lead.sub_category_name)}`}
              </div>
            </div>
            <div className="lead-view-popup__request-detail-item">
              <span className="lead-view-popup__request-detail-item-label">
                {`${t("CLIENT_PLATFORM_LEAD_ENQUIRY_PRODUCT")} : `}
              </span>
              <div className="lead-view-popup__request-detail-item-value">
                {lead.product.length > 0 && lead.product[0].product?.name
                  ? lead.product[0].product.name
                  : t("CLIENT_PLATFORM_NO_PRODUCT")}
              </div>
            </div>
            <div className="lead-view-popup__request-detail-item">
              <span className="lead-view-popup__request-detail-item-label">
                {`${t("CLIENT_PLATFORM_LEAD_ENQUIRY_DATE_DURATION")} : `}
              </span>
              <div className="lead-view-popup__request-detail-item-value">
                {`${prepareShortDateFormat(lead.start_date)} ${renderDuration(
                  lead.duration
                )}`}
              </div>
            </div>
            <div className="lead-view-popup__request-detail-item">
              <span className="lead-view-popup__request-detail-item-label">
                {`${t("CLIENT_PLATFORM_LEAD_ENQUIRY_QUANTITY")} : `}
              </span>
              <div className="lead-view-popup__request-detail-item-value">
                {`${lead.quantity} ${t(lead.unit)}`}
              </div>
            </div>
            <div className="lead-view-popup__request-detail-item">
              <span className="lead-view-popup__request-detail-item-label">
                {`${t("CLIENT_PLATFORM_LEAD_ENQUIRY_COMMENT")} : `}
              </span>
              <div className="lead-view-popup__request-detail-item-value">
                {lead.request_comment}
              </div>
            </div>
          </div>
        </div>
        <div className="lead-view-popup__jobsite-details-wrapper">
          <div className="lead-view-popup__jobsite-info-wrapper">
            <h2 className="lead-view-popup__jobsite-info-title">
              {t("CLIENT_PLATFORM_LEAD_JOBSITE_TITLE")}
            </h2>
            <div>
              <div className="lead-view-popup__jobsite-detail-item">
                <span className="lead-view-popup__jobsite-detail-item-label">
                  {`${t("CLIENT_PLATFORM_LEAD_JOBSITE_ADDRESS")} : `}
                </span>
                <div className="lead-view-popup__jobsite-detail-item-value">
                  {lead.address ? lead.address : "-"}
                </div>
              </div>
              <div className="lead-view-popup__jobsite-detail-columns">
                <div className="lead-view-popup__jobsite-detail-item">
                  <span className="lead-view-popup__jobsite-detail-item-label">
                    {`${t("CLIENT_PLATFORM_LEAD_JOBSITE_POSTCODE")} : `}
                  </span>
                  <div className="lead-view-popup__jobsite-detail-item-value">
                    {lead.postal ? lead.postal : "-"}
                  </div>
                </div>
                <div className="lead-view-popup__jobsite-detail-item">
                  <span className="lead-view-popup__jobsite-detail-item-label">
                    {`${t("CLIENT_PLATFORM_LEAD_JOBSITE_CITY")} : `}
                  </span>
                  <div className="lead-view-popup__jobsite-detail-item-value">
                    {lead.city ? lead.city : "-"}
                  </div>
                </div>
              </div>
              <div className="lead-view-popup__jobsite-detail-item">
                <span className="lead-view-popup__jobsite-detail-item-label">
                  {`${t("CLIENT_PLATFORM_LEAD_JOBSITE_COMMENT")} : `}
                </span>
                <div className="lead-view-popup__jobsite-detail-item-value">
                  {lead.jobsite_comment}
                </div>
              </div>
            </div>
          </div>
          <div className="lead-view-popup__documents-wrapper">
            <Documents
              documents={lead.document}
              onError={onDocumentError}
              title={t("CLIENT_PLATFORM_LEAD_FILES")}
              noDocTitle={t(
                "CLIENT_PLATFORM_SITE_OVERVIEW_INFO_NO_DOCUMENTS_AVAILABLE"
              )}
              canDownload
            />
          </div>
        </div>
      </section>
    );
  };

  const closePopUp = () => {
    hideLead();
  };

  const onDocumentError = (error) => {
    setApiError(error);
    setErrorPopupvisibility(true);
  };

  const closeErrorPopup = () => {
    setApiError("");
    setErrorPopupvisibility(false);
  };

  return (
    <div>
      {showErrorPopup && (
        <ErrorPopUp
          title={t("KICK_OFF_ERROR_BOX_TITLE")}
          btnText={t("KICK_OFF_ERROR_BOX_OK")}
          message={apiError}
          onOkClick={closeErrorPopup}
        />
      )}
      <Popup
        modalParentClass="modal-parent lead-view-popup"
        modalClass="modal-box"
        title={renderTitle()}
        name="leadView"
        content={renderContent()}
        id="leadViewPopup"
        onClose={closePopUp}
      />
    </div>
  );
};

LeadViewPopup.propTypes = {
  lead: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  hideLead: PropTypes.func.isRequired,
  createSite: PropTypes.func.isRequired,
};

export default withTranslation()(LeadViewPopup);
