import React from "react";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import SectionHeader from "../../../../shared/components/SectionHeader";
import AddNew from "../../../../shared/components/AddNew";
import EmptyView from "../../../../shared/components/EmptyView";
import JobsiteTile from "../../../../shared/components/JobsiteTile";

import sitelogo from "../../../../assets/shared/site-icon.svg";

import "./MySites.scss";

const MySites = (props) => {
  const { sites, t } = props;
  const history = useHistory();

  const renderSites = () => {
    return (
      <ul className="dashboard-sites-list">
        {sites
          .filter((item, i) => i < 5)
          .map((item, i) => {
            return (
              <li
                key={item.id}
                onClick={() => {
                  props.viewJobSite(item.id);
                  history.push("/viewsite");
                }}
              >
                <JobsiteTile {...item} sitelogo={sitelogo} />
              </li>
            );
          })}
      </ul>
    );
  };

  return (
    <section className="my-sites">
      <SectionHeader
        title={t("CLIENT_PLATFORM_DASHBOARD_SITES_SECTION_TITLE")}
        link="/sitesandenquiries"
        viewAllTitle={t("CLIENT_PLATFORM_DASHBOARD_SITES_BUTTON_ALL_SITES")}
        isViewAllVisible={sites.length > 0}
      />
      {sites.length === 0 && (
        <EmptyView
          link="/createjobsite"
          description={t("CLIENT_PLATFORM_DASHBOARD_SITES_NO_SITE_DESCRIPTION")}
          title={t("CLIENT_PLATFORM_DASHBOARD_SITES_NO_SITE_TITLE")}
          icon={sitelogo}
          buttonName={t("CLIENT_PLATFORM_DASHBOARD_SITES_BUTTON_NEW_SITE")}
          isBtnVisible
          onBtnClick={() => {
            props.createSite();
          }}
        />
      )}
      {sites.length > 0 && renderSites()}
      {sites.length > 0 && (
        <AddNew
          link="/createjobsite"
          name={t("CLIENT_PLATFORM_DASHBOARD_SITE_LIST_BUTTON_NEW_SITE")}
          onAdd={() => {
            props.createSite();
          }}
        />
      )}
    </section>
  );
};

MySites.propTypes = {
  sites: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  viewJobSite: PropTypes.func.isRequired,
  createSite: PropTypes.func.isRequired,
};

export default withTranslation()(MySites);
