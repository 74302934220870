import React, { useRef } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import ContactDetailsForm from "../ContactDetailsForm";
import CustomRadioButton from "../../../shared/baseUI/CustomRadioButton";

import quantity1 from "../../../assets/shared/ic-quantity-0.svg";
import quantity1k from "../../../assets/shared/ic-quantity-1k.svg";
import quantity5k from "../../../assets/shared/ic-quantity-5k.svg";
import quantity20k from "../../../assets/shared/ic-quantity-20k.svg";

import "./ContactForm.scss";

const ContactForm = (props) => {
  const { fields, countryId, onFormUpdate, t } = props;
  const quantityRef = useRef();
  const titleRef = useRef();

  const clientTypes = [
    {
      name: t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_FORM_CLIENT_TYPE_COMPANY"
      ),
      image: "",
      id: "COMPANY",
      value: "COMPANY",
    },
    {
      name: t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_FORM_CLIENT_TYPE_INDIVIDUAL"
      ),
      image: "",
      id: "INDIVIDUAL",
      value: "INDIVIDUAL",
    },
  ];

  const wasteQuantityTypes = [
    {
      name: t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_EMAIL_FORM_WASTE_QUESTION_QUANTITY_1"
      ),
      image: quantity1,
      id: "1",
      value: "1",
    },
    {
      name: t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_EMAIL_FORM_WASTE_QUESTION_QUANTITY_2"
      ),
      image: quantity1k,
      id: "2",
      value: "2",
    },
    {
      name: t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_EMAIL_FORM_WASTE_QUESTION_QUANTITY_3"
      ),
      image: quantity5k,
      id: "3",
      value: "3",
    },
    {
      name: t(
        "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_EMAIL_FORM_WASTE_QUESTION_QUANTITY_4"
      ),
      image: quantity20k,
      id: "4",
      value: "4",
    },
  ];

  const onChange = (selectedOption) => {
    const { id, value } = selectedOption;
    if (id === "COMPANY" || id === "INDIVIDUAL") {
      setTimeout(() => {
        quantityRef?.current?.scrollIntoView?.({
          behavior: "smooth",
          block: "start",
        });
      }, 100);
      onFormUpdate({ ...fields, client_type: { value, error: "", required: true } });
    }
    if (id === "1" || id === "2" || id === "3" || id === "4") {
      titleRef?.current?.scrollIntoView?.({
        behavior: "smooth",
        block: "start",
      });

      onFormUpdate({
        ...fields,
        waste_quantity: { value, error: "", required: false },
      });
    }
  };

  return (
    <form className="v3-contact-form__container" id="lead-form">
      <CustomRadioButton
        data={clientTypes}
        label={t(
          "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_ENQUIRY_FORM_CLIENT_TYPE_TITLE"
        )}
        required
        name="client_type"
        optionsListClass="client-type-options-list"
        optionsContainerClass="client-type-options-container"
        onChange={onChange}
        selected={
          fields.client_type?.value
            ? { id: fields.client_type.value, value: fields.client_type.value }
            : {}
        }
        disabled={false}
      />
      {fields.client_type?.value && (
        <>
          <CustomRadioButton
            titleRef={quantityRef}
            data={wasteQuantityTypes}
            label={t(
              "CLIENT_PLATFORM_ONBOARDING_SIMPLIFIED_EMAIL_FORM_WASTE_QUESTION"
            )}
            optionsListClass="waste-quantity-options-list"
            optionsContainerClass="waste-quantity-options-container"
            name="waste_quantity"
            onChange={onChange}
            selected={
              fields.waste_quantity?.value
                ? {
                    id: fields.waste_quantity?.value,
                    value: fields.waste_quantity?.value,
                  }
                : {}
            }
            disabled={false}
          />
          <ContactDetailsForm
            titleRef={titleRef}
            fields={fields}
            countryId={countryId}
            onChange={onChange}
            onFormUpdate={onFormUpdate}
          />
        </>
      )}
    </form>
  );
};

ContactForm.propTypes = {
  countryId: PropTypes.number.isRequired,
  fields: PropTypes.shape({
    client_type: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.string,
      required: PropTypes.bool,
    }),
    waste_quantity: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.string,
      required: PropTypes.bool,
    }),
  }),
  onFormUpdate: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

ContactForm.defaultProps = {
  fields: {
    client_type: { value: null, required: true, error: "" },
    waste_quantity: { value: null, required: false, error: "" },
  },
};

export default withTranslation()(ContactForm);
