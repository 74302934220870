export const LOGINDATA = {
  LOADING: "LOGINDATA_LOADING",
  SUCCESS: "LOGINDATA_SUCCESS",
  ERROR: "LOGINDATA_FAILURE",
};

export const CHECK_EMAIL = {
  LOADING: "CHECK_EMAIL_LOADING",
  SUCCESS: "CHECK_EMAIL_SUCCESS",
  ERROR: "CHECK_EMAIL_FAILURE",
};

export const FORGOTPASSWORDDATA = {
  LOADING: "FORGOTPASSWORDDATA_LOADING",
  SUCCESS: "FORGOTPASSWORDDATA_SUCCESS",
  ERROR: "FORGOTPASSWORDDATA_FAILURE",
};

export const LOGOUTDATA = {
  LOADING: "LOGOUTDATA_LOADING",
  SUCCESS: "LOGOUTDATA_SUCCESS",
  ERROR: "LOGOUTDATA_FAILURE",
};

export const LANG_INFO = "LANG_INFO";
export const HANDLE_LANG = "HANDLE_LANG";
export const LOGIN_CREDENTIALS = "LOGIN_CREDENTIALS";
export const REGISTRATION_CREDENTIALS = "REGISTRATION_CREDENTIALS";
export const EMAIL_VALIDATION = "EMAIL_VALIDATION";
export const SHOW_POPUP = "SHOW_POPUP";
export const HIDE_POPUP = "HIDE_POPUP";
export const PASSWORD_VALIDATION = "PASSWORD_VALIDATION";
export const FORGOT_PASSWORD_CREDENTIALS = "FORGOT_PASSWORD_CREDENTIALS";
export const ONBOARDING_DETAILS = "ONBOARDING_DETAILS";
export const SELECT_COUNTRY_DATA = "SELECT_COUNTRY_DATA";

export const CHANGE_LANG = "CHANGE_LANG";
export const CHANGE_COUNTRY = "CHANGE_COUNTRY";
