import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import SectionHeader from "../../../../shared/components/SectionHeader";
import EmptyView from "../../../../shared/components/EmptyView";

import callOffOrderIcon from "../../../../assets/shared/call-off-order.svg";

import "./MyCallOfOrders.scss";

const MyCallOfOrders = (props) => {
  const { isButtonDisabled, t, callOfOrders } = props;

  return (
    <div className="my-calloforders">
      <SectionHeader
        title={t("CLIENT_PLATFORM_DASHBOARD_CALL_OFF_ORDERS_SECTION_TITLE")}
        link="/sitesandenquiries"
        viewAllTitle="My call-off orders"
        isViewAllVisible={callOfOrders.length > 0}
      />
      {callOfOrders.length === 0 && (
        <EmptyView
          link="/createcallofforder"
          description={t("CLIENT_PLATFORM_DASHBOARD_CALL_OFF_ORDERS_DESCRIPTION")}
          title={t("CLIENT_PLATFORM_DASHBOARD_CALL_OFF_ORDERS_TITLE")}
          icon={callOffOrderIcon}
          buttonName={t(
            "CLIENT_PLATFORM_DASHBOARD_CALL_OFF_ORDERS_BUTTON_NEW_ORDER"
          )}
          btnDisabled={isButtonDisabled}
          isBtnVisible
          onBtnClick={() => {
            props.createCalloffOrderForm();
          }}
        />
      )}
    </div>
  );
};

MyCallOfOrders.propTypes = {
  callOfOrders: PropTypes.array.isRequired,
  isButtonDisabled: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  createCalloffOrderForm: PropTypes.func.isRequired,
};

export default withTranslation()(MyCallOfOrders);
