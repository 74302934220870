import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import FileInput from "../FileInput";

const FileInputGroup = (props) => {
  const { data, labelNameData, deleteFile, fileGroupName, handleData, t } = props;

  const [uploadedDocs, setUploadedDocs] = useState([]);

  useEffect(() => {
    setUploadedDocs(data.document);
  }, []);

  const isValidFileType = (allowedFileTypes, file) => {
    return allowedFileTypes.includes(file.type);
  };

  const onFileChange = (e, type, allowedFileTypes) => {
    const { name } = e.target;

    const filesAllowed = Array.from(e.target.files).filter((x) =>
      isValidFileType(allowedFileTypes, x)
    );

    if (filesAllowed.length > 0) {
      const files = {
        id: "",
        type,
        file: filesAllowed,
      };
      const result = [files].map((x) => {
        return x.file.map((item) => {
          return {
            id: uuidv4(),
            type,
            file: item,
          };
        });
      });

      const updatedData = { ...data };
      let multipleFileUpload = [];
      let modifiedDocs = [...updatedData[name], ...result].map((docItem) => {
        if (Array.isArray(docItem)) {
          multipleFileUpload = docItem.map((doc) => doc);
          return true;
        }
        return docItem;
      });
      modifiedDocs = modifiedDocs.filter((docItem) => {
        return docItem !== true;
      });
      updatedData[name] = [...modifiedDocs, ...multipleFileUpload];
      setUploadedDocs(updatedData[name]);
      handleData({ updatedData });
    } else {
      // Show error message if file is of wrong format.
      const isFileAllowed = Array.from(e.target.files).filter(
        (x) => isValidFileType(allowedFileTypes, x) === false
      );
      if (isFileAllowed.length > 0) {
        // eslint-disable-next-line no-alert
        alert(t("CLIENT_PLATFORM_DOCUMENT_WRONG_FORMAT"));
      }
    }
  };

  const onFileDelete = (id, url) => {
    const updatedData = { ...data };
    updatedData.document = updatedData.document.filter((x) => x.id !== id);
    if (!updatedData.editData) {
      const isJobsiteDoc = updatedData.jobsite_document
        ? updatedData.jobsite_document.find((doc) => doc.id === id)
        : false;
      if (isJobsiteDoc) {
        updatedData.jobsite_document = updatedData.jobsite_document.filter(
          (doc) => doc.id !== id
        );
      }
      if (url !== "" && !isJobsiteDoc) {
        deleteFile(url);
      }
    } else if (url !== "") {
      deleteFile(url);
    }
    setUploadedDocs(updatedData.document);
    handleData({ updatedData });
  };

  return (
    labelNameData && (
      <div className="row">
        {labelNameData.map((item) => {
          return (
            <div
              className={
                fileGroupName === "request"
                  ? labelNameData.length > 1
                    ? "col-xs-12 col-sm-6 col-md-4 pr-0"
                    : "col-xs-12 col-sm-8 col-md-8"
                  : "col-xs-12 col-sm-6 col-md-6"
              }
              key={item.name + item.type}
            >
              <FileInput
                inputClass="d-none"
                divClass="form-field upload-field"
                labelClass="form-label"
                labelName={item.name}
                name="document"
                id={item.name}
                required={false}
                multiple
                accept={item.accept}
                tooltipMessage={item.tooltipMessage}
                onChange={(e) => onFileChange(e, item.type, item.allowedFileTypes)}
                data={
                  uploadedDocs
                    ? uploadedDocs.filter((x) => parseInt(x.type, 10) === item.type)
                    : []
                }
                onFileDelete={(id, url) => onFileDelete(id, url)}
              />
            </div>
          );
        })}
      </div>
    )
  );
};

FileInputGroup.propTypes = {
  data: PropTypes.shape({ document: PropTypes.arrayOf(PropTypes.object) })
    .isRequired,
  labelNameData: PropTypes.arrayOf(PropTypes.object).isRequired,
  fileGroupName: PropTypes.string.isRequired,
  deleteFile: PropTypes.func.isRequired,
  handleData: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(FileInputGroup);
