import React from "react";
import { Multiselect } from "react-widgets";
import { withTranslation } from "react-i18next";

const MultiSelectDropdown = ({
  divClass,
  labelClass,
  labelName,
  name,
  onChange,
  data,
  placeholder,
  defaultValue,
  error,
  errorMsg,
  t,
}) => {
  return (
    <div className={divClass}>
      <label className={labelClass}>{labelName}</label>
      {/* Default value can be set only once. ie first time when Multiselect component renders. 
      To set it again, required conditional rendering */}
      {defaultValue.length > 0 && (
        <Multiselect
          data={data}
          textField="label"
          valueField="id"
          name={name}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={(e) => onChange(e, name)}
          groupBy={(item) =>
            item.type === 1
              ? t("CLIENT_PLATFORM_REQUEST_FORM_TRANSPORT_TYPE_RIVER")
              : t("CLIENT_PLATFORM_REQUEST_FORM_TRANSPORT_TYPE_ROAD")
          }
        />
      )}
      {defaultValue.length === 0 && (
        <Multiselect
          data={data}
          textField="label"
          valueField="id"
          name={name}
          defaultValue={[]}
          placeholder={placeholder}
          onChange={(e) => onChange(e, name)}
          groupBy={(item) =>
            item.type === 1
              ? t("CLIENT_PLATFORM_REQUEST_FORM_TRANSPORT_TYPE_RIVER")
              : t("CLIENT_PLATFORM_REQUEST_FORM_TRANSPORT_TYPE_ROAD")
          }
        />
      )}
      {error && (
        <div
          className={
            name === "opening_time"
              ? "error-text text-danger w-100"
              : "error-text text-danger position-absolute w-100"
          }
        >
          {errorMsg}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(MultiSelectDropdown);
