import axios from "axios";
import * as moment from "moment";

import baseURL from "../../shared/utils/constant";

import { getStorageInfo } from "../../shared/utils/methods";

const axiosInstance = (token) => {
  return axios.create({
    baseURL,
    timeout: 36000,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getSiteMasterDataAPI = () => {
  const userData = getStorageInfo();
  return axiosInstance(userData.token)
    .get(`${baseURL}/sites/master/list`)
    .then((res) => res.data);
};

export const addNewJobSiteAPI = ({ token, data, form }) => {
  const formData = new FormData(form);

  formData.append(
    "is_call_for_tender",
    data.is_call_for_tender ? parseInt(data.is_call_for_tender, 10) : 0
  );
  formData.delete("end_date");
  formData.delete("start_date");

  const currentDate = moment(data.start_date);
  const durationArr =
    data.duration === "MORE" ? ["2", "YEAR"] : data.duration.split("_");
  const duration = durationArr[0];
  const durationUnit = durationArr[1] === "YEAR" ? "Y" : "M";
  const endDate = moment(currentDate)
    .add(parseInt(duration, 10), durationUnit)
    .format("YYYY-MM-DD");

  formData.append("end_date", endDate);
  formData.append("start_date", moment(data.start_date).format("YYYY-MM-DD"));
  formData.append("duration", data.duration);
  formData.append(
    "related_contact",
    data.related_contact ? JSON.stringify(data.related_contact) : []
  );
  formData.delete("request_priority");
  formData.append("request_priority", data.request_priority);
  formData.append(
    "client_comment",
    JSON.stringify({
      comment: data.user_comment ? data.user_comment : "",
      info: data["other-info-comment"] ? data["other-info-comment"] : "",
    })
  );
  formData.append("latitude", data.latitude);
  formData.append("longitude", data.longitude);
  formData.append("opening_time", data.opening_time);
  formData.append("closing_time", data.closing_time);
  formData.append("phase_id", data.phase_id);

  data.document.forEach((x) => {
    formData.append("document[][type]", x.type);
    formData.append("document[][file]", x.file);
  });
  if (data.leadId) {
    formData.append("lead_id", data.leadId);
  }
  formData.delete("document");

  return axios
    .post(`${baseURL}/sites`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
};

// for completing lead
export const addNewLeadAPI = ({ token, data, form }) => {
  const formData = new FormData(form);

  formData.append(
    "is_call_for_tender",
    data.is_call_for_tender ? parseInt(data.is_call_for_tender, 10) : 0
  );
  formData.delete("end_date");
  formData.delete("start_date");

  const currentDate = moment(data.start_date);
  const durationArr =
    data.duration === "MORE" ? ["2", "YEAR"] : data.duration.split("_");
  const duration = durationArr[0];
  const durationUnit = durationArr[1] === "YEAR" ? "Y" : "M";
  const endDate = moment(currentDate)
    .add(parseInt(duration, 10), durationUnit)
    .format("YYYY-MM-DD");

  formData.append("start_date", moment(data.start_date).format("YYYY-MM-DD"));
  formData.append("end_date", endDate);
  formData.append("duration", data.duration);
  formData.append(
    "related_contact",
    data.related_contact ? JSON.stringify(data.related_contact) : []
  );
  formData.delete("request_priority");
  formData.append("request_priority", data.request_priority);
  formData.append(
    "client_comment",
    JSON.stringify({
      comment: data.user_comment ? data.user_comment : "",
      info: data["other-info-comment"] ? data["other-info-comment"] : "",
    })
  );
  formData.append("latitude", data.latitude);
  formData.append("longitude", data.longitude);
  formData.append("opening_time", data.opening_time);
  formData.append("closing_time", data.closing_time);
  formData.append("phase_id", data.phase_id);

  data.document.forEach((x) => {
    formData.append("document[][type]", x.type);
    formData.append("document[][file]", x.file);
  });
  formData.append("lead_completed", 1);

  formData.delete("document");

  return axios
    .post(`${baseURL}/leads/${data.id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
};

export const updateJobSiteAPI = ({ token, data, form }) => {
  const formData = new FormData(form);
  formData.append(
    "is_call_for_tender",
    data.is_call_for_tender ? parseInt(data.is_call_for_tender, 10) : 0
  );
  formData.delete("end_date");
  formData.delete("start_date");
  formData.delete("request_priority");
  formData.delete("user_comment");
  formData.append("request_priority", parseInt(data.request_priority, 10));

  const currentDate = moment(data.start_date);
  const durationArr =
    data.duration === "MORE" ? ["2", "YEAR"] : data.duration.split("_");
  const duration = durationArr[0];
  const durationUnit = durationArr[1] === "YEAR" ? "Y" : "M";
  const endDate = moment(currentDate)
    .add(parseInt(duration, 10), durationUnit)
    .format("YYYY-MM-DD");

  formData.append("end_date", endDate);
  formData.append("duration", data.duration);
  formData.append("start_date", moment(data.start_date).format("YYYY-MM-DD"));
  formData.append("user_comment", data.user_comment ? data.user_comment : "");
  data.document.forEach((x) => {
    formData.append("document[][type]", x.type);
    formData.append("document[][file]", x.file);
  });
  formData.append("latitude", data.latitude);
  formData.append("longitude", data.longitude);
  formData.append("opening_time", data.opening_time);
  formData.append("closing_time", data.closing_time);
  formData.append("phase_id", data.phase_id);

  formData.delete("document");
  formData.delete("other-info-comment");
  formData.delete("comment");

  return axios
    .post(`${baseURL}/sites/${data.id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
};

export const updateLeadAPI = ({ token, data, form }) => {
  const formData = new FormData(form);
  formData.append(
    "is_call_for_tender",
    data.is_call_for_tender ? parseInt(data.is_call_for_tender, 10) : 0
  );
  formData.delete("end_date");
  formData.delete("request_priority");
  formData.delete("user_comment");
  formData.delete("start_date");

  formData.append("request_priority", parseInt(data.request_priority, 10));

  const currentDate = moment(data.start_date);
  const durationArr =
    data.duration === "MORE" ? ["2", "YEAR"] : data.duration.split("_");
  const duration = durationArr[0];
  const durationUnit = durationArr[1] === "YEAR" ? "Y" : "M";
  const endDate = moment(currentDate)
    .add(parseInt(duration, 10), durationUnit)
    .format("YYYY-MM-DD");

  formData.append("end_date", endDate);
  formData.append("start_date", moment(data.start_date).format("YYYY-MM-DD"));
  formData.append("duration", data.duration);
  formData.append("jobsite_comment", data.user_comment ? data.user_comment : "");
  data.document.forEach((x) => {
    formData.append("document[][type]", x.type);
    formData.append("document[][file]", x.file);
  });
  formData.append("latitude", data.latitude);
  formData.append("longitude", data.longitude);
  formData.append("lead_completed", 2);
  formData.append("opening_time", data.opening_time);
  formData.append("closing_time", data.closing_time);
  formData.append("phase_id", data.phase_id);

  formData.delete("document");
  formData.delete("other-info-comment");
  formData.delete("comment");

  return axios
    .post(`${baseURL}/leads/${data.id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
};

export const deleteDocumentAPI = (url) => {
  const userData = getStorageInfo();
  return axiosInstance(userData.token)
    .delete(url)
    .then((res) => res.data);
};
