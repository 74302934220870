import React, { useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { DateRange } from "react-date-range";

const DateRangePicker = (props) => {
  const { handleDate, editData, startDate, endDate } = props;

  const getDate = () => {
    const currentDate = new Date();
    if (currentDate.getHours() > 12) {
      currentDate.setDate(currentDate.getDate() + 2);
    } else {
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return currentDate;
  };

  const [state, setState] = useState([
    {
      startDate: editData ? new Date(startDate) : getDate(),
      endDate: editData ? new Date(endDate) : getDate(),
      key: "selection",
    },
  ]);

  return (
    <DateRange
      editableDateInputs
      onChange={(item) => {
        setState([item.selection]);
        handleDate(item.selection);
      }}
      moveRangeOnFirstSelection={false}
      ranges={state}
      endDatePlaceholder="End-Date"
      minDate={getDate()}
    />
  );
};

export default DateRangePicker;
