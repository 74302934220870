import { takeLatest, put, call } from "redux-saga/effects";

import {
  GET_SITE_LISTING_DATA,
  GET_SITE_LISTING_INACTIVE_DATA,
} from "./SitesList.constants";

import {
  getSiteListingDataAPI,
  getSiteListingInactiveDataAPI,
} from "./SitesList.api";

function* getSiteListingData(action) {
  let { payload } = action;
  if (!payload === undefined) {
    payload = {
      pageNum: payload.pageNum,
    };
  }

  try {
    const data = yield call(getSiteListingDataAPI, payload);
    yield put({ type: GET_SITE_LISTING_DATA.SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_SITE_LISTING_DATA.ERROR, error });
  }
}

function* getSiteListingInactiveData(action) {
  let { payload } = action;
  if (!payload === undefined) {
    payload = {
      pageNum: payload.pageNum,
    };
  }

  try {
    const data = yield call(getSiteListingInactiveDataAPI, payload);
    yield put({ type: GET_SITE_LISTING_INACTIVE_DATA.SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_SITE_LISTING_INACTIVE_DATA.ERROR, error });
  }
}

export function* getSiteListingDataSaga() {
  yield takeLatest(GET_SITE_LISTING_DATA.LOADING, getSiteListingData);
}

export function* getSiteListingInactiveDataSaga() {
  yield takeLatest(
    GET_SITE_LISTING_INACTIVE_DATA.LOADING,
    getSiteListingInactiveData
  );
}
