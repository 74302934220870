import {
  GET_USERACCOUNT_DATA,
  MY_ACCOUNT_DETAILS,
  UPDATE_MYACCOUNT_DATA,
  UPDATE_PASSWORD_CREDENTIALS,
  UPDATE_PASSWORD_DATA,
  HANDLE_POPUP,
  CLEAR_UPDATE_PASSWORD_FORM,
} from "./MyAccount.constants";

export const getUserAccountData = (payload) => ({
  type: GET_USERACCOUNT_DATA.LOADING,
  payload,
});

export const handleMyAccountDetails = (payload) => ({
  type: MY_ACCOUNT_DETAILS,
  payload,
});

export const submitMyAccountData = (payload) => ({
  type: UPDATE_MYACCOUNT_DATA.LOADING,
  payload,
});

export const handleUpdatePasswordDetails = (payload) => ({
  type: UPDATE_PASSWORD_CREDENTIALS,
  payload,
});

export const submitUpdatePasswordData = (payload) => ({
  type: UPDATE_PASSWORD_DATA.LOADING,
  payload,
});

export const handlePopUp = () => ({
  type: HANDLE_POPUP,
});

export const clearUpdatePasswordForm = () => ({
  type: CLEAR_UPDATE_PASSWORD_FORM,
});
