import axios from "axios";

import baseURL from "../../shared/utils/constant";

import { getStorageInfo } from "../../shared/utils/methods";

const axiosInstance = (token) => {
  return axios.create({
    baseURL,
    timeout: 36000,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createTransportRequestAPI = (data) => {
  const userData = getStorageInfo();
  return axiosInstance(userData.token)
    .post(`${baseURL}/transports/requests`, data)
    .then((res) => res.data);
};

export const updateTransportRequestAPI = (data) => {
  const userData = getStorageInfo();
  return axiosInstance(userData.token)
    .post(`${baseURL}/transports/update/${data.id}`, data)
    .then((res) => res.data);
};
