import {
  LANG_INFO,
  HANDLE_LANG,
  LOGINDATA,
  LOGIN_CREDENTIALS,
  CHECK_EMAIL,
  REGISTRATION_CREDENTIALS,
  EMAIL_VALIDATION,
  PASSWORD_VALIDATION,
  FORGOT_PASSWORD_CREDENTIALS,
  FORGOTPASSWORDDATA,
  ONBOARDING_DETAILS,
  HIDE_POPUP,
  LOGOUTDATA,
  CHANGE_LANG,
  CHANGE_COUNTRY,
} from "./constant";
import { INIT, LOADING, SUCCESS, ERROR } from "../../shared/utils/constant";

export const initialState = {
  phase: INIT,
  errorObj: {
    success: false,
    message: "",
  },
  loginData: {
    email: "",
    password: "",
    rememberme: "",
  },
  loginStatus: false,
  loginUserId: "",
  registrationData: {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    acceptance: false,
  },
  forgotPasswordData: {
    email: "",
  },
  showPopup: false,
  forgotPasswordStatus: {
    code: "",
    message: "",
  },
  isLangChanged: {
    id: "",
    updated: false,
  },
  isLangUpdated: null,
  emailError: "",
  isEmailExists: null,
  passwordError: false,
  loginDisabledBtn: true,
  selectedCountryId: "",
  logoutStatus: false,
};

export const loginStore = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_LANG: {
      return {
        ...state,
        isLangChanged: {
          id: action.payload,
          updated: true,
        },
        selectedCountryId: parseInt(action.payload, 10),
      };
    }

    case CHANGE_LANG: {
      localStorage.setItem("isLangUpdated", action.payload);
      return {
        ...state,
        isLangUpdated: action.payload,
      };
    }

    case CHANGE_COUNTRY: {
      localStorage.setItem("userCountryId", action.payload);
      return {
        ...state,
        countryId: action.payload,
      };
    }

    case LOGINDATA.LOADING:
    case CHECK_EMAIL.LOADING:
    case FORGOTPASSWORDDATA.LOADING:
    case LOGOUTDATA.LOADING:
      return {
        ...state,
        phase: LOADING,
      };

    case LOGINDATA.SUCCESS: {
      const data = { ...action.data };

      const errorObj = {
        success: false,
        message: "",
      };

      const userData = {
        email: data.user.email,
        password: data.user.password,
      };

      localStorage.setItem("token", data.token.token);
      localStorage.setItem("userId", data.user.id);
      localStorage.setItem("userRoleId", data.user.role_id);
      localStorage.setItem("countryId", data.user.country.id);
      localStorage.setItem("userInfo", JSON.stringify(data.user));
      localStorage.setItem("loginStatus", data.success);
      localStorage.setItem("userCountry", JSON.stringify(data.user.country));
      localStorage.setItem("userCountryId", data.user.country.id);
      let lngFlag = "fr";
      if (data.user.country.id === 2) {
        lngFlag = "fr";
      } else if (data.user.country.id === 3) {
        lngFlag = "pl";
      } else if (data.user.country.id === 8) {
        lngFlag = "ch";
      } else if (data.user.country.id === 1) {
        lngFlag = "en";
      }
      localStorage.setItem("langFlag", lngFlag);

      return {
        ...state,
        phase: SUCCESS,
        loginData: userData,
        loginUserId: data.user.id,
        userRoleId: data.user.role_id,
        selectedCountryId: parseInt(data.user.country.id, 10),
        loginStatus: data.success,
        logoutStatus: !action.data.success,
        errorObj,
      };
    }
    case LOGINDATA.ERROR: {
      const response = action.error.response.data;
      return {
        ...state,
        phase: ERROR,
        errorObj: response,
        status: action.error.response.status,
        showPopup: action.error.response.status === 403,
      };
    }

    case LOGIN_CREDENTIALS: {
      const data = action.payload.logindata;
      const btnCheck = !(data.email && data.password !== "");
      return {
        ...state,
        loginData: action.payload.logindata,
        loginDisabledBtn: btnCheck,
      };
    }
    case REGISTRATION_CREDENTIALS: {
      return {
        ...state,
        registrationData: action.payload.registrationdata,
      };
    }
    case EMAIL_VALIDATION: {
      const emailValue = action.payload.value;
      const checkEmail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const checkEmailValid = !checkEmail.test(emailValue);

      return {
        ...state,
        emailError: checkEmailValid,
        isEmailExists: checkEmailValid ? null : action.payload.emailExists,
      };
    }
    case CHECK_EMAIL.SUCCESS: {
      return {
        ...state,
        phase: SUCCESS,
        isEmailExists: action.data === "" ? 200 : null,
        showPopup: true,
      };
    }
    case CHECK_EMAIL.ERROR: {
      return {
        ...state,
        phase: ERROR,
        isEmailExists: action.error.response.status,
      };
    }
    case HIDE_POPUP: {
      return {
        ...state,
        showPopup: false,
      };
    }
    case PASSWORD_VALIDATION: {
      const passwordValue = action.payload;
      const passwordValid = !(passwordValue.length >= 6);

      return {
        ...state,
        passwordError: passwordValid,
      };
    }
    case FORGOT_PASSWORD_CREDENTIALS: {
      return {
        ...state,
        forgotPasswordData: action.payload.forgotpassworddata,
      };
    }
    case FORGOTPASSWORDDATA.SUCCESS: {
      return {
        ...state,
        phase: SUCCESS,
        forgotPasswordData: action.data,
        showPopup: true,
        forgotPasswordStatus: {
          code: "",
          message: "",
        },
      };
    }
    case FORGOTPASSWORDDATA.ERROR: {
      return {
        ...state,
        showPopup: true,
        forgotPasswordStatus: {
          code: action.error.response.status,
          message: action.error.response.data.message,
        },
      };
    }

    case LOGOUTDATA.SUCCESS: {
      window.localStorage.clear();

      return {
        ...state,
        phase: SUCCESS,
        loginStatus: false,
        loginUserId: "",
        selectedCountryId: "",
        isLangUpdated: "",
        countryId: "",
        loginData: {
          email: "",
          password: "",
        },
      };
    }
    case LOGOUTDATA.ERROR: {
      return {
        ...state,
        phase: ERROR,
        logoutStatus: action.data?.success,
      };
    }
    default:
      return state;
  }
};

// Action creator --
export const getLangInfo = () => ({
  type: LANG_INFO,
});

export const handleLang = (payload) => ({
  type: HANDLE_LANG,
  payload,
});

export const changeLang = (payload) => ({
  type: CHANGE_LANG,
  payload,
});

export const changeCountry = (payload) => ({
  type: CHANGE_COUNTRY,
  payload,
});

export const loginData = (payload) => ({
  type: LOGINDATA.LOADING,
  payload,
});

export const handleLoginDetails = (payload) => ({
  type: LOGIN_CREDENTIALS,
  payload,
});

export const handleRegistrationDetails = (payload) => ({
  type: REGISTRATION_CREDENTIALS,
  payload,
});

export const checkIsEmailExists = (payload) => ({
  type: CHECK_EMAIL.LOADING,
  payload,
});

export const handlePopUp = () => ({
  type: HIDE_POPUP,
});

export const checkEmailValidation = (payload) => ({
  type: EMAIL_VALIDATION,
  payload,
});

export const checkPasswordValidation = (payload) => ({
  type: PASSWORD_VALIDATION,
  payload,
});

export const handleForgotPasswordData = (payload) => ({
  type: FORGOT_PASSWORD_CREDENTIALS,
  payload,
});

export const forgotPasswordData = (payload) => ({
  type: FORGOTPASSWORDDATA.LOADING,
  payload,
});

export const handleOnBoardingDetails = (payload) => ({
  type: ONBOARDING_DETAILS,
  payload,
});

export const handleLogOut = (payload) => ({
  type: LOGOUTDATA.LOADING,
  payload,
});
