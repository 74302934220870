import React from "react";
import axios from "axios";
import { withTranslation } from "react-i18next";

import baseURL from "../../utils/constant";

import "./Documents.scss";

const Documents = (props) => {
  const { t, documents, title, canDownload } = props;
  const axiosInstance = (token) => {
    return axios.create({
      baseURL,
      timeout: 36000,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const downloadPDFDoc = (id) => {
    axiosInstance(localStorage.getItem("token"))
      .get(`${baseURL}/documents/download/${id}`)
      .then((res) => {
        const { fileName } = res.data;
        fetch(res.data.data)
          .then((resp) => resp.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .catch((err) => {
            props.onError(err.response?.data?.message);
          });
      })
      .catch((err) => {
        props.onError(err.response?.data?.message);
      });
  };

  const getDocument = (documentsList, type) => {
    return (
      <>
        {documentsList.map((x, i) => {
          if (x.type === type) {
            return (
              <li key={i}>
                <i
                  className="fa fa-file-pdf-o pr-2 text-danger"
                  aria-hidden="true"
                />
                {canDownload && (
                  <div className="link" onClick={() => downloadPDFDoc(x.id)}>
                    {x.filename}
                  </div>
                )}
                {!canDownload && (
                  <a
                    href={x.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                    className="link"
                  >
                    {x.filename}
                  </a>
                )}
              </li>
            );
          }
          return "";
        })}
      </>
    );
  };

  const renderDocuments = (documentsList) => {
    let type1;
    let type2;
    let type3;
    let type4 = false;
    documentsList.forEach((doc) => {
      if (doc.type === 1) {
        type1 = true;
      } else if (doc.type === 2) {
        type2 = true;
      } else if (doc.type === 3) {
        type3 = true;
      } else if (doc.type === 4) {
        type4 = true;
      }
    });

    return (
      <div className="documents-list">
        {type1 && (
          <ul>
            <li>
              <h2 className="document-title">{t("CHEMICAL_ANALYSIS")}</h2>
            </li>
            {getDocument(documentsList, 1)}
          </ul>
        )}
        {type2 && (
          <ul>
            <li>
              <h2 className="document-title">{t("ANALYSE_GTR")}</h2>
            </li>
            {getDocument(documentsList, 2)}
          </ul>
        )}
        {type3 && (
          <ul>
            <li>
              <h2 className="document-title">{t("OTHER_DOCUMENT")}</h2>
            </li>
            {getDocument(documentsList, 3)}
          </ul>
        )}
        {type4 && (
          <ul>
            <li>
              <h2 className="document-title">{t("SPECIFICATIONS")}</h2>
            </li>
            {getDocument(documentsList, 4)}
          </ul>
        )}
      </div>
    );
  };

  return (
    <>
      <h2 className="documents-title">{`${title} ${
        documents && documents.length > 0 ? ` (${documents.length})` : ""
      }`}</h2>
      {documents.length > 0 ? (
        renderDocuments(documents)
      ) : (
        <p className="no-documents">{props.noDocTitle}</p>
      )}
    </>
  );
};

export default withTranslation()(Documents);
