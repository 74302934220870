import { takeLatest, put, call } from "redux-saga/effects";

import { DASHBOARDATA, LEADS } from "./Dashboard.constants";

import { getDashboardDataAPI, getDashboardLeadsAPI } from "./Dashboard.api";

// Worker sagas
function* getDashboardData(action) {
  try {
    const data = yield call(getDashboardDataAPI, action.payload);
    yield put({ type: DASHBOARDATA.SUCCESS, data });
  } catch (error) {
    yield put({ type: DASHBOARDATA.ERROR, error });
  }
}

function* getDashboardLeads(action) {
  try {
    const data = yield call(getDashboardLeadsAPI, action.payload);
    yield put({ type: LEADS.SUCCESS, data });
  } catch (error) {
    yield put({ type: LEADS.ERROR, error });
  }
}

// Watcher Sagas
export function* getDashboardDataSaga() {
  yield takeLatest(DASHBOARDATA.LOADING, getDashboardData);
}

export function* getDashboardLeadsSaga() {
  yield takeLatest(LEADS.LOADING, getDashboardLeads);
}
